import { reactive } from '@vue/reactivity'
import {API, LQ, model} from "../model"
import {ICON} from "../ui/icons";
import {Spinner} from "../ui/Spinner";
import {t} from "../i18n";
import {Box} from "../ui/Grid";
import {Sidebar} from "../ui/Sidebar";
import './Ioc.scss'
import {Description} from "../ui/Description";
import {H1, H3} from "../ui/H";
import {i18n} from "../ui/dates";
import {Tags} from "../ui/Tags";
import {capitalize} from "../util";
import {Badge} from "../ui/Badge";
import { Switch } from "../ui/Switch";
import { Button, IconButton } from '../ui/Button'
import { DataTable } from "../ui/DataTable"
import { Link } from "../utils/router"
import { link } from "../utils/routing"
import { D_FROM_TO } from "../ui/dates"
import { AlertStatus } from "./Alert"
import { Severity } from "../ui/Severity"
import { useCurrentUserStore } from '../store/CurrentUserStore';

export const Ioc = {
    setup() {
        const userStore = useCurrentUserStore
        const data = reactive({
            refs_editing: false
        })

        async function setDescription(d) {
            await API.setIOC(model.ioc.ioc, {report_description:d})
            LQ.ioc.refresh()
        }

        async function setReferences(r) {
            var rr = r.split("\n")
            await API.setIOC(model.ioc.ioc, {external_refs:rr})
            LQ.ioc.refresh()
        }

        async function addTag(tag) {
            await API.addIOCTag(model.ioc.ioc, tag)
            LQ.ioc.refresh()
        }

        async function createTag(tag) {
            await API.addIOCTag(model.ioc.ioc, tag)
            LQ.ioc.refresh()
        }

        async function deleteTag(tag) {
            await API.deleteIOCTag(model.ioc.ioc, tag)
            LQ.ioc.refresh()
        }

        async function setConfidence(c) {
            await API.setIOC(model.ioc.ioc, {confidence:parseInt(c)})
            LQ.ioc.refresh()
        }

        const toggleWhitelisted = () => model.ioc.details.tags?.includes("whitelist") ? deleteTag("whitelist") : addTag("whitelist")

        return () => {
            if (!model.ioc) return <Spinner/>

            const whitelisted = model.ioc.details.tags?.includes("whitelist")

            const level = model.ioc.details.confidence < 25 ? 'low' :
                model.ioc.details.confidence < 50 ? 'medium' :
                    model.ioc.details.confidence < 75 ? 'high' : 'full'

            return <div id="ioc" class="page with-sidebar">
                <div>
                    <h1>
                        <div className="flex">
                            <div className="title-wrapper">
                                {ICON("ioc")}
                                <H1 class="title" class={{small:model.ioc.ioc.length>20}} value={model.ioc.ioc}/>
                            </div>
                        </div>
                        <div className="tlp">
                            <span>TLP : <Badge
                                class={model.ioc.details.tlp?.replace(/\s+/g, '')}>{capitalize(model.ioc.details.tlp || "unknown")}</Badge></span>
                        </div>
                    </h1>
                    <div class="flex-row">
                        <Tags tags={model.ioc.details.tags?.filter(t=>t!=="whitelist")}
                            editable
                            onAdd={addTag}
                            onCreate={createTag}
                            onDelete={deleteTag}
                            allowed_tags={[]}
                        />
                        <Switch value={whitelisted} onClick={toggleWhitelisted}>Whitelist</Switch>
                    </div>
                    <Box class='description-box'>
                        <h2>{t('Last report')}</h2>
                        <p>
                            <strong>{i18n(model.ioc.details.last_report, true, false, true)} : </strong>
                            {model.ioc.details.report_name}
                        </p>
                    </Box>
                    <Box class='description-box'>
                        <Description
                            title="Description"
                            source={model.ioc.details.report_description}
                            editable
                            onSave={setDescription}
                        />
                    </Box>
                    <Box class="description-box references">
                        <b>{t("References")}<IconButton onClick={()=>data.refs_editing=true}>{ICON("edit")}</IconButton></b>
                        {
                            data.refs_editing ? 
                                <><textarea  onInput={e => (data.inputValue = e.target.value)} >{model.ioc?.details?.external_refs?.map(r => r + "\n")}</textarea> 
                                <div class="controls"><Button onClick={()=>{setReferences(data.inputValue);data.refs_editing=false}}>{t("Save")}</Button>
                                <Button secondary onClick={()=>data.refs_editing=false}>{t("Cancel")}</Button></div></>
                                :
                                model.ioc?.details?.external_refs && <ul>
                                    {model.ioc?.details?.external_refs.map(r => <li><a href={r} target='_blank'>
                                        {r}
                                    </a></li>)}
                                </ul>
                        }
                    </Box>
                    
                    {userStore.hasPermissions("iocs", "write_private") && model.ioc?.details.alerts_count>0 && <Box class="description-box">
                        <b>{t("Alerts")}</b>
                        <DataTable class="ioc_alerts"
                        noSearch noToolbar
                        data={model.ioc.alert_groups.items}
                        columns={[
                            {title:t("Detection"), type:"detection-dates", render:x=>D_FROM_TO(x.first_detection, x.last_detection)},
                            ...(model.scope ? [] : [{title:t("Scope"), type:"scope", render:ScopeItem}]),
                            {title:t('Qualification'), type:"status", render:x=><AlertStatus horizontal alert={x}/>},
                            {title:t('Message'), type:"description", render:x=><div>
                                <Link href={link(`/scope/${x.scope_id}/alert/${x.id}`)}>
                                    {(x.message || x.alerts_count>1) && <>
                                        <span>{x.message}&nbsp;{x.alerts_count>1 && <Badge>{ICON("alert")} {x.alerts_count}</Badge>}</span> 
                                    </>}
                                </Link>
                                {x.case && <><br/><span class="case">
                                    {ICON("case")} &nbsp;
                                    <Link href={link(`/scope/${x.scope_id}/case/${x.case.id}`)}>
                                            {x.case.title}
                                    </Link>
                                    </span>
                                </>}
                            </div>},
                            {title:t('Source'), type:"field", render:x=>x.source},
                            {title:t('Destination'), type:"field", render:x=>x.destination},
                            {title:t('Severity'), render:x=><Severity val={x.severity}/>},
                            {title:t('Tags'), type:"tags", noLink:true, render:a=>Tags({...a, tags:a.tags.filter(t=>!t.startsWith("attack."))})},
                        ]}
                    /></Box>}
                    
                </div>
                <Sidebar>
                    <Box class={'confidence'}>
                        <h2>{t('Confidence')}</h2>
                        <Box class={'score ' + level}>
                            <H3 value={model.ioc?.details?.confidence} onSave={x=>setConfidence(x)}/>
                        </Box>
                    </Box>
                    <Box>
                        <h2>{t('Authors')}</h2>
                        {model.ioc?.details?.authors && <ul>
                            {model.ioc?.details?.authors.map(r => <li>{r}</li>)}
                        </ul>}
                    </Box>
                    <Box>
                        <h2>{t('Sources')}</h2>
                        {model.ioc?.details?.src && <ul>
                            {model.ioc?.details?.src.map(r => <li>{r}</li>)}
                        </ul>}
                    </Box>
                    <Box>
                        <h2>{t('Potential malwares')}</h2>
                        {model.ioc.details?.potential_malware && <ul>
                            {model.ioc.details.potential_malware.map(v =><li>{v}</li>)}
                        </ul>}
                    </Box>
                    <Box>
                        <h2>{t('Potential actors')}</h2>
                        {model.ioc.details?.potential_actor && <ul>
                            {model.ioc.details.potential_actor.map(v => <li>{v}</li>)}
                        </ul>}
                    </Box>
                </Sidebar>
            </div>
        }
    }
}

export const ScopeItem = ({scope_id})=><div class="scope-item">
    <img src={model.scopes?.find(s=>s.id===scope_id)?.logo}/>
    <Link href={`scope/${scope_id}/iocs` + location.search}>{model.scopes?.find(s=>s.id===scope_id)?.display_name}</Link>
</div>