import "./Diags.scss"
import { goTo, link } from "../utils/routing"
import { model } from "../model"
import { Link } from "../utils/router"
import { Badge } from "../ui/Badge"
import { DataTable } from "../ui/DataTable"
import { LiveQuery } from "../api/livequery"
import { optionsFromQueryParams, queryParams, setQueryParams } from "../utils/queryParams"
import { t, t_fem } from "../i18n"
import { formatDate, STATUS } from "../api/mock/util"
import { Avatar } from "../ui/Avatar"
import { Button } from "../ui/Button"
import { Tab, Tabs } from "../ui/Tabs"
import { reactive } from "vue"
import { ScopesFilter } from "../ui/ScopesFilter"
import { useCurrentUserStore } from "../store/CurrentUserStore"

export const Diags = {
  setup(){
    const userStore = useCurrentUserStore
    const data = reactive({
      filteredScopes: new Set(userStore.user?.settings?.filteredScopes || []),
      filter_by_status: {},
    })

    let lq_diags_by_status = null
    lq_diags_by_status = LiveQuery("diags_by_status", "", a=>data.filter_by_status = a,
    ()=>[{
      ...data.interval,
    }], 
      {subscription:"diags", throttle:5000, interval: 5000}
    )
    
    const lq = LiveQuery("diags", `{
      pages items {id name steps, scope_id start_at end_at status scope {display_name} related_users {id, display_name}}
    }`, 
        s=>{
          model.diags=s || s
        }, 
        ()=>!!userStore.user && [{
          limit:parseInt(queryParams().limit) || 20,
          ...optionsFromQueryParams(),
          not_scope_ids: model.scopeId ? [] : (userStore.user.settings?.filteredScopes || []),
          status: queryParams().status==="all" ? ["Not started", "In progress", "Finished"] : queryParams().status?.split(","),
         }], 
        { interval: 30000 },
    )

    function getUser(diag) {
      return diag.related_users.map(x => {
        return <Avatar user={x} />
      })
    }

    return () => {
      const tab = queryParams().status || "all"

      return <div className="diags">
        <div className="header">
            <h1>Audits</h1>
            <Button onClick={() => goTo("/newaudit")}>New Diag</Button>
        </div>
        <Tabs>
            <Tab active={tab==="all"} onClick={()=>setQueryParams({status:"all"})} label={<span>{t_fem("All", {nb:data.all})} <Badge>{(data.filter_by_status?.not_started||0) + (data.filter_by_status?.in_progress||0) + (data.filter_by_status?.Finished||0)}</Badge></span>}/>
            <Tab active={tab==="Not started"} onClick={()=>setQueryParams({status:"Not started"})} label={<span>{t_fem("Not started", {nb:data.filter_by_status?.not_started})} <Badge>{data.filter_by_status?.not_started||0}</Badge></span>}/>
            <Tab active={tab==="In progress"} onClick={()=>setQueryParams({status:"In progress"})} label={<span>{t_fem("In progress", {nb:data.filter_by_status?.in_progress})} <Badge>{data.filter_by_status?.in_progress||0}</Badge></span>}/>
            <Tab active={tab==="Finished"} onClick={()=>setQueryParams({status:"Finished"})} label={<span>{t_fem("Finished", {nb:data.filter_by_status?.Finished})} <Badge>{data.filter_by_status?.Finished ||0}</Badge></span>}/>
        </Tabs>
        <ScopesFilter value={data.filteredScopes} onChange={x=>data.filteredScopes=x}/>
        <div>
          <div className="diags-grid">
            <DataTable
                loading={lq.loading}
                data={model.diags}
                columns={[
                  {title:"ID", field:"id", render: x=><div>{x.id}</div>},
                  {title:"Name", field:"name", render: x=><div><Link href={link(`scope/${x.scope_id}/audit/${x.id}`)}>{x.name}</Link></div>},
                  {title:t("Start"), field:"start", render: x=><div>{formatDate({timestamp:new Date(x.start_at), isGlobalDates:true, lang:model.lang})}</div>},
                  {title:t("End"), field:"finish", render: x=><div>{formatDate({timestamp:new Date(x.end_at), isGlobalDates:true, lang:model.lang})}</div>},
                  {title:t("Scope"), field:"scope", render: x=><div><Link href={link(`scope/${x.scope_id}`)}>{x.scope?.display_name}</Link></div>},
                  {title:t("Status"), field:"status", render: x=><div class={`badge ${STATUS[x.status]}`}>{t(x.status)}</div>},
                  {title:t("Assigned"),type:"users", render:x=>getUser(x)},
                ]}
            />
          </div>
        </div>
      </div>
    };
  }
}