import { model, API } from '../model'
import { reactive } from '@vue/reactivity'
import { Avatar } from '../ui/Avatar'
import { Form } from '../ui/Form'
import { validateEmail } from '../util'
import { t } from '../i18n'
import './Profile.scss'
import {Link} from "../utils/router";
import {link} from "../utils/routing";
import {DataTable} from "../ui/DataTable";
import { watchEffect } from "@vue/runtime-core"
import { Dropdown, DropdownItem } from '../ui/Dropdown'
import { Spinner } from '../ui/Spinner'
import { NotFound } from '../ui/NotFound'
import { useCurrentUserStore } from '../store/CurrentUserStore'

const notifications_status = ["All", "None", "Only my notifications", "All except my notifications"]
const notifications_status2 = ["All", "None"]

export const Profile = {
    setup() {
        const userStore = useCurrentUserStore
        const data = reactive({
            notifications: []
        })
        watchEffect(async () => data.notifications = await API.getNotificationsSettings())
        // const notificationsSettings = async () => {await  API.getNotificationsSettings()}
        function save(x) { API.setUser(userStore.user.id, x) }
        function getNotificationStatus(name){
            var notif = data.notifications.find(e => e.description == name )
            if(!notif){
                return "All"
            }
            else{
                return(notif.settings)
            }
        }

        function setNotification(x, status){
            var notif = data.notifications.find(e => e.description == x.name )
            if(!notif){
                notif = {}
                notif.description = x.name
                data.notifications.push(notif)
            }
            notif.settings = status
            const { description, settings } = notif
            API.setNotificationsSettings(description, settings, userStore.user.id)
        }

        return ()=>{
        if(data.notifications == []) return <Spinner/>
        
        return <div id="profile">
            <h1><Avatar user={userStore.user}/> {userStore.user.display_name}</h1>
            <Form object={userStore.user} fields={{
                display_name:{name:t("Name")},
                email:{validate:validateEmail},
                avatar:{type: "image"},
            }} onSave={save}>
            </Form>

            <h1>Configuration des notifications mails</h1>
            <DataTable
                noSearch
                data={[
                    {name: 'new case', type: t("New case"), status: getNotificationStatus('new case')},
                    {name: 'case visibility edited', type: t("Case visibility edited"),status: getNotificationStatus('case visibility edited')},
                    {name: 'new client comment', type: t("New client comment"), status: getNotificationStatus('new client comment')},
                    {name: 'new critical alert', type: t("New critical alert"), status: getNotificationStatus('new critical alert')}
                ]}
                columns={[
                    {title: "Type de notification", render:x=>x.type},
                    {title: "Réglages", type:"status", noLink:true, render: x =>
                            <Dropdown class='status' button={t(x.status)} items={()=>
                                 x.name == 'new case' ?
                                    notifications_status.map(r=><DropdownItem onClick={()=>setNotification(x, r)}>{t(r)}</DropdownItem>) :
                                    notifications_status2.map(r=><DropdownItem onClick={()=>setNotification(x, r)}>{t(r)}</DropdownItem>)
                            }/>
                    },
                ]}
            
            />
            <h1>Mes scopes</h1>

            <DataTable
                noSearch
                data={model.scopes}
                columns={[
                    {title:t('Logo'), type:'logo', render:x=><img src={x.logo} alt=''/>},
                    {title:t('Name'), type:'name', render:x=><Link href={link(`/scope/${x.id}`)}>{x.display_name}</Link>},
                    {title:t('Role'), type:"role", render:x=>t(x.role)},
                ]}
            />
        </div>}
    }
}