import { reactive, onMounted } from "@vue/runtime-core";
import { LiveQuery } from "../../api/livequery";
import { model } from "../../model";
import { Donut } from "../../ui/Donut";
import './AttackCoverage.scss'

export const AttackCoverage = {
    setup() {

        const data = reactive({
            hide_ignored_ttps: true,
            hide_not_covered_ttps: false,
            expanded: {}
        })

        LiveQuery("attack_coverage", "", data=>{
            model.attack_coverage = data
        }, ()=>[])

        return ()=>{
            if(!model.attack_coverage) return null;
            let {software, ruleset, date, nb_rules, TACTICS} = model.attack_coverage 

            const ttps = {}
            const subtechniques = {}
            
            for(const tactic in TACTICS) {
                for(const {id} of TACTICS[tactic]) {
                    ttps[id] = false;
                    if(id.includes(".")) {
                        const parent_id = id.split(".")[0]
                        subtechniques[parent_id] ||= []
                        subtechniques[parent_id].push(id)
                    }
                }
            }

            for(const tactic in TACTICS) {
                for(const {id, ignored} of TACTICS[tactic]) {
                    if(ignored) {
                        ttps[id] = "ignored";
                    }
                }
            }

            for(const tactic in TACTICS) {
                for(const {id, covered} of TACTICS[tactic]) {
                    if(covered) ttps[id] = "covered";
                }
            }

            for(const ttp in ttps) {
                if(data.hide_ignored_ttps && ttps[ttp]==='ignored') delete ttps[ttp]
            }
 
            const nb_ttps_covered = Object.values(ttps).filter(c=>c==='covered').length
            const nb_ttps_ignored = Object.values(ttps).filter(c=>c==='ignored').length
            const nb_ttps = Object.values(ttps).length
            const nb_ttps_not_covered = nb_ttps - nb_ttps_ignored - nb_ttps_covered
            const score = Math.round(nb_ttps_covered*100 / nb_ttps)

            const ttps_donut = [
                {label:nb_ttps_covered + ' TTPs covered', data:nb_ttps_covered, color:'#108548'},
                {label:nb_ttps_not_covered + ' TTPs not covered', data:nb_ttps_not_covered, color:'#dd2b0e'}, 
                {label:nb_ttps_ignored + ' TTPs ignored', data:nb_ttps_ignored, color:'orange'},
            ]

            const filter_techniques = t=>t?.filter(t=>
                (!t.ignored || !data.hide_ignored_ttps)
                && (!!t.covered || !data.hide_not_covered_ttps)
            )
            const nb_covered = t=>filter_techniques(t)?.filter(t=>t.covered).length ||0
            const nb_not_covered = t=>filter_techniques(t)?.filter(t=>!t.covered).length ||0

            const has_subtechniques = t => !t.id.includes('.') && subtechniques[t.id]?.length>0

            return <div id="attack-coverage">

            <header>
                <div>
                    <h1 class='rules'>MITRE ATT&amp;CK coverage</h1>
                    <div class='grid'>
                        <div><b>Software</b>{software}</div>
                        <div><b>Ruleset</b>{ruleset}</div>
                        <div><div>Submitted</div><div class="date"><i class="far fa-calendar"></i><span>{date}</span></div></div>
                        <div>
                            <h2>{nb_rules} rules</h2>
                        </div>
                        <div style={{gridColumn:"span 2"}}>
                            <b>Coverage score</b>
                            <div class="score">
                                <div class="progress" style="height:40px;">
                                    <div class="progress-bar" class={{'bg-danger':score<40, 'bg-warning': score<60, 'bg-success': score>=60}} role="progressbar" style={{width: score+"%"}} aria-valuenow="10" aria-valuemin="0" aria-valuemax="100">
                                    {score}% ({nb_ttps_covered} TTPs covered / {nb_ttps})
                                    </div>
                                </div>
                            </div>
                            <div class="horizontal">
                                <div><input type="checkbox" onClick={()=>data.hide_not_covered_ttps=!data.hide_not_covered_ttps}/> Hide not covered TTPs</div>
                                <div><input type="checkbox" onClick={()=>data.hide_ignored_ttps = !data.hide_ignored_ttps} checked/> Exclude ignored TTPs</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <Donut data={ttps_donut}/>
                </div>
            </header>

            <div class="tactics">
                {Object.entries(TACTICS||{}).map(([tactic, techniques])=>
                <div>
                    <div class="name">
                    {tactic}
                    <div class="counts">
                        <div>{nb_covered(techniques) && <><i class="fas fa-shield-alt"></i><span>{nb_covered(techniques)}</span></>}</div>
                        {nb_not_covered(techniques)>0 && <div><i class="fas fa-eye-slash"></i><span>{nb_not_covered(techniques)}</span></div>}
                    </div>
                    <div class="count-hist">
                        <span style={{width:(nb_covered(techniques) / (filter_techniques(techniques).length) * 100)+"%"}}></span>
                        <span style={{width:(nb_not_covered(techniques) / (filter_techniques(techniques).length) * 100)+"%"}}></span>
                    </div>
                    </div>
                    {filter_techniques(techniques)?.filter(({id})=>!id.includes(".") || data.expanded[id.split(".")[0]]).map(t=>
                        <div class="technique" onClick={()=>data.expanded[t.id]=!data.expanded[t.id]} class={{
                            'sub':t.id.includes('.'),
                            'covered':t.covered, 
                            'ignored': t.ignored, 
                            'unknown':t.unknown,
                            'custom':t.custom,
                        }}>
                            <div class="name">
                                <b>
                                    {has_subtechniques(t) && <i class={!data.expanded[t.id] ? "fas fa-chevron-right" : "fas fa-chevron-down"}/>}&nbsp;
                                    <span>{t.id}</span>
                                    {t.custom && <span class="badge custom">CUSTOM</span>}
                                    {t.ignored && <span class="badge ignored">IGNORED</span>}
                                    {t.unknown && <span class="badge unknown">UNKNOWN</span>}
                                    <span class="nbsub"></span>
                                </b>
                                <br/>
                                {t.name}
                            </div>
                            <div class="rules">
                                {t.rules?.map(r=>
                                    <div>
                                        <a href={r.url} target="_blank" onClick={e=>e.stopPropagation()}>
                                            <i class="fas fa-shield-alt"></i> {r.title || r.description}
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            )}
            </div>

        </div>
        }
    }
}