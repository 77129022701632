export function ago_to_date(ago) {
    if(!ago) return null
    const days = parseInt(ago.match(/([0-9]+)\s*d/)?.[1]) || 0
    const months = parseInt(ago.match(/([0-9]+)\s*mo/)?.[1]) || 0
    const years = parseInt(ago.match(/([0-9]+)\s*y/)?.[1]) || 0
    const minutes = parseInt(ago.match(/([0-9]+)\s*mi/)?.[1]) || 0
    const hours = parseInt(ago.match(/([0-9]+)\s*h/)?.[1]) || 0
    const seconds = parseInt(ago.match(/([0-9]+)\s*s/)?.[1]) || 0
    const d = new Date()
    d.setFullYear(d.getFullYear()-years, d.getMonth()-months, d.getDate()-days)
    return new Date(+d - (hours*3600+minutes*60+seconds)*1000)
}

export function decode_ago(ago) {
    if(!ago) return ""
    const days = parseInt(ago.match(/([0-9]+)\s*d/)?.[1])
    const months = parseInt(ago.match(/([0-9]+)\s*mo/)?.[1])
    const years = parseInt(ago.match(/([0-9]+)\s*y/)?.[1])
    const minutes = parseInt(ago.match(/([0-9]+)\s*mi/)?.[1])
    const hours = parseInt(ago.match(/([0-9]+)\s*h/)?.[1])
    const seconds = parseInt(ago.match(/([0-9]+)\s*s/)?.[1])
    let s = ""
    if(years) s += t_n(years, "year") + " "
    if(months) s += t_n(months, "month") + " "
    if(days) s += t_n(days, "day") + " "
    if(hours) s += t_n(hours, "hour") + " "
    if(minutes) s += t_n(minutes, "minute") + " "
    if(seconds) s += t_n(seconds, "second") + " "
    return s.toLowerCase()
}

export function encode_ago(ago) {
    if(!ago) return ""
    ago = ago.toLowerCase()
    const days = parseInt(ago.match(/([0-9]+)[,\s]*[dj]/)?.[1])
    const months = parseInt(ago.match(/([0-9]+)[,\s]*mo[in]?/)?.[1])
    const years = parseInt(ago.match(/([0-9]+)[,\s]*[ya]/)?.[1])
    const minutes = parseInt(ago.match(/([0-9]+)[,\s]*mi/)?.[1] || 
        ago.match(/([0-9]+)[,\s]*m[^a-z]/)?.[1] || 
        ago.match(/([0-9]+)[,\s]*m\s*$/)?.[1])
    const hours = parseInt(ago.match(/([0-9]+)[,\s]*h/)?.[1])
    const seconds = parseInt(ago.match(/([0-9]+)[,\s]*s/)?.[1])
    let s = ""
    if(years) s += `${years}y `
    if(months) s += `${months}month `
    if(days) s += `${days}d `
    if(hours) s += `${hours}h `
    if(minutes) s += `${minutes}min `
    if(seconds) s += `${seconds}s `
    return s ? s + "ago" : ""
}