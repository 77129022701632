import { createApp } from 'vue';
import { App, setRoutes } from './App';
import { useCurrentUserStore } from './store/CurrentUserStore'
import { piniaInstance } from './global/GlobalStore';
import './css/main.scss'
import './css/fonts.scss'
import { Alerts } from './pages/Alerts';
import { Cases } from './pages/Cases';
import { Diags } from './pages/Diags';
import { Incidents } from './pages/Incidents';
import { Members } from './pages/Members';
import { Recommendations } from './pages/Recommendations';
import { Findings } from './pages/Findings';
import { Scenarios } from './pages/Scenarios';
import { Scopes } from './pages/Scopes';
import { ScopeSettings } from "./pages/ScopeSettings";
import { Documents } from './pages/Documents';
import { Users } from './pages/Users';
import { Profile } from './pages/Profile';
import { Dashboard } from './pages/Dashboard';
import { Alert } from './pages/Alert';
import { Recommendation } from './pages/Recommendation';
import { Finding } from './pages/Finding';
import { Scenario } from './pages/Scenario';
import { Case } from './pages/Case';
import { DeclareIncident } from './pages/new/DeclareIncident';
import { RequestInvestigation } from './pages/new/RequestInvestigation';
import { Login } from './pages/login/Login';
import { Signup } from './pages/login/Signup';
import { ResetPassword } from './pages/login/ResetPassword';
import { createRouter } from './utils/router';
import { MonthlyReport } from './pages/MonthlyReport';
import { NewCase } from './pages/new/NewCase';
import { NewIncident } from './pages/new/NewIncident';
import { NewScope } from './pages/new/NewScope';
import { NewAlert } from './pages/new/NewAlert';
import { NewRecommendation } from './pages/new/NewRecommendation';
import { NewScenario } from './pages/new/NewScenario';
import { NewUser } from './pages/new/NewUser';
import { EditUser } from './pages/EditUser';
import { NoScope } from './pages/NoScope';
import { Assets} from "./pages/Assets";
import { Asset } from "./pages/Asset";
import { Vulnerabilities } from "./pages/Vulnerabilities";
import { NewVulnerability } from "./pages/new/NewVulnerability";
import { Vulnerability } from "./pages/Vulnerability";
import { Iocs } from "./pages/Iocs";
import { Ioc } from "./pages/Ioc";
import { NewIOC } from './pages/new/NewIOC';
import { SettingsGlobal } from './pages/settings/SettingsGlobal';
import { SettingsTags } from './pages/settings/SettingsTags';
import { SettingsDrilldowns } from './pages/settings/SettingsDrilldowns';
import { SettingsBackups } from './pages/settings/SettingsBackups';
import { SettingsQualifications } from './pages/settings/SettingsQualifications';
import { SettingsRules } from './pages/settings/SettingsRules';
import { TrackingDiag } from './pages/TrackingDiag';
import { Impersonate } from './pages/login/Impersonate';
import { AttackCoverage } from './pages/settings/AttackCoverage';
import { SettingsMaestro } from './pages/settings/SettingsMaestro';
import { Notifications } from './pages/Notifications';
import { Messages } from './pages/Messages';
import { Agents } from './pages/Agents'
import { Agent } from './pages/Agent'
import { Rules } from './pages/Rules'
import { Rule } from './pages/Rule';
import RulesDefaultSections from './pages/RulesDefaultSections.vue';
import { Whitelists } from './pages/Whitelists';
import { Whitelist } from './pages/Whitelist';
import { NotFound } from './ui/NotFound';
import { NewDiag } from './pages/new/NewDiag';
import { SettingsSavedAlertsSearches } from './pages/settings/SettingsSavedAlertsSearches';
import { AgentsSettings } from './pages/AgentsSettings';
import SettingsApi from './pages/settings/SettingsApi.vue';
import SettingsShuffleWorkflows from './pages/settings/SettingsShuffleWorkflows.vue';
import SettingsShuffleAuthentications from './pages/settings/SettingsShuffleAuthentications.vue';


window.CONFIG = CONFIG
console.log("ASTRAIA VERSION : " + CONFIG.VERSION)

export const ROUTES = [    
    { path: "/:lang/scope/:scopeId/dashboard", name:"dashboard", component:Dashboard},
    { path: "/:lang/scope/:scopeId/messages", name:"messages", component:Messages},
    { path: "/:lang/scope/:scopeId/reports/:year?/:month?", name:"reports", component:MonthlyReport},
    { path: "/:lang/scope/:scopeId/alerts", name:"alerts", component:Alerts, permission:{"subject": "alerts", "action": "read_private"} },
    // { path: "/:lang/scope/:scopeId/incidents", name:"incidents", component:Incidents },
    { path: "/:lang/scope/:scopeId/cases", name:"cases", component:Cases },
    { path: "/:lang/scope/:scopeId/recommendations", name:"recommendations", component:Recommendations },
    { path: "/:lang/scope/:scopeId/findings", name:"findings", component:Findings },
    { path: "/:lang/scope/:scopeId/scenarios", name:"scenarios", component:Scenarios },
    { path: "/:lang/scope/:scopeId/whitelists",name:"whitelists", component:Whitelists },
    { path: "/:lang/scope/:scopeId/vulnerabilities", name:"vulnerabilities", component:Vulnerabilities },
    { path: "/:lang/scope/:scopeId/members", name:"members", component:Members },
    { path: "/:lang/scope/:scopeId/documents", name:"documents", component:Documents },
    { path: "/:lang/scope/:scopeId/settings", name:"settings", component:ScopeSettings, permission:{"subject": "scopes", "action": "write"} },
    { path: "/:lang/scope/:scopeId/settings/notifications", name:"notifications", component:Notifications, permission:{"subject": "roles", "action": "read_private"} },
    { path: "/:lang/scope/:scopeId/settings/saved-alerts-searches", component:SettingsSavedAlertsSearches, permission:{"subject": "alerts", "action": "read_private"} },
    { path: "/:lang/scope/:scopeId/settings/alerts_notifications", name:"alerts_notifications", component:Notifications, permission:{"subject": "roles", "action": "read_private"} },
    { path: "/:lang/scope/:scopeId/settings/tags", name:"settings-tags", component:SettingsTags, permission:{"subject": "tags", "action": "read_private"} },
    { path: "/:lang/scope/:scopeId/settings/maestro", name:"settings-maestro", component:SettingsMaestro, permission:{"subject": "scopes", "action": "read_admin"} },
    { path: "/:lang/scope/:scopeId/settings/drilldowns", component:SettingsDrilldowns, permission:{"subject": "drilldowns", "action": "read_private"} },
    { path: "/:lang/scope/:scopeId/settings/shuffle-workflows", component:SettingsShuffleWorkflows, permission:{"subject": "shuffle", "action": "read_private"} },
    { path: "/:lang/scope/:scopeId/settings/shuffle-authentications", component:SettingsShuffleAuthentications, permission:{"subject": "shuffle", "action": "read_private"} },
    { path: "/:lang/scope/:scopeId/agents/settings", component:AgentsSettings, permission:{"subject": "agents", "action": "read_private"}},
    { path: "/:lang/scope/:scopeId/assets", name:"assets", component:Assets },
    { path: "/:lang/scope/:scopeId/asset/:type", name:"asset", component:Asset },
    { path: "/:lang/scope/:scopeId/agents", name:"agents", component: Agents},
    { path: "/:lang/scope/:scopeId/", redirect:{name:"dashboard"} },
    { path: "/:lang/scope/:scopeId/:path(.*)", redirect:{name:"dashboard"}},
    { path: "/:lang/vulnerability/:vulnerabilityId/", component:Vulnerability },
    { path: "/:lang/scope/:scopeId/vulnerability/:vulnerabilityId/", component:Vulnerability },
    { path: "/:lang/scope/:scopeId/iocs", component:Iocs },
    { path: "/:lang/scope/:scopeId/ioc/:iocId", component:Ioc },
    { path: "/:lang/scope/:scopeId/newioc", component:NewIOC },
    { path: "/:lang/scope/:scopeId/alert/:alertGroupId", name:"alertgroup", component:Alert},
    { path: "/:lang/scope/:scopeId/alert/:alertGroupId/:alertId", name:"alert", component:Alert},
    { path: "/:lang/scope/:scopeId/recommendation/:recommendationId", name:"recommendation", component:Recommendation},
    { path: "/:lang/scope/:scopeId/finding/:findingId", name:"finding", component:Finding},
    { path: "/:lang/scope/:scopeId/scenario/:scenarioId", name:"scenario", component:Scenario},
    { path: "/:lang/scope/:scopeId/case/:caseId", name:"case", component:Case},
    { path: "/:lang/scope/:scopeId/agent/:agentId", name:"agent", component:Agent},
    { path: "/:lang/rule/:ruleId", name:"rule", component:Rule},
    { path: "/:lang/whitelist/:whitelistId", name:"whitelist", component:Whitelist},
    // { path: "/scope/:scopeId/incident/:incidentId", name:"incident", component:Incident},

    { path: "/:lang/scope/:scopeId/request-investigation", name:"request-investigation", component:RequestInvestigation},
    { path: "/:lang/scope/:scopeId/declare-incident", name:"declare-incident", component:DeclareIncident},

    { path: "/:lang/scope/:scopeId/newcase", component:NewCase},
    { path: "/:lang/scope/:scopeId/newincident", component:NewIncident},
    { path: "/:lang/scope/:scopeId/newalert", component:NewAlert},
    { path: "/:lang/scope/:scopeId/newrecommendation", component:NewRecommendation},
    { path: "/:lang/scope/:scopeId/newscenario", component:NewScenario},
    { path: "/:lang/newvulnerability", component:NewVulnerability},
    { path: "/:lang/newscope", component:NewScope, permission:{"subject": "scopes", "action": "write_private"}},
    { path: "/:lang/newuser", component:NewUser, permission:{"subject": "users", "action": "write_private"}},
    { path: "/:lang/user/:id", component:EditUser, permission:{"subject": "users", "action": "write_admin"}},


    { path: "/:lang/scopes", name:"/scopes", component:Scopes, multiscope:true },
    { path: "/:lang/messages", name:"/messages", component:Messages, multiscope:true },
    { path: "/:lang/alerts", component:Alerts, multiscope:true},
    { path: "/:lang/incidents", component:Incidents, multiscope:true },
    { path: "/:lang/cases", component:Cases, multiscope:true },
    { path: "/:lang/recommendations", component:Recommendations, multiscope:true }, 
    { path: "/:lang/findings", component:Findings, multiscope:true },
    { path: "/:lang/scenarios", component:Scenarios, multiscope:true },
    { path: "/:lang/rules", component:Rules },
    { path: "/:lang/whitelists", component:Whitelists },
    { path: "/:lang/vulnerabilities", component:Vulnerabilities, multiscope:true },
    { path: "/:lang/iocs", component:Iocs, multiscope:true},
    { path: "/:lang/ioc/:iocId", component:Ioc },
    { path: "/:lang/newioc", component:NewIOC },
    { path: "/:lang/users", component:Users, permission:{"subject": "users", "action": "read_all"} },
    { path: "/:lang/agents", component: Agents},
    { path: "/:lang/profile", component:Profile },
    { path: "/:lang/scope/:scopeId/audit/:diagId", component:TrackingDiag },
    { path: "/:lang/scope/:scopeId/audit", component:TrackingDiag },
    { path: "/:lang/audits", component: Diags, role:"analyst" },
    { path: "/:lang/newaudit", component: NewDiag, role:"analyst" },
    { path: "/notFound", name:"Not found", component:NotFound},
    { path: "/:lang/scope/:scopeId/audit/:path(.*)", redirect:{name:"Not found"}},
    { path: "/:lang/settings", component:SettingsGlobal, permission:{"subject": "settings", "action": "read"} },
    { path: "/:lang/settings/tags", component:SettingsTags, permission:{"subject": "tags", "action": "read_private"} },
    { path: "/:lang/settings/qualifications", component:SettingsQualifications, permission:{"subject": "conclusion_codes", "action": "read_private"} },
    { path: "/:lang/settings/drilldowns", component:SettingsDrilldowns, permission:{"subject": "drilldowns", "action": "read_private"} },
    { path: "/:lang/settings/rules-default-section", component:RulesDefaultSections, permission:{"subject": "rules", "action": "read_private"} },
    { path: "/:lang/settings/saved-alerts-searches", component:SettingsSavedAlertsSearches, permission:{"subject": "alerts", "action": "read_private"} },
    { path: "/:lang/settings/backups", component:SettingsBackups, permission:{"subject": "backups", "action": "read_admin"} },
    { path: "/:lang/rules-coverage", component:SettingsRules, permission:{"subject": "rules_ci", "action": "read_private"} },
    { path: "/:lang/rules/attack-coverage", component:AttackCoverage, permission:{"subject": "attack_coverages", "action": "read"} },
    { path: "/:lang/agents/settings", component:AgentsSettings, permission:{"subject": "agents", "action": "read_private"}},
    { path: "/:lang/settings/api", component: SettingsApi, permission:{"subject": "api", "action": "read_private"} },

    { path: "/:lang/scope/:scopeId/impersonate", component:Impersonate, permission:{"subject": "users", "action": "impersonate"} },

    { path: "/:lang/noscope", name:"noscope", component:NoScope, menu:false },

    { name:"login", path:"/:lang/login", component:Login, anonymous:true},
    { name:"reset-password", path:"/:lang/reset-password/:token", component:ResetPassword, anonymous:true},
    { name:"signup", path: "/:lang/activate/:token", component:Signup, anonymous:true},

    { path: "/", redirect: ()=>({name:"/scopes",params:{lang:"fr"}})},
    { path: "/:path(.*)", redirect: ()=>({name:"/scopes",params:{lang:"fr"}})},
]

setRoutes(ROUTES)
export const router = createRouter(ROUTES, "/")

const app = createApp(App)
app.use(piniaInstance)

// Set user at init app
const userStore = useCurrentUserStore
userStore.setUserData()

app.use(router)
app.mount('#app');