import { reactive, ref } from '@vue/reactivity';
import { BaseButton, IconButton } from './Button'
import './Dropdown.scss'
import { createPopper } from '@popperjs/core';
import { Link } from '../utils/router';
import { onMounted } from '@vue/runtime-core';
import { onUnmounted } from '@vue/runtime-core';

export const DROPDOWN = reactive({
    cur: null,
    close: ()=>{},
})

$(window).click(()=>DROPDOWN.close())
$(window).keydown((e)=>{
    if(e.keyCode===27) DROPDOWN.close()
})

let GUID = 0


export const Dropdown = {
    props:['items', 'button', 'title', 'iconbutton', 'slim', 'solid', 'top', 'menuClass', 'onOpen', 'onClose', 'class', 'placement', 'disabled', 'footer'],
    setup(props) {
        const btn = ref()
        const guid = GUID++

        function open() {
            props.onOpen?.()

            DROPDOWN.close?.()
            DROPDOWN.guid = guid
            DROPDOWN.close = close

            DROPDOWN.cur = ()=><DropdownMenu key={Math.random()} class={props.menuClass} onClick={close} button={btn.value?.$el || btn.value}>
                {props.top && <div class="top" 
                    onKeyUpCapture={e=>{e.stopPropagation();e.preventDefault();}}
                    onClickCapture={e=>{e.stopPropagation();e.preventDefault();}}
                >{props.top}</div>}
                <div class="content">
                    {props.items?.()}
                    {props.footer}
                </div>
            </DropdownMenu>
        }

        function toggle() {
            if(DROPDOWN.guid === guid) close();
            else open()
        }

        function close() {
            DROPDOWN.guid = null
            DROPDOWN.cur = null
            props.onClose?.()
        }

        return ()=>{
            const {button, title, iconbutton, slim=false, solid=false, disabled=false} = props
            
            const Button = iconbutton ? IconButton : BaseButton
            return <>
            <Button ref={btn} secondary 
                title={title} 
                disabled={disabled}
                class="btn btn-secondary dropdown-toggle" class={{solid, slim}} class={props.class} 
                type="button"
                onClick={(e)=>{toggle(); e.stopPropagation(); }}
            >
                {iconbutton || button}
            </Button>
        </>
        }
    }
}


export const DropdownItem = ({to},{slots}) => (
    <li>
        <Link class="dropdown-item" href={to}>
            {slots.default?.()}
        </Link>
    </li>
)


const DropdownMenu = {
    props: ['placement', 'button'],
    setup(props, {slots}) {
        const el = ref()
        let popper = null

        onMounted(()=>{
            popper = createPopper(props.button?.$el || props.button, el.value, {
                placement:props.placement || "bottom-start"
            });
        })

        onUnmounted(()=>{
            popper?.destroy()
        })

        return ()=>{
            return <ul class="ui-dropdown-menu show" ref={el}>
                {slots?.default?.()}
            </ul>
        }
    }
}