<script setup>
    import "./SettingsShuffleWorkflows.scss"
    import { model, API } from "../../model"
    import { reactive, ref } from "vue"
    import {t} from "../../i18n/index"
    import { h } from "vue"
    import { watchEffect } from "vue"
    import { Spinner } from "../../ui/Spinner"
    import { DataTable } from "../../ui/DataTable"
    import { Button } from "../../ui/Button"
    import { Input } from "../../ui/Input"
    
    const data = reactive({
        workflows: null,
        executions: null,
        selectedWorkflowId: null,
        argument: "{}"
    })
    
    watchEffect(async () => {
        data.workflows = await API.getShuffleWorkflows(model.scopeId)
    })

    const getExecutions = async (id) => {
        data.selectedWorkflowId = id
        const executions = await API.getShuffleExecutions(id, model.scopeId)
        for (const ex of executions.items) {
            if (ex.source==="default") {
                ex.source = "trigger"
            } 
            else {
                for (const w of data.workflows.items) {
                    if (ex.source===w.id) {
                        ex.source = `workflow [${w.name}]`
                        break
                    }
                }
            }
        }
        data.executions = executions
    }

    const trigger = async (id) => {
        await API.executeShuffleWorkflow(id, +model.scopeId, data.argument)
        await getExecutions(data.selectedWorkflowId)
    }
</script>

<template>
    <div id="shuffle-settings">
        <h1>Shuffle workflows</h1>
        <div v-if="data.workflows">
            <div class="section">
                <h2>Workflows</h2>
                <DataTable 
                :no-search="true"
                :data="data.workflows.items"
                :columns="[
                    {title:t('Name'), type:'field', render:x=>x.name},
                    {title:t('Created'), type:'date', render: x=>x.created_at},
                    {title:t('Updated'), type:'date', render:x=>x.updated_at},
                    {title:t('Tags'), type:'field', render:x=>x.tags?.join(', ')}
                    ]"
                :on-row-click="async (x) => await getExecutions(x.id)"
                />
            </div>
            <div v-if="data.executions" class="section">
                <h2>Executions</h2>
                <Input :on-input="(e)=>data.argument=e.target.value" label='Execution argument' value=""/>
                <Button @click="trigger(data.selectedWorkflowId)">{{t('Trigger new execution')}}</Button>
                <DataTable 
                :no-search="true"
                :data="data.executions.items"
                :columns="[
                    {title:t('Status'), type:'field', render:x=>x.status},
                    {title:t('Started at'), type:'date', render: x=>x.started_at},
                    {title:t('Completed at'), type:'date', render:x=>x.completed_at},
                    {title:t('Source'), type:'field', render:x=>x.source},
                    {title:t('Argument'), type:'field', render:x=>x.argument.substring(0,50)},
                    {title:t('Result'), type:'field', render:x=>x.result.substring(0, 50)}
                    ]"
                />
            </div>
        </div>
    </div>
</template>