import { model, API } from "../model"
import { DataTable } from "../ui/DataTable"
import { optionsFromQueryParams, queryParams, setQueryParams, watchQueryParams } from "../utils/queryParams"
import { t } from "../i18n"
import { LiveQuery } from "../api/livequery"
import { Spinner } from "../ui/Spinner"
import { Recos } from "../ui/Recos"
import "./Scenarios.scss"
import { createTagDialog, Tags } from "../ui/Tags"
import {Link} from "../utils/router"
import {link} from "../utils/routing"

import { gotoScope } from "../utils/routing"
import { AddButton } from "../ui/AddButton"
import { ScopeItem } from "./Alerts"
import { Checkbox } from "../ui/Checkbox"
import {md} from "../ui/Markdown";
import { TagsMixin } from "../mixins/Tags"
import { useCurrentUserStore } from "../store/CurrentUserStore"

const userStore = useCurrentUserStore

export const Scenarios = {
    setup() {
        const lq = LiveQuery("scenarios", `{
            count, pages, items {
                id, scope_id, title, short_body, created_at, updated_at, recos_by_status, tags, is_public
            }
        }`, s=>model.scenarios=s,
            ()=>[{
                scope_id:model.scopeId,
                sort:"updated_at",
                order:"desc",
                limit:parseInt(queryParams().limit) || 20,
                ...optionsFromQueryParams(),
            }, {scope_id:model.scopeId}]
        )

        const {Tags} = TagsMixin("Scenario")

        function togglePublic(c) { API.setScenario(c.id, {is_public:!c.is_public})}

        function newScenario() { gotoScope("newscenario") }

        return ()=>{

            const allowed_tags = model.scope?.tags

            return <div id="scenarios">
                <DataTable
                    loading={lq.loading}
                    header={<>
                        {userStore.hasPermissions("scenarios", "write_private") && model.scopeId && <AddButton secondary onClick={newScenario}>{t("New Scenario")}</AddButton>}
                    </>}
                    data={model.scenarios}
                    rowClass={x=>!x.is_public && "private"}
                    columns={[
                        ...(model.scope ? [] : [{title:t("Scope"), type:'scope', render:ScopeItem, sort:"scope"}]),
                        {title:t('First activity'), type:'date', render:x=>x.created_at, sort:"created_at"},
                        {title:t('Last activity'), type:'date', render:x=>x.updated_at, sort:"updated_at"},
                        {title:"Description", type:'description', render:x=><div class='description'>
                            <div><Link href={link(`scope/${x.scope_id}/scenario/${x.id}`)}>{x.title}</Link></div>
                            <div class="body">{x.short_body}</div>
                        </div>, sort:"body"},
                        {title:t("Recommendations"), render:Recos, sort:"status"},
                        userStore.hasPermissions("scenarios", "write_private") && {title:"Public", render:x=><Checkbox value={x.is_public} onClick={()=>togglePublic(x)}/>},
                        {title:t('Tags'), type:"tags", noLink:true, render:a=>Tags(a)},
                    ]}
                    defaultSort={{sort:"updated_at", order:"desc"}}
                />
            </div>
            }
    }
}