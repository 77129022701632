<script setup>
  import { computed, ref, watch } from "vue";
  import { Drilldown } from "../ui/Drilldown"
  import { model } from "../model"
  import './AlertTableView.scss'
  import { DISABLE_GRID } from "../utils/disableGrid"
  
  const props = defineProps(["source", "prioritySections", "drilldown"])
  const flatSource = ref({})

  let fullObject = { }

  // Smoothes the source
  const unwrap = (obj, prefix) => {
    let res = {};
    if (obj) {
      for (let k of Object.keys(obj)) {
        let val = obj[k], key = prefix ? prefix + '.' + k : k
        if (Array.isArray(val)) res[key] = val.length === 1 ? val[0] : [...val] 
        else if (typeof val === 'object') Object.assign(res, unwrap(val, key)) // <-- Recursion
        else res[key] = val
      }
    }
    return res;
  }

  const json = computed(() => unwrap(typeof(props.source)==="string" ? JSON.parse(props.source) : props.source))

  // Convert object keys to lower case
  const convertKeysToLowerCase = (obj) => {
    if (obj === null || typeof obj !== 'object') return obj

    if (Array.isArray(obj)) return obj.map(it => convertKeysToLowerCase(it))

    const newOb = {}

    for (const k in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, k)) {
        const newK = k.toLowerCase()
        const v = obj[k]

        if (typeof v === 'object' && v !== null) newOb[newK] = convertKeysToLowerCase(v)
        else newOb[newK] = v
      }
    }

    return newOb
  }

  const getOrinalKeys = (key) => {
    return Object.values(flatSource.value).map(e => {
      const res = Object.keys(e).find(x => x.toLocaleLowerCase()===key.toLocaleLowerCase())
      return res
    })
  }

  const checkSecondaryKey = ({keys, source, key}) => {
    const ks = [...keys]

    map(ks, k => k?.toLowerCase())

    const res = ks.map((k) => {
      const value = dig(source, `${k?.split(".")[0]}`, `${k}`)

      if (value) {
        delete dig(source, `${k.split(".")[0]}`, k)
        const originKey = getOrinalKeys(k)

        return { [[...new Set(originKey)].join("")]: value }
      }

    }).filter(Boolean)

    let object = {}

    res.forEach(item => {
      const k = Object.keys(item)[0]
      object[k] = item[k]
    })

    return { ...object , ...source[key[0]] }
  }

  // Call keys of an object event if a key if null
  const dig = (obj, ...keys) => {
    if (!obj) return 
    
    const key = keys.shift()
    const value = obj[key]
    
    if (keys.length === 0) return value
    else return dig(value, ...keys)
  }

  // Destructive map
  const map = (a, callback) => {
    a?.forEach((item, i) =>  a[i] = callback(item))
    return a
  }

  // Sorts the source according to the fields to be prioritized
  const sorter = (s, keys) => {
    let source = { ...s }
    const lowerCaseKeys = convertKeysToLowerCase(keys)
    const lowerCaseSource = convertKeysToLowerCase(source)

    if (!keys) return Object.entries(source)

    // Highlight a complete section
    if (typeof keys==="string") {
      let newObj = { [keys]: source[keys] }

      for (const k in source) {
        if (k !== keys) newObj[k] = source[k]
      }

      source = newObj
    }

    const o = Object.entries(lowerCaseKeys).map((k) => {
      if (source[k[0]]) {
        const priof = checkSecondaryKey({ keys: k[1], source: lowerCaseSource, key: k })

        delete source[k[0]]
        return [k[0], priof]
        
      } else {
        if (k[0].toLowerCase()==='hidden-sections') {
          k[1].map(e => {
            delete source[e]
          })
        }

        if (k[0].match(/^\d+$/)) return
        const priof = checkSecondaryKey({ keys: k[1], source: lowerCaseSource, key: k })

        return [k[0], priof]
      }

    }).filter(item=>item!==undefined)

    return [...o, ...Object.entries(source)]
  }

  // This function is used to hide fields in the "hidden" section.
  const hidding = (vs, flatSource) => {
    const source = {...flatSource }
    const lowerCaseSource = convertKeysToLowerCase(source)
    const lowerCasePrio =  Object.keys(convertKeysToLowerCase(vs))
    
    map(lowerCasePrio, (k) => {
      const v = dig(lowerCaseSource, `${k.split(".")[0]}`, `${k}`)
      if (v) delete flatSource[k.split(".")[0]][k]
    })
    return flatSource
  }

  // This function is used to hide the "hidden" section, which is similar to another section.
  const clear = (key, newVs) => {
    if (key.toLowerCase()==='hidden') return
    if (key.toLowerCase()==='hidden-sections') return
    else return [key, newVs]
  }


  const computeFinalOutput = (outPut) => {

    let finalOutput = outPut.map(([key, vs]) => {
      let newVs
      let isHidden
      if (key.toLowerCase()==='hidden') newVs = hidding(vs, flatSource.value)
      else if (props.prioritySections && props.prioritySections[key] && !isHidden) {

        newVs = sorter(vs, props.prioritySections[key])
      }
      else {
        if(!vs)return
        newVs = Object.entries(vs)
      }

      return clear(key, newVs)
    }).filter(item=>item!==undefined)

    return finalOutput
  }

  // Get first_detection & last_detection for Drilldowns
  const {first_detection, last_detection} = model.alertGroup
    
  // Create sections with titles from source
  let src = typeof(props.source)==="string" ? JSON.parse(props.source) : props.source
  Object.entries(src).forEach(([key, value]) => {
    if (typeof value==="string") flatSource.value[key] = { [key]:value }
    else if (Proxy && Array.isArray(value)) flatSource.value[key] = [...value].flat()
    else flatSource.value[key] = unwrap(value, key)
  })
  // keep full object for drilldowns
  src.first_detection = first_detection
  src.last_detection = last_detection
  fullObject = src
  // Calls the sorter function, checking if there are any fields in the priorities, otherwise returns an array with no fields
  let newOutput = sorter(flatSource.value, props.prioritySections)
  let finalOutput = computed(() => computeFinalOutput(newOutput)) 

  // Check if props has been changed
  watch(() => props.source, (old, _) => {
    
    let src = typeof(old)==="string" ? JSON.parse(old) : old

    Object.entries(src).forEach(([key, value]) => {

      if (typeof value==="string") flatSource.value[key] = { [key]:value }
      else if (Proxy && Array.isArray(value)) flatSource.value[key] = [...value].flat()
      else flatSource.value[key] = unwrap(value, key)

    })

    src.first_detection = first_detection
    src.last_detection = last_detection
    fullObject = src

    newOutput = sorter(flatSource.value, props.prioritySections)
    finalOutput.value = computeFinalOutput(newOutput)
  })
  const isDisableField = (field) => !DISABLE_GRID.includes(field.toLocaleLowerCase())
  const isPrioritySections = computed(() => convertKeysToLowerCase(props.prioritySections))
  const isTableReduce = ref(!!model.alertId)
</script>

<template>
  <div class="table" :class="{'table-reduce' : isTableReduce}">
    <table v-for="(item, index) in Array.from(finalOutput)" :key="index">
      <tr :class="{ 'highlight': item[0].toLowerCase() === isPrioritySections, 'tabs': true }">
        <td :class="{ 'keys': true, 'highlight': Object.keys(isPrioritySections).includes(item[0]) }">{{ item[0] }}</td>
        <td v-for="(value, i) in item[1]" v-if="props.drilldown" :key="i" :class="{'values': true, 'highlight': map(isPrioritySections[item[0]], el => el.toLowerCase())?.includes(value[0].toLowerCase())}">
          {{ value[0].match(/^\d+$/) ? null : `${value[0]} :`}}
          <div :class="{'grid': Array.isArray(value[1]) && value[1].length >= 3 && isDisableField(value[0])}">
            <div v-for="(el, idx) in value[1]" v-if="Array.isArray(value[1])" :key="idx">
              <Drilldown :field="value[0]" :value="el" :index="idx" :obj="fullObject"/>
            </div>
            <Drilldown v-else :field="value[0]" :value="value[0].match(/^\d+$/) ? value[1] : json[value[0]]?.toString()" :obj="fullObject"/>
          </div>
        </td>
        <td v-for="(value, i) in item[1]" v-if="!props.drilldown" :key="i" class="values">{{ value[0] }} : <span>{{ value[1] }}</span></td>
      </tr>
    </table>
  </div>
</template>