import { API, model } from "../../model"
import { Button } from "../../ui/Button"
import { t } from "../../i18n"
import { DataTable } from "../../ui/DataTable"
import "./SettingsRules.scss"
import { LiveQuery } from "../../api/livequery"
import { queryParams } from "../../utils/queryParams"
import { D } from "../../ui/dates"
import { Badge } from "../../ui/Badge"
import { mutation } from "../../api/graphql"
import { Donut } from "../../ui/Donut"

export const SettingsRules = {
    setup() {
        const lq = LiveQuery("rules_ci", "{code,message,created_at,updated_at,rule,sample,level,atlas_version}", 
            x=>model.rules_ci = x, ()=>[],
            {interval:30000},
        )

        function fixLater({message}) {
            mutation("rules_ci_fix_later", {message}, '{updated_at}')
            lq?.refresh()
        }

        const reco_category = (r) => ({
            P:"prevent",
            H:"harden",
            C:"correct",
        }[r[0]])

        const finding_category = (f) => ({
            C:"configuration",
            E:"exposure",
            M:"missing",
            V:"vulnerability",
        }[f[0]])

        return ()=>{
            let all_rules = {}
            model.rules_ci?.filter(({code})=>code==="detected").forEach(({rule})=>all_rules[rule.path] = "detected")
            model.rules_ci?.filter(({code})=>code==="rule_without_sample").map(({rule})=>all_rules[rule.path] = "without_sample")
            model.rules_ci?.filter(({code})=>code==="undetected").forEach(({rule})=>all_rules[rule.path] = "undetected")
            let rules = {
                detected: Object.values(all_rules).filter(x=>x==="detected"),
                undetected: Object.values(all_rules).filter(x=>x==="undetected"),
                without_sample: Object.values(all_rules).filter(x=>x==="without_sample"),
            }

            let samples = {
                detected: model.rules_ci?.filter(({code,sample})=>sample && code==="detected"),
                undetected: model.rules_ci?.filter(({code,sample})=>sample && code==="undetected"),
                false_positives: model.rules_ci?.filter(({code,sample})=>sample && code==="false_positive"),
                without_rule: model.rules_ci?.filter(({code,sample})=>sample && code==="sample_without_rule"),
                not_whitelisted: model.rules_ci?.filter(({code,sample})=>sample && code==="not_whitelisted"),
            }
            

            const rules_donut = [
                {label:rules.detected?.length + " Detected rules", data:rules.detected?.length, color:"green"},
                {label:rules.undetected?.length + " Undetected rules", data:rules.undetected?.length, color:"red"},
                {label:rules.without_sample?.length + " Rules without sample", data:rules.without_sample?.length, color:"#888"},
            ]

            const samples_donut = [
                {label:samples.detected?.length + " Correctly detected samples", data:samples.detected?.length, color:"green"},
                {label:samples.undetected?.length + " Undetected samples", data:samples.undetected?.length, color:"red"},
                {label:samples.false_positives?.length + " False positives", data:samples.false_positives?.length, color:"orange"},
                {label:samples.not_whitelisted?.length + " Incorrectly whitelisted", data:samples.not_whitelisted?.length, color:"#888"},
                {label:samples.without_rule?.length + " Samples without rule", data:samples.without_rule?.length, color:"#888"},                
            ]

            const findings_stats = model.rules_ci?.find(({code})=>code==="findings-stats")?.rule

            const findings_donut = [
                {label:findings_stats?.['ok'] + " well-formed findings", data:findings_stats?.['ok'], color:"green"},
                {label:findings_stats?.['with-warning'] + " findings with warnings", data:findings_stats?.['with-warning'], color:"#888"},
                {label:findings_stats?.['with-error'] + " findings with errors", data:findings_stats?.['with-error'], color:"red"},
            ]

            const recos_stats = model.rules_ci?.find(({code})=>code==="recos-stats")?.rule
            
            const recos_donut = [
                {label:recos_stats?.['ok'] + " well-formed recommendations", data:recos_stats?.['ok'], color:"green"},
                {label:recos_stats?.['with-warning'] + " recommendations with warnings", data:recos_stats?.['with-warning'], color:"#888"},
                {label:recos_stats?.['with-error'] + " recommendations with errors", data:recos_stats?.['with-error'], color:"red"},
            ]

            
            return <div id="rules-settings">
            <h3>Rules</h3>
            <div class='charts'>
                <Donut data={rules_donut} />
                <Donut data={samples_donut} />
                <Donut data={findings_donut} />
                <Donut data={recos_donut} />
            </div>
            <DataTable
                loading={!model.rules_ci}
                data={model.rules_ci}
                noSearch
                classSelect={'select-display-none'}
                columns={[
                    {title:t("Level"), render:x=><Badge class={x.level}>{x.level}</Badge>},
                    {title:t("Message"), render:x=><div v-html={
                        x.message
                            .replace(/Rule ([^ ]+) /, (_,r)=>`Rule <div class='path'><a href="https://git.ct-squa.re/dev/ruleset/-/tree/master/rules/${r}.yaml">${r}</a></div>`)
                            .replace(/Sample ([^ ]+) /, (_,r)=>`Sample <div class='path'><a href="https://git.ct-squa.re/dev/ruleset/-/tree/master/samples/${r}">${r}</a></div>`)
                            .replace(/for sample $/, '')
                            .replace(/sample (\[.*\])/, (_,s)=>{
                                s = JSON.parse(s).map(s=>`<div class='path'><a href="https://git.ct-squa.re/dev/ruleset/-/tree/master/samples/${s}">${s}</a></div>`)
                                return s.length > 1 ? `samples <br/>${s}` : `sample ${s}`
                            })
                            .replace(/Reco ([^ ]+)( has same .* as )([^ ]+)/, (_, r1, text, r2)=>`Reco <div class='path'><a href="https://git.ct-squa.re/dev/ruleset/-/tree/master/recos/${reco_category(r1)}/${r1}.yaml">${r1}</a></div>${text}<div class='path'><a href="https://git.ct-squa.re/dev/ruleset/-/tree/master/recos/${reco_category(r2)}/${r2}.yaml">${r2}</a></div>`)
                            // .replace(/Reco ([^ ]+) /, (_, r)=>`Reco <div class='path'><a href="https://git.ct-squa.re/dev/ruleset/-/tree/master/recos/${reco_category(r)}/${r}.yaml">${r}</a></div>`)
                            .replace(/Finding ([^ ]+) /, (_, r)=>`Finding <div class='path'><a href="https://git.ct-squa.re/dev/ruleset/-/tree/master/findings/${finding_category(r)}/${r}.yaml">${r}</a></div>`)
                            .replace(/(.* reco) ([^ ]+)( and finding )([^ ]+)/, (_, before, r, text, f)=>before + `<div class='path'><a href="https://git.ct-squa.re/dev/ruleset/-/tree/master/recos/${reco_category(r)}/${r}.yaml">${r}</a></div>` + text + `<div class='path'><a href="https://git.ct-squa.re/dev/ruleset/-/tree/master/findings/${finding_category(f)}/${f}.yaml">${f}</a></div>`)
                    }/>},
                    {title:t("Appeared"), render:x=>D(x.created_at, "short-abs-time")},
                    {title:t("Last encountered"), render:x=>D(x.updated_at, "short-abs-time")},
                    {title:t("Atlas version"), render:x=>x.atlas_version},
                    {render:x=><div><Button onClick={()=>fixLater(x)}>Fix later</Button></div>}
                ]}
            />
            </div>
        }
    }
}

