import { ref } from "@vue/reactivity"
import { watchEffect } from "@vue/runtime-core"
import { t } from "../i18n"
import './dates.scss'

export const fr_hm = (d) => (
  d.getHours() + "h" + (d.getMinutes() >= 10 ? d.getMinutes() : "0"+d.getMinutes())
  // + "m" + (d.getSeconds() >= 10 ? d.getSeconds() : "0"+d.getSeconds()) + "s"
)

export const isThisYear = (d) => (
    new Date().getFullYear() === d.getFullYear()
)

export const isYesterday = (d) => {
  const _24h = new Date(new Date().getTime() - 1000*60*60*24)
  return isSameDay(d, _24h)
}

export const isSameDay = (d1,d2) => {
  return d1.getDate() == d2.getDate() &&
    d1.getMonth() == d2.getMonth() &&
    d1.getFullYear() == d2.getFullYear()
}

export const isToday = (d) => {
  return isSameDay(d, new Date())
}


// NOTE : This function only supports 2 languages : EN, FR
export const i18n = (timestamp, long=false, always_time=false, abs=false) => {
  const d = new Date(timestamp)
  if(t('just now')==='just now') {
    return long ? long_en(d, always_time, abs) : short_en(d, always_time, abs)
  } else {
    return long ? long_fr(d, always_time, abs) : short_fr(d, always_time, abs)
  }
}

function long_en(d, always_time) {
  if(always_time || isToday(d) || isYesterday(d)) return d.toLocaleString("en-US", {day:'numeric',month:'short',year:'numeric',minute:'numeric',hour:'numeric'})
  else return d.toLocaleString("en-US", {day:'numeric',month:'short',year:'numeric'})
}

function short_en(d, always_time, abs) {
  if(!abs && isToday(d)) return d.toLocaleString("en-US", {minute:'numeric',hour:'numeric'})
  if(!abs && isYesterday(d)) return 'yesterday at ' + d.toLocaleString("en-US", {minute:'numeric',hour:'numeric'})
  if(isThisYear(d)) {
    if(always_time) return d.toLocaleString("en-US", {day:'numeric',month:'short', minute:'numeric',hour:'numeric'})
    else return d.toLocaleString("en-US", {day:'numeric',month:'short'})
  }
  if(always_time) return  d.toLocaleString("en-US", {day:'numeric',month:'short',year:'numeric',minute:'numeric',hour:'numeric'})
  else return d.toLocaleString("en-US", {day:'numeric',month:'short',year:'numeric'})
}

function long_fr(d, always_time) {
  if(always_time || isToday(d) || isYesterday(d)) return d.toLocaleString("fr-FR", {day:'numeric',month:'short',year:'numeric'}) + " à " + fr_hm(d)
  else return d.toLocaleString("fr-FR", {day:'numeric',month:'short',year:'numeric'})
}

function short_fr(d, always_time, abs) {
  if(!abs && isToday(d)) return fr_hm(d)
  if(!abs && isYesterday(d)) return "hier à " + fr_hm(d)
  if(isThisYear(d)) {
    if(always_time) return pretty(d.toLocaleString("fr-FR", {day:'numeric',month:'short', minute:'numeric',hour:'numeric'}))
    else return d.toLocaleString("fr-FR", {day:'numeric',month:'short'})
  }
  if(always_time) return  pretty(d.toLocaleString("fr-FR", {day:'numeric',month:'short',year:'numeric',minute:'numeric',hour:'numeric'}))
  else return d.toLocaleString("fr-FR", {day:'numeric',month:'short',year:'numeric'})
}

function pretty(dateStr) {
  return dateStr.replace(":", "h").replace(",", " à ")
}

const elapsedMonths = (timestamp) => (
  new Date().getMonth()-new Date(timestamp).getMonth()
  + (new Date().getFullYear()-new Date(timestamp).getFullYear())*12)


const getTime = (date) => {
  try { 
    if(!(date instanceof Date)) date = new Date(date)
    if(isNaN(date)) return null;
    return date.getTime()
  } 
  catch(e) { return null; }
}

export const D = (date, fmt) => {
  const d = getTime(date)
  if(!d) return '?';
  if(fmt==="text") return i18n(d, true, true)
  if(fmt==='long') return <span class="date long">
    <span>{i18n(d, true, true)}</span>
    <LiveDate date={d}/>
  </span>

  if(fmt==="short-abs-time") return <span class="date">{i18n(d, false, true, true)}</span>
  if(fmt==="normal" || !fmt) return  <span class="date">{i18n(d,true)}</span>
  if(fmt==='short-abs') return <span class="date">{i18n(d, false, false, true)}</span>
  if(fmt==='short') return <span class="date">{i18n(d)}</span>

  return <LiveDate date={d}/>
}

const LiveDate = {
  props:['date'],
  setup(props) {
    const x = ref();
    function update() {

      function up(v, refresh_seconds) {
        setTimeout(update, refresh_seconds*1000)
        x.value = v
      }

      if(!props.date) return;
      const d = new Date(props.date)
      const ts = d.getTime()
      let s = Math.floor((new Date().getTime()-ts)/1000)
      if(s<0) return;
      if(s<60) return up(t("just now"), 1)
      // if(s<60) return up(t_ago(s, "second"), 1)
    
      s = Math.floor(s/60)
      if(s<60) return up(t_ago(s, "minute"), 60)
    
      s = Math.floor(s/60)
      if(s<24) return up(t_ago(s,"hour"), 24*60)
    
      s = Math.floor(s/24)
      if(s<7) return up(isYesterday(d) ? t("yesterday") : t_ago(s,"day"), 24*60)
    
      s = Math.floor(s/7)
      if(s<5) return up(t_ago(s, "week"), 24*60)
    
      s = elapsedMonths(ts)
      if(s<12) return up(t_ago(s, "month"), 24*60)
      s = Math.floor(s/12)
      return up(t_ago(s, "year"), 24*60)
    }
    watchEffect(()=>update(props.date))
    return ()=><span class="live date">{x.value}</span>
  }
}


const t_ago = (n,unit) => {
  if(t('just now')==='just now') { 
    const plural = {}
    if(n>1) unit = plural[unit] || (unit+"s")
    return `${n} ${unit} ago`
  }
  else {
    const plural = {
      "mois":"mois"
    }
    if(n>1) unit = plural[t(unit)] || (t(unit)+"s")
    return `il y a ${n} ${t(unit)}`
  }
}


const parseMonth = (x)=>
    ["janvier","fevrier","mars","avril","mai","juin","juillet","aout","septembre","octobre","novembre","decembre"]
    .findIndex(m=>m.startsWith(x.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase().replace(/[^a-z]/g,"")))+1

export function parseDate(d) {
  if(!d) return null;
  d = d.replaceAll("à", " ")
  d = d.replaceAll("h", "/")
  d = d.replaceAll(":", "/")
  d = d.replace(/[\s]+/g, "/")
  let [day,month,year,hour=0,min=0,sec=0] = d.split("/")
  day = parseInt(day) || 0
  month = (parseInt(month) || parseMonth(month))-1
  year = parseInt(year) || 0
  hour = parseInt(hour) || 0
  min = parseInt(min) || 0
  sec = parseInt(sec) || 0
  return new window.Date(year, month, day, hour,min,sec)
}

export const days_before = (n) => new Date(new Date() - n*1000*3600*24)

export function parseInterval(x) {
  if(!x) return null
  x = x.toLowerCase()
  if(x==="now") return new Date()
  if(x.endsWith("ago")) {
    const now = new Date()
    let year = now.getFullYear()
    let month = now.getMonth()
    let day = now.getDate()
    let dt = +now - new Date(year, month, day)

    const [,years] = x.match(/([0-9]+)\s*y/) || []
    if(years) year -= parseInt(years)
    const [,months] = x.match(/([0-9]+)\s*mo/) || []
    if(months) month -= parseInt(months)
    const [,days] = x.match(/([0-9]+)\s*d/) || []
    if(days) day -= parseInt(days)

    let t = new Date(year, month, day).getTime() + dt
    
    const [,hours] = x.match(/([0-9]+)\s*h/) || []
    if(hours) t -= parseInt(hours) * 3600000
    const [,min] = x.match(/([0-9]+)\s*mi/) || []
    if(min) t -= parseInt(min) * 60000
    const [,s] = x.match(/([0-9]+)\s*s/) || []
    if(s) t -= parseInt(s) * 1000
    return new Date(t)
  }
  return new Date(x)
}


function date_to_bin(d, unit, bin_size) {
  const seconds = Math.floor(d.getTime() / 1000)
  if(unit==="second") return Math.floor(seconds / bin_size) * bin_size
  const minutes = Math.floor(seconds / 60)
  if(unit==="minute") return Math.floor(minutes / bin_size) * bin_size
  const hours = Math.floor(minutes / 60)
  if(unit==="hour") return Math.floor(hours / bin_size) * bin_size
  const days = Math.floor(hours / 24)
  if(unit==="day") return Math.floor(days / bin_size) * bin_size            
  const months = d.getFullYear()*12 + d.getMonth()
  if(unit==="month") return Math.floor(months / bin_size) * bin_size            
  const years = d.getFullYear()
  if(unit==="year") return Math.floor(years / bin_size) * bin_size            
}

function bin_to_date(b, unit) {
  if(unit==="second") return new Date(b*1000)
  if(unit==="minute") return new Date(b*60*1000)
  if(unit==="hour") return new Date(b*3600*1000)
  if(unit==="day") return new Date(b*3600*24*1000)
  if(unit==="month") return new Date(Math.floor(b/12), b%12-1)
  if(unit==="year") return new Date(b, 0)
}

export function short_form(d, unit, from, to) {
  const is_en = t('just now')==='just now'
  if(unit==="year") return d.getFullYear()
  if(unit==="month") {
      return d.toLocaleDateString(is_en ? "en-us" : "fr-fr", {year:"numeric", month:"short"})
  }
  if(unit==="day") {
    if(from?.getFullYear() === to?.getFullYear()) {
      return d.toLocaleDateString(is_en ? "en-us" : "fr-fr", {month:"short", day:"2-digit"})
    } else {
      return d.toLocaleDateString(is_en ? "en-us" : "fr-fr", {year:"numeric", month:"short", day:"2-digit"})
    }
  }
  
  if(d.getSeconds()) return d.toLocaleString(is_en ? "en-us" : "fr-fr", {
    hour:"numeric", 
    minute:"numeric", 
    second:"numeric",
  })

  if(from?.getDate() === to?.getDate()) return d.toLocaleString(is_en ? "en-us" : "fr-fr", {
    hour:"numeric", 
    minute:"numeric", 
  }).replace(":", "h").replace(":", "m")

  return d.toLocaleString(is_en ? "en-us" : "fr-fr", {
    year:d.getFullYear()===new Date().getFullYear() ? undefined : "numeric",
    month:"long", day:"numeric",
    hour:"numeric", 
    minute:"numeric", 
    second:d.getSeconds() ? "numeric" : undefined,
  }).replace(":", "h")
}

function compute_bins(from, to, unit, bin_size) {
  from = date_to_bin(from, unit, bin_size)
  to = date_to_bin(to, unit, bin_size)
  const bins = []
  for(var t = from; t<=to; t += bin_size) bins.push(t)
  return bins
}

export function convert_histogram_series({histogram={}, unit, from, to, bin_size}={}) {
  let matrix = {}
  const bins = compute_bins(new Date(from), new Date(to), unit, bin_size)
  for(const k in histogram) {
      const [status, t] = JSON.parse(k)
      if(!matrix[status]) matrix[status] = {}
      matrix[status][t] = histogram[k]
  }
  const x = bins.map(b=>short_form(bin_to_date(b, unit), unit, new Date(from), new Date(to)))
  const y = {}
  for(const k in matrix) {
      y[k] = bins.map(b=>matrix[k][b]||0)
  }
  return [x, y]
}

export function convert_histogram({histogram, unit, from, to, bin_size}) {
  const bins = compute_bins(new Date(from), new Date(to), unit, bin_size)
  return [
    bins.map(b=>short_form(bin_to_date(b, unit), unit, new Date(from), new Date(to))),
    bins.map(b=>histogram[b]||0),
    bins.map(b=>bin_to_date(b, unit))
  ]
}

export const D_FROM_TO = (dfrom, dto) => 
  dfrom ? 
  dfrom === dto ? D(dfrom, "short-abs-time") 
      : <>
          <div>{t("date:from")} {D(dfrom, "short-abs-time")}</div>
          <div>{t("date:to")} {D(dto, "short-abs-time")}</div>
      </>
  : null
