import { reactive, watchEffect } from "@vue/runtime-core"
import { model, API, getMembers } from "../model"
import { Accordion } from "../ui/Accordion"
import { Description } from "../ui/Description"
import { H1 } from "../ui/H"
import { ASSET_ICON, ICON, ICONS } from "../ui/icons"
import { Related } from "../ui/Related"
import { Sidebar } from "../ui/Sidebar"
import { Spinner } from "../ui/Spinner"
import { Timeline } from "../ui/Timeline"
import { capitalize, debounce } from "../util"
import './Finding.scss'
import { t, t_fem } from "../i18n"
import { Step } from "../ui/Chain"
import { InProgressIcon } from "../ui/InProgressIcon"
import { Button } from "../ui/Button"
import { gotoScope, link } from "../utils/routing"
import { Severity } from "../ui/Severity"
import { Date } from "../ui/Date"
import { Switch } from "../ui/Switch"
import { TagsMixin } from "../mixins/Tags"
import { Link } from "../utils/router"
import { useCurrentUserStore } from "../store/CurrentUserStore"

const userStore = useCurrentUserStore

const VISIBILITIES = ["public", "internal", "private", "waiting_for_response"]

const allowed_statuses = ["draft", "open", "done", "rejected", "validated"]


export const Finding = {
    setup() {
        const data = reactive({
            related_recommendations:[],
            vars:{
                related_recommendations:{},
            },
        })

        function setPublic(is_public) { API.setFinding(model.findingId, {is_public})}

        function sendMessage(body, visibility, files) {
            API.addComment("finding", model.findingId, files, {body, visibility})
            Array.from(body.matchAll(/#\\\[(.+?):(.+?)\\]\(.+?\)/gm)).forEach((x) => {
                const [_,type,id] = x
                API.addRelated("Finding", model.findingId, capitalize(type), id)
            })
        }
        function setVisibility(comment, visibility) { API.setComment(comment.id, {visibility}) }
        function saveMessage(comment, body) { API.setComment(comment.id, {body}) }
        function deleteMessage(comment) { API.removeComment(comment.id) }

        const {Tags} = TagsMixin("Finding")
        
        function deleteCommentObject(comment, o) { API.deleteCommentObject(comment.id, o.objType, o.objId) }

        function addRelatedRecommendation(r) { API.addRelatedRecommendation("finding", model.findingId, r.id) }
        function removeRelatedRecommendation(r) { API.removeRelatedRecommendation("finding", model.findingId, r.id) }
        function newRecommendation() { gotoScope(`newrecommendation?related_findings=${model.findingId}`); }

        const fetchMembers = async x => (await queryMembers(x)).map(m=>"@"+m.user.display_name.replace(/\s/g, "_"))
        
        const fetchObjects = async x => (await API.getObjects({search:x, scope_id:model.scope.id, type:["recommendation"]}))
                .map(({id,type,title})=>'#['+type+":"+id+"]("+title+")")

        const queryMembers = search => getMembers({search})
        const queryRecommendations = filter => API.getRecommendations({scope_id:model.scope.id, search:filter, order:"desc", sort:"updated_at"})
        
        watchEffect(async ()=>data.related_recommendations = model.finding?.id && model.scopeId && await API.getRelatedRecommendations("finding", model.scopeId, model.finding.id, data.vars.related_recommendations))

        const getScore = (criticity) => {
            switch(criticity) {
                case "silent" :
                    return <Severity val={0} />;
                case "low" :
                    return <Severity val={1} />;
                case "medium" :
                    return <Severity val={2} />;
                case "high" :
                    return <Severity val={3} />;
                case "critical" :
                    return <Severity val={4} />;
            };
        }

        watchEffect(() => {
            if (model.finding?.title.match(/CVE-\d{4}-\d{4,}/g)) {
                data.cve =  model.finding.title.match(/CVE-\d{4}-\d{4,}/g)?.join("")
            }
        })

        const getAssets = (assets) => {
            return Object.entries(assets).map((asset) => {
                if (asset[0].startsWith("device--")) {
                    return <Link href={link(`scope/${model.scopeId}/asset/${asset[0].split("--")[0]}?q=${asset[1]}&id=${asset[0]}`)}><span id="asset-badge">{ASSET_ICON("workstation")} {asset[1]}</span></Link>
                } else if (asset[0].startsWith("domainname--")) {
                    return <Link href={link(`scope/${model.scopeId}/asset/${asset[0].split("--")[0]}?q=${asset[1]}&id=${asset[0]}`)}><span id="asset-badge">{ASSET_ICON("domain")} {asset[1]}</span></Link>
                } else if (asset[0].startsWith("account--")) {
                    return <Link href={link(`scope/${model.scopeId}/asset/${asset[0].split("--")[0]}?q=${asset[1]}&id=${asset[0]}`)}><span id="asset-badge">{ASSET_ICON("account")} {asset[1]}</span></Link>
                } else if (asset[0].startsWith("activedirectory--")) {
                    return <Link href={link(`scope/${model.scopeId}/asset/${asset[0].split("--")[0]}?q=${asset[1]}&id=${asset[0]}`)}><span id="asset-badge">{ASSET_ICON("activedirectory")} {asset[1]}</span></Link>
                } else {
                    return <Link href={link(`scope/${model.scopeId}/asset/${asset[0].split("--")[0]}?q=${asset[1]}&id=${asset[0]}`)}><span id="asset-badge"><i className="fas fa-ethernet me-1/"/> {asset[1]}</span></Link>
                }
            })
        }

        return ()=>{
        if(!model.finding) return <Spinner/>
        const {tags, kraken_id, body, comments, impact, criticity, docs, assets, created_at, updated_at, is_public} = model.finding

        const allowed_tags = model.scope?.tags

        const findingViewUrlCategory = model.finding?.category.toLowerCase().replace("'", "-").split(" ").join("_").normalize('NFD').replace(/[\u0300-\u036f]/g, '')

        const RW = userStore.hasPermissions("findings", "write_private")

        return <div id="finding" class="page with-sidebar">
            <div>
            <div class="sticky-header">
                <h1>
                    <div class="head-page">
                        <div class="title-wrapper">
                            <H1 class="title" value={<div className="title-content">{model.finding.title}{ model.finding.title.match(/CVE-\d{4}-\d{4,}/g) && <a id="cve-link" target="_blank" href={`https://cve.mitre.org/cgi-bin/cvename.cgi?name=${data.cve}`}>CVE {ICONS["external-link"]}</a>}</div>}
                                render={x=><h1>{ICON('finding')} {x}</h1>}
                            />
                        </div>
                        {RW && <div className="buttons-gitlab">
                            <Button onClick={()=>{window.open(`https://git.ct-squa.re/dev/ruleset/-/blob/master/findings/${findingViewUrlCategory}/${model.finding?.template_id}.yaml`, '_blank', 'noreferrer');}}>{ICONS["eye"]}</Button>
                            <Button onClick={()=>{window.open(`https://git.ct-squa.re/dev/ruleset/-/edit/master/findings/${findingViewUrlCategory}/${model.finding?.template_id}.yaml`, '_blank', 'noreferrer');}}>{ICONS["edit"]}</Button>
                        </div>}
                    </div>
                </h1>

                <div class="row">
                     {RW && <Switch name="Public" value={is_public} onClick={()=>setPublic(!is_public)}>{t("Public")}</Switch> }

                    {Tags(model.finding)}
                </div>

                <section className="box">
                    <span>{t("Details")}</span>

                    <Description
                        title="Description"
                        source={body}
                        />

                    <Description
                        title="Impact"
                        source={impact}
                        />

                    <Description
                        title="Docs"
                        source={docs}
                        />
                    
                    <div className="assets-section">
                        <span className="assets-title">Assets</span>
                        <div className="assets">{getAssets(assets)}</div> 
                    </div>
                </section>


                <br/>
                </div>
                <div>
                    <Timeline
                        items={comments}
                        editable={RW} 
                        onSend={sendMessage}
                        onSave={saveMessage}
                        onDelete={deleteMessage}

                        visibilities={RW && VISIBILITIES}
                        setVisibility={RW && setVisibility}

                        fetchUsers={fetchMembers}
                        fetchObjects={fetchObjects}

                        onDeleteObject={RW && deleteCommentObject}
                        previousSelector={".sticky-header"}
                    />
                </div>
            </div>

            <Sidebar>

                <Accordion>

                    <div class="dates">
                        {RW && <><div>{t("Ruleset")}</div><div>{kraken_id}</div></>}
                        <div>{t("Created")}</div><div><Date  value={created_at}/></div>
                        <div>{t("Last activity")}</div><div><Date  value={updated_at}/></div>

                    <div id="criticity">
                        <h3>{t("Criticity")}</h3>
                        {getScore(criticity)}
                    </div>
                    </div>

                    <Related type='recommendation' title="Recommendations" items={data.related_recommendations}
                        query={queryRecommendations}
                        // editable={RW}
                        watch={()=>model.finding}
                        onAdd={addRelatedRecommendation}
                        onDelete={removeRelatedRecommendation}
                        addTooltip="Add related recommendation"
                        update={debounce(v=>data.vars.related_recommendations.search=v?.search)}
                        footer={<Button secondary onClick={newRecommendation}>{ICON('add')} {t("New recommendation")}</Button>}
                    />

                </Accordion>

            </Sidebar>
        </div>
        }
    }
}
