import { reactive } from 'vue'
import { Accordion, AccordionItem } from './Accordion'
import './DBG.scss'
import { ICON } from './icons'

export const DBG_INFOS = reactive({
    livequeries:{}
})

export const DBG = {
    setup() {
        return ()=>{
            const LQ = Object.values(DBG_INFOS.livequeries||{})
            return <div class="dbg toast show">
            <Accordion>
                <AccordionItem title={<>Livequeries ({LQ.length})</>}>
                    <ul>{LQ.map(({query,vars,ticks=0, listeners, throttle, interval})=>(
                        <li>{query} {vars ? `(${JSON.stringify(vars)}) ` : '() '} 
                            ({listeners} listeners)&nbsp;
                            {ticks>0 && <div style={{float:"right", marginLeft:"75px"}}>{ICON("fas fa-wand-sparkles")} {ticks} </div>}
                            {throttle>0 && <>throttle: {throttle} </>}
                            {interval>0 && <>interval: {interval} </>}
                        </li>
                    ))}</ul>
                </AccordionItem>
            </Accordion>
            </div>
        }
    }
}
