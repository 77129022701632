import { API, getTags } from "../model";
import { createTagDialog } from "../ui/Tags";
import { capitalize } from "../util";
import { Tags as UITags } from "../ui/Tags"
import { useCurrentUserStore } from "../store/CurrentUserStore";

const userStore = useCurrentUserStore

export const TagsMixin = (type, {onAdd, onRemove, refresh}={}) => {
    type = capitalize(type)

    async function addTag(a, t) {
        if(onAdd) await onAdd(t, a).then(refresh)
        else await API.tag(type, a.id, t)
        refresh?.()
    }
    
    async function removeTag(a, t) {
        if(onRemove) await onRemove(t, a)
        else await API.untag(type, a.id, t).then(refresh) 
        refresh?.()
    }
    
    async function createTag(o, tag) { 
        const forbid = getTags(o?.scope_id, type)?.map(x=>x.name)
        const res = await createTagDialog(tag, o?.scope_id, type, forbid)
        if(!res) return;
        const {name, color, scoped, typed} = res
        await API.createTag(name, color, scoped ? o?.scope_id : null, typed ? type : null)
        if(o) return await addTag(o, tag)
    }

    const Tags = (o) => <UITags
        tags={o.tags}
        editable={userStore.hasPermissions("tags", "write_private")}
        allowed_tags={getTags(o.scope_id, type)}
        onAdd={x=>addTag(o,x)}
        onDelete={x=>removeTag(o,x)}
        onCreate={x=>createTag(o,x)}
    />

    return {addTag, removeTag, createTag, Tags}
}