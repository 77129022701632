import { reactive } from 'vue'
import { capitalize, nl2br } from '../util'
import './Donut.scss'

export const Donut = {
    props:['data', 'innerRadius', 'showEmpty', 'title'],
    setup(props) {
        const ui = reactive({
            hover:-1
        })
        return ()=>{
            const {hover} = ui
            const {data, innerRadius=.45, showEmpty} = props
            if(!data) return;

            const sum = data.reduce((s,{data})=>s+data,0)
            let pct = data.map(({data})=>data/sum)
            const acc = [...pct]
            acc.forEach((_,i)=>acc[i]=(acc[i-1]||0)+(pct[i-1]||0))
        
            const positionLabel = (pct, r) => ({x:16+r*Math.cos(pct*6.28), y:16+r*Math.sin(pct*6.28)})
        
            const empty = pct.some(x=>isNaN(x)) || pct.length===0
            if(empty && showEmpty) pct = [1]

            return <div class="chart donut" class={{hover:hover!==-1, empty}} >
                {/* <svg viewBox="0 0 33 33">
                <defs>
                <mask id="progress" maskUnits="userSpaceOnUse">
                    <rect x="-1" y="-1" width="100%" height="100%" fill="#fff"/>
                    <circle r="50" cx="50" cy="50" fill="none" stroke="#000" stroke-width="10" stroke-dasharray="146 192" transform="rotate(-227, 34.5, 34.5)"/>
                </mask>
                </defs>
                <g class="graph-arc" transform="translate(1,1)" mask="url(#progress)">
                    {pct.map((pct,i)=>pct && <Fragment key={i}>
                        <path fill={data[i].color} pct={pct} class={{hover:hover===i, hasLink:data[i].goTo}}
                                d={sectorPath(16, 16, 16, pct, acc[i], innerRadius*16)}
                                onmouseover={e=>ui.hover = i}
                                onmouseout={e=>ui.hover = -1}
                                onClick={data[i].goTo}
                        />
                        <text {...positionLabel(acc[i]+(pct+0.01)/2, (1+innerRadius)/2*16)} class={{hover:hover===i, hasLink:data[i].goTo}}>
                            {data[i].data}
                        </text>
                    </Fragment>)}
                </g>
                </svg> */}
                <svg viewBox="0 0 33 33" xmlns="http://www.w3.org/2000/svg">
    <defs>
        <mask id="progress" maskUnits="userSpaceOnUse">
            <rect x="-1" y="-1" width="100%" height="100%" fill="#fff"/>
            <circle fill="none" stroke="#000" transform="" cx="16" cy="16" stroke-width="10" r="12" stroke-dasharray="146 192" transform="rotate(-227, 34.5, 34.5)" />
            {/* <circle r="30.5" cx="34.5" cy="34.5" fill="none" stroke="#000" stroke-width="5" stroke-dasharray="146 192" transform="rotate(-227, 34.5, 34.5)"/> */}
        </mask>
    </defs>
    <g mask="url(#progress)">
    {pct.map((pct,i)=>pct && <Fragment key={i}>
                        <path fill={data[i].color} pct={pct} class={{hover:hover===i, hasLink:data[i].goTo}}
                                d={sectorPath(16, 16, 16, pct, acc[i], innerRadius*16)}
                                onmouseover={e=>ui.hover = i}
                                onmouseout={e=>ui.hover = -1}
                                onClick={data[i].goTo}
                        />
                        <text {...positionLabel(acc[i]+(pct+0.01)/2, (1+innerRadius)/2*16)} class={{hover:hover===i, hasLink:data[i].goTo}}>
                            {data[i].data}
                        </text>
                    </Fragment>)}
    </g>
</svg>
                {(!empty || showEmpty) && <div class='legend'>
                    {props.title && <h5>{props.title}</h5>}
                    {data.map(({color, label, data, goTo, showZero = false}, i) =>
                        (data > 0 || showZero) &&
                        <div key={i} class={{hover: hover === i, hasLink: goTo}} onmouseover={e => ui.hover = i}
                            onmouseout={e => ui.hover = -1} onClick={goTo}>
                            <span class='square' style={{background: color}}/>
                            <div>
                                <text>{nl2br(capitalize(label))}</text>
                            </div>
                        </div>)
                    }
                </div>}
            </div>        
        }
    }   
}

const sectorPath = (r, cx, cy, pct, offsetPct=0, innerRadius=0) => {
    const polars = [[offsetPct,r],[offsetPct+pct,r],[offsetPct+pct,innerRadius],[offsetPct,innerRadius]]
    const [A,B,C,D] = polars.map(([phi,rho])=>[
        cx + Math.cos(phi*6.28)*rho,
        cy + Math.sin(phi*6.28)*rho
    ].join(" "))
    return `M${A} A${r} ${r} 0 ${pct>0.5?1:0} 1 ${B} L${C} A${innerRadius} ${innerRadius} 0 ${pct>0.5?1:0} 0 ${D} Z`
}

