import "./Rules.scss"
import { model } from "../model"
import { LiveQuery } from "../api/livequery"
import { DataTable } from "../ui/DataTable"
import { Button } from "../ui/Button"
import { Link } from "../utils/router"
import { link } from "../utils/routing"
import { optionsFromQueryParams, queryParams } from "../utils/queryParams"
import { Severity } from "../ui/Severity"
import { Tags } from "../ui/Tags"

import { ICONS } from "../ui/icons"
import { t } from "../i18n"
import { ref, reactive } from "@vue/reactivity"

export const Rules = {
    setup() {
        const guidInput = ref(null);
        const data = reactive({
            isCopied: false,
            guidCopied: ""
        })
        const lq = LiveQuery("rules", `{pages, count, items{
                  rule_id,
                  alerts_count, 
                  name,
                  description,
                  git_url,
                  severity,
                  tags,
                  git_edit_url,  
                }}`, 
            x=>model.rules = x, ()=>[{
                limit:parseInt(queryParams().limit) || 20,
                ...optionsFromQueryParams()
            }],
            {interval:30000}
            )

        const getScore = (severity) => {
            switch(severity) {
                case "low" :
                    return <Severity val={1} />;
                case "medium" :
                    return <Severity val={2} />;
                case "high" :
                    return <Severity val={3} />;
                case "critical" :
                    return <Severity val={4} />;
            };
        }

        function copyGuid(guid) {
            navigator.clipboard.writeText(guid);
            data.isCopied = true
            data.guidCopied = guid

            setTimeout(() => {
                data.isCopied = false
            }, 2000)
        }

        return () => {
            return <div>
                <div className="header">
                    <h1>Rules</h1>
                </div>
                <DataTable
                loading={lq.loading}
                data={model.rules}
                columns={[
                    {title:"ID", field:"rule_id", render: x=><div onClick={() => copyGuid(x.rule_id)} className={data.isCopied && data.guidCopied===x.rule_id ? "copied-color" : "guid"}>{data.isCopied && data.guidCopied===x.rule_id ? "Copied!" : x.rule_id}</div>},
                    {title:"Name", field:"name", render: x=><div><Link href={link(`rule/${x.rule_id}`)}>{x.name}</Link></div>},
                    {title:"Description", field:"description", render: x=><div><Link href={link(`rule/${x.rule_id}`)}>{x.description}</Link></div>},
                    {title:"Alerts", field:"alerts_count", render: x=><div>{x.alerts_count < 1 ? ICONS["unlink"] : <span className="nb-alerts">{x.alerts_count}</span>}</div>},
                    {title:"Severity", field:"severity", render: x=><div>{getScore(x.severity)}</div>},
                    {title:"Tags", field:"tags", render: x=><div>{<Tags tags={x.tags}/>}</div>},
                    {title:"Git", field:"git_url", render: x=><div><Link onClick={()=>{window.open(x.git_url, '_blank', 'noreferrer');}}>{ICONS["eye"]}</Link></div>},
                    {title:"Editer", field:"git_edit_url", render: x=><div><Link onClick={()=>{window.open(x.git_edit_url, '_blank', 'noreferrer');}}>{ICONS["edit"]}</Link></div>}
                ]}
                />
            </div>
        }
    }
}