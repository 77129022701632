import { model, API } from "../model"
import { Form } from "../ui/Form"
import { t } from "../i18n"
import "./ScopeSettings.scss"
import {debounce} from "../util";
import { useCurrentUserStore } from "../store/CurrentUserStore";

const userStore = useCurrentUserStore

export const ScopeSettings = {
    setup() {
        function save(data) {
            API.setScope(model.scopeId, data)
        }
        return ()=>{
            const {scope} = model
            if(!scope) return;
            return <div id="scope-settings">
                <h1><img src={scope.logo}/> {scope.display_name} configuration</h1>
                
                
                <Form object={scope}
                    onSave={userStore.hasPermissions("scopes", "write") && save} 
                    fields={{
                        display_name:{name:t("Name")},
                        logo:{type: 'image'},
                        kibana_space:{name:'Kibana space',},
                        scope_identifier:{name: 'Scope Identifier', test:debounce(async (x,set) => {
                            const t = await API.scopeIdentifierTest(scope.id, x);
                            set(t)
                        }, 1000)},
                        magellan_url:{name:'Magellan URL', 
                            //     test:debounce(async (x,set) => {
                            //     const t = await API.assetsTest(x)
                            //     set(t)
                            // }, 1000)
                        },
                        locked:{name:t("Locked"), type:'switch'},
                        api_token:{name:t("API Token"), render:()=><input id='api-token' value={scope.api_token} disabled size={scope.api_token?.length}/>},
                        shuffle_url:{name:t("Shuffle URL")},
                        shuffle_key:{name:t("Shuffle Key")}
                    }}
                />
            </div>
        }
    }
}
