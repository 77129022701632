<script setup>
    import "./SettingsApi.scss"
    import { API } from "../../model"
    import { reactive, ref } from "vue"
    import {t} from "../../i18n/index"
    import { h } from "vue"
    import { watchEffect } from "vue"
    import { Spinner } from "../../ui/Spinner"
    import { DataTable } from "../../ui/DataTable"
    
    const data = reactive({
        token: null,
        deprecatedToken: null,
    })
    
    watchEffect(async () => {
        data.token = await API.getAllApiSession()
        data.deprecatedToken = await API.getDeprecatedTokens()
    })

</script>

<template>
    <div id="api-settings">
        <h1>Api</h1>
        <div v-if="data.token && data.deprecatedToken">
            <div class="section">
                <h2>Api session</h2>
                <DataTable 
                :no-search="true"
                :data="data.token"
                :columns="[
                    {title:t('Name'), type:'field', render:x=>x.name},
                    {title:t('Rights'), type:'rights', render: x=>x.rights.split(',').map(x=>h('p',x))},
                    {title:t('Revoked'), type:'field', render:x=>t(x.revoked ? 'yes' : 'no')},
                    {title:t('Created'), type:'date', render:x=>x.created_at},
                    {title:t('Last seen'), type:'date', render:x=>x.last_seen ? x.last_seen : t('never')},
                    ]"
                />
            </div>
            <div class="section">
                <h2>Deprecated api key</h2>
                <DataTable 
                :data="data.deprecatedToken"
                :no-search="true"
                :columns="[
                    {title:t('Controller'), type:'field', render:x=>x.controller},
                    {title:t('Payload'), type:'JSON', render:x=>x.payload},
                    {title:t('Last seen'), type:'date', render:x=>x.last_seen},
                    ]"
                />
            </div>
        </div>
        <div v-else>
            <Spinner/>
        </div>
    </div>
</template>