import { queryParams, setQueryParams } from "../utils/queryParams"
import { debounce, max, min, range } from "../util"
import { D } from "./dates"
import './DataTable.scss'
import { ICON } from "./icons"
import { Input} from './Input'
import { t } from "../i18n"
import { Select, SelectItem } from "./Select"
import { Button, DeleteButton } from "./Button"
import { reactive } from "@vue/reactivity"
import { onMounted, watch } from "@vue/runtime-core"
import { Checkbox } from "./Checkbox"
import { clickAsEnter } from "../api/mock/util"

export const DataTable = {
    props: ['classSelect', 'title', 'placeholder', 'searchTooltip', 'selection', 'buttons', 'headerBottom', 'noHeader', 'defaultSort', 'defaultFilter', 'loading', 'limit', 'columns', 'emptyText', 'rowClass', 'data', 'page', 'setPage', 'onRowClick', 'onDelete', 'update', 'header', 'noToolbar', 'footer', 'headerLeft', 'collapse', 'onExpand', 'noSearch', 'subrow'],
    setup(props) {
        const ui = reactive({search:"", sort:null, order:null, page:1, collapsed:null, columnSort:[], searched:""})
        const hover = reactive({hover:null})
        const rended = reactive({data:[]})

        watch(ui,()=>props.update?.(ui))


        const setSearch = (search) => {
            ui.collapsed = null
            if(!!props.update) {
                ui.search = search
                ui.page = 1
            }
            else setQueryParams({search, page: 1}, true)
        }

        const countChoices = [10, 20, 50]

        return ()=>{
            let {
                columns,
                data,
                noHeader, noToolbar, noSearch,
                placeholder = "Search...",
                searchTooltip,
                classSelect,
            } = props
            const noQueryParams = !!props.update

            if(!columns) { columns=[{render:x=>x}]; noHeader = true; }

            columns = columns.filter(c=>!!c)
            
            if(ui.columnSort.length !== columns.length){
                ui.columnSort = Array(columns.length).fill(-1)
                columns.map((c,i)=>c.sort && (ui.columnSort[i] = 0))
            }

            let {page, search, order, sort:sortField} = ui

            if(props.setPage) page = props.page

            if(!noQueryParams) {
                page = props.page || parseInt(queryParams().page) || 1
                search = queryParams().search || ui.searched
                sortField = queryParams().sort
                order = queryParams().order==="desc" ? "desc" : "asc"
            }

            if(!sortField) {
                sortField = props.defaultSort?.sort
                order = queryParams().order==="desc" ? "desc" : queryParams().order==="asc" ? "asc" : props.defaultSort?.order
            } 

            const [items, pages] = Array.isArray(data) ? [data, 1] : data ? [data.items, data.pages] : [[],1]

            if(rended.data.length === 0){
                items.map((x,i)=>{
                    rended.data[i] = []
                    columns.map(({type,render=x=>x})=>{
                        rended.data[i].push(x.rended=renderField(type, render(x,i), i))
                    })
                })
            }

            if(pages>1) {
                if(page>pages) setPage(pages)
                else if(page<1) setPage(1)
            }

            function setPage(page) {
                ui.collapsed = null
                if(props.setPage) props.setPage(page)
                else if(noQueryParams) ui.page = page
                else setQueryParams({page})
            }
            
            function setSort(sort, valOrder) {
                if(noQueryParams) ui.sort = sort
                else setQueryParams({sort, order:valOrder===0 ? null : valOrder===1 ? "asc":"desc"})
            }

            function toggleSelectItem({id}) {
                if(props.selection.includes(id)) props.selection.splice(props.selection.indexOf(id),1)
                else props.selection.push(id)
            }

            function toggleSelectAll() {
                if(props.selection?.length === items?.length) props.selection.splice(0, props.selection.length)
                else {
                    props.selection.splice(0, props.selection.length, ...items?.map(({id})=>id)||[])
                }
            }

            function columnSortIcon(colId) {
                let v = ui.columnSort[colId]
                if(v===-1) return 
                else if(v===0) return ICON("double_sort")
                else if(v===1) return ICON("sort_up")
                else if(v===2) return ICON("sort_down")
            }

            function changeColumnSort(val, colId) {
                if(ui.columnSort[colId] === -1) return
                ui.columnSort[colId] = (ui.columnSort[colId]+1)%3
                ui.columnSort.map((v,i)=>i!==colId && v !== -1 && (ui.columnSort[i] = 0))
                setSort(val.sort, ui.columnSort[colId])
            }

            let input = document.querySelector(".search")
            input && clickAsEnter({input:input, id:"submit"})

            return <div class="datatable" class={{multipage:pages>1, loading:props.loading}}>   
            {!noHeader && !noToolbar && <div class="header">
                
                {props.title && <h1 class='left'>{props.title}</h1>}
                {props.headerLeft && <span class='left'>{props.headerLeft}</span>}
                {props.buttons}
                <div className="rights-filters">
                    {!noSearch && <Input class="search" title={t(searchTooltip)} placeholder={t(placeholder)} value={ui.searched} onChange={e=>ui.searched = e.target.value} debounce={500} pre={ICON("search")}/>}
                    {!noSearch && <Button id="submit" onClick={() => setSearch(ui.searched)}>{t("Enter")}</Button>}
                    {props.header}
                </div>
            </div>}

            {props.headerBottom}

            <div><table class={{empty:!items?.length}}>
                {!noHeader && <thead>
                    <tr>
                        {props?.collapse && <th class='collapse-head'/>}
                        {!!items?.length && props?.selection && <th title={t("Select all")}><Checkbox value={props.selection.length && props.selection.length===items?.length} onClick={toggleSelectAll} /></th>}
                        {columns.map((c, i)=><th class={[c.type]} width={c.width} ><span onClick={()=>{changeColumnSort(c, i)}}>{c.title||""} {columnSortIcon(i)}</span></th>)}
                        {props.onDelete && <th/>}
                    </tr>
                </thead>}
                <tbody>
                    {items?.length ? items.map((x,i)=>
                        <><tr key={x.id} class={props?.rowClass?.(x)} class={{hover: hover.hover === i}} 
                        onClick={(e)=> {
                            if (props?.collapse?.(x)) {
                                if (ui.collapsed === i) ui.collapsed = null
                                else {
                                    ui.collapsed = i
                                    props?.onExpand?.(x,i)
                                }
                            }
                            props.onRowClick?.(x, e)
                        }}>

                            {props?.collapse && <td>
                                <div>
                                    {props?.collapse?.(x) && (ui.collapsed === i ? ICON("chevron-down") : ICON("chevron-right"))}
                                </div>
                            </td>
                            }

                            {props?.selection && 
                                <td class="noLink checkbox" onClick={()=>toggleSelectItem(x)}>
                                <div>
                                    <Checkbox value={props.selection.includes(x.id)}/>
                                </div>
                                </td>
                            }

                            {columns.map(({type,render=x=>x,width}, j)=><td class={[type]} width={width}>
                                <div>{renderField(type, render(x,i), i)}</div>
                            </td>)}
                            {props.onDelete && <td class="noLink delete"><DeleteButton onClick={()=>props.onDelete(x, i)}/></td>}
                        </tr>

                            {props.subrow && <tr class='subrow' class={{hover: hover.hover === i}} onmouseover={()=>hover.hover = i} onmouseout={()=>hover.hover = null}>
                                <td colSpan={props.columns.length}><div>{props.subrow(x)}</div></td>
                            </tr>}

                            {ui.collapsed === i && <tr class='collapsed-row'>
                                <td colSpan={props.columns.length + 1}>
                                    {props.collapse(x)}
                                </td>
                            </tr>
                            }
                        </>
                    ) 
                    : <tr><td colSpan={columns.length}>
                        {props.loading ? false : (props.emptyText || t("No result"))}
                      </td></tr>
                    }
                </tbody>
            </table></div>

            {pages>1 && <div class="footer">
                <div class="pages">
                <Select placeholder={t("Page size")} displayNone={classSelect} items={()=>
                            countChoices.map(count=><SelectItem onClick={()=>setQueryParams({limit: count})}>{count}</SelectItem>)
                        } /> 
                    {<button disabled={page<=1} class="nav" onClick={()=>setPage(page-1)}>{ICON("left")}</button>}
                    {pages <= 10 ? range(pages).map(i=><button class={{active:i+1===page}} onClick={()=>setPage(i+1)}>{i+1}</button>)
                    : <>
                    {page > 5 && <button class={{active:1===page}} onClick={()=>setPage(1)}>{1}</button>}
                    {page > 6 && <span>...</span>}
                    {range(9).map(i=>i+min(max(page-4,1),pages-8)).map(i=><button class={{active:i===page}} onClick={()=>setPage(i)}>{i}</button>)}
                    {page < pages - 5  && <span>...</span>}
                    {page < pages - 4 && <button class={{active:pages===page}} onClick={()=>setPage(pages)}>{pages}</button>}
                    </>  
                    }
                    {<button disabled={page>=pages} class="nav" onClick={()=>setPage(page+1)}>{ICON("right")}</button>}
                
                </div>
            </div>}

            {props.footer && <div>{props.footer}</div>}
        </div>
        }
    }
}


export const renderField = (type, x, i) => {
    const render = {
        "date": x=>D(x, 'short'),
        "date-abs": x=>D(x, 'short-abs-time')
    }[type]
    if(render) return render(x, i)
    return x
}
  