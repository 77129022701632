import { reactive } from "@vue/reactivity"
import { IconButton } from "./Button"
import { ICON } from "./icons"
import { Input } from "./Input"
import './H.scss'
import { watchEffect } from "@vue/runtime-core"

export const H = {
    props:['render', 'value', 'level', 'onSave', 'editing', 'validate'],
    setup(props) {
        const data = reactive({
            editing:props.editing || false,
            value:props.value || ""
        })
        watchEffect(()=>data.value = props.value || "")
        return ()=>{
            const error = props.validate && !props.validate(data.value)
            return !data.editing ? 
                props.render(<>
                    {props.value} 
                    {props.onSave && <IconButton class="edit" onClick={()=>data.editing=!data.editing}>{ICON("edit")}</IconButton>}
                </>)
            : 
                props?.render(<div class={["h-editing", "level-"+(props?.level||1)]} class={{error}}>
                    <Input autofocus value={data.value} onInput={e=>data.value = e.target.value}/>
                    <IconButton class='edit' disabled={error} onClick={async ()=>{await props.onSave?.(data.value); data.editing=false;}}>{ICON("ok")}</IconButton>
                    <IconButton class="edit" onClick={()=>{data.value = props.value||""; data.editing = false;}}>{ICON("close")}</IconButton>
                </div>)
        }
    }
}


export const H1 = (props, {slots}) => <H render={x=><h1 class="h-editable">{slots?.default?.() || x}</h1>} {...props} level={1} />
export const H2 = (props, {slots}) => <H render={x=><h2 class="h-editable">{slots?.default?.() || x}</h2>} {...props} level={2} />
export const H3 = (props, {slots}) => <H render={x=><h3 class="h-editable">{slots?.default?.() || x}</h3>} {...props} level={3} />
export const H4 = (props, {slots}) => <H render={x=><h4 class="h-editable">{slots?.default?.() || x}</h4>} {...props} level={4} />
export const H5 = (props, {slots}) => <H render={x=><h5 class="h-editable">{slots?.default?.() || x}</h5>} {...props} level={5} />
export const H6 = (props, {slots}) => <H render={x=><h6 class="h-editable">{slots?.default?.() || x}</h6>} {...props} level={6} />
