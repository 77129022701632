import {reactive} from "@vue/reactivity"
import {API, LQ, model} from "../model"
import {t, t_fem} from "../i18n";
import {capitalize} from "../util";
import './Vulnerability.scss'
import {Sidebar} from "../ui/Sidebar";
import {ICON} from "../ui/icons";
import {Badge} from "../ui/Badge";
import {modal} from "../ui/Modal";
import {Dialog} from "../ui/Dialog";
import {JSONView} from "../ui/JSONView";
import {Dropdown, DropdownItem} from "../ui/Dropdown";
import {Spinner} from "../ui/Spinner";
import {H1, H2} from "../ui/H";
import {Button, IconButton} from "../ui/Button";
import {Description} from "../ui/Description";
import { CVSS_COLOR, CVSS_EXPLOITABILITY, CVSS_IMPACT, NotifyVuln } from "./Vulnerabilities";
import { D } from "../ui/dates";
import { AccordionItem, Section } from "../ui/Accordion";
import { FilteredDropdown } from "../ui/FilteredDropdown";
import { Checkbox } from "../ui/Checkbox";
import { DataTable } from "../ui/DataTable";
import { propsToAttrMap } from "@vue/shared";
import { link } from "../utils/routing";
import { useCurrentUserStore } from "../store/CurrentUserStore";

export const EXPLOITATIONS = ["unknown", "likely", "in the wild", "public"]

const userStore = useCurrentUserStore

export const Vulnerability = {
    setup() {
        const data = reactive({
            vars: {
                scopes: {}
            }
        })

        function refresh() {
            LQ.vulnerability?.refresh()
        }

        function openJSON() {
            return modal(({close}) =>
                <Dialog close={close} onSubmit={() => close(data)}
                        header={t("JSON object")}
                        body={<JSONView source={model.vulnerability?.original}/>}
                />
            )
        }

        function setDescription(description) { API.updateVulnerability(model.vulnerabilityId, {description}) }
        function setTitle(title) { API.updateVulnerability(model.vulnerabilityId, {title}) }
        function setRemediation(remediation) { API.updateVulnerability(model.vulnerabilityId, {remediation}) }
        function setExploitation(exploitation) { API.updateVulnerability(model.vulnerabilityId, {exploitation}) }

        return () => {
            if(!model.vulnerability) return <Spinner/>

            const RW = userStore.hasPermissions("vulnerabilities", "write")

            const original = typeof(model.vulnerability?.original)==="string" ? JSON.parse(model.vulnerability?.original) : model.vulnerability?.original
            const products = getVulnerabilityProducts(original?.vulnerable_product)

            const exploitability = CVSS_EXPLOITABILITY(original?.['cvss-vector'])
            const impact = CVSS_IMPACT(original?.['cvss-vector'])

            return <div id="vulnerability" class="page with-sidebar">
                <div>
                    <h1>
                        <div className="flex">
                            <div className="title-wrapper">
                                {ICON("vulnerability")}
                                <H1 class="title" value={model.vulnerability.cve_id}/>
                            </div>
                            <H2 class="title" onSave={RW && setTitle} value={model.vulnerability.title}/>
                        </div>
                        <div className="exploitation">
                            {RW ?
                                <Dropdown button={<span>Exploitation <Badge class={model.vulnerability.exploitation?.replace(/\s+/g, '')}>{capitalize(t_fem(model.vulnerability.exploitation || "unknown"))}</Badge></span>} items={()=>
                                    EXPLOITATIONS.map(s=><DropdownItem onClick={()=>setExploitation(s)}>
                                        {capitalize(t_fem(s))}
                                    </DropdownItem>)
                                }/>
                                : <span>Exploitation <Badge  class={model.vulnerability.exploitation?.replace(/\s+/g, '')}>{capitalize(t_fem(model.vulnerability.exploitation || "unknown"))}</Badge></span>}
                        </div>
                    </h1>

                    <div class="flex-row">
                        <div>
                            {(RW || model.vulnerability.description) && <Description
                                editable={RW}
                                title="Description"
                                source={model.vulnerability.description}
                                onSave={setDescription}
                                placeholder={t("No description")}
                            />}
                            {(RW || model.vulnerability.remediation) && <Description
                                editable={RW}
                                title={t("Countermeasures")}
                                source={model.vulnerability.remediation}
                                onSave={setRemediation}
                                placeholder={t("No countermeasure")}
                            />}
                        </div>
                    </div>

                    <div class='vulnerability-fields'>
                        {/* <div>{original?.summary}</div> */}

                        {original?.references && <div class="references">
                            <h2>{t("References")}</h2>
                            {original?.references.map(r => <a href={r} target='_blank'>{r}</a>)}
                            {original?.id && <>
                                <p class="ref">{ICON("go")} <strong>NVD</strong> <a target='_blank' href={`https://nvd.nist.gov/vuln/detail/${original?.id}`}>{`https://nvd.nist.gov/vuln/detail/${original?.id}`}</a></p>
                                <p class="ref">{ICON("go")} <strong>Mitre CVE</strong> <a target='_blank' href={`https://cve.mitre.org/cgi-bin/cvename.cgi?name=${original?.id}`}>{`https://cve.mitre.org/cgi-bin/cvename.cgi?name=${original?.id}`}</a></p>
                            </>}
                        </div>}
                    </div>
                </div>
                <Sidebar>
                    <div class="scores">
                        {original?.cvss && <table>
                            <tr><td colSpan="6">
                            <div class="nvd-dates">
                            {original?.Published && <p><strong>{t('Published')} : </strong>{D(original?.Published)}</p>}
                            {original?.Modified && <p><strong>{t('Updated')} : </strong>{D(original?.Modified)}</p>}
                            </div>
                            </td></tr>
                            <tr class='cvss-score'><td>{t("CVSS score")}</td><td><div class="score" style={{background:CVSS_COLOR(original?.cvss)}}>{original?.cvss.toFixed(1)}</div></td>
                            <td>{t("Exploitability")}</td><td><div class="score" style={{background:CVSS_COLOR(exploitability)}}>{exploitability.toFixed(1)}</div></td>
                            <td>{t("Impact")}</td><td><div class="score" style={{background:CVSS_COLOR(impact)}}>{impact.toFixed(1)}</div></td></tr>
                            <tr><td colSpan="2">{t('Access Vector')}<br/><Badge class={'vector ' + original?.access.vector}>{t(original?.access.vector)}</Badge></td>
                            <td colSpan="2">{t('Access Complexity')}<br/><Badge class={'complexity ' + original?.access.complexity}>{t_fem(original?.access.complexity)}</Badge></td>
                            <td colSpan="2">{t('Authentication')}<br/><Badge class={'authentication ' + original?.access.authentication}>{t_fem(original?.access.authentication)}</Badge></td></tr>
                            <tr><td colSpan="2">{t('Confidentiality Impact')}<br/><Badge class={'confidentiality ' + original?.impact.confidentiality}>{t(original?.impact.confidentiality)}</Badge></td>
                            <td colSpan="2">{t('Integrity Impact')}<br/><Badge class={'integrity ' + original?.impact.integrity}>{t(original?.impact.integrity)}</Badge></td>
                            <td colSpan="2">{t('Availability Impact')}<br/><Badge class={'availability ' + original?.impact.availability}>{t(original?.impact.availability)}</Badge></td></tr>
                        </table>}
                    </div>

                    {original && <div class="json-button">{ICON("json")} <strong>JSON : </strong> <IconButton onClick={openJSON}>{ICON("eye")}</IconButton></div>}

                    {!model.scopeId && model.vulnerability.status!=="deleted" && <AffectedScopes onChange={refresh}/>}

                    <AccordionItem title={t('Affected products')} class='products'>
                        {products && Object.keys(products).map(v => products[v].map(pp => <Badge>{ICON("software")}{pp}</Badge>))}
                    </AccordionItem>
                </Sidebar>
            </div>
        }
    }
}

export const ScopeVulnerabilityStatus = (x, onChange) => {
    const Status = (
        x.status === "exposed" ? <Badge color="red">{t("to fix")}</Badge>
        : x.status === "patched" ? <Badge color="green">{ICON("done")} {t_fem("patched")}</Badge>
        : <Badge color="#aaa">{t_fem("no impact")}</Badge>
    )
    if(userStore.hasPermissions("vulnerabilities", "write")&& onChange) return <Dropdown button={Status} items={()=><>
        <DropdownItem onClick={()=>onChange(x.scope.id, "exposed")}>{t("to fix")}</DropdownItem>
        <DropdownItem onClick={()=>onChange(x.scope.id, "patched")}>{ICON("done")} {t_fem("patched")}</DropdownItem>
        <DropdownItem onClick={()=>onChange(x.scope.id, "no_impact")}>{t("no impact")}</DropdownItem>
    </>}/>

    return Status
}


const AffectedScopes = {
    props:["onChange"],
    setup(props) {
        const data = reactive({
            page:1,
            selected:{},
        })

        const setPage = page => data.page = page
       
        const setScopeVulnerabilityStatus = async (scope_id, status) => {
            await API.setScopeVulnerabilityStatus(model.vulnerabilityId, scope_id, {status}) 
            props.onChange?.()
        }

        const notify = async (scope_id) => {
            await API.setScopeVulnerabilityStatus(model.vulnerabilityId, scope_id, {notified:true})
            props.onChange?.()
        }

        const onDelete = async (sv) => {
            await API.deleteScopeVulnerabilityStatus(model.vulnerabilityId, sv.scope.id) 
            props.onChange?.()
        }

        async function onAdd() {
            for(const scope_id in data.selected) {
                if(data.selected[scope_id]) await API.setScopeVulnerabilityStatus(model.vulnerabilityId, scope_id, {status:"exposed"})
            }
            data.selected = {}
            props.onChange?.()
        }

        function getSelection() {
            const scope_ids = []
            for(const k in data.selected) {
                if(data.selected[k]) scope_ids.push(k)
            }
            return scope_ids
        }

        const getScopes = filter => model.scopes?.filter(s=>
            s.display_name.startsWith(filter) &&
            !model.vulnerability?.scopes?.find(sv=>sv.scope.id === s.id)
        )

        return ()=>{
            if(!model.vulnerability) return null;
            const editable = userStore.hasPermissions("vulnerabilities", "write")

            const {scopes} = model.vulnerability
            return <Section class="related scopes" title={t("Affected Scopes")} badges={<>
                <Badge>{scopes.count||0}</Badge>
                <div class="flex"/>
                {editable && <FilteredDropdown 
                    menuClass="vulnerability-scopes-selector"
                    iconbutton={ICON("add")} query={getScopes} 
                    onSelect={({id},e)=>{data.selected[id]=!data.selected[id]; e.stopPropagation(); e.preventDefault();}}
                    render={x=><div>
                        <Checkbox value={data.selected[x.id]}/>
                        <img src={x.logo}/>
                        {x.display_name}
                    </div>} 
                    footer={<Button disabled={!getSelection()?.length} onClick={onAdd}><i class="fas fa-check"/></Button>}
                />}
            </>}>
                <DataTable class="no-search-single-page"
                    data={scopes} 
                    columns={[
                        {render:x=><img src={x.scope.logo}/>},
                        {render:x=><a href={link(`/scope/${x.scope.id}/vulnerability/${model.vulnerability.id}`)}>{x.scope.display_name}</a>},
                        {render:x=>ScopeVulnerabilityStatus(x, setScopeVulnerabilityStatus)},
                        {render:x=><NotifyVuln vuln={x} notify={()=>notify(x.scope.id)}/>},
                    ]}  
                    onDelete={editable && onDelete}
                    noHeader
                    setPage={setPage}
                    page={data.page}
                    noSearch
                />
            </Section>
        }
    }
}

export function getVulnerabilityProducts(x) {
    let res = {}
    if (x) {
        x.map(p => {
            let y = p.split(':')
            let [vendor, product] = [y[3], y[4]]
            if (vendor in res) {
                if (!res[vendor].includes(product)) res[vendor].push(product)
            } else {
                res[vendor] = [product]
            }
        })
    }
    return res
}
