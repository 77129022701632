import { reactive, ref } from "@vue/reactivity"
import { t } from "../i18n"
import { API, model } from "../model"
import { toSnakeCase } from "../util"
import './Drilldown.scss'
import { Dropdown, DropdownItem } from "./Dropdown"
import { ICON } from "./icons"
import Handlebars from 'handlebars'
import { gotoScope, link, scopeLink } from "../utils/routing"
import { IconButton } from "./Button"
import { watchEffect } from "@vue/runtime-core"
import { ExpandableText } from "./ExpandableText"
import Clipboard from "./Clipboard.vue"
import { useCurrentUserStore } from "../store/CurrentUserStore"

const userStore = useCurrentUserStore

// Template Helpers https://handlebarsjs.com/guide/#custom-helpers
Handlebars.registerHelper('uppercase', s=>s.toUpperCase())
Handlebars.registerHelper('lowercase', s=>s.toLowerCase())
Handlebars.registerHelper('snakecase', s=>toSnakeCase(s))
Handlebars.registerHelper('pluscase', s=>s.replace(/\s/g, "+"))
Handlebars.registerHelper('urlencode', encodeURIComponent)
Handlebars.registerHelper('kibana', forgeKibanaDrilldown)

export const Drilldown = ({field, value, obj, index}) => {
    const data = reactive({})
    const userStore = useCurrentUserStore
    obj = {...obj, 
        // Additional templating fields
        field, 
        index, 
        value, 
        scope:model.scope?.id, 
        user:userStore.user?.display_name, 
        kibana_space:model.scope?.kibana_space
        /////
    }
    const drilldowns = model.drilldown?.filter(d=>d.field === field && d.enabled)
    const ioc_drilldown = drilldowns?.find(d=>d.template.replace(/\s/,"")=='{{check_ioc}}')

    const removeUselessSpace = (string) => {
        return string.includes("\n") ? string.split("\n").join(" ") : value
    }

    return <><div class='drilldown'>
        {value.length>130 && <Clipboard text={value}/>}
        {value.length>130 ? <ExpandableText text={value}/> : <span>{typeof value==='string' ? removeUselessSpace(value) : value}</span>}
        <div class='tools' class={{empty:!drilldowns?.length}}>
            {ioc_drilldown && <IOCDrilldown field={field} value={value} obj={obj} />}
            {userStore.hasPermissions("drilldowns", "read_private") && 
                <Dropdown menuClass="drilldown-tools" iconbutton={ICON("drilldown")} title={t('Investigate via external tools')} items={()=><>
                    {drilldowns.map(({icon,template='',name})=>{
                        let to = "#"
                        let error = false
                        try {
                            to = Handlebars.compile(template, {noEscape:true})(obj)
                        } catch(e) {
                            console.error(e)
                            error = true;
                        }
                        return <DropdownItem to={to}><img src={icon}/>{name} {error && "(drilldown syntax error)"}</DropdownItem>
                    })}
                    <DropdownItem to={link(`/settings/drilldowns#new-drilldown:${field}`)}>{ICON("add")} New custom drilldown</DropdownItem>
                </>}/>
            }
        </div>
    </div>
    </>
}
  

export const IOCDrilldown = {
    props:['field','value','obj'],
    setup(props) {
        const data = reactive({})

        watchEffect(async ()=>{
            data.ioc = await API.getIOC(props.value)
        })

        return ()=>{
            const {ioc:{ioc, details:{report_description:description="", tags=[]}={}}={}} = data
            const info = tags.map(t=>`[${t}] `).join("") + description
            return ioc && <IconButton class='ioc-drilldown' title={t('IOC detected : ') + info} onClick={()=>gotoScope("ioc/" + ioc)}>
                {ICON('exclamation')} 
            </IconButton>
        }
    }
}

function time_before(ts, n, unit) {
    if(["seconds","second"].includes(unit)) return new Date(Math.round(+new Date(ts) - n * 1000)).toISOString()
    if(["minutes","minute"].includes(unit)) return new Date(Math.round(+new Date(ts) - n * 60 * 1000)).toISOString()
    if(["hours","hour"].includes(unit)) return new Date(Math.round(+new Date(ts) - n * 60 * 60 * 1000)).toISOString()
    if(["days","day"].includes(unit)) return new Date(Math.round(+new Date(ts) - n * 24 * 60 * 60 * 1000)).toISOString()
    if(["weeks","week"].includes(unit)) return new Date(Math.round(+new Date(ts) - n * 7 * 24 * 60 * 60 * 1000)).toISOString()
    if(["months", "month"].includes(query[i-1])) return new Date(Math.round(+new Date(ts) - n * 31 * 24 * 60 * 60 * 1000)).toISOString()
    if(["years", "year"].includes(query[i-1])) return new Date(Math.round(+new Date(ts) - n * 366 * 24 * 60 * 60 * 1000)).toISOString()
}

function time_after(ts, n, unit) {
    if(["seconds","second"].includes(unit)) return new Date(Math.round(+new Date(ts) + n * 1000)).toISOString()
    if(["minutes","minute"].includes(unit)) return new Date(Math.round(+new Date(ts) + n * 60 * 1000)).toISOString()
    if(["hours","hour"].includes(unit)) return new Date(Math.round(+new Date(ts) + n * 60 * 60 * 1000)).toISOString()
    if(["days","day"].includes(unit)) return new Date(Math.round(+new Date(ts) + n * 24 * 60 * 60 * 1000)).toISOString()
    if(["weeks","week"].includes(unit)) return new Date(Math.round(+new Date(ts) + n * 7 * 24 * 60 * 60 * 1000)).toISOString()
    if(["months", "month"].includes(query[i-1])) return new Date(Math.round(+new Date(ts) + n * 31 * 24 * 60 * 60 * 1000)).toISOString()
    if(["years", "year"].includes(query[i-1])) return new Date(Math.round(+new Date(ts) + n * 366 * 24 * 60 * 60 * 1000)).toISOString()
}

function dig(obj, key, _default=null) {
    let cur = obj
    for(var k of key.split(".")) {
        cur = cur[k]
        if(cur === undefined) return _default
    }
    return cur
}

function forgeKibanaDrilldown(query, context) {
    const {field, value} = this
    const constraints = {}
    const timestamp = this['@timestamp'] || this.last_detection || this['event.created']
    let tfrom = time_before(timestamp, 2,"minutes")
    let tto = time_after(timestamp, 2,"minutes")
    if(typeof(query) === "string") {
        query = query?.split(/\s+/)
        query?.forEach((q,i)=>{
            if(q=="before") tfrom = time_before(timestamp, parseFloat(query[i-2]), query[i-1])
            else if(q=="after") tto = time_after(timestamp, parseFloat(query[i-2]), query[i-1])
            else if(q.includes("=")) {
                let [f,v] = q.split("=",2)
                if(f==="field") f = field
                if(v==="value") v = value
                else if(v.includes(".")) v = dig(context.data.root, v, v)
                constraints[f] = v
            }
        })
    }
    return `/kb/s/${model.scope?.kibana_space}/app/discover#/?` + encodeKibanaQuery({
        query:constraints, from:tfrom, to:tto
    })
}

const encodeValue = (v)=>{
    if(typeof(v)!=="string") return v
    return `"${encodeURIComponent(v)}"`
}

const encodeKuery = (params={}) => "'" + 
    Object.entries(params).map(([k,v])=>`${k}:%20${encodeValue(v)}`).join("%20and%20") 
+ "'"

export const encodeKibanaQuery = (params={}) => {
    if(!params._g || !params._a) return encodeKibanaQuery(createKibanaQuery(params));
    const encode = v => typeof v === "string" ? v : ("(" + 
        Object.entries(v).filter(([k,v])=>v!==undefined).map(([k,v])=>`${k}:${encode(v)}`).join(',')
    + ")")
    return Object.entries(params).map(([k,v])=>`${k}=${encode(v)}`).join("&")
}

const createKibanaQuery = ({from,to,query}) => ({
    _g:from && to ? {
        filters:'!()',
        time:{
            from:"'"+from+"'",
            to:"'"+to+"'"
        }
    } : {},
    _a:{
        columns:'!(_source)',
        filters:'!()',
        interval:'auto',
        query:{
            language:'kuery',
            query: encodeKuery(query)
        },
    }
})
