import { t } from "../../i18n"
import { Input } from "../../ui/Input"
import { Description } from "../../ui/Description"
import { reactive } from "@vue/reactivity"
import "./DeclareIncident.scss"
import { API, model } from "../../model"
import { Button, FileButton } from "../../ui/Button"
import { gotoScope, scopeLink } from "../../utils/routing"
import { DeleteButton } from "../../ui/Button"
import { ICON } from "../../ui/icons"
import { useCurrentUserStore } from "../../store/CurrentUserStore"

export const DeclareIncident = {
    setup(props) {
        const userStore = useCurrentUserStore
        const data = reactive({
            title: "",
            body: "",
            message: "**URGENT** Bonjour, pourriez-vous prendre en charge cet incident de sécurité svp ?\nMerci !\n\n\n" + userStore.user.display_name,
            files: [],
        })

        async function send() {
            const id = (await API.createCase({
                scope_id: model.scopeId,
                body: data.body,
                title: data.title,
                is_public: true,
                type: "incident",
                status: "pending",
            }))?.id
            if(id) await API.addComment("Case", id, data.files, {body:data.message, visibility:"waiting_for_response"})
            gotoScope("")
        }

        return ()=>{
            return <div id="declare-incident">
                <h1>{t("Declare incident")}</h1>
                
                <p class="info">
                    {ICON("alert")} <b>Vous êtes sur le point de déclarer un incident de sécurité.</b>
                    <br/>Cette déclaration déclenchera immédiatement une procédure de réponse à incident pouvant impliquer un dépot de plainte, une analyse d'impact et une analyse post-mortem de vos équipements et données.
                    Nous vous recommandons de ne déclencher cette procédure que lorsque vous avez constaté une <b>alteration manifeste</b> de la confidentialité, de l'intégrité ou de la disponibilité de votre périmètre.
                    En cas de doute, merci de formuler de préférence une <router-link to={scopeLink("request-investigation")}>demande d'investigation</router-link>.
                </p>
                
                <p><b><em>1</em>Décrivez l'incident en quelques mots.</b>
                </p>
                
                <h1>
                <Input placeholder={t("Exemples : \"PC123 verrouillé par un rançongiciel\", \"Fuite de mots de passe d'administration\", \"Disparition des backups\", etc")} 
                        value={data.title} 
                        onInput={e=>data.title = e.target.value}
                        key={data.key}
                    />
                </h1>

                <Description
                    editing
                    title={<p><em>2</em>Donnez à notre SOC un maximum d'éléments de contexte      
                        <span><FileButton onChange={(e)=> data.files = Array.from(new Set([...data.files, ...Array.from(e.target.files)]))} class='attach'/> joindre des fichiers</span>
                        <p class="details">N'hesitez pas à y associer captures d'écran (via copier-coller), liens, pièces-jointes qui aideraient nos analystes dans leur investigation. 
                        Si possible, mentionnez la ou les machines (nom d'hôte, IP, domaine), comptes utilisateurs, concernés ainsi que la plage de temps approximative à investiguer</p>           
                    </p>}
                    source={data.body}
                    onInput={x=>data.body = x}
                />

                <Description
                    editing
                    title={<p><em>3</em>Si désiré, complétez votre demande par un message qui sera directement adressé à nos analystes</p>}
                    source={data.message}
                    onInput={x=>data.message = x}
                />

                <footer>                                
                <p class="warn">
                    <b>ATTENTION</b>: Ne mentionnez dans votre message aucun élément sensible tel que mot de passe, identifiants bancaires, <i>etc</i>.
                    <br/>Notre SOC vous indiquera en réponse à votre demande la procédure à suivre pour un échange plus restreint de données personnelles ou confidentielles le cas échéant.
                </p>

                <Button disabled={!data.title || !data.message || !data.body} primary onClick={send}>Envoyer</Button>
                <Button secondary to={scopeLink("")}>Annuler</Button>
                </footer>
            </div>
        }
    }
}