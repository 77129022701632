import { defineStore } from 'pinia'
import { piniaInstance } from '../global/GlobalStore'
import { API } from '../model'
import { model } from '../model'

export const useCurrentUserStore = defineStore("user", {
  state: () => ({ user: false }),
  getters: {
    hasPermissions: state => (subject, action) => {
      if (!state.user) {
        return false
      } else if (model.scope) {
        return !!state.user.permissions[subject] && state.user.permissions[subject][action]?.indexOf(parseInt(model.scope.id)) > -1
      } else {
        return !!state.user.permissions[subject] && state.user.permissions[subject][action]?.length > 0
      }
    },
  },
  actions: {
    async setUserData() {
      this.user = await API.getCurrentUser()
      return this.user
    },
    updateUserQuery(newUser) {
      this.user = newUser
    }
  },
})(piniaInstance)