import { reactive, toRaw } from "@vue/reactivity"
import { model } from "../model"
import { t, t_n } from "../i18n"
import { Badge } from "./Badge"
import { Button } from "./Button"
import { ColorPicker } from "./ColorPicker"
import { Dialog } from "./Dialog"
import { DropdownCombo } from "./DropdownCombo"
import { ICON } from "./icons"
import { Input } from "./Input"
import { modal } from "./Modal"
import "./Tags.scss"
import { Switch } from "./Switch"

export const AddTagButton = ({available_tags, allowed_tags, addTag, onCreate}) => (
    <DropdownCombo 
        menuClass="add-tag"
        iconbutton={ICON("add-tag")} 
        title="Add tags" 
        items={filter=>available_tags?.filter(x=>x.name?.toLowerCase().includes?.(filter?.toLowerCase()))}
        render={({name, color})=><><span class="thumb" style={{backgroundColor:color}}/>{name}</>}
        onSelect={tag=>addTag(tag.name)}
        onCreate={onCreate}
        allowCreate={x=>allowed_tags?.find(t=>t.name?.toLowerCase()===x?.toLowerCase())}
        createLabel={t("Create this tag")}
    />
)

/** @param {tags} list of strings or {tag,color} */
export const Tags = ({editable, tags, allowed_tags, onAdd, onDelete, onCreate}) => {
    allowed_tags = allowed_tags?.map(tag=>typeof(tag)==="string" ? ({name:tag}) : tag) || []
  
    tags = tags?.map(tag=>typeof(tag)==="string" ? (allowed_tags.find(({name})=>name===tag) || ({name:tag})) : tag) || []
    const available_tags = allowed_tags?.filter(t=>!t.deleted && !tags?.find(x=>x.name===t.name))
    tags = tags?.filter(t=>!t.deleted)
    return <div class="tags">
        {tags?.map(({name, color, description})=><Badge title={description} onDelete={editable && onDelete && (()=>onDelete(name))} style={color && {backgroundColor:color}}>{t(name)}</Badge>)}
        {editable && onAdd && <>
            <AddTagButton available_tags={available_tags} allowed_tags={allowed_tags} addTag={onAdd} onCreate={onCreate}/>
            {!tags?.length && <small>{t("Add tags")}</small>}
        </>}
    </div>
}



export function createTagDialog(name, scope_id, taggable_type, forbid=[]) {
    const data = reactive({
        name, 
        color:"#"+Math.floor(Math.random()*16777215).toString(16), 
        scoped:!!scope_id, 
        typed:!!taggable_type,
    })
    const validate = t => !forbid.includes(t)

    return modal(({close})=>
        <Dialog class="create-tag-dialog" close={close} onSubmit={()=>close(data)}
            header={t("Edit tag")}
            body={<>
                <ColorPicker value={data.color} onChange={e=>data.color=e.target.value} />
                <Input autofocus label={t("Name")} value={data.name} onInput={e=>data.name=e.target.value} validate={validate} />
                {!!scope_id && <Switch value={data.scoped} onClick={(e)=>{data.scoped=!data.scoped; e.stopPropagation(); e.preventDefault()}}>{t("Restrict to scope")} {model.scopes.find(({id})=>id===scope_id)?.display_name}</Switch>}
                {!!taggable_type && <Switch value={data.typed} onClick={(e)=>{data.typed=!data.typed; e.stopPropagation(); e.preventDefault()}}>{t("Restrict to")} {t(taggable_type?.toLowerCase(), {nb:2})}</Switch>}
            </>}
            buttons={<>
                <Button secondary onClick={()=>close(null)}>{t("Cancel")}</Button>
                <Button type="submit" disabled={!validate(data.name)}>{t("OK")}</Button>
            </>}
        />
    )
}