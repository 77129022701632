import {reactive} from "@vue/reactivity"
import {API, model} from "../../model"
import {goTo} from "../../utils/routing"
import {t, t_fem} from "../../i18n";
import {capitalize, debounce} from "../../util";
import {Box} from "../../ui/Grid";
import './NewVulnerability.scss'
import {Sidebar} from "../../ui/Sidebar";
import {ICON} from "../../ui/icons";
import {Badge} from "../../ui/Badge";
import {modal} from "../../ui/Modal";
import {Dialog} from "../../ui/Dialog";
import {JSONView} from "../../ui/JSONView";
import {Dropdown, DropdownItem} from "../../ui/Dropdown";
import {Button, IconButton} from "../../ui/Button";
import {Description} from "../../ui/Description";
import {Input} from "../../ui/Input";
import './New.scss'
import {EXPLOITATIONS, getVulnerabilityProducts} from "../Vulnerability"
import { CVSS_COLOR, CVSS_EXPLOITABILITY, CVSS_IMPACT } from "../Vulnerabilities";
import { Spinner } from "../../ui/Spinner";
import { D } from "../../ui/dates";
import { ERR } from "../../ui/Toasts";


export const NewVulnerability = {
    setup() {
        const data = reactive({
            vulnerability: {
                cve_id:"",
                title:"",
                description:"",
                remediation:"",
            },
            scopes: new Set([]),
            vars: {
                scopes: {}
            }
        })

        async function save() {
            try {
                const {id} = await API.createVulnerability({
                    ...data.vulnerability,
                    original: JSON.stringify(data.vulnerability.original)
                })
                goTo(`/vulnerability/${id}`)
            } catch {
                ERR("This CVE already exists in the database")
            }
        }

        function cancel() {
            goTo("/vulnerabilities")
        }

        function openJSON() {
            return modal(({close}) =>
                <Dialog close={close} onSubmit={() => close(data)}
                        header={t("JSON object")}
                        body={<JSONView source={data.vulnerability.original}/>}
                />
            )
        }


        async function fetchCVE() {
            data.loading = true
            data.products = data.exploitability = data.impact = data.vulnerability.original = null
            
            data.vulnerability.original = await API.getCVE(data.vulnerability.cve_id)

            if(data.vulnerability.original) {
                if(!data.vulnerability.description) data.vulnerability.description = data.vulnerability.original.summary
                data.key = Math.random()            

                data.products = getVulnerabilityProducts(data.vulnerability.original?.vulnerable_product)
                data.exploitability = CVSS_EXPLOITABILITY(data.vulnerability.original?.['cvss-vector'])
                data.impact = CVSS_IMPACT(data.vulnerability.original?.['cvss-vector'])
            }

            data.loading = false
        }

        return () => {

            const {exploitability, impact, products} = data
            const {cve_id, exploitation, original, title, description, remediation, } = data.vulnerability

            return <div id="new-vulnerability" class="new page with-sidebar">
                <div>
                    <div class='top flex-row'>
                        <b>{t("CVE ID")}</b>
                        <Input value={cve_id} onInput={debounce(e=>{data.vulnerability.cve_id=e.target.value; fetchCVE()})} placeholder={`${t("Example")} : CVE-2020-1234`}/>
                        <div className='exploitation'>
                            <Dropdown button={<span>Exploitation : <Badge class={exploitation?.replace(/\s+/g, '')}>{capitalize(t_fem(exploitation || "unknown"))}</Badge></span>} items={()=>
                                EXPLOITATIONS.map(s=><DropdownItem onClick={()=>data.vulnerability.exploitation = s}>
                                    {capitalize(t_fem(s))}
                                </DropdownItem>)
                            }/>
                        </div>
                    </div>

                    {original?.cvss && <>
                        <h2>{t('CVSS v2.0')}</h2>
                        <div class='cvss'>
                            <div>{t('Access Vector')}<Badge class={'vector ' + original?.access.vector}>{t(original?.access.vector)}</Badge></div>
                            <div>{t('Access Complexity')}<Badge class={'complexity ' + original?.access.complexity}>{t_fem(original?.access.complexity)}</Badge></div>
                            <div>{t('Authentication')}<Badge class={'authentication ' + original?.access.authentication}>{t_fem(original?.access.authentication)}</Badge></div>
                            <div>{t('Confidentiality Impact')}<Badge class={'confidentiality ' + original?.impact.confidentiality}>{t(original?.impact.confidentiality)}</Badge></div>
                            <div>{t('Integrity Impact')}<Badge class={'integrity ' + original?.impact.integrity}>{t(original?.impact.integrity)}</Badge></div>
                            <div>{t('Availability Impact')}<Badge class={'availability ' + original?.impact.availability}>{t(original?.impact.availability)}</Badge></div>
                        </div>
                    </>}

                    <div>
                        <h2>
                            <b>{t("Title")}</b>
                            <Input value={title}
                                   onInput={e => data.vulnerability.title = e.target.value}
                            />
                        </h2>

                        <Description
                            key={data.key}
                            editing
                            title="Description"
                            source={description}
                            onInput={x=>data.vulnerability.description = x}
                        />
                        <Description
                            editing
                            title={t("Remediation")}
                            source={remediation}
                            onInput={x=>data.vulnerability.remediation = x}
                        />
                    </div>
                    {data.loading && <div class="loading"><Spinner/> Loading CVE details from <a href="https://cve.circl.lu/">https://cve.circl.lu/</a></div>}
                    {original && <div class='vulnerability-fields'>
                        {original?.references && <div class="references">
                            <h2>{t("References")}</h2>
                            {original?.references.map(r => <a href={r} target='_blank'>{r}</a>)}
                        </div>}
                    </div>}
                </div>
                <Sidebar>
                    <div class='buttons'>
                        <Button disabled={!data.vulnerability.cve_id} onClick={() => save(data.vulnerability)}>{t("Save changes")}</Button>
                        <Button secondary onClick={cancel}>{t("Cancel")}</Button>
                    </div>

                    {original?.cvss && <div class="scores">
                        <div>{t("CVSS score")}<div class="score" style={{background:CVSS_COLOR(original?.cvss)}}>{original?.cvss.toFixed(1)}</div></div>
                        <div class='sub'>
                            <div>{t("Exploitability")}<div class="score" style={{background:CVSS_COLOR(exploitability)}}>{exploitability.toFixed(1)}</div></div>
                            <div>{t("Impact")}<div class="score" style={{background:CVSS_COLOR(impact)}}>{impact.toFixed(1)}</div></div>
                        </div>
                    </div>}

                    {data.vulnerability.original && <Box class='information'>
                        {original.Published && <p><strong>{t('Published')} : </strong>{D(original.Published)}</p>}
                        {original.Modified && <p><strong>{t('Updated')} : </strong>{D(original.Modified)}</p>}
                        {original.id && <>
                            <hr/>
                            <p>{ICON("go")} <strong>NVD</strong><br/><a target='_blank' href={`https://nvd.nist.gov/vuln/detail/${data.vulnerability.cve_id}`}>{`https://nvd.nist.gov/vuln/detail/${data.vulnerability.cve_id}`}</a></p>
                            <p>{ICON("go")} <strong>Mitre CVE</strong><br/><a target='_blank' href={`https://cve.mitre.org/cgi-bin/cvename.cgi?name=${data.vulnerability.cve_id}`}>{`https://cve.mitre.org/cgi-bin/cvename.cgi?name=${data.vulnerability.cve_id}`}</a></p>
                        </>}
                        <hr/>
                        <p>{ICON("json")} <strong>JSON : </strong> <IconButton onClick={openJSON}>{ICON("eye")}</IconButton></p>
                    </Box>}
                    {data.vulnerability.products && <Box class='products'>
                        <h2>{t('Products affected')}</h2>
                        <ul>
                            {Object.keys(data.vulnerability.products).map(v => {
                                return <li>
                                    <span>{v}</span>
                                    <ul>
                                        {data.vulnerability.products[v].map(pp => <li>{pp}</li>)}
                                    </ul>
                                </li>
                            })}
                        </ul>
                    </Box>}
                </Sidebar>
            </div>
        }
    }
}