import { model, API } from "../../model"
import { ICON } from "../../ui/icons"
import { DataTable } from "../../ui/DataTable"
import { watchEffect, reactive } from "@vue/runtime-core"
import "./SettingsGlobal.scss"
import { LiveQuery } from "../../api/livequery"
import { H6 } from "../../ui/H"
import { UI } from "../../App"
import { Switch } from "../../ui/Switch"
import { useCurrentUserStore } from "../../store/CurrentUserStore"

export const SettingsGlobal = {
    setup() {
        const userStore = useCurrentUserStore
        watchEffect(async ()=>model.status = await API.getStatus({scope_id:model.scope?.id}))
        return ()=><div id="global-settings">
            <h3>Noscit stack status</h3>
            <DataTable 
                class="status-table"
                loading={model.status===undefined}
                noSearch
                data={Object.entries(model.status||{}).map(([feature,status])=>({
                    feature,
                    status:Array.isArray(status) ? status[0]===true : status===true,
                    message:Array.isArray(status) ? status[1] : status
                }))}
                rowClass={x=>x.status===true ? "ok" : "ko"}
                columns={[
                    {title:"Feature", render:x=>x.feature},
                    {title:"Status", render:x=>x.status ? ICON("ok") : ICON("ko")},
                    {title:"Message", render:x=>x.message},
                ]}
            />

            {userStore.hasPermissions("settings", "read_private") && <MattermostConfig/>}
            {<Switch value={UI.dbg} onClick={()=>{UI.dbg=!UI.dbg; localStorage.setItem("dbg", UI.dbg)}}>Debug mode</Switch>}

        </div>
    }
}




const MattermostConfig = {
    setup() {
        const data = reactive({
        })

        return ()=><section id='edit-mattermost-webhook'>
        </section>
    }
}