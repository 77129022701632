import { reactive } from "@vue/reactivity";
import {ICON} from "../../ui/icons";
import './../Ioc.scss'
import "./NewIOC.scss"
import { Input } from "../../ui/Input";
import { t } from "../../i18n";
import { Button } from "../../ui/Button";
import { goTo, gotoScope } from "../../utils/routing";
import { API, model } from "../../model";
import { CodeMirror } from "../../ui/CodeMirror";
import { Switch } from "../../ui/Switch";
import { Tags } from "../../ui/Tags";
import { Dropdown, DropdownItem } from "../../ui/Dropdown";
import { useCurrentUserStore } from "../../store/CurrentUserStore";

export const NewIOC = {
    setup() {
        const userStore = useCurrentUserStore
        const data = reactive({
            value:"",
            whitelist:false,
            tags:[],
            report_name:"",
            tlp:"amber",
            report_description:"",
            confidence:80,
        })

        async function save() {
            let last = null
            for(const value of data.value.trim().split("\n").map(x=>x.trim()).filter(x=>!!x)) {
                await API.setIOC(value, {
                    authors:[userStore.user.display_name], 
                    src:["CT-Square"],
                    tags:[...data.tags,...(data.whitelist?["whitelist"]:[])],
                    report_name:data.report_name,
                    tlp:data.tlp,
                    report_description:data.report_description,
                    confidence:data.confidence,
                })
                last = value
            }
            if(model.scope) gotoScope(`ioc/${last}`)
            else goTo(`ioc/${last}`)
        }

        function cancel() {
            if(model.scope) gotoScope("iocs")
            else goTo("iocs")
        }

        return () => {
            return <div id="new-ioc" class="page with-sidebar">
                <div>
                    <h1>{ICON("ioc")} New IOCs</h1>
                    <h3>Enter a list of IPs, domains, hashes, ... separated by newlines</h3>
                    <CodeMirror noupdate class="title" value={data.value} onChange={x=>data.value = x}/>

                    <section>
                        <Input label="Report name" value={data.report_name} onInput={x=>data.report_name = x.target.value}/>
                        <Input label="Description" value={data.report_description} onInput={x=>data.report_description = x.target.value}/>
                        <Input label="Confidence (0-100)" value={data.confidence} onInput={x=>data.confidence = parseInt(x.target.value)}/>
                        <Dropdown button={<>TLP: {data.tlp}</>} items={()=><>
                            <DropdownItem onClick={()=>data.tlp="red"}>Red (confidential)</DropdownItem>
                            <DropdownItem onClick={()=>data.tlp="amber"}>Amber (internal)</DropdownItem>
                            <DropdownItem onClick={()=>data.tlp="green"}>Green (community)</DropdownItem>
                            <DropdownItem onClick={()=>data.tlp="white"}>White (public)</DropdownItem>
                        </>}/>
                    </section>

                    <section>
                        <Switch value={data.whitelist} onClick={()=>data.whitelist=!data.whitelist}>Whitelist</Switch>
                    </section>

                    <section>
                        <Tags
                            editable
                            allowed_tags={[]}
                            tags={data.tags}
                            onAdd={x=>{if(!data.tags.includes(x)) data.tags.push(x)}}
                            onCreate={x=>{if(!data.tags.includes(x)) data.tags.push(x)}}
                            onDelete={x=>data.tags = data.tags.filter(t=>t!==x)}
                        />
                    </section>

                    <div className="create-controls">
                        <Button disabled={!data.value.trim()} onClick={save}>{t("Create")}</Button>
                        <Button secondary onClick={cancel}>{t("Cancel")}</Button>
                    </div>
                </div>
            </div>
        }
    }
}