import { reactive } from "vue"
import { t } from "../i18n"
import { model, API } from "../model"
import { Avatar } from "./Avatar"
import { Button } from "../ui/Button"
import { ICONS } from "../ui/icons"
import { useCurrentUserStore } from "../store/CurrentUserStore";


export const DiagResponsable =  {
    props:["diag", "user", "scope"],

    setup(props) {
        const data = reactive({
            user: props.user,
            diagId: props.diag,
            scopeId: props.scope,
            response: null,
            remove: null
        })

        const userStore = useCurrentUserStore
        const RW = userStore.hasPermissions("diags", "write_private")


        const handleClick = async () => {
            try {
                if (data.diagId && data.user["id"]) {
                    const response = await API.addDiagUser({user_ids:[parseInt(data.user["id"])], id:parseInt(data.diagId), is_add_users:true})
                    data.remove = null
                    data.response = response
                }
            } catch (error) {
                console.log(error)
            }
        }

        const removeUser = async () => {
            try {
                if (userStore.user) {
                    const response = await API.removeDiagUser({user_ids:[], id:parseInt(data.diagId), is_add_users:false})
                    data.remove = response
                }
            } catch (error) {
                console.log(error)
            }
        }

        const isCurrentUserPresent = () => {
            if (model.diag) {
                const currentUserPresent = Object.entries(model.diag?.related_users).map(v => v[1]["id"] === userStore.user.id)
                if (currentUserPresent.includes(true)) return true
            }
            false
        }

        const displayCurrentUserAvatar = () => {
            return data.reponse ? <div className="pastille-box">
                 <Avatar user={data.reponse} userId={userStore.user.id}/>
                 {RW && <span onClick={removeUser}>{ICONS["cross"]}</span>}
             </div> :  <div className="pastille-box">
                 <Avatar user={userStore.user} userId={userStore.user.id}/>
                 {RW && <span onClick={removeUser}>{ICONS["cross"]}</span>}
             </div>
        }
        
        return () => {
            return (
                <div className="box-pastilles">
                    <div className="pastilles" id="btn-pastille">
                       {model.diag ? RW ? !data.response && !isCurrentUserPresent() || data.remove ? <Button onClick={handleClick}>{t("Join")}</Button> : displayCurrentUserAvatar() : null : ICONS["error"]} 
                        {model.diag && Object.entries(model.diag?.related_users).map((user) => (
                            <div className="pastille">
                                {user[1].id === userStore.user.id ? null : <Avatar user={user[1]} userId={userStore.user.id}/>}
                            </div>
                        ))}
                    </div>
                </div>
            );
        }
    }
}