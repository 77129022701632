import { t } from "../i18n";
import {Link} from "../utils/router"
import {link} from "../utils/routing"
import { Section } from "./Accordion";
import { Badge } from "./Badge";
import { Button } from "./Button";
import { DataTable } from "./DataTable";
import { D, D_FROM_TO } from "./dates";
import { FilteredDropdown } from "./FilteredDropdown";
import { Flex } from "./Flex";
import { ICON } from "./icons";
import './Related.scss'


export const Related = ({columns, rowClass, editable, type, page, setPage, title, viewAllUrl, items=[], query, onAdd, footer, onDelete, render, renderCombo, addTooltip, update, noHeader, watch, noSearch}) => {

    const default_combo_render = x => 
        typeof(x) === "string" ? x :
        Flex([
            [ <>{ICON(type)} <div class='flex'>{x.title || x.message}</div></>, D_FROM_TO(x.first_detection, x.last_detection) || D(x.detection_date || x.created_at, "short") ],
            <div class="multiline-ellipsis">{x.description}</div>
        ])
    
    const default_columns = [
        {render:x=><>
            {ICON(type)} 
            {typeof(x)==="string" ? x : 
            <Link href={x.id && x.scope_id && link(`/scope/${x.scope_id}/${type}/${x.id}`)}>
                {x.title||x.message}
            </Link>}
        </>},
        {type:'related-date', render:x=>D_FROM_TO(x.first_detection, x.last_detection) || D(x.first_detection || x.created_at, "short-abs-time")},
    ]

    return <Section class="related" title={viewAllUrl ? <Link class="related-link" href={viewAllUrl}>{t(title)}</Link> : t(title)} badges={<>
        <Badge>{(Array.isArray(items) ? items.length : items.count)||0}</Badge>
        {(items.search && items.total && <span>/<Badge>{items.total}</Badge></span>)||null}
        <_/>

        {editable && onAdd && query && <FilteredDropdown title={addTooltip} iconbutton={ICON("add")} query={query} onSelect={onAdd}
            render={renderCombo || render || default_combo_render} 
            footer={footer}
        />}
    </>}>
        <DataTable class="no-search-single-page"
            data={items} 
            columns={columns || (render ? [{render}] : default_columns)}  
            onDelete={editable && onDelete}
            update={update}
            noHeader={!update || noHeader}
            setPage={setPage}
            page={page}
            noSearch={noSearch}
            rowClass={rowClass}
        />
    </Section>
}


const _ = ()=><div class="flex"/>

