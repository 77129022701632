import { Chain } from "../ui/Chain"
import {t_n, t_fem, t_n_fem, t}  from "../i18n";
import { model, API } from "../model";
import { Donut } from "../ui/Donut";
import { Spinner } from "../ui/Spinner";
import { ICON } from "../ui/icons"
import { Markdown } from "../ui/Markdown"
import { DataTable } from "../ui/DataTable"
import { Badge } from "../ui/Badge"
import {  reactive, toRaw, watchEffect } from "@vue/runtime-core"
import { LiveQuery } from "../api/livequery"
import { capitalize, getMonth, range, readableNumber } from "../util"
import { KPI } from "./Dashboard"
import { Recos } from "../ui/Recos"
import { gotoScope, link, goTo } from "../utils/routing"
import { getCurrentRouteName, Link } from "../utils/router"
import { Dropdown, DropdownItem } from "../ui/Dropdown"
import { Description } from "../ui/Description"
import { Input } from "../ui/Input"
import './Dashboard.scss'
import './MonthlyReport.scss'
import './MonthlyReport.print.scss'
import { Button, DeleteButton, IconButton } from "../ui/Button";
import {Switch} from '../ui/Switch'

import { CASES_TYPES } from './Case'
import { D } from "../ui/dates";
import { modal } from "../ui/Modal";
import { Dialog } from "../ui/Dialog";
import { Checkbox } from "../ui/Checkbox";

import CTS_LOGO from "../../public/assets/ct-square-logo.png"
import { CaseStatus } from "./Cases";
import { Severity } from "../ui/Severity";
import { Date } from "../ui/Date"
import { ScopeVulnerabilityStatus } from "./Vulnerability";
import { CVSS_COLOR } from "./Vulnerabilities";
import { Ellipsis } from "../ui/Ellipsis";

import { useCurrentUserStore } from "../store/CurrentUserStore";

const userStore = useCurrentUserStore

export const MonthlyReport = {
    setup() {
        const data = reactive({edited:null})

        const PALETTE = {
            "investigation":"#6c60ff", // Accent1 (violet clair)
            "patrol":"#b7f3b1", // Accent2 (vert)
            "audit":"#181031", // Accent 4 (violet foncé)
            "incident":"#f2513f", // Accent 3 (Rouge)
            "investigation1":"#3f36bf", // Accent 5 (violet bleu foncé)
            "other":"#d7bfff", // Accent 6 (violet clair)
            "pending": "#d7bfff",
            "open": "#3f36bf",
            "closed": "#181031"
        }

        LiveQuery("reports", "{year, month, notified, is_public}", 
            i=>model.reports=i?.map(({month,year})=>({month:(""+month).padStart(2,"0"),year:""+year})), 
            ()=>!!model.scopeId && [{
                scope_id:model.scopeId,
            }]
        )

        // Enforce last available report if not found in query params
        watchEffect(()=>{
            if(getCurrentRouteName()==="reports" && (!model.year || !model.month)) {
                if(model.reports?.length) {
                    setTimeframe(model.reports[0].year, model.reports[0].month)
                }
            }
        })

        function setTimeframe(year, month) {
            if(year===model.year && month===model.month) return;
            model.report = null;
            gotoScope(`reports/${year}/${month}`)
        }

        const pageHasRecos = page => page.some(c => 0 + c.recos_by_status?.closed + c.recos_by_status?.open !== 0)

        function setSummary(summary) { API.setReport(model.report.id, {summary}) }
        function setCoordination(coordination) { API.setReport(model.report.id, {coordination}) }
        function setControlPoints(control_points) { API.setReport(model.report.id, {control_points}) }
        function setVulnerabilities(vulnerabilities) { API.setReport(model.report.id, {vulnerabilities}) }
        function setLegacyVulnerabilities(legacy_vulnerabilities) { API.setReport(model.report.id, {legacy_vulnerabilities})}
        function setCasesPageBreak(cases_page_break) { API.setReport(model.report.id, {cases_page_break}) }
        function setVulnerabilitiesPageBreak(vulnerabilities_page_break) { API.setReport(model.report.id, {vulnerabilities_page_break})}
        function notify() { API.notifyReport(model.report.id) }

        function togglePublic() { API.setReport(model.report.id, {is_public:!model.report.is_public})}

        function saveCase() {
            API.setReport(model.report.id, {cases:JSON.stringify(toRaw(model.report.cases))})
                .then(() => data.edited = null)
        }

        function deleteCase(id) {
            API.setReport(model.report.id, {cases:JSON.stringify(toRaw(model.report.cases.filter(c=>c.id!==id)))})
                .then(() => data.edited = null)
        }

        function deleteVulnerability(id) {
            API.setReport(model.report.id, {vulnerabilities:JSON.stringify(toRaw(model.report.vulnerabilities?.filter(c=>c.id!==id) || []))})
        }


        function toggleVulnerabilitiesVersion() {
            API.setReport(model.report.id, {vulnerabilities_version: model.report.vulnerabilities_version === 1 ? 0 : 1})
        }

        return () => {
            const {report, year, month, scope} = model

            const RW = userStore.hasPermissions("reports", "write_private")

            if(year && month && model.reports && !model.reports.some(r=>r.year===year && r.month===month)) return <h1 class="error">
                {ICON("close")}
                {t("No report for")} {t(getMonth(month))} {year}
                {RW && <><br/>
                    <Button onClick={()=>generateReport({month, year})}>{t("Generate report")}</Button>
                </>}
            </h1>

            if(!(year && month && scope && report)) return <Spinner/>


            const FirstPageHeader = ()=><div id="header">
                <span>Confidentiel</span>
                {RW && <div class="controls no-print">
                    <Switch value={report.vulnerabilities_version===1} onClick={()=>toggleVulnerabilitiesVersion()}>{t("Nouveau format vulnerabilités")}</Switch>
                    <Switch value={report.is_public} onClick={togglePublic}>{t("public")}</Switch>
                    <Button secondary onClick={()=>generateReport({month, year})}>{ICON("reset")} {t("Regenerate report")}</Button>
                   
                    {report.is_public && (
                        report.notified ? <div class="notified">{ICON('mail')} {ICON('done')} {t("Notified by email")}</div>
                        :  <Button onClick={notify}>{ICON("mail")} {t("Notify by email")}</Button>
                    )}
                </div>}
                <img id="brand" src={require("./images/head.png")}/>
                <div><img id="scopeLogo" src={scope.logo} alt=''/></div>
            </div>

            const Header = ()=><div className="page-header">
                <img src={CTS_LOGO}/>
                <div>Rapport d'activité {scope.display_name} - <span>{t(getMonth(month))} {year}</span></div>
            </div>

            const Footer = (page,pages)=><footer>
                <p><b>CONFIDENTIEL</b></p>
                <p>CT-Square – SAS au capital de 100 362 €</p>
                <p>SIRET 823 033 741 – NAF 6202A – TVA FR88 823 033 741 – RCS de Versailles</p>
                <div className="page-count">{page}/{pages}</div>
            </footer>

            if(!report) return <div id="monthly-report"><div id="manager-dashboard" >{
                    [
                        <>
                            <h1>
                            <span id="title">{t("Activity report")}</span>
                            <Dropdown class="timespan" button={<span>{ICON("timeline")} {t(getMonth(month))} {year}</span>} items={()=><>
                                {model.reports?.map(({year,month})=>
                                    <DropdownItem onClick={()=>setTimeframe(year,month)}>{t(getMonth(month))} {year}</DropdownItem>
                                )}
                                {RW && <DropdownItem onClick={generateReport}>{ICON("add")} {t("Generate new report")}</DropdownItem>}
                            </>}/>
                            </h1>
                            <Spinner/>
                        </>
                    ].map((page,i,{length})=>
                    <section>
                        {i===0 ? FirstPageHeader() : Header()}
                        {page}
                        {Footer(i+1, length)}
                    </section>
                    )}
            </div></div>

            const casesByStatus = [
                {label:t_n_fem(report.cases_by_status.pending, "Case pending"), data: report.cases_by_status.pending, color:PALETTE.pending},
                {label:t_n_fem(report.cases_by_status.open, "Case open"), data: report.cases_by_status.open, color:PALETTE.open},
                {label:t_n_fem(report.cases_by_status.closed, "Case closed"), data: report.cases_by_status.closed, color:PALETTE.closed},        
            ]

            const casesByType = [
                {label:t_n_fem(report.cases_by_type.investigation, "investigation"), data: report.cases_by_type.investigation, color:PALETTE.investigation, showZero:true},
                {label:t_n_fem(report.cases_by_type.patrol, "patrol"), data: report.cases_by_type.patrol, color:PALETTE.patrol, showZero:true},
                {label:t_n(report.cases_by_type.audit, "audit"), data: report.cases_by_type.audit, color:PALETTE.audit, showZero:true},
                {label:t_n(report.cases_by_type.incident, "incident"), data: report.cases_by_type.incident, color:PALETTE.incident, showZero:true},
            ]

            const recosByStatus = [
                {label:t_n(report.recos_by_status.open, "Recommendation pending"), data: report.recos_by_status.open, color:PALETTE.pending},
                {label:t_n(report.recos_by_status.closed, "Recommendation validated"), data: report.recos_by_status.closed, color:PALETTE.open},
            ]

            const cases_limit = report.cases_page_break || 10
            const cases_pages = Math.ceil((report.cases?.length||0)/cases_limit)

            const vulnerabilities_limit = report.vulnerabilities_version===1 ? (report.vulnerabilities_page_break || 10) : 0
            const vulnerabilities_pages = report.vulnerabilities_version===1 ? Math.ceil((report.vulnerabilities?.length||0)/vulnerabilities_limit) : 0

            return <div id="monthly-report" class={{editable:RW}}>
                <div id="manager-dashboard" >{[
                <>
                    <h1>
                        <span id="title">{t("Activity report")}</span>
                        <Dropdown class="timespan" button={<span>{ICON("timeline")} {t(getMonth(month))} {year}</span>} items={()=><>
                            {model.reports?.map(({year,month})=>
                                <DropdownItem onClick={()=>setTimeframe(year,month)}>{t(getMonth(month))} {year}</DropdownItem>
                            )}
                            {RW && <>
                                <DropdownItem onClick={generateReport}>{ICON("add")} {t("Generate new report")}</DropdownItem>
                            </>}
                        </>}/>
                    </h1>
                    <div id='kpis'>
                        <KPI title={t("Network logs")} icon={ICON("network")} value={report.kpis.network}/>
                        <KPI title={t("System logs")} icon={ICON("computer")} value={report.kpis.agents} />
                        <KPI title={t("Cloud logs")} icon={ICON("cloud")} value={report.kpis.cloud} />
                    </div>
                    <div id="charts">
                        <Donut data={casesByStatus} innerRadius=".40"/>
                        <Donut data={casesByType} showEmpty innerRadius=".40"/>
                        { (report.recos_by_status.open||0) + (report.recos_by_status.closed||0) > 0 && <Donut data={recosByStatus} innerRadius=".40"/>}
                        {/* <img src={require('./images/risk.png').default} width="320px"/> */}
                    </div>

                    <Markdown id="summary" class={{"no-print":!report.summary}}
                        editable={RW}
                        source={report.summary} 
                        onSave={setSummary} 
                        placeholder="<Redigez une courte synthèse ici>"
                    />

                    <div id="coordination" class={{"no-print":!report.coordination}}>
                        <Description 
                            editable={RW}
                            title={<h2>Coordination</h2>} 
                            source={report.coordination} 
                            onSave={setCoordination}
                        />
                    </div>
                </>,
                ...range(cases_pages).map(i=>
                <>
                <h1 id="analyses">
                    Analyses {i>0 && `(${i+1}/${cases_pages})`} 
                    {RW && <small class="no-print"><Input value={cases_limit} onInput={e=>setCasesPageBreak(parseInt(e.target.value)||10)}/> analyses par page</small>}
                </h1>
                <div>
                    <DataTable
                        noToolbar
                        data={report.cases?.slice(i*cases_limit,(i+1)*cases_limit)}
                        columns={[
                            {title:t('First activity'), render:x=><>
                                {data.edited===x ? <div><Date onSave={e=>x.created_at = e} value={x.created_at}/></div>
                                : D(x.created_at,'short-abs')}
                            </>},
                            {title:t('Last activity'), render:x=><>
                                {data.edited===x ? <div><Date onSave={e=>x.last_activity = e} value={x.last_activity || x.updated_at}/></div>
                                : D(x.last_activity || x.updated_at, "short-abs")}
                            </>},
                            {title:"Type", render:(x)=>
                            <div class={{type:true, [x.case_type]:true}}>

                                {data.edited===x ?
                                <Dropdown slim button={<span class="type"><span class={[x.case_type]}/>{t(x.case_type) || "<type>"}</span>} items={()=><>
                                    {CASES_TYPES.map(type=><DropdownItem onClick={()=>x.case_type = type}><span class="type"><span class={[type]}/>{capitalize(t(type))}</span></DropdownItem>)}
                                </>}/> :

                                <>
                                <span class={x.case_type}/>
                                {t(x.case_type)}   
                                {!!x.nb_alerts && <Badge icon={ICON('alert')}>{x.nb_alerts}</Badge>}
                                </>}
                            </div>},
                            {title:"Description", render:x=>
                            
                            data.edited===x ? <div class='description'>
                                <Input label="titre" value={x.title} onInput={e=>x.title=e.target.value}/>
                                <Input label="conclusion" value={x.conclusion} onInput={e=>x.conclusion=e.target.value}/>
                                <Input label="description" value={x.body} onInput={e=>x.body=e.target.value}/>
                             </div>
                            
                            :<div class='description'>
                                <div><Link href={link(`/scope/${model.scopeId}/case/${x.id}`)}>{x.title}</Link></div>
                                <div>{x.conclusion}</div>
                                <div class="ellipsis"><div>{x.body}</div></div>
                            </div>
                            },
                            {title:t("Qualification"), type:'status', render:x=><div>    
                                {x.steps && x.status ?   
                                    Chain(x)                              
                                : <>
                                    {data.edited===x && <div>
                                        <Input label="qualification" value={x.conclusion_code?.name||""} onInput={e=>x.conclusion_code={name:e.target.value}}/>
                                        <Input label="status" value={x.status} onInput={e=>x.status=e.target.value}/>
                                    </div>}
                                    
                                    <CaseStatus horizontal readonly case={x}/>
                                    </>}
                            </div>
                            },
                            report.cases?.some(x=>x.severity) && {title:t('Severity'), render:x=><Severity val={x.severity}/>},
                            (pageHasRecos(report.cases.slice(i*cases_limit,(i+1)*cases_limit)) || data.edited) && {title:t("Recommendations"), render:x=><div>
                                {data.edited===x && <div>
                                    <Input label="appliquées" value={""+(x.recos_by_status?.closed||0)} onInput={e=>{
                                        if(!x.recos_by_status) x.recos_by_status={}
                                        x.recos_by_status.closed=parseInt(e.target.value)
                                    }}/>
                                    <Input label="ouvertes" value={""+(x.recos_by_status?.open||0)} onInput={e=>{
                                        if(!x.recos_by_status) x.recos_by_status={}
                                        x.recos_by_status.open=parseInt(e.target.value)
                                    }}/>
                                </div>}
                                {Recos(x)}
                            </div>},

                            RW && {render:(x)=>
                                data.edited !== x ? <>
                                <IconButton onClick={()=>data.edited=x}>{ICON("edit")}</IconButton>
                                <DeleteButton onClick={()=>deleteCase(x.id)}/>
                                </> : <>
                                <IconButton onClick={saveCase}>{ICON("ok")}</IconButton>
                                <IconButton onClick={()=>data.edited=null}>{ICON("close")}</IconButton>
                                </>
                            }
                        ]}
                    />  
                    {i<cases_pages-1 && <p class="more">suite des analyses page suivante</p>}
                </div>
                </>),

                print_only_if(report.control_points, <div id="control-points">
                    <h1>Points de contrôle</h1>
                    <Markdown 
                        editable={RW}
                        source={report.control_points} 
                        placeholder="Cette section vide ne sera pas affichée"
                        onSave={setControlPoints}
                    />
                </div>),

                ...range(vulnerabilities_pages).map(i=>
                    <>
                    <h1>
                        Vulnérabilités {i>0 && `(${i+1}/${vulnerabilities_pages})`} 
                        {RW && <small class="no-print"><Input value={vulnerabilities_limit} onInput={e=>setVulnerabilitiesPageBreak(parseInt(e.target.value)||10)}/> vulnérabilités par page</small>}
                    </h1>
                    <div>
                        <DataTable
                            class="report-vulnerabilities"
                            noToolbar
                            data={report.vulnerabilities?.slice(i*vulnerabilities_limit,(i+1)*vulnerabilities_limit)}
                            columns={[
                                {title:t('Collected'), render:x=>D(x.created_at, 'short-abs')},
                                {title:t('Updated'),  render:x=>D(x.updated_at, 'short-abs')},
        
                                {title:t("Status"), type:'status', render:x=>
                                    !model.scopeId ? (
                                        x.status==="new" ? <>{ICON("star")}<span>{t_fem("New")}</span></> 
                                        : x.status==="deleted" ? t_fem("Ignored")
                                        : x.status==="open" ? <><InProgressIcon/><span>{t("vuln:Open")}</span></>
                                        : <>{ICON("done")} {t_fem("vuln:Closed")}</>
                                    ) : ScopeVulnerabilityStatus(x)
                                },
                                
                                {title:t('Description'), type:'description', render:x=><div class="description">
                                    <div><Link href={link(model.scopeId ? `scope/${model.scopeId}/vulnerability/${x.id}` : `vulnerability/${x.id}`)}><b>{x.cve_id} {x.title && x.title!==x.cve_id && <>- {x.title}</>}</b></Link></div>
                                    {!!x.description && <div class="ellipsis">{x.description}</div>}
                                    {/* {!!get_products(x)?.length && <div><small><b>{t("Impacted products")}</b> {get_products(x)?.map(x=><Badge>{x}</Badge>)}</small></div>} */}
                                </div>},
                                
                                !model.scopeId && {title:t('Scopes'), type:'scopes', render:x=>
                                    !x.scopes?.length ? t("none") :
                                    <>
                                        {x.scopes_by_status.exposed && <Badge color='red'>{t_n(x.scopes_by_status.exposed, "to fix")}</Badge>}
                                        {x.scopes_by_status.notified && <Badge color='red'>{t_n(x.scopes_by_status.notified, "vuln:notified")}</Badge>}
                                        {x.scopes_by_status.no_impact && <Badge color='#aaa'>{t_n(x.scopes_by_status.no_impact, "vuln:no_impact")}</Badge>}
                                        {x.scopes_by_status.patched && <Badge color='green'>{t_n(x.scopes_by_status.patched, "vuln:patched")}</Badge>}
                                    </>
                                },
        
                                {title:t('Products'), render:x=> <>
                                <span class="products">
                                    {x.short_products?.map(p => <span>{ICON("software")}&nbsp;{capitalize(p.replace('_', ' '))}</span>)}
                                    {(x.short_products?.length < x.nb_products) &&  <Badge>+{x.nb_products - x.short_products?.length}</Badge>}
                                </span>
                                </>},
                                
                                {title:t('Exploitation'), render:x=><Badge class={x.exploitation?.replace(/\s+/g, '') || "unknown"}>{capitalize(t_fem(x.exploitation || "unknown"))}</Badge>},
                                {title:t('CVSS score'), type:'score', render:x=><Badge color={CVSS_COLOR(x.score)}>{(x.score||0).toFixed(1)}</Badge>},
                                

                                RW && {render:(x)=>
                                    <DeleteButton onClick={()=>deleteVulnerability(x.id)}/>
                                }
                            ]}
                        />  
                        {i<vulnerabilities_pages-1 && <p class="more">suite des vulnérabilités page suivante</p>}
                    </div>
                    </>),

                    report.vulnerabilities_version===0 && print_only_if(report.legacy_vulnerabilities, 
                    <div id="vulnerabilities">
                        <h1>Veille sur les vulnérabilités</h1>
                        <Markdown 
                            editable={RW}
                            source={report.legacy_vulnerabilities} 
                            placeholder="Cette section vide ne sera pas affichée"
                            onSave={setLegacyVulnerabilities}
                        />
                    </div>),

                // <div id="notice">
                //     <h1>Notice</h1>
                //     <p>
                //         Le SOC CT-Square Noscit produit quatre catégories d’analyses. 
                //         Chaque analyse peut donner lieu à une ou plusieurs recommandations à destination de vos équipes. 
                //         La synthèse des analyses actives sur la période est présentée page 1. Leur détail est listé page 2.
                //         La clôture d’une analyse signifie qu’à l’issue d’une investigation approfondie nos experts ont conclu que l’activité était normale à la date de clôture.
                //         Une analyse est escaladée en incident si nos experts concluent qu’une tentative d’attaque a eu lieu. 
                //     </p>
                //     <p>Chaque analyse transite par nos algorithmes de prédiction/qualification automatique et passe entre les mains de différents experts selon le niveau d’investigation. Le détail de ces phases présenté en page 2 vous permet de suivre précisément leur processus et le travail de notre équipe SOC.</p>
                //     <div className="notice-grid">
                //     <div>
                //         <h2><span className='investigation'/>Investigations</h2>
                //         <p>Une investigation est créée lorsque notre chaîne automatisée de détection/corrélation d’alertes juge qu’une analyse approfondie par un expert est nécessaire.</p>
                //         <table>
                //             <tr><td><Step icon="Q">Qualification</Step></td><td>La qualification est le premier niveau du SOC. Elle contextualise et catégorise la détection, la replace dans un espace de risque ajusté à votre périmètre et au panorama de la menace. Elle décide ensuite si l’investigation doit être poursuivie par un expert niveau 2, conclut à un faux positif ou à une activité normale.</td></tr>
                //             <tr><td><Step icon="A">Analyse</Step></td><td> Les événements annotés comme suspects lors de la qualification entrent en phase d’analyse par un expert niveau 2, qui procède aux vérifications actives nécessaires, à une fouille poussée dans les journaux archivés, et effectue au besoin une levée de doute en coopération avec vos équipes techniques.</td></tr>
                //         </table>
                //         <p>La phase d’analyse conduit au classement de la détection selon ses conclusions:</p>
                //         <table>
                //             <tr><td><Step icon="N">Activité normale</Step></td><td> Les opérations d’administration, privilégiées, ou inhabituelles peuvent malgré tout émaner d’une activité légitime. Notre SOC a alors vérifié que cette activité était bien connue de vos équipes techniques.</td></tr>
                //             <tr><td><Step icon="F">Faux positif</Step></td><td> Un faux positif est une détection indésirable liée au calibrage de la chaîne de détection, aux collecteurs ou à la définition de votre <abbr title="Politique de sécurité du système d'information">PSSI</abbr>. Un ajustement de ces paramètres peut s’avérer pertinent mais la sécurité de votre périmètre n’est pas en jeu.</td></tr>
                //             <tr><td><Step icon="E">Escalade</Step></td><td> L’analyse approfondie a conclu qu’une tentative d’attaque a eu lieu ou est en cours. L’analyse passe en mode "incident" et un nouvel incident est ouvert suivant un cycle prope de réponse à incident/<abbr title="Digital Forensic and Incident Response">DFIR</abbr>.</td></tr>
                //         </table>
                //     </div>
                //     <div>
                //         <h2><span className='audit'/>Audits</h2>
                //         <p><Chain type="audit" status="done" steps={["planification", "exécution", "restitution"]}/> 
                //         Un audit est une analyse active de votre périmètre technique et organisationnel. Sa réalisation est planifiée et soumise à votre accord. L'audit peut comporter une phase de test d'intrusion (<i>pentest</i>) où notre équipe émule pendant plusieurs jours des attaques réalistes sur votre réseau. Une analyse de risque complète ou des scans de vulnérabilités peuvent également être effectués. L'audit aboutit à une restitution par nos soins, validée par vos équipes.</p>
                //     </div>
                //     <div>
                //         <h2><span className='patrol'/>Patrouilles</h2>
                //         <p><Chain type="patrol" status="done" steps={["investigation", "analyse", "escalade"]}/>
                //         Notre capacité de détection est complétée par des patrouilles spontanées de nos analystes. Orientées par notre veille sur le panorama de la menace et alimentée par votre espace de risque, ces patrouilles comprennent la fouille active dans les journaux archivés et/ou le scan actif de votre périmètre à la recherche d’eventuelles traces de compromission. Comme les détections, ces patrouilles peuvent donner lieu à une analyse approfondie et le cas échéant lever un incident.</p>
                //     </div>
                //     <div>
                //         <h2><span className='incident'/>{ICON("incident")} Incidents</h2>
                //         <p><Chain type="incident" status="done" steps={["confinement", "remédiation", "rétablissement"]}/> 
                //         Un incident est ouvert lorsqu’une détection ou une patrouille a identifié les traces d’une attaque. L’incident suit alors un processus de réponse à incident, qui intègre au besoin une phase de confinement, de remédiation, puis de rétablissement.</p>
                //     </div>
                //     </div>
                //     <div>
                //         <h2>{ICON("recommendation")} Recommandations</h2>
                //         <p>Chaque analyse, proactive ou réactive, peut donner lieu à une ou plusieurs recommandations à destination de vos équipes. La synthèse en page 1 montre l’état d’avancement des recommandations émises. Une fois appliquée, chaque recommandation est vérifiée puis validée par nos experts. Pour chaque recommandation, nous mesurons son impact sur votre espace de risque afin de prioriser les recommandations les plus urgentes.</p>
                //     </div>
                // </div>,

                ].filter(page=>!!page).reduce((pages, page)=>[...pages,{
                    i:(pages[pages.length-1]?.i||0) + (page.print===false ? 0 : 1), 
                    page: page.page || page,
                    print: !(page.print===false)
                }], [])
                .map(({page,i,print},_,pages)=>
                    <section class={{"no-print":!print}}>
                        {i===1 ? FirstPageHeader() : Header()}
                        {page}
                        {Footer(i, pages[pages.length-1].i)}
                    </section>
                )
            }
            </div>
            </div>
        }
    }
}


const print_only_if = (b,page)=>({print:!!b,page})



// Generate reports

export async function generateReport({scope_id, month, year}, ask=true) {
    if(!scope_id) scope_id = model.scopeId
    if(!month || !year) return ask && generateReport(await generateReportDialog() || {}, false)
    setTimeout(async ()=>{
        let overwrite_cases = false
        if(model.reports.some(r=>r.month===month && r.year===year)) {
            const [confirmed, o] = await overwriteCasesDialog() || []
            if(!confirmed) return;
            overwrite_cases = o
        }
        await API.generateReport({scope_id, month, year, overwrite_cases})
        goTo(`scope/${scope_id}/reports/${year}/${month}`)
    }, 1000)
}        

export function generateReportDialog() {
    const data = reactive({year:"", month:"01"})

    const validate = (year) => parseInt(year)>2018 && year===""+parseInt(year)

    return modal(({close})=>
        <Dialog class='generate-report' close={close} onSubmit={()=>close(data)}
            header={t("Generate new report")}
            body={<>
                <p>
                    {t("Month")}
                    <Dropdown menuClass="generate-report" button={capitalize(t(getMonth(data.month)))} items={()=><>
                        {range(12).map(m=><DropdownItem onClick={()=>data.month=(m+1)>=10 ? ""+(m+1) : "0"+(m+1)}>{capitalize(t(getMonth(m)))}</DropdownItem>)}
                    </>}/>
                </p>
                <p>
                    {t("Year")}
                    <Input onInput={(e)=>data.year=e.target.value} value={data.year} validate={validate}/>
                </p>
            </>}
            buttons={<>
                <Button secondary onClick={()=>close(null)}>{t("Cancel")}</Button>
                <Button type="submit" disabled={!data.year || !data.month || !validate(data.year)}>{t("Generate")}</Button>
            </>}
        />
    )
}

export function overwriteCasesDialog() {
    return modal(({close})=>
        <Dialog class='confirm-overwrite' close={close} onSubmit={()=>close([true, $("#overwrite-cases").is(':checked')])}
            header={t("Report already exists")}
                body={<>
                <p>{t("Report already exists. Do you really want to overwrite KPI data ?")}</p>
                <p><Checkbox id="overwrite-cases"/> {t("Overwrite existing cases too !")}</p>
            </>}
            buttons={<>
                <Button secondary onClick={()=>close(null)}>{t("Cancel")}</Button>
                <Button type="submit">{t("Overwrite existing report")}</Button>
            </>}
        />
    ) || []
}

