import { reactive } from "vue";
import { useRoute } from "vue-router";
import './css/App.scss'
import './ui/ui.scss'
import { Menu } from "./ui/Menu";
import { Topbar } from "./ui/Topbar";
import { model, connect, init_livequeries } from "./model";
import { Toasts } from "./ui/Toasts";
import { Spinner } from "./ui/Spinner";
import { Screen } from "./ui/Screen";
import { DBG } from "./ui/DBG";
import { NotFound } from "./ui/NotFound";
import { goTo, gotoScope } from "./utils/routing";
import {graphql} from "./api/graphql";
import { DROPDOWN } from "./ui/Dropdown";
import { MODAL } from "./ui/Modal";
import { TOOLTIP } from "./ui/Tooltip";
import { Button } from "./ui/Button";
import { ICON } from "./ui/icons";
import { useCurrentUserStore }   from "./store/CurrentUserStore";

window.addEventListener("scroll", ()=>document.body.classList.toggle("scrolled", window.pageYOffset))

const userStore = useCurrentUserStore

export const UI = reactive({
    loading:false,
    dbg: userStore.hasPermissions("settings", "debug") ? localStorage.getItem("dbg") === "true" : false,
})


let ROUTES = []
export const getRouteDef = (route) => ROUTES.find(r=>r.path===route.matched[0]?.path)
export function setRoutes(r) { ROUTES = r; }

const redirectToV2 = () => {
    const location = window.location
    if (location.hostname === 'localhost') {
        location.replace(`http://localhost:5173/v2${location.pathname}`)
    } else {
        location.replace(`${location.origin}/v2${location.pathname}`)
    }
}


export const App = {
    setup() {
        const route = useRoute()

        const userStore = useCurrentUserStore
        init_livequeries();

        if(localStorage.getItem("token")) connect(localStorage.getItem("token"))
        else userStore.user = null;

        return ()=>{
            const {anonymous, menu, multiscope, name, permission} = getRouteDef(route) || {}

            if(!route.matched[0] || userStore.user===false) return <Screen><Spinner/></Screen>
            if(!userStore.user && !anonymous) {
                let redirect = route.redirectedFrom?.fullPath || route.fullPath
                if(["/", "/scopes", "/fr/scopes", "/en/scopes", "/fr/login", "/en/login", "/login"].includes(redirect)) redirect = null
                else redirect = encodeURIComponent(redirect)
                console.log('goto login')
                goTo("/login" + (redirect ? `?redirect=${redirect}`: ""))
                return;
            }

            // console.log('setings', userStore.user?.settings)
            // console.log('name', name)
            if (name !== 'login' && userStore.user && !!userStore.user.settings.useV2) redirectToV2()

            if(userStore.user && !model.scopes) return <Screen><Spinner/></Screen>
            if(model.scopes?.length<1 && name!=="noscope" && !userStore.hasPermissions("scopes", "noscope")) return goTo("noscope")
            if(model.scope && permission && !userStore.hasPermissions(permission.subject, permission.action)) return gotoScope("/")
            if(permission && !userStore.hasPermissions(permission.subject, permission.action)) return goTo("/")
            if(multiscope && (model.scopes?.length<2 && !userStore.hasPermissions("scopes", "noscope"))) return goTo(`/scope/${model.scopes[0].id}/`)
            if(name=="dashboard" && !userStore.hasPermissions("cases", "read") && userStore.hasPermissions("diags", "read")) return gotoScope("audit")


            if (graphql.is404) return <NotFound/>

            return <><div>
                {(!anonymous && menu!==false) ? <>
                    <Topbar/>
                    <Menu/>
                    <div id="page">
                        <router-view/>
                    </div>
                </> : <router-view/>}
                {UI.dbg && userStore.hasPermissions("settings", "debug", model.scope) && <DBG/>}
                <Toasts/>
            </div>
            {!!MODAL.cur && <div id="modal" class={{show:!!MODAL.show}}>{MODAL.cur?.()}</div>}
            {DROPDOWN.cur?.()}
            {TOOLTIP.cur?.()}
            </>
        }
    }
}