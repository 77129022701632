import { reactive } from "@vue/reactivity"
import { model, API } from "../../model"
import { t } from "../../i18n"
import {createTagDialog} from "../../ui/Tags"
import {AddButton} from "../../ui/AddButton";
import { LiveQuery } from "../../api/livequery"
import { H5, H6 } from "../../ui/H"
import { DataTable } from "../../ui/DataTable"
import { Switch } from "../../ui/Switch"
import './SettingsTags.scss'
import { queryParams } from "../../utils/queryParams";

export const SettingsTags = {
    setup() {
        const data = reactive({tags:model.all_tags, loading: false})
        const lq = LiveQuery("tags", "{id,name,color,deleted,instances,description,taggable_type,scope_id}", tags=>data.tags=tags, ()=>model.scopes && [], {loading_on_notify:true})
        async function createTag() {
            const {name,color,scoped} = await createTagDialog("", model.scopeId||null, null)
            await API.createTag(name, color, scoped ? model.scopeId : null)
        }

        const updateTag = async (id, d) => {
            data.loading = true
            await API.updateTag(id, d)
            data.loading = false
        }

        return ()=>{
            let tags = data.tags?.filter(t=>t.scope_id===(model.scopeId||null))
            if(queryParams().search) tags = tags?.filter(t=>t.name.toLowerCase().startsWith(queryParams().search.toLowerCase()))
            return <div id="tags-settings">
                <h3>Tags</h3>
                <DataTable
                    loading={lq.loading || data.loading}
                    buttons={<AddButton onClick={createTag}>{t("New tag")}</AddButton>}
                    data={tags}
                    columns={[
                        {title:t('Name'), render:tag=><H5 validate={x=>x===tag.name || !tags.find(t=>t.name===x)} value={tag.name} onSave={x=>updateTag(tag.id, {name:x})}/>},
                        {title:t('Description'), render:tag=><H6 value={tag.description} onSave={x=>updateTag(tag.id, {description:x})}/>},
                        {title:t('# instances'), render:tag=>tag.instances},
                        {title:t('Color'), render:tag=><input type="color" value={tag.color} onChange={e=>updateTag(tag.id, {color:e.target.value})}/>},
                        {title:t('Enabled'), render:tag=><Switch value={!tag.deleted} onClick={()=>updateTag(tag.id, {deleted:!tag.deleted})}/>},
                        {title:t('Scoped'), render:tag=>tag.scope_id===null ? t("no") : t("yes")},
                        {title:t('Type'), render:tag=>tag.taggable_type===null ? "-" : tag.taggable_type},
                    ]}
                />
            </div>
        }
    }
}
