import { model, API } from "../../model"
import { ICON } from "../../ui/icons"
import { readableNumber} from "../../util";
import { DataTable } from "../../ui/DataTable"
import { onMounted } from "@vue/runtime-core"
import { D } from "../../ui/dates"
import './SettingsBackups.scss'

export const SettingsBackups = {
    setup(props) {

        onMounted(async ()=>{
            const backups = await API.get_backups()
            if(!backups?.error) model.backups = backups?.reverse().map(({name,size})=>{
                try {
                    const [_,version,date] = name.match(/astraia-([^-]+)-(.*).gz/)
                    return {date: new Date(date), version, name, size}
                } catch { return {name, size} }
            })
            else model.backups = backups
        })

        return ()=>{
            return <div id="backups-settings">
                <h3>Backups</h3>
                {model.backups?.error ? 
                    <h6>{ICON("alert")} {model.backups?.error}</h6>
                : <DataTable
                    loading={!model.backups}
                    noSearch
                    data={model.backups}
                    columns={[
                        {title:"Date", render:x=>D(x.date, "long")},
                        {title:"Astraia version", render:x=>x.version},
                        {title:"Name", render:x=>x.name},
                        {title:"Size", render:x=>readableNumber(x.size) + "o"},
                    ]}
                />}
            </div>
        }
    }
}


