import { range } from "../util";
import { IconButton } from "./Button";
import { ICON } from "./icons";
import './Severity.scss'

export const Severity = ({val, max=4, onChange}) => {
    const pct = parseInt(val/max*100)
    const level = pct <= 25 ? 'low' : pct <= 50 ? 'medium' : pct <= 75 ? 'high' : 'critical'
    return <span class='severity'>
    {onChange && <IconButton style={{visibility:val>1 ? "visible" : "hidden"}} onClick={()=>onChange(val-1)}>{ICON("minus")}</IconButton>}
    {range(max).map(i => i<val ?
        <span class={'circle ' + level} />:
        <span class={'dotted ' + level} />
    )}
    {onChange && <IconButton style={{visibility:val<max ? "visible" : "hidden"}}  onClick={()=>onChange(val+1)}>{ICON("plus")}</IconButton>}
    </span>
}
