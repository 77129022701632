import {API, model} from "../model"
import { DataTable } from "../ui/DataTable"
import { optionsFromQueryParams, queryParams } from "../utils/queryParams"
import { LiveQuery } from "../api/livequery"
import {t} from "../i18n";
import {Tags} from "../ui/Tags";
import './Iocs.scss'
import {gotoScope, link, scopeLink} from "../utils/routing";
import {getRouter, Link} from "../utils/router";
import { ICON } from "../ui/icons";
import { AddButton } from "../ui/AddButton"
import { Switch } from "../ui/Switch";
import { ERR } from "../ui/Toasts";
import { reactive } from "@vue/reactivity";
import { IconButton } from "../ui/Button";
import { modal } from "../ui/Modal";
import { JSONView } from "../ui/JSONView";
import { Dialog } from "../ui/Dialog";
import { Badge } from "../ui/Badge";
import { D_FROM_TO } from "../ui/dates";
import { Severity } from "../ui/Severity";
import { AlertStatus } from "./Alert"
import { watch } from "@vue/runtime-core";
import { useCurrentUserStore } from "../store/CurrentUserStore";

const userStore = useCurrentUserStore

export const Iocs = {
    setup() {
        const data = reactive({disabled:false})
        const lq = LiveQuery("iocs", `{
            count, pages, items {
                ioc, details
            }
        }`, s=>{data.disabled = false; model.iocs = s},
            ()=>[{
                scope_id: model.scopeId,
                limit:parseInt(queryParams().limit) || 20,
                ...optionsFromQueryParams(),
            }], {
                onError:(e)=>{
                    if(e.code === "Not found") data.disabled = true
                    else ERR(t("Can't reach the IOC provider service"))
                }
            }
        )
        
        watch(()=>model.scopeId, ()=>model.iocs = null)

        function newIOC() {
            getRouter().push(model.scopeId ? scopeLink("/newioc") : link("/newioc"))
        }

        async function addTag(ioc, tag) {
            await API.addIOCTag(ioc.ioc, tag)
            lq.refresh()
        }

        async function createTag(ioc, tag) {
            await API.addIOCTag(ioc.ioc, tag)
            lq.refresh()
        }

        async function deleteTag(ioc, tag) {
            await API.deleteIOCTag(ioc.ioc, tag)
            lq.refresh()
        }

        async function toggleWhitelist(ioc) {
            if(ioc.details.tags?.includes("whitelist")) return deleteTag(ioc, "whitelist")
            else return createTag(ioc, "whitelist")
        }

        function showJSON(ioc) {
            return modal(({close}) =>
            <Dialog close={close} onSubmit={()=>close(null)}
                    className="dialog"
                    header={t("JSON IOC")}
                    body={<JSONView source={ioc}/>}
            />)
        }

        return ()=>{
            const all_tags = Array.from(new Set(model.iocs?.items?.reduce?.((t,ioc)=>[...t, ...ioc.details?.tags||[]],[]) || []))
            return <div id="iocs">
                {data.disabled ? <h3 class="feature-disabled">
                    {ICON("settings")} {t("IOC collector is disabled on this scope")}
                </h3>
                : <DataTable
                    key={model.scopeId}
                    header={<>
                        {userStore.hasPermissions("iocs", "write_private") && <AddButton secondary onClick={newIOC}>{t("New IOC")}</AddButton>}
                    </>}
                    loading={lq.loading}
                    data={model.iocs}
                    columns={[
                        {render:x=>ICON_FROM_TAGS(x.details.tags)},
                        {title:t('IOC'), render:x=><Link href={link(`/ioc/${x.ioc}`)}>{x.ioc}</Link>},
                        {title:t('# Alerts'), type:"alerts_count", render:x=>x.details.alerts_count>0 && <Badge color="orange">{ICON("alert")} {x.details.alerts_count}</Badge>},
                        {title:t('Confidence'), render:x=>CONFIDENCE(x.details.confidence)},
                        {title:t('TLP'), render:x=>x.details.tlp},
                        {title:t('Authors'), render:x=>x.details.authors?.join()},
                        {title:t("Whitelist"), render:x=><Switch value={x.details.tags?.includes("whitelist")} onClick={()=>toggleWhitelist(x)}/>},
                        {title:t('Tags'), type:"tags", noLink:true, render:a=>
                            <Tags tags={a.details.tags?.filter(t=>t!=="whitelist")} allowed_tags={all_tags}
                                editable={userStore.hasPermissions("iocs", "write_private")}
                                onAdd={(x)=>addTag(a, x)}
                                onDelete={x=>deleteTag(a, x)}
                                onCreate={(x)=>createTag(a, x)} 
                            />
                        },
                        {render:a=><>
                            <IconButton onClick={()=>showJSON(a.details)}>{ICON("json")}</IconButton>
                        </>}
                    ]}

                    onExpand={async x=>x.alerts = (await API.getIOCAlerts(x.ioc, {scope_id:model.scopeId}))?.alert_groups}
                    collapse={x=>userStore.hasPermissions("iocs", "write_private") && x.details.alerts_count>0 && <DataTable class="ioc_alerts"
                        noSearch noToolbar
                        data={x.alerts}
                        columns={[
                            {title:t('Alert ID'), type:'id', render:x=><>
                                <Link href={link(`/scope/${x.scope_id}/alert/${x.id}`)}>
                                    {x.id}
                                </Link>
                            </>},
                            {title:t("Detection"), type:"detection-dates", render:x=>D_FROM_TO(x.first_detection, x.last_detection)},
                            ...(model.scope ? [] : [{title:t("Scope"), type:"scope", render:ScopeItem}]),
                            {title:t('Qualification'), type:"status", render:x=><AlertStatus horizontal alert={x}/>},
                            {title:t('Message'), type:"description", render:x=><div>
                                <Link href={link(`/scope/${x.scope_id}/alert/${x.id}`)}>
                                    {(x.message || x.alerts_count>1) && <>
                                        <span>{x.message}&nbsp;{x.alerts_count>1 && <Badge>{ICON("alert")} {x.alerts_count}</Badge>}</span> 
                                    </>}
                                </Link>
                                {x.case && <><br/><span class="case">
                                    {ICON("case")} &nbsp;
                                    <Link href={link(`/scope/${x.scope_id}/case/${x.case.id}`)}>
                                            {x.case.title}
                                    </Link>
                                    </span>
                                </>}
                            </div>},
                            {title:t('Source'), type:"field", render:x=>x.source},
                            {title:t('Destination'), type:"field", render:x=>x.destination},
                            {title:t('Severity'), render:x=><Severity val={x.severity}/>},
                            {title:t('Tags'), type:"tags", noLink:true, render:a=>Tags({...a, tags:a.tags.filter(t=>!t.startsWith("attack."))})},
                        ]}
                    />}
                />}
            </div>
            }
    }
}

const ICON_FROM_TAGS = (tags=[]) => {
    for(var t of tags) {
        t = t.toLowerCase()
        if(["malware"].includes(t)) return ICON("malware")
        if(["domain"].includes(t)) return ICON("domain")
        if(["address", "ip", "ipv4", "ipv6"].includes(t)) return ICON("ip")
    }
}

const CONFIDENCE = x => <div class="progress confidence">
    <div class="progress-bar" role="progressbar" style={{width:x+"%"}}></div>
    <span>{x}</span>
</div>

export const ScopeItem = ({scope_id})=><div class="scope-item">
    <img src={model.scopes?.find(s=>s.id===scope_id)?.logo}/>
    <Link href={`scope/${scope_id}/iocs` + location.search}>{model.scopes?.find(s=>s.id===scope_id)?.display_name}</Link>
</div>