import { createPopper } from '@popperjs/core';
import { ref, reactive } from "@vue/reactivity"
import { onMounted, onUnmounted } from "@vue/runtime-core"
import './Tooltip.scss'

export const TOOLTIP = reactive({
    cur: null
})

export const Tooltip = {
    props:["anchor", "placement"],
    setup(props, {slots}) {

        const el = ref()
        let popper = null

        onMounted(()=>{
            popper = createPopper(props.anchor?.$el || props.anchor, el.value, {
                placement:props.placement || "bottom",
                modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, 7],
                      },
                    },
                ],
            });
        })

        onUnmounted(()=>{
            popper?.destroy()
        })

        return ()=>{
            return <div key={TOOLTIP.guid} class="ui-tooltip" class={{show:!!TOOLTIP.show}} ref={el}>
                <div class='arrow'/>
                {slots?.default?.()}
            </div>
        }
    }
}