import { model, API, getTags, getAllTagsForType } from "../model"
import { Severity } from "../ui/Severity"
import { t, t_fem, t_n, t_n_fem } from "../i18n"
import { AddTagButton } from '../ui/Tags'
import "./Alerts.scss"
import { reactive } from "@vue/reactivity"
import { Button, IconButton } from "../ui/Button"
import { ICON } from "../ui/icons"
import { FilteredDropdown} from '../ui/FilteredDropdown'
import { InProgressIcon } from "../ui/InProgressIcon"
import {optionsFromQueryParams, queryParams, setQueryParams, watchQueryParams, intervalFromQueryParams} from "../utils/queryParams"
import { LiveQuery } from "../api/livequery"
import { AddButton } from "../ui/AddButton"
import { Link } from "../utils/router"
import { link, gotoScope } from "../utils/routing"
import { Badge } from "../ui/Badge"
import { UI } from "../App"
import { ICONS } from "../ui/icons"
import { DataTable } from "../ui/DataTable"
import { subscribe } from "../api/graphql"
import {toRaw, watch, watchEffect} from "@vue/runtime-core"
import {Switch} from "../ui/Switch";
import { Dropdown, DropdownItem } from '../ui/Dropdown'
import {Checkbox} from "../ui/Checkbox";
import {download_alerts_as_csv as download_as_csv, download_alerts_as_json as download_as_json} from "../utils/download";
import {TagsMixin} from "../mixins/Tags"
import {Histogram} from "../ui/Histogram"
import {ProgressesHistogram} from "../ui/ProgressesHistogram"
import { capitalize } from "../util"
import { convert_histogram, convert_histogram_series, D, days_before, D_FROM_TO, parseInterval } from "../ui/dates"
import { onUnmounted, onMounted } from "@vue/runtime-core"
import { DropdownCombo } from "../ui/DropdownCombo"
import { question } from "../ui/Modal"
import { AlertStatus } from "./Alert"
import { Qualification } from "./Cases"
import { Input } from '../ui/Input'
import  DatePicker  from "../ui/DatePicker"
import { Tab, Tabs } from "../ui/Tabs"
import { ScopesFilter } from "../ui/ScopesFilter"
import { Dialog } from "../ui/Dialog"
import { modal } from "../ui/Modal"
import { useCurrentUserStore } from "../store/CurrentUserStore"

const userStore = useCurrentUserStore

export const Alerts = {
    props: ['filteredConclusionCodes'],
    setup(props) {
        const userStore = useCurrentUserStore
        const data = reactive({
            selectedItems:[],
            alerts_by_status:{},
            conclusion_codes:[],
            new_alerts:0,
            new_alerts_message:"",
            filteredScopes: new Set(userStore.user?.settings?.filteredScopes || []),
            filteredConclusionCodes: new Set(props.filteredScopes || []),
            histogramMode: userStore.user?.settings?.alertsHistogramMode || "histogram",
            interval:intervalFromQueryParams(),
            savedAlertsSearches:[],
        })

        watchQueryParams(()=>{
            data.interval = intervalFromQueryParams()
        })

        let lq_alerts_by_status = null
        if(!UI.disable_alerts_by_status) {
            lq_alerts_by_status = LiveQuery("alert_groups_by_status", "", a=>data.alerts_by_status=a,
                ()=>[{
                scope_id:model.scopeId,
                case_id:queryParams().case_id,
                not_scope_ids: model.scopeId ? [] : (userStore.user.settings?.filteredScopes || []),
                ...data.interval,
                }], 
                {subscription:"alerts", throttle:5000, interval: 15000}
            )
        }
        
        const lq = LiveQuery("alert_groups", `{
            count, pages, items {
                id, scope_id, message, scope_id, tags, severity, first_detection, last_detection, status, alerts_count, source, destination,
                rule_description, case{title,id}, observer,
                conclusion_code{name,description,code}
            }
        }`, c=>{
                model.alerts=c;
                data.new_alerts=0;
            },
            ()=>[{
                scope_id:model.scopeId,
                limit:parseInt(queryParams().limit) || 20,
                ...optionsFromQueryParams(),
                status: queryParams().status==="all" ? ["new", "open", "closed", "fast-closed"] : queryParams().status==="closed" ?  ["closed", "fast-closed"] : queryParams().status?.split(",") || ["new"],
                not_scope_ids: model.scopeId ? [] : (userStore.user.settings?.filteredScopes || []),
                conclusion_code_id: queryParams().status?.split(",").includes("closed") ? Array.from(data.filteredConclusionCodes) : [],
                ...data.interval,
            }, {scope_id:model.scopeId}],
            {throttle:30000, interval:30000}
        )

            let lq_newalerts = null;
            watchEffect(async ()=>{
                lq_newalerts?.unsubscribe()
                lq_newalerts = await subscribe("new_alert", {scope_id:model.scopeId}, ()=>{
                    UI.enable_alerts_auto_reload ? refresh() : data.new_alerts++
                })
            })
           
            watch(()=>model.scopeId, ()=>{model.alerts = null;})
            watch(()=>model.scopeId, async ()=>{data.savedAlertsSearches = await API.getSavedAlertsSearches({scope_id: model.scopeId})})


        onMounted(async ()=>{
            data.conclusion_codes = await API.getConclusionCodes()
            data.savedAlertsSearches = await API.getSavedAlertsSearches({scope_id: model.scopeId})

        })

        async function refresh() { 
            await Promise.all([
                lq_alerts_by_status?.refresh(),
                lq.refresh() 
            ])
        }
        
        async function onRemove(t, a) {
            if (data.selectedItems.includes(a.id)) {
                await Promise.all(data.selectedItems.map(id=>{
                    let al = model.alerts.items.find(item => item.id == id)
                    if (!!al && al.tags.includes(t)) {
                        API.untag("AlertGroup", id, t)
                    }
                }))
            }
            else {
                await API.untag("AlertGroup", a.id, t)
            }
        }

        const {createTag, Tags} = TagsMixin("AlertGroup", {onRemove, refresh})


        async function addTagToSelection(x) {
            await Promise.all(data.selectedItems.map(id=>API.tag("AlertGroup", id, x)))
            refresh();
        }
        
        async function addSelectionToCase(c) {
            let keep_conclusion = true
            const some_conclusion = data.selectedItems.some(id=>!!model.alerts?.items?.find(x=>x.id===id)?.conclusion_code)
            if(some_conclusion) {
                const res = await question(
                    t("Keep alerts qualifications ?"), 
                    <>
                        <p><b>{t("Yes")}</b>: {t("Alerts will keep their current qualification and will be fast-closed")}</p>
                        <p><b>{t("No")}</b>: {t("Alerts will inherit qualification from the investigation")}</p>
                    </>,
                    {yes:t("Yes"), no:t("No")}) 
                if(!res) return;
                keep_conclusion = (res == "yes")
            }
            await Promise.all(data.selectedItems.map(id=>API.setAlertGroup(id, {case_id:c.id, keep_conclusion})))
            data.selectedItems = []
            refresh();
        }

        function addSelectionToNewCase() {
            gotoScope("newcase?attach_alerts="+data.selectedItems.join(","))
            data.selectedItems = []
        }

        async function setConclusionCode(c) {
            await Promise.all(data.selectedItems.map(id=>API.setAlertGroup(id, {conclusion_code:c.code})))
            data.selectedItems = []
            refresh()
        }

        const applySavedAlertsSearch = (favorite) => {
            let params = JSON.parse(favorite.search)
            data.filteredConclusionCodes = new Set(params.conclusionCodes)
            delete params["conclusionCodes"]
            setQueryParams(params)
            refresh()
        }

        const SavedAlertsSearches = () => {
            return <div class='filter-scopes'>
                <Dropdown button={ICON('star')} items={
                    ()=>data?.savedAlertsSearches?.map((favorite)=>
                    <DropdownItem onClick={() => {
                        applySavedAlertsSearch(favorite)
                    }}>
                        {favorite.name}
                    </DropdownItem>)}
                footer={<Button secondary onClick={()=>createAlertsSearch()}>{ICON('add')} {t("Add favorite")}</Button>}
                />
            </div>
        }

        async function detachSelection() {
            const some_conclusion = data.selectedItems.some(id=>!!model.alerts?.items?.find(x=>x.id===id)?.conclusion_code)
            let reopen = false
            if(some_conclusion) {
                const res = await question(
                    t("Reopen selected alerts ?"), 
                    <>
                        <p><b>{t("Yes")}</b>: {t("All selected alerts will be reopened and marked as 'new'")}</p>
                        <p><b>{t("No")}</b>: {t("Closed alerts will keep their qualification and will be marked as 'fast-closed', open alerts will be marked as 'new'")}</p>
                    </>,
                    {yes:t("Yes"), no:t("No")})
                if(!res) return;
                reopen = (res == "yes")
            }
            await Promise.all(data.selectedItems.map(id=>API.setAlertGroup(id, {case_id:null, reopen})))
            data.selectedItems = []
            refresh()
        }

        async function detach(alert) {
            let reopen = false
            if(alert.conclusion_code) {
                const res = await question(
                    t("Reopen alert ?"), 
                    <>
                        <p><b>{t("Yes")}</b>: {t("Alert will be reopened and marked as 'new'")}</p>
                        <p><b>{t("No")}</b>: {t("Alert will keep its qualification and will be marked as 'fast-closed'")}</p>
                    </>,
                    {yes:t("Yes"), no:t("No")}) 
                if(!res) return
                reopen = (res == "yes")
            }
            await API.setAlertGroup(alert.id, {case_id:null, reopen})
            refresh()
        }

        async function reopen(alert) {
            await API.setAlertGroup(alert.id, {status:"open"})
            refresh()
        }


        async function setHistogramMode(mode) {
            await API.setUserSettings(userStore.user.id, {
                ...userStore.user.settings,
                alertsHistogramMode: mode
            })
            data.histogramMode = mode
        }

        const displayObserver = (observers) => {
            if (!observers) return
            if (observers.startsWith("[")) {
                const parsedObservers = JSON.parse(observers).join(", ")
                return <span id="observers">{parsedObservers}</span>
            } else {
                return <span id="observers">{observers}</span>
            }
        }

        function newAlert() { gotoScope("newalert") }

        async function createAlertsSearch() {
            let modal = await createAlertsSearchModal()
            let params = {...queryParams(), conclusionCodes: Array.from(data.filteredConclusionCodes)}
            let scope = model.scopeId && !modal.scopeShare ? model.scopeId : null
            let user = !modal.userShare ? userStore.user.id : null
            delete params["page"]
            delete params["to"]
            delete params["from"]
            API.createSavedAlertsSearch({scope_id: scope, name: modal.name, user_id: user, search: JSON.stringify(params)})
            .then(async ()=>data.savedAlertsSearches = await API.getSavedAlertsSearches({scope_id: model.scopeId}))
        }

        function getCases(search) { return API.getCases({scope_id:model.scopeId,search,limit:10,order:"desc", sort:"last_activity"}) }

        const mergedKeys = (keys, data) => {
            const merged = {...data}
            if(keys) {
                for(const k in keys) {
                    merged[keys[k]] = (merged[keys[k]]||0) + (merged[k]||0)
                    delete merged[k]
                }
            }
            return merged
        }

        return ()=>{
            const allowed_tags = getTags(model.scopeId, "AlertGroup")
            const tab = queryParams().status || "new"
            const first_older_alert = model.alerts?.items?.find((x=>
                data.interval?.from && new Date(x.last_detection)<data.interval?.from
            ))?.id

            const RW = userStore.hasPermissions("alerts", "write_private")

            return <div id="alerts">
            <DataTable
                title={t("Alerts")}
                placeholder={t("Search by title, IP/range, description, or tags ...")}
                loading={lq.loading}
                data={model.alerts}
                header={<>
                    <DatePicker />
                    {!localStorage.getItem("disable-histograms") &&
                    <Dropdown button={ICON(data.histogramMode)} items={()=><>
                        <DropdownItem onClick={()=>setHistogramMode("histogram")}>
                            {ICON("histogram")}
                            {t("Detection date histogram")}
                        </DropdownItem>
                        <DropdownItem onClick={()=>setHistogramMode("conclusions")}>
                            {ICON("conclusions")}
                            {t("Statuses and conclusions histogram")}
                        </DropdownItem>
                        <DropdownItem onClick={()=>setHistogramMode("tags")}>
                            {ICON("tags")}
                            {t("Tags histogram")}
                        </DropdownItem>                        
                        <DropdownItem onClick={()=>setHistogramMode("rules")}>
                            {ICON("rules")}
                            {t("Rules histogram")}
                        </DropdownItem>
                    </>}/>}
                </>}
                
                headerBottom={<div class="header-bottom">
                    {!localStorage.getItem("disable-histograms") && <>
                        {data.histogramMode==="histogram" && <AlertsHistogram/>}
                        {data.histogramMode==="conclusions" && <ConclusionsHistogram/>}
                        {data.histogramMode==="tags" && <TagsHistogram/>}
                        {data.histogramMode==="rules" && <RulesHistogram/>}
                    </>}
                    <div class='action-bar'>
                        <div>
                            {!model.scopeId && <ScopesFilter value={data.filteredScopes} onChange={x=>data.filteredScopes=x}/>}
                            {RW && <>
                                <div class="actions" className={{hide:!data?.selectedItems?.length}}>
                                    {data.selectedItems.length>0 && <>
                                        <span class="selected-count">{t_n(data.selectedItems.length, "alert selected")}</span>
                                        <div class="operations">
                                            {model.scopeId && <>
                                                <Button onClick={addSelectionToNewCase}>{ICON("add")} {t("Open a new investigation")}</Button>
                                                <FilteredDropdown
                                                    button={<>{ICON("case")} {t("Add to existing investigation")}</>}
                                                    query={getCases}
                                                    render={x=><>{ICON("case")} {x.title}</>}
                                                    onSelect={addSelectionToCase}
                                                    placement="top-start"
                                                />
                                            </>}
                                            <DropdownCombo menuClass="fast-close-actions" 
                                                button={<><i class="fas fa-meteor"/>{t("Fast close")}</>} 
                                                items={filter=>data.conclusion_codes.filter(c=>c.fast_close && `${c.code} ${c.name}`.toLowerCase().includes(filter.toLowerCase()))}    
                                                render={Qualification}
                                                onSelect={setConclusionCode}
                                                placement="top-start"
                                            />
                                            {data.selectedItems.some(id=>model.alerts?.items?.find(x=>x.id===id)?.case) && 
                                                <Button secondary onClick={detachSelection}><i class='fas fa-unlink'/>{t("Detach")}</Button>
                                            }
                                        </div>
                                        <AddTagButton onCreate={x=>createTag(null,x).then(()=>addTagToSelection(x))} available_tags={allowed_tags} allowed_tags={allowed_tags} addTag={addTagToSelection}/>
                                        <IconButton title={t("Download as JSON")} onClick={()=>download_as_json(data.selectedItems, model.scopeId)}>{ICON('download')}</IconButton>
                                        <IconButton title={t("Download as CSV")} onClick={()=>download_as_csv(data.selectedItems, model.scopeId)}>{ICON('csv')}</IconButton>
                                    </>}
                                </div>
                                <div id="refresh-now">
                                    {data.new_alerts>0 && <>
                                        <IconButton title={t("Refresh")} onClick={refresh}>{ICON("refresh")}</IconButton>
                                        {t_n_fem(data.new_alerts, "alert")} {t("since last refresh")}
                                        </>}
                                </div>
                            </>}
                        </div>
                        <Tabs>
                            <Tab active={tab==="new"} onClick={()=>setQueryParams({status:undefined})} label={<span>{t_fem("New", {nb:data.alerts_by_status?.new})} <Badge>{data.alerts_by_status?.new||0}</Badge></span>}/>
                            <Tab active={tab==="open"} onClick={()=>setQueryParams({status:"open"})} label={<span>{t_fem("Open", {nb:data.alerts_by_status?.open})} <Badge>{data.alerts_by_status?.open||0}</Badge></span>}/>
                            <Tab active={tab==="closed"} onClick={()=>setQueryParams({status:"closed"})} label={<span>{t_fem("Closed", {nb:mergedKeys({"fast-closed":"closed"}, data.alerts_by_status)})} <Badge>{mergedKeys({"fast-closed":"closed"}, data.alerts_by_status)["closed"]}</Badge></span>}/>
                            <Tab active={tab==="all"} onClick={()=>setQueryParams({status:"all"})} label={<span>{t("All")} <Badge>{(data.alerts_by_status?.new||0) + (mergedKeys({"fast-closed":"closed"}, data.alerts_by_status)["closed"]||0) + (data.alerts_by_status?.open||0)}</Badge></span>}/>
                        </Tabs>
                        {queryParams().status?.split(",").includes("closed") && <ConclusionCodesFilter items={data.conclusion_codes} value={data.filteredConclusionCodes} onChange={x=>data.filteredConclusionCodes=x} />}
                        {<SavedAlertsSearches/>}
                    </div>
                </div>}
                
                columns={[
                    {title:t('ID'), type:'id', render:x=><>
                        <Link href={link(`/scope/${x.scope_id}/alert/${x.id}`)}>
                            {x.id}
                        </Link>

                        {first_older_alert===x.id && <div id="older-alerts">{t("Older alerts")}</div>}
                    </>, sort:"id"},
                    {title:t("First detection"), type:"date-abs", render:x=>x.first_detection, sort:"first_detection"},
                    {title:t("Last detection"), type:"date-abs", render:x=>x.last_detection, sort:"last_detection"},
                    ...(model.scope ? [] : [{title:t("Scope"), type:"scope", render:ScopeItem}]),
                    {title:t('Qualification'), type:"status", render:x=><AlertStatus horizontal alert={x} onChange={refresh} reopen={()=>reopen(x)} detach={()=>detach(x)}/>, sort:"status"},
                    {title:t('Message'), type:"description", render:x=><div>
                        <Link href={link(`/scope/${x.scope_id}/alert/${x.id}`)}>
                            {(x.message || x.alerts_count>1) && <>
                                <span>{x.message}&nbsp;{x.alerts_count>1 && <Badge>{ICON("alert")} {x.alerts_count}</Badge>}</span> 
                            </>}
                        </Link>
                        {x.case && <><br/><span class="case">
                            {ICON("case")} &nbsp;
                            <Link href={link(`/scope/${x.scope_id}/case/${x.case.id}`)}>
                                    {x.case.title}
                            </Link>
                            </span>
                        </>}
                    </div>, sort:"message"},
                    {title: 'Observer', type:"field", render:x=>displayObserver(x.observer)},
                    {title:t('Source'), type:"field", render:x=>x.source},
                    {title:t('Destination'), type:"field", render:x=>x.destination},
                    {title:t('Severity'), render:x=><Severity val={x.severity}/>, sort:"severity"},
                    {title:t('Tags'), type:"tags", noLink:true, render:a=>Tags({...a, tags:a.tags.filter(t=>!t.startsWith("attack."))})},
                ]}
                
                defaultSort={{sort:"last_detection", order:"desc"}}

                selection={RW && data.selectedItems}

                rowClass={x=>({older:first_older_alert===x.id})}

                footer={
                    RW && model.scopeId &&
                    <Switch
                        class='autoreload-switch'
                        value={UI.enable_alerts_auto_reload}
                        onClick={()=>UI.enable_alerts_auto_reload=!UI.enable_alerts_auto_reload}
                    >
                        {"Auto Reload"}
                    </Switch>
                }
            />
        </div>
        }
    }
}

export const ScopeItem = ({scope_id})=><div class="scope-item">
    <img src={model.scopes?.find(s=>s.id===scope_id)?.logo}/>
    <Link href={`scope/${scope_id}/alerts` + location.search}>{model.scopes?.find(s=>s.id===scope_id)?.display_name}</Link>
</div>


export const ConclusionCodesFilter = {
    props: ["items", "value", "onChange"],
    setup(props) {
        const data = reactive({open:false, filter:""})
        return () => {
            const items = props.items.filter(ccl=>`${ccl.code} ${ccl.name}`.toLowerCase().includes(data.filter.toLowerCase())).sort((a, b) =>
            {
                if (props.value.has(a.id) && !props.value.has(b.id)) {
                    return -1;
                }
                if (props.value.has(b.id) && !props.value.has(a.id)) {
                    return 1;
                }
                return a.code.localeCompare(b.code)
            })
            return <div class='filter-scopes'>
                <Dropdown button={t('Filter conclusions')} 
                    top={<Input 
                        autofocus
                        placeholder={t("Search...")} 
                        value={data.filter} 
                        onInput={e=>data.filter = e.target.value} 
                        pre={ICON("search")} 
                        post={data.loading ? <InProgressIcon/> : <small>{items?.length||0} {t('results')}</small>}
                    />}
                    items={()=><>
                        <Button secondary onClick={(e) => {
                            props.value.clear()
                            props.onChange?.(props.value)
                            e.stopPropagation()
                            e.preventDefault()
                        }}>{t("Clear")}</Button>
                        {items.map((ccl)=>
                    <DropdownItem class='conclusion-item' onClick={(e) => {
                        props.value.delete(ccl.id) || props.value.add(ccl.id)
                        props.onChange?.(props.value)
                        e.stopPropagation()
                        e.preventDefault()
                    }}>
                        <Checkbox value={props.value.has(ccl.id)}/>
                        <Qualification code={ccl.code} name={ccl.name} />
                    </DropdownItem>)}
                    </>}
                />
            </div>
        }
    }
}

const RulesHistogram = {
    setup(props) {
        const data=reactive({
            histogram:null, 
            interval:intervalFromQueryParams(),
        })

        watchQueryParams(()=>{
            data.interval = intervalFromQueryParams()
        })

        let refr = setInterval(()=>{
            data.interval = intervalFromQueryParams()
        }, 10000)
        onUnmounted(()=>{
            clearInterval(refr)
        })

        const lq = LiveQuery("alerts_histogram", "", update, ()=>[{
            mode:'rules', scope_id:model.scopeId, 
            ...props,
            ...data.interval, 
            not_scope_ids: model.scopeId ? [] : (userStore.user.settings?.filteredScopes || []),
        }], {
            subscription: 'alerts', 
            throttle:60000, 
            interval:60000,
            onError:()=>{}
        })

        function update(x) {
            data.histogram = Object.entries(x).sort();
        }

        return ()=><ProgressesHistogram key={model.scopeId} data={data.histogram}/>
    }
}

const TagsHistogram = {    
    setup(props) {
        const data=reactive({
            histogram:null,
            interval:intervalFromQueryParams(),
        })

        watchQueryParams(()=>{
            data.interval = intervalFromQueryParams()
        })

        let refr = setInterval(()=>{
            data.interval = intervalFromQueryParams()
        }, 10000)
        onUnmounted(()=>{
            clearInterval(refr)
        })

        const lq = LiveQuery("alerts_histogram", "", update, ()=>[{
            mode:'tags', scope_id:model.scopeId, 
            ...props,
            ...data.interval,
            not_scope_ids: model.scopeId ? [] : (userStore.user.settings?.filteredScopes || []),
        }], {
            subscription: 'alerts',
            throttle:60000, 
            interval:60000,
            onError:()=>{}
        })

        function update(x) {
            const tags = getAllTagsForType('Alert')
            data.histogram = Object.entries(x).map(([name,value])=>([
                <Badge style={{backgroundColor:tags[name]?.color}}>{t(name)}</Badge>, value, tags[name]?.color
            ])).filter(([k,v])=>k).sort();
        }

        return ()=><ProgressesHistogram key={model.scopeId} data={data.histogram}/>
    }
}

const ConclusionsHistogram = {
    setup(props) {
        const data=reactive({
            histogram:null,            
            interval:intervalFromQueryParams(),
        })

        watchQueryParams(()=>{
            data.interval = intervalFromQueryParams()
        })

        let refr = setInterval(()=>{
            data.interval = intervalFromQueryParams()
        }, 10000)
        onUnmounted(()=>{
            clearInterval(refr)
        })

        const lq = LiveQuery("alerts_histogram", "", update, ()=>[{
            mode:'conclusions', scope_id:model.scopeId, 
            ...props,
            ...data.interval,
            not_scope_ids: model.scopeId ? [] : (userStore.user.settings?.filteredScopes || []),
        }], {
            subscription: 'alerts',
            throttle:60000, 
            interval:60000,
            onError:()=>{}
        })

        function update(x) {
           const {new:news, open, ...rest} = x
           data.histogram = [
               [<>{ICON('star')} {t_fem("alert:New",{nb:2})}</>,news||0], 
               [<>{ICON('case')} {t_fem("alert:Open")}</>,open||0], 
               ...Object.entries(rest).sort().map(([k,v])=>{
                   let [code, ...name] = k.split(" ")
                   name = name.join(" ")
                   return [code,name,v]
               })
               .filter(([code])=>!["TMP:FIX","UNK:OLD"].includes(code))
               .map(([code,name,v])=>[Qualification({code,name}), v])
            ]
        }

        return ()=><ProgressesHistogram key={model.scopeId} data={data.histogram}/>
    }
}


const AlertsHistogram = {
    setup(props) {
        const userStore = useCurrentUserStore
        const data=reactive({
            histogram:null,
            interval:intervalFromQueryParams(),
        })

        watchQueryParams(()=>data.interval = intervalFromQueryParams())

        // Slide the time window every minute
        let refr = setInterval(()=>data.interval = intervalFromQueryParams(), 60000)
        onUnmounted(()=>clearInterval(refr))

        // Cleanup when switching scope 
        watch(()=>model.scopeId, ()=>data.histogram = null)

        const colors = {
            "closed": '#57bf57',
            "fast-closed": '#5b7c5b',
            "investigating": '#cdcdcd',
            "new": '#ffce00',
        }

        const labels = {
            "open": "investigating",
            "fast-closed": t("alerts:fast-closed")
        }

        function update(d) {
            const [x,y] = convert_histogram_series(d || {})
            data.histogram = {
                labels:x,
                datasets: ["closed", "open", "new", "fast-closed"].map(s=>({
                    label: capitalize(t_fem(labels[s]||s, {nb:2})),
                    backgroundColor: colors[s],
                    data:y[s],
                    stack:0
                })),
            }
        }

        UI.enable_active_polling = true

        const lq = LiveQuery("alerts_histogram", "", update, ()=>[{
            mode:"histogram", scope_id:model.scopeId, 
            ...props,
            ...data.interval,
            not_scope_ids: model.scopeId ? [] : (userStore.user.settings?.filteredScopes || []),
        }], {
            subscription: 'alerts',
            throttle:60000, 
            interval:60000,
            onError:()=>{}
        })

        return ()=><Histogram stacked dates data={data.histogram}/>
    }
}

export async function createAlertsSearchModal() {

    const data = reactive({
        name: "",
        scopeShare: false,
        userShare: false,
    })

    return modal(({close})=>
    <Dialog close={close} onSubmit={()=>close(data)}
        header={t("Add favorite")}
        body={<div id="add-favorite-modal">
                <Input label={t("Name")} placeholder={t("Favorite name")} value={data.name} onChange={(e)=>data.name=e.target.value}/>
                <span>
                    <Checkbox class="checkbox" isChecked={data.userShare} onChange={() => data.userShare = !data.userShare} />
                    {t("Share between user")}
                </span>
                {model.scopeId && <span><br/>
                    <Checkbox class="checkbox" isChecked={data.scopeShare} onChange={() => data.scopeShare = !data.scopeShare}/>
                    {t("Share between scope")}
                </span>}
        </div>}
        buttons={<>
            <Button secondary onClick={()=>close(null)}>{t("Cancel")}</Button>
            <Button type="submit" disabled={data.name === ""}>{t("OK")}</Button>
        </>}
    />)
}