import { capitalize } from "../util"

const PLURALS = {
    "Case pending": "Analyses en attente",
    "Analyse en attente": "Analyses en attente",
    "Analyse traitée": "Analyses traitées",
    "Analyse en cours": "Analyses en cours",
    "Case open": "Analyses en cours",
    "Case closed": "Analyses traitées",
    "Recommendation pending": "Recommandations en attente",
    "pending": "en attente",
    "Recommendation validated": "Recommandations validées",
    "validated out of": "validées sur",
    "alert selected": "alertes sélectionnées",
    "Case selected": "analyses sélectionnées",
    "Agent selected": "agents sélectionnés",
    "False positive": "Faux positifs",
    "Investigating": "En cours d'analyse",
    "En cours d'analyse": "En cours d'analyse", 
    "case:All": "Toutes",
    "case:Open": "En cours d'analyse",
    "case:Closed": "Traitées",
    "reco:All": "Toutes",
    "reco:Open": "Ouvertes",
    "reco:Closed": "Traitées",
    "case:Done": "Traitées",
    "to fix": "à corriger",
    "à corriger": "à corriger",
    "To fix": "A corriger",
    "A corriger": "A corriger",
    
    "Vulnerability observed": "Vulnérabilités observées",
    "Vulnerability notified": "Vulnérabilités notifiées",
    "Vulnerability without impact": "Vulnérabilités sans impact",
    "Vulnerability patched": "Vulnérabilités corrigées",

    "Waiting": "En attente",
    "alert": "alertes",
    "Closed": "Fermés",
    "Open": "Ouverts",
    "ouverte": "ouvertes",
    "en cours": "en cours",
    "En cours": "En cours",
    "traitée": "traitées",
    "en cours d'analyse": "en cours d'analyse",
    "vuln:no_impact": "Sans impact",
    "mois": "mois",
    "month": "mois",
    "alert:Open": "En cours d'analyse",
    "Traitée (qualification rapide)": "Traitées (qualification rapide)",
    "en cours": "en cours",
    "open": "en cours",
    "pending": "en attente",
    "en attente": "en attente",
    "Without impact": "Sans impact",
    "without impact": "sans impact",
    "sans impact": "sans impact",
}


const TRANSLATIONS = {
    "true": "vrai",
    "false": "faux",
    "revoked":"révoqué",
    "never": "jamais",
    "filters": "filtres",
    "Filters": "Filtres",
    "Relatives dates": "Date relatives",
    "no":"non",
    "Enter": "Entrer",
    "yes":"oui", 
    "Hidden": "Caché",
    "This is not a date": "Ceci n'est pas une date",
    "Join": "Rejoindre",
    "Length": "Durée",
    "days": "jours",
    "day": "jour",
    "All rights reserved" : "Tous droits réservés",
    "Diagnosis": "Diagnostic",
    "Support": "Accompagnement",
    "Visit us at": "Retrouvez-nous",
    "All categories": "Toutes les catégories",
    "Action plan": "Plan d'action",
    "Findings": "Constatations",
    "Contact us": "Contactez-nous",
    "New": "Nouveau",
    "See more": "Voir plus",
    "Less": "Moins",
    "go in finding details": "aller dans le détail de la consatation",
    "This feature lets you configure the sections that will be highlighted by default on alerts if no configuration has been made in the associated rules.": "Cette fonctionnalité permet de configuer les sections qui seront mise en avant par défault sur les alertes si aucune configuration n'a été faites dans les règles associés",
    "Settings rules sections": "Paramètres règles sections",
    "Removed": "Suppression",
    "Updated": "Mise à jour",
    "Submit": "Soumettre",
    "Created": "Création", 
    "You": "Vous",
    "Public": "Publique",
    "last edited": "dernière modification",
    "last detection": "dernière détection",
    "first detection": "première détection",
    "Triggering event": "Événement déclencheur",
    "First detection": "Première détection",
    "Last detection": "Dernière détection",
    "Detection date": "Date de détection",
    "Older alerts": "Alertes plus anciennes",
    "Generate": "Générer",
    "Details": "Détails",
    "administrator": "Administrateur",
    "supervisor": "Superviseur",
    "Assigned": "Assigné(e)s",
    "external": "Externe",
    "No result": "Aucun resultat",
    "Notify nothing": "Ne rien notifier",
    "Notify case open/close": <>Notifier les<br/>ouvertures/fermetures <br/>d'analyses</>,
    "Notify comments": <>Notifier les<br/> commentaires</>,
    "Notify vulnerabilities": <>Notifier les<br/> vulnérabilités</>,
    "Notify reports": <>Notifier les<br/>rapports mensuels</>,
    "Send": "Envoyer",
    "No case active in last 30 days": "Aucune analyse active dans les 30 derniers jours",
    "Sections highlighted in alerts associated with this rule":"Sections mises en avant dans les alertes associées à cette règle",
    "Your sections have been added": "Vos sections ont bien été ajouté",
    "Activated": "Activé",
    "waiting_for_response": "en attente de réponse",
    "waiting for response ...": "en attente de réponse ...",
    "date:from": "du",
    "date:to": "au",
    "to fix": "à corriger",
    "specific": "spécifique",
    "Select a status": "Sélectionnez un statut",
    "To fix": "A corriger",
    "Rules": "Règles de détection",
    "Rule": "Règle de détection",
    "Global": "Globale",
    "Not started": "Pas commencé",
    "Start": "Début",
    "auditor": "Auditeur",
    "audit_manager": "Client CT-Diag",
    "In progress" : "En cours",
    "Finished": "Terminé",
    "State": "Etat",
    "Audit name": "Nom de l'audit",
    "End": "Fin",
    "Rules coverage": "Couverture des règles",
    "Att&ck Coverage": "Couverture MITRE ATT&CK",
    "Password reset email sent to user but user has\'nt set his password yet": "Email de réinitialisation du mot de passe envoyé à l'utilisateur. L'utilisateur n'a pas encore redéfini son mot de passe",
    "Email sent on": "Email envoyé le",
    "Activation pending": "En attente d'activation",
    "Activation email sent on": "Email d'activation envoyé le",
    "Resend email": "Renvoyer l'email",
    "Send": "Envoyer",
    "Update": "Mettre à jour",
    "Delete case": "Supprimer l'analyse",
    "Next case": "Suivante",
    "Previous case": "Précédente",

    "Notify customers ?": "Notifier les clients ?",
    "Do you want to send an email for this vulnerability ?": "Voulez vous notifier cette vulnerabilité par email ?",

    "Are you sure you want to delete this favorite ?": "Êtes-vous sûr de vouloir supprimer ce favori ?",
    "Saved alerts searches": "Recherches d'alertes sauvegardées",
    "Delete favorite": "Supprimer le favori",
    "Add favorite": "Ajouter un favori",
    "Favorite name": "Nom du favori",
    "Share between scope": "Partager entre scope",
    "Share between user": "Partager entre utilisateur",

    "Password reset requested": "Réinitialisation du mot de passe demandée",
    "New password": "Nouveau mot de passe",
    "You have requested a password reset": "Vous avez demandé une réinitialisation de votre mot de passe",
    "Please reset your password now": " ",
    "Reset my password": "Définir mon mot de passe",
    "Passwords do not match !": "Les mots de passe ne correspondent pas !",
    "Password successfully updated !": "Votre mot de passe a été mis à jour",
    "Resend activation email": "Renvoyer l'email d'activation",
    "Invalid token !": "Jeton invalide",
    "Back to login page": "Retour à la page de connexion",
    "Welcome ":"Bienvenue ",
    "Reset password": "Réinitialiser le mot de passe",
    "Activation email sent to ": "Email d'activation envoyé à ",
    "Password reset email sent to ": "Email de réinitialisation de mot de passe envoyé à ",
    "Set my password": "Définir mon mot de passe",
    "Add related case": "Lier à une analyse",
    "CVSS score": "Score CVSS",
    "vuln:notified": "Notifié",
    "vuln:exposed": "Exposé",
    "vuln:no_impact": "Sans impact",
    "vuln:patched": "Corrigé",
    "Case open": "Analyse en cours",
    "Add recepients": "Ajouter des destinataires",
    "New recepients will receive an email": "Les nouveaux destinataires recevront un email",
    "The comment has been emailed to ": "Le commentaire a été envoyé par email à ",
    "Case closed": "Analyse traitée",
    "Action on" : "Action sur",
    "Report already exists. Do you really want to overwrite KPI data ?": "Voulez-vous vraiment écraser les données de KPI ?",
    "Overwrite existing cases too !": "Écraser aussi les analyses existantes !",
    "Overwrite existing report": "Mettre à jour le rapport existant",
    "Report already exists": "Ce rapport existe déjà",
    "Recommendation pending": "Recommandation en attente",
    "pending": "en attente",
    'This alert has no fields': "Cette alerte n'a pas les champs requis",
    "Recommendation validated": "Recommandation validée",
    "Investigation": "Investigation",
    "Cases by status": "Statut des analyses",
    "Cases by type": "Types d'analyses",
    "Patrol": "Patrouille",
    "investigation": "investigation",
    "patrol": "patrouille",
    "Last activity": "Dernière activité",
    "First activity": "Première activité",
    "Status": "Statut",
    "Recommendation": "Recommandation",
    "Recommendations": "Recommandations",
    "Vulnerability": "Vulnérabilité",
    "Vulnerabilities": "Vulnérabilités",
    "Cancelled": "Annulé",
    "False positive": "Faux positif",
    "Analysis": "Analyse",
    "Planning": "Planification",
    "Activity report": "Rapport d'activité",
    "Network logs": "Logs réseau", 
    "System logs": "Logs système",
    "Cloud logs": "Logs Cloud",
    "Cases filtered by scopes": "Analyses filtrés par les scopes",
    "Generate new report": "Générer un rapport",
    "Send a mail notification": "Envoyer une notification par mail",
    "last days": "derniers jours",
    "Recommendations pending": "Recommandations non validées",
    "detection date": "date de détection",
    "validated out of": "validée sur",
    "Search...": "Rechercher...",
    "Sort by": "Trier par",
    "Jump to unread messages": "Voir les messages non lus",
    "Jump to last messages": "Voir les derniers messages",
    "No new message": "Vous n'avez aucun nouveau message",
    "No message sent": "Vous n'avez envoyé aucun message",
    "No message": "Aucun message",
    "Inbox": "Boîte de réception",
    "Sent": "Messages envoyés",
    "analysts": "analystes",
    "internal": "interne",
    "public": "public",
    "Since beginning": "Depuis le début",
    "Not deployed on": "Non déployé sur",
    "your scope": "votre périmètre",
    "Propagate to other scopes": "Propager à d'autres scopes",
    "private": "privé",
    "edited": "édité",
    "Save": "Sauvegarder",
    "Cancel": "Annuler",
    "Delete": "Supprimer",
    "Conditions": "Conditions",
    "second": "seconde",
    "hour": "heure",
    "day": "jour",
    "A month": "Un mois",
    "Since beginning": "Depuis le début",
    "week": "semaine",
    "month": "mois",
    "year": "an",
    "report": "rapport mensuel",
    "Sent as email": "Envoyé par email",
    "results": "resultats",
    "alerts": "alertes",
    "Alerts": "Alertes",
    "Conclusions": "Conclusions de l'analyse",
    "analyst": "analyste",
    "manager": "manager",
    "Priority": "Priorité",
    "Difficulty": "Difficulté",
    "Cost": "Coût",
    "Control": "Contrôle",
    "Refs": "Références",
    "Send as email": "Envoyer par email",
    "Add to playbook": "Ajouter au playbook",
    "Add to monthly report": "Ajouter au rapport mensuel",
    "Convert to recommendation": "Convertir en recommandation",
    "Add related scenario": "Lier un scénario",
    "Add related recommendation": "Lier une recommandation",
    "Add related alert": "Lier une alerte",
    "Add alert": "Ajouter une alerte",
    "Add participant": "Ajouter un participant",
    "Visibility": "Visibilité",
    "Add tags": "Ajouter des tags",
    "Locked": "Verrouillé",
    "Supervisor": "Superviseur",
    "# Alerts": "# Alertes",
    "# Cases": "# Analyses",
    "Example": "Exemple",
    "# Recommendations": "# Recommandations",
    "alert selected": "alerte sélectionnée",
    "Case selected": "Analyse sélectionnée",
    "Ignore": "Ignorer",
    "Add to case": "Ajouter à l'analyse",
    "Severity": "Gravité",
    "Number of alerts": "Nombre d'alertes",
    "Save changes": "Sauvegarder",
    "alert:New": "Nouvelle",
    "Investigating": "En cours", 
    "alert:Done": "Traitée",
    "alert:Ignored": "Ignorée",
    "severity": "gravité",
    "Qualify selected alerts": "Qualifier les alertes sélectionnées",
    "open": "En cours",
    "Criticality": "Criticité",
    "Create": "Créer",
    "Creation date":"Date de création",
    "New Scope": "Nouveau Scope",
    "New Alert": "Nouvelle Alerte",
    "New Recommendation": "Nouvelle Recommandation",
    "New Scenario": "Nouveau Scenario",
    "New Vulnerability": "Nouvelle vulnérabilité",
    "New scope": "Nouveau scope",
    "Profile": "Mon compte",
    "profile": "mon compte",
    "Add member": "Ajouter un membre",
    "Add": "Ajouter",
    "Add scope member": "Ajouter un membre au scope",
    "My scopes": "Mes scopes",
    "User": "Utilisateur",
    "reports": "Rapports",
    "users": "utilisateurs",
    "Users": "Utilisateurs",
    "Attach to case": "Rattacher à une analyse",
    "Rule": "Règle de détection",
    "Rules": "Règles de détection",
    "Rul": "Règle",
    "author": "auteur",
    "Raw alert": "Alerte brute",
    "Members": "Membres",
    "Settings": "Configuration",
    "Request for investigation": "Demander une investigation",
    "Declare incident": "Déclarer un incident",
    "Investigate": "Investiguer",
    "Case pending": "Analyse en attente",
    "Pending": "En attente",
    "Cases": "Analyses",
    "Investigation request": "Demande d'investigation",
    "Declare incident": "Declaration d'incident",
    "Case": "Analyse",
    "Recommendation": "Recommandation",
    "Assets": "Actifs",
    "New asset": "Ajouter un actif",
    "case:All": "Toutes",
    "case:Open": "En cours d'analyse",
    "case:Closed": "Traitée",
    "reco:All": "Toutes",
    "reco:Open": "Ouverte",
    "reco:Closed": "Traitée",
    "Waiting": "En attente",
    "case:Done": "Traitée",
    "case:Canceled": "Annulée",
    "reco:Done": "Appliquée",
    "reco:open": "Ouverte",
    "reco:canceled": "Annulée",
    "reco:done": "Appliquée",
    "reco:draft": "Brouillon",
    "reco:deleted": "Supprimée", 
    "reco:archived": "Archivée",
    "reco:validated": "Validée",
    "reco:rejected": "Rejetée",
    "reco:closed": "Fermée",
    "reco:archived-validated": "Validée et archivée",
    "reco:todo": "Emise", 
    "Last signin": "Dernière connexion",
    "Last seen": "Dernière activité",
    "Created": "Date de création",
    "Devices": "Machines",
    "domain-controller": "Contrôleur de domaine",
    "cases":"analyses",
    "vulnerabilities":"vulnérabilités",
    "members":"membres",
    "settings":"configuration",
    "New user": "Nouvel utilisateur",
    "Invalid email": "Email invalide",
    "New scenario...": "Nouveau scenario...",
    "New case...": "Nouvelle analyse...",
    "New incident...": "Nouvel incident...",
    "New recommendation...": "Nouvelle recommandation...",
    "New vulnerability...": "Nouvelle vulnérabilité...",
    "New alert...": "Nouvelle alerte...",
    "New scenario": "Nouveau scenario",
    "New recommendation": "Nouvelle recommandation",
    "New vulnerability": "Nouvelle vulnérabilité",
    "New case": "Nouvelle analyse",
    "New incident": "Nouvel incident",
    "New alert": "Nouvelle alerte",
    'cancel': 'annulé',
    "containment": "confinement",
    "mitigation": "remédiation",
    "recovery": "rétablissement",
    "closed": "traité",
    "priority": "priorité",
    "Regenerate report": "Regénérer le rapport",
    "Unread messages": "Messages non lus",
    "All notified messages": "Tous les messages notifiés",
    "Mark all as read": "Tout marquer comme lu",
    "Tracking":"Suivi",
    "Page size":"Page",
    "Agent selected": "Agent sélectionné",
    "Show agent logs on kibana": "Voir les logs de l'agent sur kibana",
    "January": "Janvier",
    "February": "Fevrier",
    "March": "Mars",
    "April": "Avril",
    "May": "Mai",
    "June": "Juin",
    "July": "Juillet",
    "August": "Août",
    "September": "Septembre",
    "October": "Octobre",
    "November": "Novembre",
    "December": "Décembre",
    "just now": "à l'instant",
    "project_manager": "Chef de Projet",
    "Select user...": "Sélectionnez un utilisateur...",
    "Name": "Nom",
    "Invalid email or password": "Email ou mot de passe invalide",
    "No profile": "Aucun profil",
    "Apply": "Appliquer",

    "No recommendation": "Aucune recommandation\n sur cette période",
    "Aucun": "None",
    "Aucune": "None",
    "draft": "brouillon",
    "doing": "En cours",
    "done": "Validé",
    "process": "traitement",
    "returned": "restitution",
    "nothing to declare": "rien à signaler",
    "canceled": "annulé",
    "waiting": "en attente",
    "new": "nouveau",
    "Last 30 days": "30 derniers jours",
    "first activity": "première activité",
    "last activity": "dernière activité",
    "status":"statut",
    "recommendations":"recommandations",
    "Sign out": "Deconnexion",
    "Create new": "Créer un nouveau", 
    "Create this tag": "Créer ce tag",
    "Aggregated alerts": "Alertes aggrégées",
    "server": "serveur",
    "New device": "Nouvelle machine",
    "New account": "Nouveau compte",
    "New network": "Nouveau réseau",
    "recommendations pending": "recommandations en attente",
    "Monthly reports": "Rapports mensuels",
    "ignore": "ignoré",
    "You don't have any scope yet": "Vous n'êtes membre d'aucun périmètre",
    "user": "utilisateur",
    "vulnerability": "vulnérabilité",
    "Last login": "Dernière connexion",
    "operator": "opérateur",
    "none": "aucun",
    "Impacted products": "Produits impactés",
    "Refresh": "Rafraichir",
    "Welcome": "Bienvenue", 
    "This is your first time in CT-Square Astraia": "C'est votre première visite dans CT-Square Astraia",
    "Please set your password before connecting": "Veuillez définir un mot de passe avant de vous connecter",
    "Password": "Mot de passe", 
    "Without impact": "Sans impact",
    "Confirm password": "Confirmez votre mot de passe",
    "Dashboard": "Tableau de bord",
    "audit:closed": "terminé",
    "incident:closed":"traité",
    "incident-step:done": "résolu",
    "false positive": "faux positif",
    "Investigate via external tools": "Investiguer via des outils externes",
    "No report for": "Aucun rapport mensuel pour",
    "Generate report": "Générer le rapport",
    "You don't have any files":"Aucun fichier",
    "since last refresh": "depuis le dernier rafraichissement",
    "alert":"alerte",
    "Open a new investigation": "Nouvelle investigation",
    "Add to existing investigation": "Ajouter à une investigation existante",
    "Download as JSON": "Télécharger en JSON",
    "Domain":"Domaine",
    "Hostname":"Nom d'hôte", 
    "Download as CSV": "Télécharger en CSV",
    "Edit": "Editer",

    "Error : 404 page not found": "Erreur : 404 page introuvable",
    "Sorry, the page you're looking for cannot be accessed": "Désolé, la page que vous cherchez n'est pas accessible",
    "Go back to main page": "Retour à l'accueil",

    "Filter scopes": "Filtrer les scopes",
    "Filter categories": "Filtrer les catégories",
    "Filter conclusions": "Filtrer par conclusion",
    "Clear": "Effacer",
    "Select all": "Tout sélectionner",
    "Filename": "Nom de fichier",
    "Cotech reports": "Cotechs",

    "Show privates": "Privées",
    "Active": "Active",
    "Today": "Aujourd'hui",
    "In": "Dans les",

    "Hostname": "Nom d'hôte",
    "Network": "Réseau",
    "Networks": "Réseaux",
    "Kind": "Type",
    "Vendor": "Fabricant",
    "Datasets": "Données",
    "Accounts": "Comptes",

    "exposed": "exposé",
    "notified": "notifié",
    "no impact": "sans impact",
    "patched": "corrigé",

    "IP address": "Addresse IP",
    "Vendors": "Fabricants",
    "Products": "Produits",
    "Updated": "Mise à jour",
    "Published": "Publiée",
    "creation date": "date de création",
    "last update": "dernière mise à jour",
    "published date": "date de publication",
    "Summary": "Résumé",
    "Remediation": "Remédiation",
    "References": "Références",
    "Products affected": "Produits concernés",
    "Exploitability": "Exploitabilité",
    "Vector": "Vecteur",
    "Access Vector": "Vecteur d'accès",
    "Access Complexity": "Complexité d'accès",
    "Authentication": "Authentification",
    "Confidentiality Impact": "Impact sur la confidentialité",
    "Integrity Impact": "Impact sur l'intégrité",
    "Availability Impact": "Impact sur la disponibilité",
    "None": "Aucun",
    "Low": "Faible",
    "Medium": "Moyen",
    "High": "Élevé",
    "Critical": "Critique",
    "LOCAL": "LOCAL",
    "ADJACENT NETWORK": "RÉSEAU ADJACENT",
    "NETWORK": "RÉSEAU",
    "NONE": "AUCUN",
    "PARTIAL": "PARTIEL",
    "COMPLETE": "COMPLET",
    "IP addresses": "Adresses IP",
    "Select Scopes": "Sélectionner les scopes",
    "Can't reach the IOC provider service": "Impossible de joindre le service de collecte d'IOC",
    "Search by title, IP/range, description, or tags ...": "Rechercher par titre, IP/range, description, tags, ...",
    "unknown user won't be notified": "utilisateur inconnu. Cet utilisateur ne sera pas notifié.",
    "Added an external link": "A ajouté un lien externe",
    "with" : "avec",
    "IOC detected : ": "IOC détecté : ",
    "IOC collector is disabled on this scope": "Le collecteur d'IOC n'est pas activé sur votre périmètre",
    "Restrict to scope": "Restreindre au scope",
    "Restrict to":"Restreindre aux",
    "Detection date histogram": "Histogramme des dates de détection",
    "Statuses and conclusions histogram": "Histogramme des statuts et conclusions",
    "Tags histogram": "Histogramme des tags",
    "Rules histogram": "Histogramme des règles de détection",
    "alert:open": "en cours d'analyse",
    "Affected Scopes": "Scopes impactés",
    "Now": "Maintenant",
    "now": "maintenant",
    "Don't update dates": "Ne pas mettre les dates à jour",
    "alert:Open": "En cours d'analyse",
    "alert:New": "Nouvelle",
    "alert:Closed": "Traitée",
    "Collected": "Collectée",
    "Next alert": "Alerte suivante",
    "Previous alert": "Alerte précédente",
    "See all": "Voir toutes",
    "vuln:open": "En cours de traitement",
    "vuln:Open": "A corriger",
    "Affected products": "Produits impactés",
    "restore": "restaurer",
    "Restore": "Restaurer",
    "vuln:Closed": "Corrigé",
    "Ignored": "Ignoré",
    "Create investigation": "Créer une investigation",
    "Fast close": "Qualification rapide",
    "New conclusion code": "Nouveau code de conclusion",
    "Conclusion code": "Code de conclusion", 
    "Reopen": "Réouvrir",
    "Detach": "Détacher de l'investigation",
    "Fast closed": "Qualification rapide",
    "alert:closed": "Traitée",
    "Attach to existing investigation": "Rattacher à une investigation existante",
    "alerts:fast-closed": "Traitée (qualification rapide)",
    "Keep alerts qualifications ?": "Preserver les qualifications des alertes sélectionnées ?",
    "Alerts will keep their current qualification and will be fast-closed": "Les alertes conserveront leur qualification actuelle et seront cloturées en qualification rapide",
    "Alerts will inherit qualification from the investigation": "Les alertes heriteront de la qualification de cette investigation",
    "Cannot delete case with linked alerts": "Impossible de supprimer une analyse avec des alertes",
    "Yes": "Oui",
    "No": "Non",
    "by": "par",
    "Vulnerability observed": "Vulnérabilité observée",
    "Vulnerability notified": "Vulnérabilité notifiée",
    "Vulnerability without impact": "Vulnérabilité sans impact",
    "Vulnerability patched": "Vulnérabilité corrigée",
    "No vulnerability": "Aucune vulnérabilité",
    "case:open": "en cours",
    "Reopen selected alerts ?": "Réouvrir les alertes sélectionnées ?",
    "All selected alerts will be reopened and marked as 'new'": "Toutes les alertes sélectionnées seront réouvertes et marquées comme 'nouvelles'",
    "Closed alerts will keep their qualification and will be marked as 'fast-closed', open alerts will be marked as 'new'": "Les alertes qualifiées conserveront leur qualification et seront marquées en 'qualification rapide', les alertes en cours seront marquées comme 'nouvelles'",
    "Detach": "Detacher",
    "Reopen alert ?": "Réouvrir l'alerte ?",
    "Alert will be reopened and marked as 'new'": "L'alerte perdra sa qualification et sera marquée comme 'nouvelle'",
    "Alert will keep its qualification and will be marked as 'fast-closed'": "L'alerte conserva sa qualification et sera marquée en 'qualification rapide'",
    "No Alert": "Pas d'alerte", 
    "Attach": "Rattacher",
    "Change investigation":"Changer d'investigation",
    "reopen": "réouvrir",
    "Example": "Exemple",
    "Unavailable": "Indisponible",
    "Title": "Titre",
    "Countermeasures": "Remediations",
    "Countermeasure": "Remediation",
    "No countermeasure": "Aucune remediation",
    "No description": "Aucune description",
    "Without impact": "Sans impact",
    "notify": "notifier",
    "Notify": "Notifier",
    "yesterday": "hier",
    "Show alerts": "Voir les alertes",
    "Alert group": "Groupe d'alertes",
    "Aggregated alerts": "Alertes agregées",
    "Running": "Fonctionne",
    "Stopping":"En cours d'arret",
    "Not installed":"Pas installé",
    "Stopped":"Arreté",
    "Percentage distribution of conclusion_codes" : "Répartitions des conclusion_codes en pourcentage",
    "Installing":"En cours d'installation",
    "unknown": "inconnue",
    "Uninstalling":"En cours de désinstallation",
    "Starting": "En démarrage",
    "Error":"Erreur",
    "Unknow":"Inconnu",

    // Settings alert
    "New case": "Nouvelle Analyse",
    "Case visibility edited": "Modification de la visibilité d'une analyse",
    "New client comment": "Nouveau commentaire de client",
    "New critical alert": "Nouvelle alerte critique",
    "all": "Tous",
    "All":"Toutes",
    "None": "Aucune",
    "Only my notifications": "Uniquement mes notifications",
    "All except my notifications" : "Toutes sauf mes notifications",

    "Update agent profile":"Changer le profile de l'agent",

    "Notified by email": "Notifié par email",
    "Notify by email": "Notifier par email",
    "Notify": "Notifier",
    "Notified": "Notifié",

    "Can't contact cartography service": "Impossible de contacter le service de cartographie",

}

const FEM = {
    "CRITICAL": "CRITIQUE",
    "HIGH": "ÉLEVÉE",
    "MEDIUM": "MOYENNE",
    "LOW": "FAIBLE",
    "NONE": "AUCUNE",
    "SIGLE": "SIMPLE",
    "MULTIPLE": "MULTIPLE",
    "Cancelled": "Annulée",
    "Validated": "Validée",
    "done": "Validée",
    "canceled": "Annulée",
    "new": "nouvelle",
    "private": "privée",
    "public": "publique",
    "ignore": "ignorée",
    "muted": "ignorée",
    "false-positive": "faux positif",
    "false positive": "faux positif",
    "acknowledged": "qualifiée",
    "investigating": "en cours d'analyse",
    "resolved": "traitée",
    "investigation:closed": "traitée",
    "patrol:closed":"traitée",
    "nothing to declare": "rien à signaler",
    "since last refresh": "depuis le dernier rafraichissement",
    "alert": "alerte",
    "likely": "probable",
    "in the wild": "observée",
    "unknown": "inconnue",
    "closed": "traitée",
    "new": "nouvelle",
    "New": "Nouvelle",
    "closed": "traitée", 
    "vuln:Open": "A corriger",
    "vuln:Closed": "Corrigée",
    "Ignored": "Ignorée",
    "Exposed": "Exposée",
    "Patched": "Corrigée",
    "No impact": "Sans impact",
    "Notified": "Notifiée",
    "without impact": "sans impact",
    "Without impact": "Sans impact",
    "All": "Toutes",
    "exposed": "exposée",
    "notified": "notifiée",
    "no impact": "sans impact",
    "patched": "corrigée",
    "open": "en cours",
    "pending": "en attente",
    "validated": "validée",
    "Observed": "Observée",
    "observed": "observée",
    "Closed": "Traitée",
    "Open": "En cours",
}

const plural = (text)=>PLURALS[text]||(t(text)+"s")

const t = (text, opts)=> {
    if(typeof(text)!=="string") return text
    if(opts?.fem) return t_fem(text, opts) 
    if(opts?.nb>1) return plural(text) 
    return (opts?.type && TRANSLATIONS[opts.type+":"+text]) || TRANSLATIONS[text] || text
}

const t_fem = (text, opts)=> {
    const r = (opts?.type && FEM[opts.type+":"+text]) || FEM[text] || t(text) 
    if(opts?.nb>1) return plural(r) 
    return r
}

const t_n = (nb, text)=>(
    nb>0 ? nb + " " + capitalize(t(text,{nb}))
    : "Aucun " + t(text,{nb})
)

const t_n_fem = (nb, text)=>(
    nb>0 ? nb + " " + capitalize(t_fem(text,{nb}))
    : "Aucune " + t_fem(text,{nb})
)

const t_interval = (_from, _to) => {
    if(!_from || !_to) return ""
    _from = _from.trim()
    _to = _to.trim()
    if(_from.match(/[^0-9\shms:]/)) {
        _from = _from.replace(/^1\s+/, "1er ")
        _from = "le " + _from
    }
    if(_to.match(/[^0-9\shms:]/)) {
        _to = _to.replace(/^1\s+/, "1er ")
        _to = "le " + _to
    }
    return `entre ${_from} et ${_to}`
}

export default {t_fem,t_n,t,t_n_fem,t_interval}
