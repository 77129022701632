import { t } from "../i18n"
import { Button } from "./Button"
import { Dialog } from "./Dialog"
import { reactive } from "@vue/reactivity"
import './Modal.scss'

export const MODAL = reactive({
    cur: null,
    show: false,
})

export function modal(node=({close})=>{}) {
    return new Promise((resolve,reject)=>{
        function close(result) { 
            setImmediate(()=>MODAL.show = false)
            setTimeout(()=>MODAL.cur = null, 500)
            resolve(result)
        }
        MODAL.cur = ()=>node({close})
        setImmediate(()=>MODAL.show = true)
    })
}


export function question(title, body, buttons, defaultButton) {
    buttons ||= {"ok":t("OK"), "cancel":t("Cancel")}
    return modal(({close})=><Dialog 
        close={close}
        header={title}
        body={body}
        onSubmit={defaultButton && (()=>close(defaultButton))}
        buttons={
            Object.entries(buttons).map(([val, text])=>
            <Button onClick={()=>close(val)}>{text}</Button>
            )
        }
    />)
}