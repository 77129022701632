import { onUnmounted, reactive, watch, getCurrentInstance } from "vue"
import { DBG_INFOS } from "../ui/DBG"

const { query, subscribe } = require(CONFIG.MOCK ? "./mock" : "./graphql")

const LIVEQUERIES = {}

let GUID=0

/**
 * Creates a reactive graphql livequery that is automatically unsubscribed/resubscribed when reactive variables change
 * If called in a Vue setup() function, automatically unsubscribe when component is unmounted
 * 
 * @param {string} q : the GQL query name
 * @param {string} fields : requested fields, in GQL nested format
 * 
 * @param {function} cb : function called on every update from the server 
 * (called with 'null' on unsubscribe, but NOT on resubscription for new variables)
 * 
 * @param {()=>[vars,svars?]? | bool} watchVariables : a function passed to watchEffect returning
 *   - [query_variables,subscription_variables] to resubscribe with new variables (if subscription_variables is undefined => subscription_variables=query_variables)
 *   - false (or any falsey value) to unsubscribe until variables change
 *   - true to resubscribe without any variable
 * 
 * @param options : 
 *  - {string} subscription : the GQL subscription name (set to ${q} if undefined, no subscription if null)
 *  - {int} throttle : throttle timeout in ms
 *  - {int} interval : autorefresh interval in ms
 *  - {(e)=>{}} onError : error callback
 */
export const LiveQuery = (query, fields, cb, watchVariables, {
    subscription,
    onError, 
    interval = 0/*(UI.active_polling_interval||20)*1000*/,
    throttle = 2000,
    loading_on_notify = false,
}={}) => {
    if(subscription===undefined) subscription = query

    let vars = watchVariables?.()
    let lq = null

    const self = reactive({
        guid: GUID++,
        cb, onError, interval, throttle, loading_on_notify,
        stop() {
            stopWatchingVariables()
            unsubscribe()
        },
        refresh() { lq?.refresh() },
        subscribed: false,
        error: null,
        loading: false,
    })

    function subscribe() {
        setImmediate(()=>{
            lq = get_or_create_lq(query, fields, vars, subscription)
            lq.addListener(self)
            lq.initialQuery()
        })
    }

    function unsubscribe() {
        if(lq) lq = lq.removeListener(self)
    }

    if(vars) subscribe()

    const stopWatchingVariables = watch(()=>vars = watchVariables?.(), ()=>{
        unsubscribe()
        if(vars) subscribe()
        else cb(null)
        self.subscribed = !!vars
    }, {deep: false, flush: 'post'})

    if(getCurrentInstance()) onUnmounted(()=>self.stop())

    return self
}

function get_or_create_lq(query, fields, vars, subscription) {
    let subscription_vars = {}
    if(!vars || vars === true) vars = {}
    if(!Array.isArray(vars)) { vars = vars; subscription_vars = vars }
    else { subscription_vars = vars[1] || vars[0]; vars = vars[0];  }
    
    const signature = JSON.stringify([query,vars,fields,subscription,subscription_vars])

    const lq = LIVEQUERIES[signature] 
    if(!lq) return new LQ(query, fields, vars, subscription, subscription_vars, signature)
    return lq
}

export class LQ {
    static last_mutation_time = 0

    constructor(query, fields, vars, subscription, subscription_vars, signature) {
        this.query = query
        this.fields = fields
        this.subscription = subscription
        this.vars = vars
        this.subscription_vars = subscription_vars
        this.throttle = 0
        this.interval = 0
        this.signature = signature        
        LIVEQUERIES[this.signature] = this
        DBG_INFOS.livequeries[this.signature] = {query, fields, vars, subscription, subscription_vars, listeners:0}
        this.listeners = {}
        this.last_query_time = 0
        this.ticks = 0
        this.subscription_promise = subscribe(this.subscription, this.subscription_vars, ()=>this.onNotify()).then(sub=>this.sub=sub)
    }

    doQuery() {
        return query(this.query, this.vars, this.fields, false)
            .then(x=>this.onData(x))
            .catch(e=>this.onError(e))
    }

    addListener(lq) {
        if(lq.throttle && (lq.throttle < this.throttle || !this.throttle)) {
            this.throttle = lq.throttle
            DBG_INFOS.livequeries[this.signature].throttle = this.throttle
        }
        if(lq.interval && (lq.interval < this.interval || !this.interval)) {
            this.interval = lq.interval
            if(this._refresh_interval !== undefined) clearInterval(this._refresh_interval)
            this._refresh_interval = setInterval(()=>this.autoRefresh(), this.interval)
            DBG_INFOS.livequeries[this.signature].interval = this.interval
        }
        this.listeners[lq.guid] = lq
        DBG_INFOS.livequeries[this.signature].listeners++
        return this
    } 

    removeListener(lq) {
        delete this.listeners[lq.guid]
        DBG_INFOS.livequeries[this.signature].listeners--
        if(Object.keys(this.listeners).length === 0) this.destroy()
    }

    destroy() {
        if(this._refresh_interval !== undefined) clearInterval(this._refresh_interval)
        this.subscription_promise?.then(()=>this.sub?.unsubscribe())
        delete LIVEQUERIES[this.signature]
        delete DBG_INFOS.livequeries[this.signature]
    }

    initialQuery() {
        if(window.dbg_lq) console.log("%c"+this.query, 'background:green;color:white;padding:5px;margin-top:15px;')
        if(window.dbg_lq) console.info("params : (",this.vars,",", this.subscription_vars,")")
        if(window.dbg_lq) console.info("fields: ", this.fields)
        Object.values(this.listeners).forEach(lq=>lq.loading = true)
        if(document.hidden === false) this.doQuery()
    }

    refresh() {
        if(window.dbg_lq) console.log("%c"+"forcedrefresh"+"%c"+this.query+ (this.vars ? ('('+JSON.stringify(this.vars)+")") : ""), 'background:#00f5;color:black;padding:0px 4px;', 'color:green;padding:0px 4px;')
        Object.values(this.listeners).forEach(lq=>lq.loading = true)
        if(document.hidden === false) this.doQuery()
    }

    autoRefresh() {
        if(window.dbg_lq) console.log("%c"+"autorefresh"+"%c"+this.query+ (this.vars ? ('('+JSON.stringify(this.vars)+")") : ""), 'background:#00f5;color:black;padding:0px 4px;', 'color:green;padding:0px 4px;')
        Object.values(this.listeners).forEach(lq=>{if(lq.loading_on_notify) lq.loading = true})
        if(document.hidden === false) this.doQuery()
    }

    onNotify() {
        // Only throttle when our user isn't calling mutation (in a 3s time frame)
        if(+new Date() - LQ.last_mutation_time > 3000) { 
            if(this.throttle && +new Date() - this.last_query_time < this.throttle) return;
        }

        if(window.dbg_lq) console.log("%c"+"refresh"+"%c"+this.query+ (this.vars ? ('('+JSON.stringify(this.vars)+")") : ""), 'background:#00f5;color:black;padding:0px 4px;', 'color:green;padding:0px 4px;')
        Object.values(this.listeners).forEach(lq=>{if(lq.loading_on_notify) lq.loading = true})
        if(document.hidden === false) this.doQuery()
    }

    onData(x) {
        if(window.dbg_lq) console.info(this.query, "output : ", x)
        Object.values(this.listeners).forEach(lq=>{
            lq.cb(x)
            lq.loading = false;
        })
        this.last_query_time = +new Date()
        this.ticks++
        DBG_INFOS.livequeries[this.signature].ticks = this.ticks
    }

    onError(e) {
        Object.values(this.listeners).forEach(lq=>{
            lq.error = e
            lq.loading = false
            lq.onError?.(e)
        })
    }
}