import { reactive } from "@vue/reactivity"
import { model, API } from "../../model"
import { t } from "../../i18n"
import { H5} from "../../ui/H"
import { DataTable } from "../../ui/DataTable"
import { Link } from "../../utils/router"
import { IconButton } from "../../ui/Button";
import { ICON } from "../../ui/icons";
import { onMounted } from "vue";
import { Button } from "../../ui/Button";
import { modal } from "../../ui/Modal";
import { Dialog } from "../../ui/Dialog"

export const SettingsSavedAlertsSearches = {
    setup() {
        const data = reactive({
            loading: true,
            savedSearches: [],
        })

        const updateTag = async (id, d) => {
            data.loading = true
            await API.updateTag(id, d)
            data.loading = false
        }

        async function deleteSavedAlertsSearch(id) {
            let modal = await deleteSavedAlertsSearchModal()
            if (modal) {
                API.deleteSavedAlertsSearch({id})
                .then(async ()=>data.savedSearches = await API.getSavedAlertsSearches({scope_id: model.scopeId ? model.scopeId :"all"}))
            }
        }

        onMounted(async ()=>{
            data.savedSearches = await API.getSavedAlertsSearches({scope_id: model.scopeId ? model.scopeId :"all"})
            data.loading = false
        })
        return ()=>{
            let tags = data.tags?.filter(t=>t.scope_id===(model.scopeId||null))
            return <div id="tags-settings">
                <h3>{t('Saved alerts searches')}</h3>
                <DataTable
                    loading={data.loading}
                    data={data.savedSearches.filter(s=> model.scopeId ? (s.scope ? s.scope.id === model.scopeId : false) : true)}
                    columns={[
                        {title:t('Name'), render:savedSearches=><H5 validate={x=>x===savedSearches.name || !savedSearches.find(t=>t.name===x)} value={savedSearches.name} />},
                        {title:t('Public'), render:savedSearches=>savedSearches.user===null ? t("yes") : t("no")},
                        ...(model.scopeId ? [] : [{title:t('Scope'), type:'scope', render:savedSearches=>savedSearches.scope===null ? t("All") : ScopeItem(savedSearches)}]),
                        {title:'', render:savedSearches=><IconButton onClick={()=>deleteSavedAlertsSearch(savedSearches.id)}>{ICON('trash')}</IconButton>},
                    ]}
                    noSearch
                />
            </div>
        }
    }
}


export async function deleteSavedAlertsSearchModal() {
    return modal(({close})=>
    <Dialog close={close} onSubmit={()=>close(true)}
        header={t("Delete favorite")}
        body={<>
                <p>{t("Are you sure you want to delete this favorite ?")}</p>
        </>}
        buttons={<>
            <Button secondary onClick={()=>close(false)}>{t("Cancel")}</Button>
            <Button type="submit">{t("Delete")}</Button>
        </>}
    />)

}

export const ScopeItem = (e)=><div class="scope-item">
    <img src={model.scopes?.find(s=>s.id===e.scope?.id)?.logo}/>
    <Link href={`/${model.lang}/scope/${e.scope?.id}/settings/saved-alerts-searches` + location.search}>{model.scopes?.find(s=>s.id===e.scope?.id)?.display_name}</Link>
</div>