import { LiveQuery } from "../api/livequery"
import { t } from "../i18n"
import { model } from "../model"
import { Avatar } from "../ui/Avatar"
import { Badge } from "../ui/Badge"
import { D } from "../ui/dates"
import { Spinner } from "../ui/Spinner"
import { API } from "../model"
import { ICON } from "../ui/icons"
import { Tab, Tabs } from "../ui/Tabs"
import { optionsFromQueryParams, queryParams, setQueryParams } from "../utils/queryParams"
import { link } from "../utils/routing"
import { max, min, range } from "../util"
import "./Messages.scss"
import { reactive } from "vue"
import { Button } from "../ui/Button"
import { useCurrentUserStore } from "../store/CurrentUserStore"

const userStore = useCurrentUserStore

export const Messages = {
    setup(props) {

        const data = reactive({
            loading: false,
        })

        const lq = LiveQuery("messages", `{
            count,
            pages,
            items { 
                scope_id
                id, short_body, visibility, created_at, updated_at, tags, seen, notified,
                commentable_type
                commentable {
                    id, title, created_at, updated_at, status, conclusion_code {code}, author { display_name, avatar, id, email }
                }
                author { id, display_name, avatar, email },
                files { filename, url }
            }}`, c=>model.messages = c,
            ()=>userStore.hasPermissions("comments", "read") && [{
                    ...optionsFromQueryParams(),
                    box: queryParams().show || undefined,
                    scope_id:model.scopeId
                }, {scope_id:model.scopeId}], 
            { subscription: "messages_stats" }
        )

        function setPage(page) {
            setQueryParams({page})
        }

        const isSOC = m => m.author.email?.endsWith("ct-square.com")

        const markAllRead = async (scope_id) => {
            data.loading = true
            await API.markAllMessagesAsRead(scope_id)
                .then(async ()=>{await lq.refresh(); data.loading = false })
                .catch(()=>data.loading = false)
        }

        return ()=>{
            const pages = model.messages?.pages || 1
            const page = optionsFromQueryParams().page || 1
            const tab = ["sent","all", "inbox"].find(x=>x===queryParams().show) || ""
            return <div id="messages">
            <h1>{t("Messages")}</h1>

            <div class="header">
                <Tabs>
                    <Tab active={!tab} onClick={()=>{model.messages=null; setQueryParams({show:undefined})}} label={<span>{t("Unread messages")} <Badge>{model.messages_stats?.unread||0}</Badge></span>}/>
                    <Tab active={tab==="inbox"} onClick={()=>{model.messages=null; setQueryParams({show:'inbox'})}} label={<span>{t("Inbox")} <Badge>{model.messages_stats?.inbox||0}</Badge></span>}/>
                    <Tab active={tab==="sent"} onClick={()=>{model.messages=null; setQueryParams({show:"sent"})}} label={<span>{t("Sent")} <Badge>{model.messages_stats?.outbox||0}</Badge></span>}/>
                    <Tab active={tab==="all"} onClick={()=>{model.messages=null; setQueryParams({show:"all"})}} label={<span>{t("All notified messages")} <Badge>{(model.messages_stats?.all||0)}</Badge></span>}/>
                </Tabs>
                {model.messages?.count != 0 && !tab && <Button onClick={()=>markAllRead(model.scopeId)}>{t("Mark all as read")}</Button>}
            </div>
            {!tab && model.messages?.count === 0 && <h3 class="feature-disabled">{t("No new message")}</h3>}
            {tab==="sent" && model.messages?.count === 0 && <h3 class="feature-disabled">{t("No message sent")}</h3>}
            {tab==="all" && model.messages?.count === 0 && <h3 class="feature-disabled">{t("No message")}</h3>}
            {data.loading || lq.loading ? <div class="spinner"><Spinner/></div> : model.messages?.items?.map(m=><router-link class="message" to={link(`/scope/${m.scope_id}/${m.commentable_type.toLowerCase()}/${m.commentable.id}`)}>
                <div>
                    <Avatar user={m.author}/>
                    {isSOC(m) ? <Badge class="scope" color="#ffc912">CT-square</Badge> 
                            : <Badge class="scope" color="#170748">{model.scope ? model.scope.display_name : "jsp zinc"}</Badge>}
                    <div class="author">{m.author?.display_name}</div>
                    {m.visibility == "waiting_for_response" && <div class="status">en attente de réponse</div>}
                    <div class="what">
                        <router-link to={link(`/scope/${m.scope_id}/${m.commentable_type.toLowerCase()}/${m.commentable.id}`)}>
                            <div class='header'>
                                {ICON(m.commentable_type?.toLowerCase())} {t(m.commentable_type)} 
                                <b>#{m.commentable.id}</b>
                                {D(m.commentable.created_at)}
                            </div>
                            <b>{m.commentable.title}</b>
                        </router-link>
                    </div>
                    <div>{D(m.created_at, "long")}</div>
                </div>                
                <p class='body'>{m.short_body}</p>
            </router-link>)}
            {pages>1 && <div class="footer">
                <div class="pages">
                    {<button disabled={page<=1} class="nav" onClick={()=>setPage(page-1)}>{ICON("left")}</button>}
                    {pages <= 10 ? range(pages).map(i=><button class={{active:i+1===page}} onClick={()=>setPage(i+1)}>{i+1}</button>)
                    : <>
                    {page > 5 && <button class={{active:1===page}} onClick={()=>setPage(1)}>{1}</button>}
                    {page > 6 && <span>...</span>}
                    {range(9).map(i=>i+min(max(page-4,1),pages-8)).map(i=><button class={{active:i===page}} onClick={()=>setPage(i)}>{i}</button>)}
                    {page < pages - 5  && <span>...</span>}
                    {page < pages - 4 && <button class={{active:pages===page}} onClick={()=>setPage(pages)}>{pages}</button>}
                    </>  
                    }
                    {<button disabled={page>=pages} class="nav" onClick={()=>setPage(page+1)}>{ICON("right")}</button>}
                
                </div>
            </div>}      
            {/* {model.messages?.items?.map(m=><pre>
                {JSON.stringify(m)}
            </pre>)} */}
        </div>
    }}
}