import { API, getMembers, getTags, model } from "../model"
import { DataTable } from "../ui/DataTable"
import { t } from "../i18n"
import { Avatar } from "../ui/Avatar"
import { Spinner } from "../ui/Spinner"
import { reactive } from "@vue/reactivity"
import './Notifications.scss'
import { Badge } from "../ui/Badge"
import { Switch } from "../ui/Switch"
import { LiveQuery } from "../api/livequery"
import { Tab, Tabs } from "../ui/Tabs"
import { getCurrentRouteName, getRouter } from "../utils/router"
import { Dropdown, DropdownItem } from "../ui/Dropdown"
import { AddButton } from "../ui/AddButton"
import { modal } from "../ui/Modal"
import { Dialog } from "../ui/Dialog"
import { Button, DeleteButton } from "../ui/Button"
import { gotoScope } from "../utils/routing"
import { DropdownCombo } from "../ui/DropdownCombo"
import { Severity } from "../ui/Severity"
import { useCurrentUserStore } from "../store/CurrentUserStore"

const userStore = useCurrentUserStore

export const Notifications = {
    setup() {
        const data = reactive({})
        
        const userStore = useCurrentUserStore
        const lq = LiveQuery("scope", `{
            members{id, display_name, avatar, activated, email, role, created_at, last_login
                notify_cases, notify_comments, notify_reports, notify_vulnerabilities
            }
        }`, 
            c=>data.members=c?.members, 
            ()=>!!userStore.user && !!model.scopeId && 
                [{id:model.scopeId}]
        )

        const filter = members => members.filter(m=> m.role != "ghost" && (!data.search || (
            m.display_name.toLowerCase().includes(data.search.toLowerCase()) ||
            m.role.toLowerCase().includes(data.search.toLowerCase())) 
        ))

        const getNotifyNothing = (x) => {
            return !(x.notify_cases || x.notify_comments || x.notify_reports || x.notify_vulnerabilities)
        }

        const desactivateAllNotifications = (x) => {
            API.setMemberConfig(x.id, model.scopeId, {
                notify_cases: false,
                notify_comments: false,
                notify_reports: false,
                notify_vulnerabilities: false,
            })
        }

        return ()=>{
            if(!data.members) return <Spinner/>
            const RO = !userStore.hasPermissions("roles", "write_private")
            return <div id="notifications">
                <Tabs>
                    <Tab active={getCurrentRouteName()==="notifications"} label={t("Email Notifications")} onClick={()=>gotoScope(`/settings/notifications`)}/>
                    <Tab active={getCurrentRouteName()==="alerts_notifications"} label={t("Alerts Notifications")} onClick={()=>gotoScope(`/settings/alerts_notifications`)}/>
                </Tabs>

                {getCurrentRouteName()==="notifications" && 
                <DataTable
                    data={filter(data.members)}
                    rowClass={x=>!x.activated && "unactive"}
                    columns={[
                        {type:'company', render:x=>x.email.endsWith("@ct-square.com") ? <Badge class="cts">CT-Square</Badge> : <><img src={model.scope?.logo}/><Badge>{model.scope?.display_name}</Badge></>},
                        {type:'small', render:x=><Avatar user={x}/>},
                        {title:t('Name'), render:x=>x.display_name},
                        {title:t("Rôle"), type:"role", noLink:true, render: x=>t(x.role)},

                        {title:t("Notify nothing"), render:x=><Switch disabled={RO} value={getNotifyNothing(x)} onClick={()=>desactivateAllNotifications(x)}/>},
                        {title:t("Notify case open/close"), render:x=><Switch disabled={RO} value={x.notify_cases} onClick={()=>API.setMemberConfig(x.id, model.scopeId, {notify_cases:!x.notify_cases})}/>},
                        {title:t("Notify comments"), render:x=><Switch disabled={RO} value={x.notify_comments} onClick={()=>API.setMemberConfig(x.id, model.scopeId, {notify_comments:!x.notify_comments})}/>},
                        {title:t("Notify vulnerabilities"), render:x=><Switch disabled={RO} value={x.notify_vulnerabilities} onClick={()=>API.setMemberConfig(x.id, model.scopeId, {notify_vulnerabilities:!x.notify_vulnerabilities})}/>},
                        {title:t("Notify reports"), render:x=><Switch disabled={RO} value={x.notify_reports} onClick={()=>API.setMemberConfig(x.id, model.scopeId, {notify_reports:!x.notify_reports})}/>},
                    ]}
                    update={x=>data.search=x.search}
                />}

                {getCurrentRouteName()==="alerts_notifications" && <AlertsNotifications/>}
            </div>
        }
    }
}

export const AlertsNotifications = {
    setup(props) {
        const data = reactive({alerts_notifications:null})

        const lq = LiveQuery("scope", `{alerts_notifications{
            id
            user { display_name, id, avatar, email }
            severity
            tag
        }}`, x=>data.alerts_notifications=x.alerts_notifications, ()=>[{
            id: model.scopeId
        }])

        async function new_rule() {
            const available_tags = getTags(model.scopeId, "AlertGroup")
            const data = reactive({
                user: null,
                scope_id: model.scopeId,
            })
            const res = await modal(({close})=><Dialog close={close} class="dialog" onSubmit={()=>close(data)}
                header={t("New alerts notification rule")}
                body={<>
                    <p><b>User </b><DropdownCombo
                        button={data.user && <><Avatar user={data.user}/> {data.user.display_name} ({t(data.user.role)})</>}
                        items={search=>getMembers({search})}
                        render={x=><><Avatar user={x}/> {x.display_name} ({t(x.role)})</>}
                        onSelect={x=>data.user=x}
                    /></p>
                    <p><b>Minimal severity</b> <Dropdown button={<Severity val={data.severity}/>} items={()=><>
                        <DropdownItem onClick={x=>data.severity = 1}>1 low</DropdownItem>
                        <DropdownItem onClick={x=>data.severity = 2}>2 medium</DropdownItem>
                        <DropdownItem onClick={x=>data.severity = 3}>3 high</DropdownItem>
                        <DropdownItem onClick={x=>data.severity = 4}>4 critical</DropdownItem>
                    </>}/></p>
                    <p><b>Triggering tag</b> <DropdownCombo
                        button={data.tag ? <Badge>{data.tag}</Badge> : "none"}
                        items={filter=>available_tags?.filter(x=>x.name?.toLowerCase().includes?.(filter?.toLowerCase()))}
                        render={({name, color})=><><span class="thumb" style={{backgroundColor:color}}/>{name}</>}
                        onSelect={tag=>data.tag = tag.name}
                    /></p>
                </>}
                buttons={<>
                    <Button disabled={!data.user} onClick={()=>close(data)}>Add rule</Button>
                    <Button secondary onClick={()=>close()}>Cancel</Button>
                </>}
            />)
            if(res?.user) {
                const {user, ...rest} = res
                rest.user_id = user.id
                await API.createAlertsNotification(rest)
                lq.refresh()
            }
        }

        async function deleteRule(id) {
            await API.deleteAlertsNotification(id)
            lq.refresh()
        }

        async function setTag(id, tag) {
            await API.setAlertsNotification(id, {tag})
            lq.refresh()
        }

        async function setSeverity(id, severity) {
            await API.setAlertsNotification(id, {severity})
            lq.refresh()
        }

        return ()=>{
            const available_tags = getTags(model.scopeId, "AlertGroup")
            return <DataTable
                header={<AddButton onClick={new_rule}>{t('Add notification rule')}</AddButton>}
                data={data.alerts_notifications}
                columns={[
                    {title:t('User'), render:x=><><Avatar user={x.user}/> {x.user.display_name}</>},
                    {title:t('Minimal severity'), render:x=><Dropdown button={<Severity val={x.severity}/>}>
                        <DropdownItem onClick={()=>setSeverity(x.id, 1)}>1 low</DropdownItem>
                        <DropdownItem onClick={()=>setSeverity(x.id, 2)}>2 medium</DropdownItem>
                        <DropdownItem onClick={()=>setSeverity(x.id, 3)}>3 high</DropdownItem>
                        <DropdownItem onClick={()=>setSeverity(x.id, 4)}>4 critical</DropdownItem>
                    </Dropdown>},
                    {title:t('Triggering tag'), render:x=><DropdownCombo 
                        button={x.tag ? <Badge>{x.tag}</Badge> : "none"}
                        items={filter=>available_tags?.filter(x=>x.name?.toLowerCase().includes?.(filter?.toLowerCase()))}
                        render={({name, color})=><><span class="thumb" style={{backgroundColor:color}}/>{name}</>}
                        onSelect={tag=>setTag(x.id, tag.name)}
                    />},
                    {render:x=><DeleteButton onClick={()=>deleteRule(x.id)}/>}
                ]}
            />
        }
    }
}