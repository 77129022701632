<script setup>
  import { useCurrentUserStore } from "../store/CurrentUserStore";
  import { CodeMirror } from "../ui/CodeMirror"
  import { LiveQuery } from "../api/livequery"
  import { computed, reactive } from "vue";
  import { Spinner } from "../ui/Spinner"
  import { Button } from "../ui/Button"
  import "./RulesDefaultSections.scss"
  import { model } from "../model"
  import { API } from "../model"
  import { t } from "../i18n";
  
  const yaml = require("js-yaml")
  
  const userStore = useCurrentUserStore
  // Reactive is used to access and update the reactive sections
  const data = reactive({
    sections: null
  })

  // Get rule configuration for settings defaults sections
  LiveQuery("rule", `{ name rule_id alert_sections }`,c=>model.rule=c,()=>!!userStore.user && [{rule_id:"config-sections"}])  

  // Set sections
  const setDefault = () => {
    if (!data.sections) {
      data.error = "There is no difference"
      setTimeout(() => data.error = null, 1500)
      return
    }
    
    let fixSyntax
    if (data.sections.includes('-') && !data.sections.includes('sections')) fixSyntax = data.sections.replace("-", "- ") 
    else if (data.sections.includes('hidden- sections')) fixSyntax = data.sections.replace("hidden- sections", "hidden-sections") 
    if (typeof data.sections==='string') fixSyntax = data.sections
    else {
      const jsonData = yaml.load(data.sections)
      fixSyntax = JSON.stringify(jsonData)
    }

    try {
      API.setRuleSections({rule_id:"config-sections", alert_sections:fixSyntax})
      data.success = "Default sections has been submitted"
      setTimeout(() => data.success = null, 1500)

    } catch (error) {
      data.errors = `YML format is got a syntax error :  ${error.message}`
    }
  }

  // Dynamic constants rendered
  const error = computed(() => data.error)
  const success = computed(() => data.success)
  const ruleId = computed(() => model.rule?.rule_id)
  const ruleTitle = computed(() => model.rule?.name)
  const sections = computed(() => model.rule.alert_sections ? yaml.dump(model.rule.alert_sections) : "")
</script>

<template>
  <div id="sections-settings">
    <h1>{{ t("Settings rules sections") }}</h1>
    <div class="section-form">
      <div v-if="!model.rule">
        <Spinner/>
      </div>
      <div v-else-if="ruleId" class="config-section">
        <div class="edit-card-sections">
          <div class="header-card">
            <span id="name" class="title-card">{{ ruleTitle }}</span>
            <span id="rule-id">{{ ruleId }}</span>
            <span class="defautl-message">{{ t("This feature lets you configure the sections that will be highlighted by default on alerts if no configuration has been made in the associated rules.") }}</span>
          </div>
          <div class="body-card">
            <CodeMirror noupdate class="title" :value="sections" @change="x=>data.sections=x"/>
            <Button @click="() => setDefault()">{{ t("Submit") }}</Button>
            <span v-if="error" id="error">{{ error }}</span>
            <span v-if="success" id="success">{{ success }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>