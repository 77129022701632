<script setup>
  import { useClipboard } from '@vueuse/core'
  import { ICONS_VUE } from "./iconsVue.js"
  import { ref } from 'vue';
  import './Clipboard.scss'

  const props = defineProps(["text"])
  const source = ref(props.text)
  const { copy, copied, isSupported } = useClipboard({ source })
</script>

<template>
  <div v-if="isSupported" class="clipboard-box">
    <div v-if='!copied'>
      <div class="button" @click='copy(source)' v-html="ICONS_VUE['copy']"></div>
    </div>
    <span v-if='copied'><div class="wrapper" v-html="ICONS_VUE['checkmark']"></div></span>
  </div>
</template>
