import { ref } from "@vue/reactivity";
import "./ExpandableText.scss";

export const ExpandableText = {
  props: ['text'],
  setup(props) {
    const isExpanded = ref(false);

    const toggle = () => {
        isExpanded.value = !isExpanded.value
    }

    return () => (
      <div className="expandable-text" onClick={toggle}>
        <span
          id="text"
          class={isExpanded.value ? "expand align" : "reduce"}
        >
            {props.text}
        </span>
      </div>
    );
  },
};
