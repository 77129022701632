import { t } from "../i18n";
import { Progress } from "./Progress";

export const Recos = ({recos_by_status}) => {
    let {open, closed} = recos_by_status || {}
    open ||= 0
    closed ||= 0
    return open+closed>0 && 
    <Progress value={closed} max={open+closed}>
        <b>{closed}</b> {t('validated out of', {nb:closed})} <b>{open+closed}</b>
    </Progress>
}
