import { reactive, watchEffect } from "@vue/runtime-core"
import { model, API, getMembers } from "../model"
import { Accordion } from "../ui/Accordion"
import { Description } from "../ui/Description"
import { H1 } from "../ui/H"
import { ASSET_ICON, ICON } from "../ui/icons"
import { Related } from "../ui/Related"
import { Sidebar } from "../ui/Sidebar"
import { Spinner } from "../ui/Spinner"
import { Timeline } from "../ui/Timeline"
import { capitalize, debounce } from "../util"
import './Recommendation.scss'
import { Dropdown, DropdownItem } from "../ui/Dropdown"
import { t, t_fem } from "../i18n"
import { Step } from "../ui/Chain"
import { InProgressIcon } from "../ui/InProgressIcon"
import { Button } from "../ui/Button"
import { gotoScope, link } from "../utils/routing"
import { Severity } from "../ui/Severity"
import { Date } from "../ui/Date"
import { Switch } from "../ui/Switch"
import { TagsMixin } from "../mixins/Tags"
import { Link } from "../utils/router"
import { useCurrentUserStore } from "../store/CurrentUserStore"

const userStore = useCurrentUserStore

const VISIBILITIES = ["public", "internal", "private", "waiting_for_response"]

const allowed_statuses = ["draft", "open", "done", "rejected", "validated"]


export const Recommendation = {
    setup() {
        const data = reactive({
            related_cases:[],
            related_scenarios:[],
            related_findings:[],
            vars:{
                related_cases:{},
                related_scenarios:{},
                related_findings:{}
            },
            assets: model.recommendation?.related_findings?.items,
        })

        function setTitle(t) { API.setRecommendationTitle(model.recommendationId, t) }
        function setStatus(s) { API.setRecommendationStatus(model.recommendationId, s) }
        function setDescription(d) { API.setRecommendationDescription(model.recommendationId, d) }
        function setControl(d) { API.setRecommendationControl(model.recommendationId, d) }
        function setRefs(d) { API.setRecommendationRefs(model.recommendationId, d) }
        function setPriority(p) { API.setRecommendationPriority(model.recommendationId, p)}
        function setDifficulty(p) { API.setRecommendationDifficulty(model.recommendationId, p)}
        function setCost(p) { API.setRecommendationCost(model.recommendationId, p)}
        function setCreatedAt(created_at) { API.setRecommendation(model.recommendationId, {created_at})}                    
        function setUpdatedAt(updated_at) { API.setRecommendation(model.recommendationId, {updated_at})}  
        function setPublic(is_public) { API.setRecommendation(model.recommendationId, {is_public})}

        function sendMessage(body, visibility, files) {
            API.addComment("recommendation", model.recommendationId, files, {body, visibility})
            Array.from(body.matchAll(/#\\\[(.+?):(.+?)\\]\(.+?\)/gm)).forEach((x) => {
                const [_,type,id] = x
                API.addRelated("Recommendation", model.recommendationId, capitalize(type), id)
            })
        }
        function setVisibility(comment, visibility) { API.setComment(comment.id, {visibility}) }
        function saveMessage(comment, body) { API.setComment(comment.id, {body}) }
        function deleteMessage(comment) { API.removeComment(comment.id) }

        const {Tags} = TagsMixin("Recommendation")
        
        function deleteCommentObject(comment, o) { API.deleteCommentObject(comment.id, o.objType, o.objId) }

        function addRelatedScenario(r) { API.addRelatedScenario("recommendation", model.recommendationId, r.id) }
        function removeRelatedScenario(r) { API.removeRelatedScenario("recommendation", model.recommendationId, r.id) }
        function newScenario() { gotoScope(`newscenario?related_recommendations=${model.recommendationId}`); }
        function addRelatedCase(r) { API.addRelatedCase("recommendation", model.recommendationId, r.id) }
        function removeRelatedCase(r) { API.removeRelatedCase("recommendation", model.recommendationId, r.id) }
        function newCase() { gotoScope(`newcase?related_recommendations=${model.recommendationId}`); }

        const fetchMembers = async x => (await queryMembers(x)).map(m=>"@"+m.user.display_name.replace(/\s/g, "_"))
        
        const fetchObjects = async x => (await API.getObjects({search:x, scope_id:model.scope.id, type:["case", "scenario"]}))
                .map(({id,type,title})=>'#['+type+":"+id+"]("+title+")")

        const queryMembers = search => getMembers({search})
        const queryScenarios = filter => API.getScenarios({scope_id:model.scope.id, search:filter, order:"desc", sort:"updated_at"})
        const queryCases = filter => API.getCases({scope_id:model.scope.id, search:filter, order:"desc", sort:"updated_at"})
        const queryFindings = filter => API.getFindings({scope_id:model.scope.id, search:filter, order:"desc", sort:"updated_at"})
        
        watchEffect(async ()=>data.related_cases = model.recommendation?.id && await API.getRelatedCases("recommendation", model.scopeId, model.recommendation.id, data.vars.related_cases))
        watchEffect(async ()=>data.related_scenarios = model.recommendation?.id && await API.getRelatedScenarios("recommendation", model.scopeId, model.recommendation.id, data.vars.related_scenarios))
        watchEffect(async ()=>data.related_findings = model.recommendation?.id && await API.getRelatedFindings("recommendation", model.scopeId, model.recommendation.id, data.vars.related_findings))

        const getAssets = (assets) => {
            if (assets==="{}") return

            return Object.entries(assets).map((asset) => {
                if (asset[0].startsWith("device--")) {
                    return <Link href={link(`scope/${model.scopeId}/asset/${asset[0].split("--")[0]}?q=${asset[1]}&id=${asset[0]}`)}><span id="asset-badge">{ASSET_ICON("workstation")} {asset[1]}</span></Link>
                } else if (asset[0].startsWith("domainname--")) {
                    return <Link href={link(`scope/${model.scopeId}/asset/${asset[0].split("--")[0]}?q=${asset[1]}&id=${asset[0]}`)}><span id="asset-badge">{ASSET_ICON("domain")} {asset[1]}</span></Link>
                } else if (asset[0].startsWith("account--")) {
                    return <Link href={link(`scope/${model.scopeId}/asset/${asset[0].split("--")[0]}?q=${asset[1]}&id=${asset[0]}`)}><span id="asset-badge">{ASSET_ICON("account")} {asset[1]}</span></Link>
                } else if (asset[0].startsWith("activedirectory--")) {
                    return <Link href={link(`scope/${model.scopeId}/asset/${asset[0].split("--")[0]}?q=${asset[1]}&id=${asset[0]}`)}><span id="asset-badge">{ASSET_ICON("activedirectory")} {asset[1]}</span></Link>
                } else {
                    return <Link href={link(`scope/${model.scopeId}/asset/${asset[0].split("--")[0]}?q=${asset[1]}&id=${asset[0]}`)}><span id="asset-badge"><i className="fas fa-ethernet me-1/"/> {asset[1]}</span></Link>
                }
            })
        }

        return ()=>{
        if(!model.recommendation) return <Spinner/>

        const {tags, body, control, refs, difficulty, cost, comments, status, priority, created_at, updated_at, is_public} = model.recommendation

        const allowed_tags = model.scope?.tags

        const RW = userStore.hasPermissions("recommendations", "write_private")
        const RWStatus = userStore.hasPermissions("recommendations", "write")

        return <div id="recommendation" class="page with-sidebar">
            <div>
            <div class="sticky-header">
                <h1>
                    <div class="flex">
                        <div class="title-wrapper">
                            <H1 class="title" value={model.recommendation.title}
                                onSave={RW && setTitle} 
                                render={x=><h1>{ICON('recommendation')} {x}</h1>}
                            />
                        </div>
                    </div>
                    <div class="actions">
                        {RW || RWStatus ? <Dropdown button={<RecommendationStatus status={status}/>} items={()=>
                            allowed_statuses.map(s=><DropdownItem onClick={()=>setStatus(s)}>
                                {capitalize(t_fem("reco:"+s))}
                            </DropdownItem>)
                        }/>
                        : <RecommendationStatus status={status}/>}
                    </div>
                </h1>

                <div class="row">
                     {RW && <Switch name="Public" value={is_public} onClick={()=>setPublic(!is_public)}>{t("Public")}</Switch> }

                    {Tags(model.recommendation)}
                </div>

                <section className="box">
                    <Description
                        title="Description"
                        editable={RW}
                        source={body}
                        onSave={setDescription}
                        />

                    {RW && <Description
                        title="Control"
                        editable={RW}
                        source={control}
                        onSave={setControl}
                        />}

                    <Description
                        title="Refs"
                        editable={RW}
                        source={refs}
                        onSave={setRefs}
                        />

                    <div className="assets-section">
                        <span className="assets-title">Assets</span>
                        <div className="assets-content">
                            {model.recommendation?.related_findings?.items.map((a) => {
                                return <div className="assets">{getAssets(a.assets)}</div> 
                            })}
                        </div>
                    </div>
                </section>

                <br/>
                </div>
                <div>
                    <Timeline
                        items={comments}
                        editable={RW} 
                        onSend={sendMessage}
                        onSave={saveMessage}
                        onDelete={deleteMessage}

                        visibilities={RW && VISIBILITIES}
                        setVisibility={RW && setVisibility}

                        fetchUsers={fetchMembers}
                        fetchObjects={fetchObjects}

                        onDeleteObject={RW && deleteCommentObject}
                        previousSelector={".sticky-header"}
                    />
                </div>
            </div>

            <Sidebar>

                <Accordion>

                    <div class="dates">
                        <div>{t("Created")}</div><div><Date onSave={RW && setCreatedAt} value={created_at}/></div>
                        <div>{t("Last activity")}</div><div><Date onSave={RW && setUpdatedAt} value={updated_at}/></div>
                    </div>

                    <div id="priority">
                        <h3>{t("Priority")}</h3>
                        <Severity val={priority} onChange={RW && setPriority}/>
                    </div>

                    <div id="difficulty">
                        <h3>{t("Difficulty")}</h3>
                        <Severity val={difficulty} onChange={RW && setDifficulty}/>
                    </div>

                    <div id="cost">
                        <h3>{t("Cost")}</h3>
                        <Severity val={cost} onChange={RW && setCost}/>
                    </div>

                    

                    <Related type='scenario' title="Scenarios" items={data.related_scenarios}
                        editable={RW}
                        query={queryScenarios}
                        onAdd={addRelatedScenario}
                        onDelete={removeRelatedScenario}
                        addTooltip="Add related scenario"
                        update={debounce(x=>data.vars.related_scenarios.search = x.search)}
                        footer={<Button secondary onClick={newScenario}>{ICON('add')} {t("New scenario")}</Button>}
                    />

                    <Related type='case' title="Cases" items={data.related_cases}
                        editable={RW}
                        class="no-dates"
                        query={queryCases}
                        onAdd={addRelatedCase}
                        onDelete={removeRelatedCase}
                        addTooltip="Add related case"
                        update={debounce(x=>data.vars.related_cases.search = x.search)}
                        footer={<Button secondary onClick={newCase}>{ICON('add')} {t("New case")}</Button>}
                    />

                    <Related type='finding' title="Findings" items={data.related_findings}
                        editable={RW}
                        class="no-dates"
                        query={queryFindings}
                        update={debounce(x=>data.vars.related_findings.search = x.search)}
                    />

                </Accordion>
            </Sidebar>
        </div>
        }
    }
}


export const RecommendationStatus = ({status}) => status && (
    ['open', 'todo'].includes(status) ? <Step icon={<InProgressIcon/>} class="final">{t_fem("reco:"+status)}</Step>
    : ["done", "archived-validated", "validated"].includes(status) ? <Step icon={ICON("done")} done class="final">{t_fem("reco:"+status)}</Step>                            
    : status==="draft" ? <Step icon={ICON("draft")} done class="final">{t_fem("reco:"+status)}</Step>                            
    : <Step icon={ICON("canceled")} class="final">{t_fem("reco:"+status)}</Step>
)
