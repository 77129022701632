import { onMounted, onUnmounted, ref, watch, reactive } from 'vue';
import { ToolButton } from './Button';
import { Help } from "./Help";
import './CodeMirror.scss'
import { ICON } from './icons';
import CM from 'codemirror/lib/codemirror.js';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/yaml/yaml.js';
import 'codemirror/theme/material-darker.css';
import 'codemirror/mode/handlebars/handlebars.js'; 

export const CodeMirror = {
    props:["value", "lang", "onChange", 'autocomplete', 'oneline', 'accept', 'wrap', 'noupdate', 'help', 'extraKeys', 'readOnly'],
    setup(props) {
        const data = reactive({dirty:false})

        let el = ref(null)
        
        let editor = null;
        let dontShowHint=false;
        let bypassOnChange=false;
        let extraKeys = props.extraKeys || {}

        if(!props.noupdate) {
            watch(()=>props.value,()=>{
                bypassOnChange = true
                editor?.setValue(props.value)
                bypassOnChange = false
            })
        }

        onMounted(()=>{
            const {lang, onChange, value, wrap} = props

            const opts = {}
            
            if(props.autocomplete) {
                async function autocomplete(cm, option) {
                    var cursor = cm.getCursor(), line = cm.getLine(cursor.line)
                    var start = cursor.ch, end = cursor.ch
                    while (start && /\w/.test(line.charAt(start - 1))) --start
                    while (end < line.length && /\w/.test(line.charAt(end))) ++end
                    var word = line.slice(start, end).toLowerCase()
                    const list = await props.autocomplete(word, line.slice(0, start), cm.getValue())
                    return list?.length ? {
                        list,
                        from: CM.Pos(cursor.line, start),
                        to: CM.Pos(cursor.line, end)
                    } : null
                }
                extraKeys = {"Ctrl-Space": "autocomplete"}
                opts.hintOptions = {hint: autocomplete, completeSingle:false}
            }

            if(wrap) opts.lineWrapping = true

            editor = CM.fromTextArea(el.value, {
                lineNumbers: true,
                mode:lang,
                scrollbarStyle: null,
                theme: "material-darker",
                extraKeys: extraKeys,
                readOnly: props.readOnly,
                ...opts
            });
            editor.setValue(value||"")

            if(props.oneline) {
                const wrapper = editor.display.wrapper
                wrapper.classList.add("oneline")
                editor.setSize(300, editor.defaultTextHeight() + 2 * 4);
                editor.on("beforeChange", function(instance, change) {
                    var newtext = change.text.join("").replace(/\n/g, ""); 
                    change.update(change.from, change.to, [newtext]);
                    return true;
                });

                // editor.on("change", function(instance, change) {
                //     $(".CodeMirror-hscrollbar").css('display', 'none');
                // });
            }
            
            if(props.autocomplete) {
                editor.on("change", ()=>{
                    if(!dontShowHint) editor.showHint()
                    dontShowHint=false
                })
                editor.on("endCompletion", ()=>dontShowHint=true)
            }
            editor.on("change", ()=>{
                if(props.accept) {
                    if(!bypassOnChange) data.dirty = true
                }
                else onChange?.(editor.getValue())
            })
        })
        onUnmounted(()=>{
            editor.toTextArea()
        })
            
        return ()=>{
            return <div class="code">
                <textarea ref={el}></textarea>
                {props.accept && data.dirty && <div class="accept">
                    <ToolButton onClick={()=>{props.onChange?.(editor.getValue()); data.dirty=false}}>{ICON("ok")}</ToolButton>
                    <ToolButton onClick={()=>{dontShowHint=true; editor.setValue(props.value); data.dirty=false}}>{ICON("close")}</ToolButton>
                </div>}
                {!!props.help && <Help>{props.help}</Help>}
            </div>
        }
    }
}