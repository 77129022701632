import { createApp } from "vue" 

export const min = (...numbers) => numbers.reduce((cur,x)=>x<=cur ? x : numbers[0])
export const max = (...numbers) => numbers.reduce((cur,x)=>x>=cur ? x : numbers[0])
export const sum = (arr_or_obj) => {
    if(!arr_or_obj) return 0
    const arr = Array.isArray(arr_or_obj) ? arr_or_obj : Object.values(arr_or_obj)
    return arr.reduce((s,x)=>s+(x||0), 0)
}

export const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]


export const months_between = (from, to) => {
    for(var r = [], month = from.getMonth(), year=from.getFullYear();;) {
        r.push(`${MONTHS[month]} ${year}`)
        if(month == to.getMonth() && year == to.getFullYear()) 
            return r;
        if(++month>11) {year++; month=0}
    }
}

export const getMonth = (m) => {
    if(typeof(m)==="string") m = parseInt(m)-1
    return MONTHS[m]
}

export const readableNumber = (n) => {
    if (n >= 1000000000) return Math.round(n/100000000)/10 + "G"
    if (n >= 1000) {
        return n >= 1000000 ? Math.round(n/1000000) + ' M': Math.round(n/1000) + ' K'
    }
    return "" + n
}
  
export const range = (n) => Array.from(Array(n),(_,i)=>i)

export const to_date = (x) => new Date(x).toLocaleDateString()


export const validateEmail = (email) => /^.+@.+\..+$/.test(email)

export function isInViewport(el) {
    const rect = el?.getBoundingClientRect?.();
    return (
        rect &&
        rect.top+3 >= 0 &&
        rect.bottom-3 <= (window.innerHeight || document.documentElement.clientHeight) 
    );
}

export function onceElementInViewport(el, cb=()=>{}) {
    let called = false
    function f() { 
        if(isInViewport(el)) {
            if(!called) cb?.(el) 
            called = true
            window.removeEventListener("scroll", f)
        }
    }
    window.addEventListener("scroll", f)
    return {off(){window.removeEventListener("scroll", f)}}
}

export function onScrollToEnd(cb=()=>{}) {
    function f() {if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight) cb?.()}
    window.addEventListener("scroll", f);
    return {off(){window.removeEventListener("scroll", f)}}
}

export const debounce = (f,t=500) => {
    let timeout = null
    return (...params)=>{
      if(timeout!==null) clearTimeout(timeout)
      timeout = setTimeout(async ()=>{
        await f(...params)
        timeout = null;
      },t)
    }
}

export const bold_first_word = x => <><b>{x.split(" ")[0]}</b> {x.substr(x.indexOf(" ")+1)}</>


export const randint = (n)=>Math.floor(Math.random()*n)
export const randpick = (arr)=>arr[randint(arr.length)]

export const randname = (n)=>capitalize(range(n||(randint(9)+3)).map(i=>randpick(i%2 ? 'aeiouy' : 'bcdfghjklmnpqrstvwxz')).join(""))

export const rand_date = (days_before) => new Date(+new Date() - randint(1000*3600*24*days_before))

export const capitalize = s => typeof(s)==="string" ? (s?.[0]?.toUpperCase() + s?.slice(1)) : s
export const nl2br = s=> typeof(s)==="string" ? s?.split("\n").map((x,i)=>i===0 ? x : <><br/>{x?.[0]===" " ? <>&nbsp;{x}</> : x}</>): s

export const wait = (timeout) => new Promise(r=>setTimeout(r,timeout))

export const repeat = (n, tab) => range(n).reduce((a,_)=>[...a, ...tab], [])

export const toSnakeCase = (str) => str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)?.map(x => x.toLowerCase()).join('_');


export const filter_null = (o) => o ? Object.fromEntries(Object.entries(o).filter(([k,v])=>v!==null && v!==undefined)) : null
export const filter_undefined = (o) => o ? Object.fromEntries(Object.entries(o).filter(([k,v])=>v!==undefined)) : null

export const first_paragraph = (x)=> x?.indexOf("\n\n")!==-1 ? x.slice(0,x.indexOf("\n\n")) : x

export const flat_keys = o => {
    if(Array.isArray(o)) return o
    if(typeof(o)==="boolean") return o.toString()
    if(typeof(o)==="string") return o
    if(typeof(o)==="number") return o
    if(typeof(o)==="bool") return o
    const out = {}
    for(var k in o) {
        var v = flat_keys(o[k])
        if(Array.isArray(v) || typeof(v)==="string" || typeof(v)==="number") out[k] = v
        else for(var kk in v) out[k+'.'+kk] = v[kk]
    }
    return out
}

export const has_flat_keys = o => Object.keys(o)[0]?.includes('.')

export const object_filter_fields = (raw, filtered) => {
    return Object.keys(raw)
        .filter(key => !filtered.includes(key))
        .reduce((obj, key) => {
            obj[key] = raw[key];
            return obj;
        }, {});
}

export const isObject = (o)=>o && typeof o === 'object' && !Array.isArray(o)

// NOTE : modify <object> in place
export function deep_merge(object, ...objects) {
    for(var o of objects) {
        if (isObject(object) && isObject(o)) {
            for (const key in o) {
                if (isObject(o[key]) && !(key in object)) {
                    object[key] = {}
                    deep_merge(object[key], o[key]);
                } else {
                    object[key] = o[key]
                }
            }
        }
    }
    return object
}

export const animateNumber = (v, n) => {
    for(v=0; v<=parseInt(n);v++){
        wait(2)
    }
}

export function arrayUnique(array, field) {
    var a = array.concat();
    for(var i=0; i<a.length; ++i) {
        for(var j=i+1; j<a.length; ++j) {
            if (field) {
                if(a[i][field] === a[j][field])
                    a.splice(j--, 1);
            } else {
                if(a[i] === a[j])
                    a.splice(j--, 1);
            }
        }
    }

    return a;
}

export function hasSearch(search, fields) {
    search = search.toLowerCase()
    if(!Array.isArray(fields)) fields = [fields]
    for(var f of fields) {
        if(f?.toLowerCase?.().includes(search)) return true
    }
    return false
}

export function JSX2html(component) {
    const c = ()=>component
    const el = document.createElement("div")
    const app = createApp(c)
    app.mount(el)
    const html = el.children[0]
    app.unmount()
    return html
}