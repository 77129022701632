import { onUnmounted, watch } from "vue";
import { filter_null } from "../util";
import { ago_to_date } from "../ui/Age"
import { getRouter } from "./router";

export const currentRoute = () => getRouter()?.currentRoute?.value

export const queryParams = () => currentRoute()?.query || {}

export function setQueryParams(q, replace=false) {
    const {path, query} = currentRoute()
    q = Object.entries({...query, ...q}).filter(([k,v])=>!!v).map(([k,v])=>k+"="+encodeURIComponent(v)).join("&")
    if(replace) getRouter().replace(path + "?" + q)
    else getRouter().push(path + "?" + q)
}

export const optionsFromQueryParams = () => filter_null({
    sort:queryParams().sort,     
    order:queryParams().order,   
    search:queryParams().search, 
    page:parseInt(queryParams().page)||undefined,
    case_id:queryParams().case_id,
})

export const watchQueryParams = (cb=(queryParams)=>{}, firstCall=true) => {
    let still_mounted = true
    const stop = watch(()=>currentRoute()?.query,()=>{
        setImmediate(()=>still_mounted && cb(queryParams()))
    })
    try { onUnmounted(()=>{still_mounted=false; stop();}) } catch(_){}
    if(firstCall) cb(queryParams())
}


export function intervalFromQueryParams(fromDate = "now-30d") {
    let from = queryParams()?.from || fromDate
    let to = queryParams()?.to || "now"
    return {from:from, to:to}    
}
