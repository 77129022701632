import { reactive } from "@vue/reactivity"
import { t } from "../../i18n"
import { API, model } from "../../model"
import { AccordionField, AccordionItem } from "../../ui/Accordion"
import { Button } from "../../ui/Button"
import { Description } from "../../ui/Description"
import { ICON } from "../../ui/icons"
import { Input } from "../../ui/Input"
import { queryParams } from "../../utils/queryParams"
import { gotoScope } from "../../utils/routing"
import './New.scss'
import {Dropdown, DropdownItem} from "../../ui/Dropdown";
import {capitalize} from "../../util";
import {CASES_TYPES, CaseType} from "../Case";
import { Switch } from "../../ui/Switch"
import { TagsMixin } from "../../mixins/Tags"
import { CaseStatus } from "../Cases"
import { DataTable } from "../../ui/DataTable"
import { ERR } from "../../ui/Toasts"
import { onMounted } from "@vue/runtime-core"
import {Date as DateComp} from "../../ui/Date"
    
export const NewCase = {
    setup() {
        const data = reactive({ 
            case:{
                scope_id:model.scopeId,
                type: queryParams().type || 'investigation',
                title: '',
                conclusion: '',
                tags:[],
                conclusion_code:null,
                status: "open",
                date: new Date()
            },
            scopes:{[model.scopeId]:true},
            alerts: queryParams().attach_alerts?.split(","),
            related_recommendations: queryParams().related_recommendations?.split(","),
            related_scenarios: queryParams().related_scenarios?.split(","),
        })

        async function save() {
            let goto_id = null
            for(const scope_id of Object.entries(data.scopes).filter(([k,v])=>v).map(([k,v])=>k)) {
                const {case:c,alerts,related_recommendations,related_scenarios} = data
                let {tags, conclusion_code, ...params} = c
                const {id} = await API.createCase({
                    ...params, 
                    scope_id, 
                })
                if(scope_id === model.scopeId) goto_id = id
                for(var t of tags) {
                    try { await API.tag("Case", id, t) }
                    catch(e) { ERR(`Can't add tag ${t} to Case ${id} in scope ${scope_id}`)}
                }
                for(var alertId of alerts||[]) await API.setAlertGroup(alertId, {case_id:id})
                for(var recoId of related_recommendations||[]) await API.addRelatedRecommendation("case", id, recoId)
                for(var scenarioId of related_scenarios||[]) await API.addRelatedScenario("case", id, scenarioId)
                if(conclusion_code?.code) await API.setCase(id, {conclusion_code: conclusion_code?.code}) 
            }
            if(goto_id) gotoScope(`case/${goto_id}`)
        }

        function cancel() {
            gotoScope("cases")
        }

        const {Tags} = TagsMixin("Case", {
            onAdd:t=>data.case.tags.push(t),
            onRemove:t=>data.case.tags = data.case.tags.filter(x=>x!==t),
        })

        onMounted(async ()=>{
            const alertId = queryParams().attach_alerts?.split(",")?.[0]
            if(alertId) {
                data.case.title = (await API.getAlertGroup({id:alertId, scope_id:model.scopeId})).message
                data.key = Math.random()
            }
        })

        return ()=><div id="new-case" class="new">
            <div>
                <h1>
                    <Dropdown slim button={<CaseType type={data.case.type}/>} items={()=>
                        CASES_TYPES.map(type=><DropdownItem onClick={()=>data.case.type = type}><span class="type"><span class={[type]}/>{capitalize(t(type))}</span></DropdownItem>)
                    }/>
                    <Input placeholder={t("New case...")} 
                        value={data.case.title} 
                        onInput={e=>data.case.title = e.target.value}
                        key={data.key}
                    />
                    <div class="date-picker">
                        <DateComp onSave={(e)=>data.case.date=e} value={data.case.date}/>    
                    </div>
                </h1>

                <Description
                    editing
                    title="Description"
                    source={data.case.body}
                    onInput={x=>data.case.body = x}
                />

                <Description
                    editing
                    title="Conclusion"
                    source={data.case.conclusion}
                    onInput={x=>data.case.conclusion = x}
                />

                <div class='grid'>
        
                    <Switch value={data.case.is_public} onClick={()=>data.case.is_public=!data.case.is_public}>Public</Switch>

                    {Tags(data.case)}

                    <CaseStatus case={data.case} 
                        onReopen={()=>{data.case.status="open"; data.case.conclusion_code=null;}}
                        onSetQualification={(c)=>{data.case.status="closed"; data.case.conclusion_code=c;}}
                    />
                </div>

                {!data.alerts && !data.related_recommendations && !data.related_scenarios && <>
                <h5>{t("Propagate to other scopes")}</h5>
                <div class='select-scopes'>
                    {model.scopes?.map(s=><div>
                        <input disabled={s.id===model.scopeId} type="checkbox" checked={data.scopes[s.id]} onClick={()=>data.scopes[s.id]=!data.scopes[s.id]}/>
                        <img src={s.logo} alt=''/>
                        <span>{s.display_name}</span>
                    </div>)}
                </div>
                </>}
                    

                <div class="create-controls">
                    <Button disabled={!data.case.title || !data.case.body} onClick={save}>{t("Create")}</Button>
                    <Button secondary onClick={cancel}>{t("Cancel")}</Button>
                </div>
            </div>
        </div>
    }
}
    