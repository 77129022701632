import { LQ, model } from "../model"
import { queryParams } from "../utils/queryParams"
import { Link, getRouter } from "../utils/router"
import { LiveQuery } from "../api/livequery"
import { DataTable } from "../ui/DataTable"
import { Severity } from "../ui/Severity"
import { link } from "../utils/routing"
import { reactive } from "vue"
import { t } from "../i18n"
import "./Asset.scss"
import { DiagFooter } from "../ui/DiagFooter"
import { useCurrentUserStore } from "../store/CurrentUserStore"

const userStore = useCurrentUserStore

export const Asset = {
  setup() {
    const userStore = useCurrentUserStore
    const data = reactive({
      assetType: getRouter().currentRoute.value?.params?.type,
      assetAddress: queryParams()?.q,
      assetTypeId: queryParams()?.id,
    })

    const RW = userStore.hasPermissions("findings", "write_private")

    LQ.findings_and_recos_by_asset = LiveQuery("findings_and_recos_by_asset", ``,
        c=>model.findings=c, 
        ()=>!!userStore.user && !!model.scopeId && 
            [{scope_id:model.scopeId, asset_address_key:data.assetTypeId, asset_address_value:data.assetAddress}]
    )

    const getScore = (criticity) => {
      switch(criticity) {
          case "low" :
              return <Severity val={1} />;
          case "medium" :
              return <Severity val={2} />;
          case "high" :
              return <Severity val={3} />;
          case "critical" :
              return <Severity val={4} />;
          default :
              return <Severity val={0} />;
      };
  }
    
    return()=>{
      return <div className="asset-list">
        <h1>{data.assetType}</h1>
        <h2>{data.assetAddress}</h2>

        <section className="datatable-row">
          <DataTable
            title={t("Findings")}
            loading={LQ.loading}
            data={model.findings?.findings}
            columns={[
                RW && {title:"ID", field:"finding_id", render: f=><div>{f.id}</div>},
                {title:"Description", type:"description", render:f=><div class='description'>
                <div>
                  <Link href={link(`scope/${model.scopeId}/finding/${f.id}`)}>{f.title}</Link></div>
                </div>, sort:"title"},
                {title:t('Criticity'), render: f=><div>{getScore(f.criticity)}</div>, sort:"criticity"},
            ]}
            noSearch={true}
            />
        
          <DataTable
            title={t("Action plan")}
            loading={LQ.loading}
            data={model.findings?.recos}
            columns={[
                RW && {title:"ID", field:"recommendation_id", render: r=><div>{r.id}</div>},
                {title:"Description", type:"description", render:r=><div class='description'>
                <div>
                  <Link href={link(`scope/${model.scopeId}/recommendation/${r.id}`)}>{r.title}</Link></div>
                </div>, sort:"title"},
                {title:t("difficulty"), field:"difficulty", render: r=><Severity val={r.difficulty}/>, sort:"difficulty"},
            ]}
            noSearch={true}
            />
        </section>
        <DiagFooter />
      </div>
    }
  }
}