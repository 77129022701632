import { model, API, getMembers, getFastLinks } from "../model"
import { Accordion, AccordionField } from "../ui/Accordion"
import { Timeline } from '../ui/Timeline'
import { ICON } from "../ui/icons"
import './Case.scss'
import { Spinner } from "../ui/Spinner"
import { Button } from "../ui/Button"
import { goTo } from "../utils/routing"
import { H1 } from "../ui/H"
import { Dropdown, DropdownItem } from "../ui/Dropdown"
import { t, t_fem } from "../i18n"
import {capitalize, debounce} from "../util"
import { Description } from "../ui/Description"
import { Sidebar } from '../ui/Sidebar'
import { Related } from "../ui/Related"
import { reactive } from "@vue/reactivity"
import { onMounted, watchEffect } from "@vue/runtime-core"
import { gotoScope, scopeLink } from "../utils/routing"
import { Date } from "../ui/Date"
import { Switch } from "../ui/Switch"
import { TagsMixin } from "../mixins/Tags"
import { CaseStatus } from "./Cases"
import { Severity } from "../ui/Severity"
import { Row } from "../ui/Flex"
import { ICONS } from "../ui/icons"
import  CaseResponsables  from "../ui/CaseResponsables.vue"
import { useRoute } from 'vue-router'
import { useCurrentUserStore } from "../store/CurrentUserStore"
import { ERR } from "../ui/Toasts"

const userStore = useCurrentUserStore

const VISIBILITIES = ["public", "internal", "private", "waiting_for_response"]

export const CASES_TYPES = [
    "investigation",
    "audit",
    "patrol",
    "incident"
]


export const Case = {
    setup() {
        const userStore = useCurrentUserStore
        const data = reactive({
            bannerPos: 0,
            alerts:[],
            related_cases:[],
            related_recommendations:[],
            related_scenarios:[],
            vars: {
                alerts:{},
                related_cases:{},
                related_recommendations:{},
                related_scenarios:{},
                participants:{},
            },
            users:{
                roles: [],
                checked: []
            },
            previousCase: null,
            nextCase: null,
            notified_users: [],
        })


        function setDescription(d) { API.setCaseDescription(model.caseId, d) }
        function setConclusion(conclusion) { API.setCase(model.caseId, {conclusion}) }
        function setCreatedAt(created_at) { API.setCase(model.caseId, {created_at})}                    
        function setLastActivity(last_activity) { API.setCase(model.caseId, {last_activity})}  
        async function setPublic(is_public) { await API.publishCase(model.caseId, is_public) }
        function sendMessage(body, visibility, files) {
            API.addComment("case", model.caseId, files, {body, visibility})
            Array.from(body.matchAll(/#\\\[(.+?):(.+?)\\]\(.+?\)/gm)).forEach((x) => {
                const [_,type,id] = x
                if(type==="alert") API.updateAlertGroupo(id, {case_id:model.caseId})
                else API.addRelated("Case", model.caseId, capitalize(type), id)
            })
        }

        function setVisibility(comment, visibility) { API.setComment(comment.id, {visibility}) }
        
        function saveMessage(comment, body) { API.setComment(comment.id, {body}) }
        function deleteMessage(comment) { API.removeComment(comment.id) }
        
        function setTitle(title) { API.setCaseTitle(model.caseId, title) }
        function setType(type) { API.setCaseType(model.caseId, type) }
        function setSeverity(severity) { API.setCase(model.caseId, {severity})}

        function notify() { API.notifyCase(model.caseId) }
        
        function investigateAlert(x) { API.setAlertGroup(x.id, {case_id:model.caseId}) }
        function uninvestigateAlert(x) { API.setAlertGroup(x.id, {case_id:null}) }
        function newAlert() { gotoScope(`newalert?cases=${model.caseId}`); }
        function addRelatedCase(r) { API.addRelatedCase("case", model.caseId, r.id) }
        function removeRelatedCase(r) { API.removeRelatedCase('case', model.caseId, r.id) }
        function newCase() { gotoScope(`newcase?related_cases=${model.caseId}`); }
        function newIncident() { gotoScope(`newincident?related_cases=${model.caseId}`); }
        function addRelatedRecommendation(r) { API.addRelatedRecommendation("case", model.caseId, r.id) }
        function removeRelatedRecommendation(r) { API.removeRelatedRecommendation('case', model.caseId, r.id) }
        function newRecommendation() { gotoScope(`newrecommendation?related_cases=${model.caseId}`); }
        function addRelatedScenario(r) { API.addRelatedScenario("case", model.caseId, r.id) }
        function removeRelatedScenario(r) { API.removeRelatedScenario("case", model.caseId, r.id) }
        function newScenario() { gotoScope(`newscenario?related_cases=${model.caseId}`); }

        const queryAlerts = filter => API.getAlertGroups({scope_id:model.scopeId, search:filter, order:"desc", sort:"updated_at"})
        const queryMembers = search => getMembers({search})
        const queryRecommendations = filter => API.getRecommendations({scope_id:model.scopeId, search:filter, order:"desc", sort:"updated_at"})
        const queryScenarios = filter => API.getScenarios({scope_id:model.scopeId, search:filter, order:"desc", sort:"updated_at"})
        const queryIncidents = filter => API.getIncidents({scope_id:model.scopeId, search:filter, order:"desc", sort:"updated_at"})
        const queryCases = filter => API.getCases({scope_id:model.scopeId, search:filter, order:"desc", sort:"updated_at"})
        const getNotifiedUsers = () => {
            API.getNotifiedUsers(model.caseId, 'notify_case')
            .then(x=>{
                data.notified_users = <>{x.map((u)=><span>{u.display_name}<br/></span>)}</>
           })
        }
        getNotifiedUsers()

        const {Tags} = TagsMixin("Case")

        const deleteCase = async () => {
            if (data.alerts && data.alerts.count>0) {
                ERR(t("Cannot delete case with linked alerts"))
            }
            else {
                await API.deleteCase(model.caseId)
                gotoScope("cases")
            }
        }

        function deleteCommentObject(comment, o) { API.deleteCommentObject(comment.id, o.objType, o.objId) }
        
        const getCoastCase = async () => {
            const {previous, next} = await API.getCoastCase(model.caseId)
            data.previousCase = previous
            data.nextCase = next
        }

        const fetchMembers = async (x) => (await queryMembers(x)).map(m=>"@"+m.user.display_name.replace(/\s/g, "_"))
        
        const fetchObjects = async (x) => await getFastLinks(x, ["recommendation", "scenario", "alert_group"])

        watchEffect(async ()=>data.alerts = model.case?.id && model.scopeId && await API.getCaseAlertGroups(model.scopeId, model.case.id, data.vars.alerts))
        watchEffect(async ()=>data.related_cases = model.case?.id && model.scopeId && await API.getRelatedCases("case", model.scopeId, model.case.id, data.vars.related_cases))
        watchEffect(async ()=>data.related_recommendations = model.case?.id && model.scopeId && await API.getRelatedRecommendations("case", model.scopeId, model.case.id, data.vars.related_recommendations))
        watchEffect(async ()=>data.related_scenarios = model.case?.id && model.scopeId && await API.getRelatedScenarios("case", model.scopeId, model.case.id, data.vars.related_scenarios))

        watchEffect(async ()=>getCoastCase())

        return ()=>{
            if(!model.case) return <Spinner/>

            const {body, comments,  type, conclusion, notified, 
                created_at, last_activity, is_public, severity } = model.case

            const RW = userStore.hasPermissions("cases", "write_private")
            
            const SEVERITIES = ['INFO', 'LOW', 'MEDIUM', 'HIGH', 'CRITICAL'].map(t_fem)

            return <div id="case" class="page with-sidebar">
            <div>
                <div class="sticky-header">
                    <div class="flex-row">
                        <div class="flex">
                            {RW  ? 
                                <Dropdown class="type" slim button={<CaseType type={type}/>} items={()=>
                                    CASES_TYPES.map(type=><DropdownItem onClick={()=>setType(type)}><CaseType type={type}/></DropdownItem>)
                                }/>
                                : <CaseType type={type}/>}
                            <H1 class="title" onSave={RW && setTitle} value={model.case.title}/>
                        </div>
                        <CaseStatus case={model.case} vertical/>
                    </div>

                    <Description 
                        title="Descripion"
                        editable={RW}
                        source={body}
                        onSave={setDescription}
                    />

                    <Description 
                        title="Conclusions"
                        class="conclusion"
                        editable={RW}
                        source={conclusion}
                        onSave={setConclusion}
                    />
                    <br/>
                </div>
                <div>
                    <Timeline 
                        items={comments} 
                        onSend={sendMessage}
                        onSave={saveMessage}
                        onDelete={deleteMessage}

                        is_public={is_public}
                        visibilities={RW && VISIBILITIES.filter(v=>is_public || v!=="waiting_for_response")}
                        setVisibility={RW && setVisibility}
                        buttons={item=>RW && <CommentActions comment={item}/>}

                        fetchUsers={fetchMembers}
                        fetchObjects={fetchObjects}

                        onDeleteObject={RW && deleteCommentObject}

                        previousSelector={".sticky-header"}
                    />
                </div>
            </div>

            <Sidebar>

                <Accordion>

                <div class="arrow-header">
                        <div class="">
                            {data.previousCase && <Button onClick={()=>goTo(`scope/${model.scopeId}/case/${data.previousCase}`)}>{ICON('prev')} {t('Previous case')}</Button>}
                        </div>
                        <div class="">
                            {data.nextCase && <Button onClick={()=>goTo(`scope/${model.scopeId}/case/${data.nextCase}`)}>{t('Next case')} {ICON('next')}</Button>}
                        </div>
                    </div>



                    <AccordionField class='center case-severity' title={t("Severity")}>
                        <Severity val={severity} onChange={RW && setSeverity}/> 
                        <h2 class={["silent", "low", "medium", "high", "critical"][severity]}>{SEVERITIES[severity] || severity}</h2>
                    </AccordionField>

                    <AccordionField center title={t("Created")}>
                        <Date onSave={RW && setCreatedAt} value={created_at}/>
                    </AccordionField>
                    <AccordionField center title={t("Last activity")}>
                        <Date onSave={RW && setLastActivity} value={last_activity}/>
                    </AccordionField>

                    {RW && <Row>
                        <AccordionField title={t("Public")}>
                            <Switch disabled={comments.find(c=>c.visibility==="waiting_for_response")} name="Public" value={is_public} onClick={()=>setPublic(!is_public)}/>
                        </AccordionField>
                        {is_public && (
                            notified ? <span class="notified">{ICON('mail')}{ICON("done")} {t("Notified by email")} </span> 
                            : <Button class="notify" title={data.notified_users} class={{notified}} onClick={notify}>{ICON('mail')} {t("Notify by email")}</Button>                        
                        )}
                    </Row>}

                    <AccordionField center title={t("Assigned")}>
                        <CaseResponsables case={model.caseId} scope={model.scopeId} user={userStore.user}/>
                    </AccordionField>
                    
                    <br/>
                    {(RW || model.case.tags?.length>0) && <AccordionField title={t("Tags")}>
                        {Tags(model.case)}
                    </AccordionField>}

                    {(type === 'incident') &&
                    <Related type='case' title={type === 'incident' ? "Cases" : "Incidents"} items={data.related_cases}
                             editable={RW}
                             query={type === 'incident' ? queryCases : queryIncidents}
                             onAdd={addRelatedCase}
                             onDelete={removeRelatedCase}
                             addTooltip={type === 'incident' ? "Add related case" : "Add related incident"}
                             update={debounce(x=>data.vars.related_cases.search = x.search)}
                             footer={<Button secondary onClick={type === 'incident' ? newCase : newIncident}>{ICON('add')} {t(type === 'incident'? "New case" : "New incident")}</Button>}
                    />}

                    <Related type='alert' title="Alerts" items={data.alerts}
                        viewAllUrl={scopeLink(`/alerts?case_id=${model.caseId}&from=-&to=now&status=all`)}
                        editable={RW}
                        query={queryAlerts}
                        onAdd={investigateAlert}
                        onDelete={uninvestigateAlert}
                        addTooltip="Add alert"
                        update={debounce(x=>data.vars.alerts.search = x.search)}
                        footer={<Button secondary onClick={newAlert}>{ICON('add')} {t("New alert")}</Button>}
                    />

                    <Related type='recommendation' title="Recommendations" items={data.related_recommendations}
                        editable={RW}
                        query={queryRecommendations}
                        onAdd={addRelatedRecommendation}
                        onDelete={removeRelatedRecommendation}
                        addTooltip="Add related recommendation"
                        update={debounce(x=>data.vars.related_recommendations.search = x.search)}
                        footer={<Button secondary onClick={newRecommendation}>{ICON('add')} {t("New recommendation")}</Button>}
                    />

                    <Related type='scenario' title="Scenarios" items={data.related_scenarios}
                        class="no-dates"
                        editable={RW}
                        query={queryScenarios}
                        onAdd={addRelatedScenario}
                        onDelete={removeRelatedScenario}
                        addTooltip="Add related scenario"
                        update={debounce(x=>data.vars.related_scenarios.search = x.search)}
                        footer={<Button secondary onClick={newScenario}>{ICON('add')} {t("New scenario")}</Button>}
                    />

                    {RW && <div class="delete">
                        <Button class="delete-btn" onClick={deleteCase} disabled={data.alerts && data.alerts.count>0}>{ICON('trash')} {t("Delete case")}</Button>
                    </div> 
                    }
                </Accordion>

            </Sidebar>
        </div>
        }
    }
}

const CommentActions = {
    props:["comment", "allowed_tags"],
    setup(props) {
        const {Tags} = TagsMixin("Comment")

        return ()=>{
            return <>
                {Tags(props.comment)}
            </>
        }
    }
}


export const CaseType = ({type}) => <span class="case-type"><span class={[type]}/>{t(type)}</span>