import { reactive } from "vue"
import { ICON } from "./icons";
import './Toasts.scss'

const TIMEOUT = 3000

const ui = reactive({toasts:[]})
let curId = 0;

export function INFO(x) { if(x) addToast({type:'info', body:x}) }
export function ERR(x) { if(x) addToast({type:'error', body:x}) }

export function addToast(t) {
    const id = curId++
    ui.toasts.push({id, ...t})
    setTimeout(()=>ui.toasts.forEach(t=>{if(t.id===id) t.status = "show"}),10)
    setTimeout(()=>closeToast(id), TIMEOUT)
}

export function closeToast(id) {
    ui.toasts.forEach(t=>{if(t.id===id) t.status = "closed"})
    setTimeout(()=>ui.toasts = ui.toasts.filter(t=>t.id!==id), 250)
}

export const Toasts = {
    setup() {
        return ()=><div id="toasts">
            {ui.toasts.map(({id, body, type, status})=>(
                <div key={id} class={["toast",type,status]}>
                    {type && ICON(type)}
                    {body}
                    <button type="button" class="btn-close btn-close-white" onClick={()=>closeToast(id)}></button>
                </div>
            ))}
        </div>
    }
}