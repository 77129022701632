
export const PALETTE = {
    "open":"#ffc912", // Jaune CT-Square
    "audit":"#9e803b", // Jaune pastel
    "closed":"#91918E", // Gris
    "incident":"#ff440b", // Rouge
    "investigation":"#170748",
    "other":"#E6CF77",
    "patrol": "#FFCE00",
    "pending": "#170748",
}