import { capitalize } from "../util"
import {query, mutation, subscribe, gql_inline_vars} from "./graphql"

/////////////////////////////////////////////////////////////////
// LIVEQUERIES (=> subscriptions with same name)

export const getCurrentUser = () =>         query("current_user", {}, `{
    id, display_name, avatar, permissions, settings
}`)

// Paginated collections : args=({..., search, limit, page, sort, order })
export const getScopes = args =>            query("scopes", args, `{
    count, pages
    items {
        id, display_name, logo
    }
}`)

export const getScopesUsers = args => query("scope", args, `{
    roles, { role, user {id, display_name, email } } 
}`)

export const getIncidents = args =>         query("incidents", args, `{
    count, pages
    items {
        id, scope_id, title, body
    }
}`)
export const getAlertGroups = args =>            query("alert_groups", args, `{
    count, pages
    items {
        id, scope_id, message, first_detection, last_detection
    }
}`)
export const getScenarios = args =>         query("scenarios", args, `{
    count, pages
    items {
        id, scope_id, title, body
    }
}`)        
export const getUsers = args =>       query("users", args, `{
    count, pages
    items {
        id, display_name, email, avatar
    }
}`)

export const getNotificationsSettings = () => query("notifications_settings", {}, `{
    description,
    settings,
}`)

export const getCases = args =>             query("cases", args, `{
    count, pages
    items {
        id, scope_id, title, body, created_at, updated_at, last_activity, status, related_users {id display_name}
    }
}`)
export const getRecommendations = args =>   query("recommendations", args, `{
    count, pages
    items {
        id, scope_id, title, body
    }
}`)
export const getFindings = args =>   query("findings", args, `{
    count, pages
    items {
        id, scope_id, title, description
    }
}`)
export const getTags = () => query("tags",{}, `{
    id,
    name,
    color,
    taggable_type,
    scope_id
}`)

export const getAgentProfiles = () => query("agent_profiles",{},`{
    id,
    name,
}`)

// Admin
export const getStatus = ({scope_id}) => query("status", {scope_id})

// Objects by id
export const getScope = ({id}) =>             query("scope", {id}, '{id, display_name, logo}')
export const getVulnerability = ({id}) =>     query("vulnerability", {id}, '{id, title}')
export const getCase = ({ id, scope_id }) =>
    query(
      "case",
      { id, scope_id },
      `{ id, title, related_users { id display_name } }`,
    );

export const getRecommendation = ({id, scope_id}) =>    query("recommendation", {id, scope_id}, '{id, title}')
export const getFinding = ({id, scope_id}) =>    query("finding", {id, scope_id}, '{id, title}')
export const getScenario = ({id, scope_id}) => query("scenario", {id, scope_id}, '{id, title}')
export const getRule = ({rule_id}) => query("rule", {rule_id}, '{rule_id, rule_body, git_url, name}')
export const getWhitelist = ({whitelist_id}) => query("whitelist", {whitelist_id}, '{whitelist_id, whitelist_body, git_url, name}')
export const getAlertGroup = ({id, scope_id}) =>   query("alert_group", {id, scope_id}, '{id, message}')
export const getIncident = ({id, scope_id}) => query("incident", {id, scope_id}, '{id, title}')
export const getReport = ({id}) => query("report", {id}, '{id, month, year, scope_id}')
export const getUser = ({id}) => query("user", {id}, '{id, display_name, email, avatar}')
export const getAgent = ({id, scope_id}) => query("agent", {id, scope_id}, `{id, uuid, scope_id, hostname, last_seen, created_at, status, last_complete_status, profile_name, visible }`)
export const getReports = ({scopeId}) =>      query("reports", {scopeId})

// Aggregated collections
export const getRecommendationsByStatus = ({scopeId}) =>   query("recommendations_by_status", {scopeId})
export const getCasesByStatus = ({scopeId}) =>             query("cases_by_status", {scopeId})
export const getAlertsByStatus = ({scope_id, not_scope_ids}) =>            query("alert_groups_by_status", {scope_id, not_scope_ids})

export const getCaseAlertGroups = async (scope_id, id, args) => (await query("case",{id, scope_id},`{alert_groups${gql_inline_vars(args)}{items{id,scope_id,message,first_detection,last_detection},count,total,search}}`))?.alert_groups

// get notified users

export const getNotifiedUsers = async (element_id, notification_type) => query("notified_users", {element_id, notification_type}, `{id, display_name}`)

//get agents default profiles

export const getAgentsDefaultProfiles = async (scope_id, os) => query("agents_default_profiles", {scope_id, os}, `{os, scope{id},profile{name}}`)

export const getDiag = ({id, scope_id}) => query("diag", {id, scope_id}, '{id name status steps diag_events {action title created_at user {display_name}}}')
export const getDiagReports = ({id, scope_id}) => query("diag", {id, scope_id}, '{reports {filename, url}}')
export const getDiags = () => query("diags", {}, '{items {id name steps scope_id status scope {display_name}}}')

/////////////////////////////////////////////////////////////////
// NON-LIVE QUERIES (no need for subscription)

export const getRelated = async (type, scope_id, id, related, vars) => (await (
    query(type, {id,scope_id}, `{related_${related}${gql_inline_vars(vars)} {
    items { id, title, body, scope_id },
    count, total, search
    } }`)
))?.[`related_${related}`]



export const getRelatedAlertGroups = async (type, scope_id, id, vars) => getRelated(type, scope_id, id, "alert_groups", vars)
export const getRelatedCases =  async (type, scope_id, id, vars) => getRelated(type, scope_id, id, "cases", vars)
export const getRelatedRecommendations = async (type, scope_id, id, vars) => getRelated(type, scope_id, id, "recommendations", vars)
export const getRelatedScenarios =  async (type, scope_id, id, vars) => getRelated(type, scope_id, id, "scenarios", vars)
export const getRelatedFindings =  async (type, scope_id, id, vars) => getRelated(type, scope_id, id, "findings", vars)

export const getObjects = async ({search, scope_id, type}) => {
    if(!Array.isArray(type)) type = [type]
    const out = {}
    for(var t of type) {
        out[t] = {
            "alert_group":()=>getAlertGroups({search, scope_id}),
            "recommendation":()=>getRecommendations({search, scope_id}),
            "scenario":()=>getScenarios({search, scope_id}),
            "case":()=>getCases({search, scope_id})
        }[t]()
    }
    for(var t of type) out[t] = (await out[t]).items
    const ret = []
    for(var t of type) {
        ret.push(...out[t].map(x=>({...x, type:t})))
    }
    return ret
}


export const getConclusionCodes = async () => (await (
    query("conclusion_codes",{}, ``)
))

export const getSavedAlertsSearches = async ({scope_id}) => (await (
    query("saved_alerts_searches",{scope_id}, `{id, name, search, user{id}, scope{id}}`)
))

/// Shuffle integration

export const getShuffleWorkflows = async (scope_id) => (await (
    query("shuffle_workflows", {scope_id}, '{ items {id, name, created_at, updated_at, tags}}')
))

export const getShuffleExecutions = async (workflow_id, scope_id) => (await (
    query("shuffle_executions", {workflow_id, scope_id}, '{ items {id, type, status, started_at, completed_at, source, argument, result}}')
))

export const getShuffleAuthentications = async (scope_id) => (await (
    query("shuffle_auths", {scope_id}, '{ items {id, name, app_name, app_version, fields, created_at, updated_at}}')
))


/////////////////////////////////////////////////////////////////
// MUTATIONS (return nothing)

// agent

export const updateAgent = (id, {profile_id, visible}) => mutation("agent_update", {id, profile_id, visible}, '{id}')
export const applyAgentDefaultProfile = (scope_id, profile ,os) => mutation("agents_default_profile_update", {scope_id, profile, os}, '{os}')

// alert
export const createAlert = ({scope_id, title, body, date, severity}) => mutation("alert_create", {scope_id, title, body, date, severity})
export const setAlertGroup = (id, {title, body, status, case_id, severity, conclusion_code, keep_conclusion, reopen}) => mutation("alert_group_update", {id, conclusion_code, title, body, status, severity, case_id, keep_conclusion, reopen})
export const setAlert = (id, {title, body, original}) => mutation("alert_update", {id, title, body, original})

export const createConclusionCode = ({name, description}) => mutation("conclusion_code_create", {name, description})
export const setConclusionCode = (id, {code, name, description, fast_close}) => mutation("conclusion_code_update", {id, name, description, fast_close, code})

// asset

export const createAsset = (type, scope_id, values) => mutation('asset_create', {type, scope_id, values:JSON.stringify(values)}, '{data}')
export const updateAsset = (type, asset_id, scope_id, values) => mutation('asset_update', {type, asset_id, scope_id, values:JSON.stringify(values)}, '{data}')
export const deleteAsset = (id, scope_id, type) => mutation('asset_delete', {id, scope_id, type}, '{data}')

// IOC
export const getIOC = (ioc, {scope_id}={}) => query("ioc", {id:ioc, scope_id}, `{ioc, details, alert_groups(page:1${scope_id ? ', scope_id:"'+scope_id+'"' : ''}){
    count
    pages
    items {
        id, scope_id, message, scope_id, tags, severity, first_detection, last_detection, status, alerts_count, source, destination,
        rule_description, case{title,id},
        conclusion_code{name,description,code}
    }
}}`, false)
export const getIOCAlerts = (ioc, {scope_id, page=1}={}) => query("ioc", {id:ioc, scope_id}, `{alert_groups(page:${page}${scope_id ? ', scope_id:"'+scope_id+'"' : ''}){
    count
    pages
    items {
        id, scope_id, message, scope_id, tags, severity, first_detection, last_detection, status, alerts_count, source, destination,
        rule_description, case{title,id},
        conclusion_code{name,description,code}
    }
}}`, false)
export const setIOC = (ioc, details) => mutation("ioc_update", {ioc,details:JSON.stringify(details)}, '')
export const addIOCTag = (ioc, tag) => mutation("ioc_tag", {ioc,tag}, '')
export const deleteIOCTag = (ioc, tag) => mutation("ioc_untag", {ioc,tag}, '')


// case

export const getCoastCase = (id) => query("cases_coast", {id})
export const createCase = ({scope_id, alerts, title, body, checklist="", type, conclusion, conclusion_code, is_public, status, date}) => mutation("case_create", {scope_id, alerts, title, body, checklist, type, conclusion, conclusion_code, is_public, status, date})
export const createIncident = ({scope_id, alerts, title, body, checklist=""}) => mutation("case_create", {scope_id, alerts, title, body, checklist, type: 'incident'})
export const setCaseType = (id, type) => mutation('case_update', {id, type})
export const setCaseTitle = (id, title) => mutation("case_update", {id, title})
export const setCaseDescription = (id, body) => mutation("case_update", {id, body})
export const setCaseConclusion = (id, conclusion) => mutation("case_update", {id, conclusion})
export const setCase = (id, {created_at, conclusion, updated_at, last_activity, status, conclusion_code, severity, dont_update_date}) => mutation("case_update", {id, created_at, updated_at, last_activity, status, conclusion_code, conclusion, severity, dont_update_date})
export const publishCase = (id, is_public) => mutation("case_publish", {id, is_public})
export const notifyCase = (id) => mutation("case_notify", {id})
export const deleteCase = (id) => mutation("case_update", {id, is_deleted:true})
export const setRuleSections = ({rule_id, alert_sections}) => mutation("rule_update", {rule_id, alert_sections}, '{alert_sections}')

// diag
export const addSectionDiag = ({id, steps, start_at, end_at}) => mutation("diag_update", {id, steps, start_at, end_at}, '{start_at end_at steps}')
export const updateStatusDiag = ({id, status}) => mutation("diag_update_status", {id, status}, '{status}')
export const addDiagUser = ({user_ids, id, is_add_users}) => mutation("diag_update", {id, user_ids, is_add_users}, '{related_users {id, display_name}}');
export const removeDiagUser = ({user_ids, id, is_add_users}) => mutation("diag_update", {id, user_ids, is_add_users}, '{related_users {id, display_name}}');
export const createDiag = ({scope_id, name}) => mutation("diag_create", {scope_id, name}, '{id}');

// diag_event
export const diagEventCreate = ({user_id, diag_id, action, title}) => mutation("diag_event_create", {user_id, diag_id, action, title}, '{title action user {display_name}}')

// recommendation
export const createRecommendation = ({scope_id, title, body}) => mutation("recommendation_create", {scope_id, title, body})
export const setRecommendationTitle = (id, title) => mutation("recommendation_update", {id, title})
export const setRecommendationDescription = (id, body) => mutation("recommendation_update", {id, body})
export const setRecommendationControl = (id, control) => mutation("recommendation_update", {id, control})
export const setRecommendationRefs = (id, refs) => mutation("recommendation_update", {id, refs})
export const setRecommendationStatus = (id, status) => mutation("recommendation_update", {id, status})
export const setRecommendation = (id, {is_public, created_at, updated_at}) => mutation("recommendation_update", {id, is_public, created_at, updated_at})
export const setRecommendationPriority = (id, priority) => mutation("recommendation_update", {id, priority})
export const setRecommendationDifficulty = (id, difficulty) => mutation("recommendation_update", {id, difficulty})
export const setRecommendationCost = (id, cost) => mutation("recommendation_update", {id, cost})

// finding
export const setFinding = (id, {is_public, created_at, updated_at}) => mutation("finding_update", {id, is_public, created_at, updated_at})


// scenario
export const createScenario = ({scope_id, title, body}) => mutation("scenario_create", {scope_id, title, body})
export const setScenarioDescription = (id, body) => mutation("scenario_update", {id,body})
export const setScenarioStatus = (id, status) => mutation("scenario_update", {id,status})
export const setScenarioTitle = (id, title) => mutation("scenario_update", {id,title})
export const setScenario = (id, {is_public, created_at, updated_at}) => mutation("scenario_update", {id, is_public, created_at, updated_at})

// scope
export const createScope = ({display_name, logo, locked, kibana_space, scope_identifier}) => mutation("scope_create", {display_name, logo, locked, kibana_space, scope_identifier}, '{id}')
export const setScope = (id, {display_name, logo, checklist, support, locked, kibana_space, magellan_url, coordination, control_points, vulnerabilities_monitoring, scope_identifier, shuffle_url, shuffle_key}) => mutation("scope_update", {id, display_name, logo, checklist, support, locked, kibana_space, magellan_url, coordination, control_points, vulnerabilities_monitoring, scope_identifier, shuffle_url, shuffle_key})
export const setScopeMaestroSpec = (id, spec) => mutation("scope_update_maestro_spec", {id, spec})

// user
export const createUser = ({email, avatar, display_name, global_role}) => mutation("user_create", {email, avatar, display_name, global_role})
export const setUser = (id, {display_name, locked, email, avatar, settings}) => mutation("user_update", {id, display_name, locked, email, avatar, settings: JSON.stringify(settings)})
export const setUserSettings = (id, settings) => setUser(id, {settings})
export const toggleAdmin = (id) => mutation("user_toggle_admin", {id})
export const toggleSupervisor = (id) => mutation("user_toggle_supervisor", {id})
export const login = (email, password) =>   mutation("login", {email, password}, '{token}', false)
export const logout = () =>                 mutation("logout", {}, '')
export const impersonate = (user_id) => mutation("impersonate", {user_id}, '{token}')
export const setPassword = ({token, user_id, password}) => mutation("set_password", {token, user_id, password}, '{id, display_name, avatar}')
export const activate = ({token}) => mutation('activate', {token}, '{id, display_name, avatar}')
export const resendActivationEmail = (user_id) => mutation("resend_activation", {id:user_id}, '')
export const reset_password = (user_id) => mutation("reset_password", {user_id}, '')
export const requestPasswordReset = (email) => mutation("request_password_reset", {email}, '')
export const requestCaseUser = ({user_ids, id, is_add_users}) => mutation("case_update", {id, user_ids, is_add_users}, '{related_users {id, display_name}}');
export const removeCaseUser = ({user_ids, id, is_add_users}) => mutation("case_update", {id, user_ids, is_add_users}, '{related_users {id, display_name}}');


// user notifications settings

export const setNotificationsSettings = (description, settings, user_id) => mutation("notifications_settings_update", {description, settings, user_id}, '{description, settings, user_id}') 


// tags
export const tag = (taggable_type, taggable_ids, tag) => mutation('tag', {
    taggable_id:!Array.isArray(taggable_ids) ? taggable_ids : undefined,
    taggable_ids:Array.isArray(taggable_ids) ? taggable_ids : undefined,
    tag, taggable_type
}, '{id}')
export const untag = (taggable_type, taggable_id, tag) => mutation('untag', {taggable_id,taggable_type,tag}, '{id}')

export const createTag = (name, color, scope_id=null, taggable_type=null, description) => mutation("tag_create", {name, color, scope_id, taggable_type, description}, '{id}')
export const deleteTag = (id) => mutation("tag_delete", {id}, '{id}')
export const updateTag = (id, args) => mutation('tag_update', {id, ...args}, '{id}')

// comments
export const addComment = async (type, id, files, {body, visibility}) => {
    const blobKeys = []
    await Promise.all(files.map(async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('object_type', type);
        formData.append('object_id', id);
        const response = await fetch("/files", {
            method: 'POST',
            body: formData
        })
        const json = await response.json()
        blobKeys.push(json.blob_key)
    }))
    return mutation('comment_create', {type:capitalize(type), id, body, visibility, files: blobKeys})
}
export const removeComment = (id) => mutation("comment_delete", {id}, "")
export const setComment = (id, {body, visibility}) => mutation("comment_update", {id, body, visibility})
export const notifyComment = (id) => mutation("comment_notify", {id}, "")
export const markAllMessagesAsRead = (scope_id) => mutation("mark_all_messages_as_read", {scope_id}, "")


// roles
export const removeMember = (user_id, scope_id) => mutation("role_delete", {scope_id, user_id}, "")
export const setMember = (user_id, scope_id, role) => mutation("role_update", {user_id, scope_id, role}, '')
export const setMemberConfig = (user_id, scope_id, data) => mutation("role_update", {user_id, scope_id, ...data}, '')
export const setGlobalRole = (user_id, role) => mutation("global_role_update", {user_id, role}, "")


// AlertsNotifications
export const createAlertsNotification = (data) => mutation("alerts_notification_create", data)
export const deleteAlertsNotification = (id) => mutation("alerts_notification_delete", {id})
export const setAlertsNotification = (id, data) => mutation("alerts_notification_update", {id, ...data})


// related
export const addRelated = (object_type, object_id, linked_object_type, linked_object_id) => mutation("link_create", {object_type, object_id, linked_object_type, linked_object_id}, '{linked_object{id},object{id}}')
export const removeRelated = (object_type, object_id, linked_object_type, linked_object_id) => mutation("link_delete", {object_type, object_id, linked_object_type, linked_object_id}, '{linked_object{id},object{id}}')

export const addRelatedAlertGroup = (type, id, alertGroupId) => addRelated(capitalize(type), id, "AlertGroup", alertGroupId)
export const addRelatedCase = (type, id, caseId) => addRelated(capitalize(type), id, "Case", caseId)
export const addRelatedRecommendation = (type, id, recommendationId) => addRelated(capitalize(type), id, "Recommendation", recommendationId)
export const addRelatedScenario = (type, id, scenarioId) => addRelated(capitalize(type), id, "Scenario", scenarioId)
export const removeRelatedAlertGroup = (type, id, alertGroupId) => removeRelated(capitalize(type), id, "AlertGroup", alertGroupId)
export const removeRelatedCase = (type, id, caseId) => removeRelated(capitalize(type), id, "Case", caseId)
export const removeRelatedRecommendation = (type, id, recommendationId) => removeRelated(capitalize(type), id, "Recommendation", recommendationId)
export const removeRelatedScenario = (type, id, scenarioId) => removeRelated(capitalize(type), id, "Scenario", scenarioId)

// report
export const setReport = (id, {cases, is_public, summary, control_points, vulnerabilities, vulnerabilities_page_break, vulnerabilities_version, legacy_vulnerabilities, cases_page_break, coordination}) => mutation("report_update", {id, summary, control_points, vulnerabilities, cases_page_break, coordination, cases, is_public, vulnerabilities_version, legacy_vulnerabilities, vulnerabilities_page_break}, '{id}')
export const notifyReport = (id) => mutation("report_notify", {id})
export const generateReport = ({scope_id, year, month, overwrite_cases=false}) => mutation("report_create", {scope_id, year, month, overwrite_cases})

// SavedAlertsSearches

export const createSavedAlertsSearch = ({scope_id, name, user_id, search}) => mutation("saved_alerts_search_create", {scope_id, name, user_id, search})
export const deleteSavedAlertsSearch = ({id}) => mutation("saved_alerts_search_delete", {id})

// config

export const createDrilldown = (data) => mutation("drilldown_create", {data:JSON.stringify(data)}, '')
export const updateDrilldown = ({id, ...data}) => mutation("drilldown_update", {id, data:JSON.stringify(data)}, '')
export const deleteDrilldown = (id) => mutation("drilldown_delete", {id}, '')

export const assetsTest = (url) => url ? query("assets_test", {url}) : ""
export const scopeIdentifierTest = (id, scope_identifier) => scope_identifier ? query("scope_identifier_test", {id, scope_identifier}) : ""

export const getCVE = (id) => query("cve", {id})
export const createVulnerability = ({cve_id, title, description, remediation, exploitation, original}) => mutation("vulnerability_create", {cve_id, title, description, remediation, exploitation, original}, '{cve_id, id}')
export const updateVulnerability = (id, {original, exploitation, remediation, description, title, deleted}) => mutation("vulnerability_update", {id, original, deleted, exploitation, remediation, title, description}, '{cve_id, id}')
export const setScopeVulnerabilityStatus = (id, scope_id, {status, notified}) => mutation("vulnerability_set_scope_status", {id, scope_id, status, notified}, '{status, notified}')
export const setScopesVulnerabilitiesStatus = (vulnerabilities_id, scopes_id, {status, notified}) => mutation("vulnerability_set_multi_scopes_status", {vulnerabilities_id, scopes_id, status, notified}, '')
export const deleteScopeVulnerabilityStatus = (id, scope_id) => mutation("vulnerability_scope_delete", {id, scope_id}, '{status}')
export const notifyVulnerability = ({scope_id, vulnerability_id}) => mutation("vulnerability_notify", {scope_id, vulnerability_id}, '{cve_id}')

// shuffle integration

export const executeShuffleWorkflow = (id, scope_id, execution_argument) => mutation("shuffle_workflow_execute", {id, scope_id, execution_argument}, '{id}')
export const addShuffleAuth = (scope_id, user_id) => mutation("shuffle_auth_add", {scope_id, user_id}, '{success, id, errors}')

//api
export const getAllApiSession = (deprecated) => query("api_sessions", {deprecated}, '{items{name, created_at, rights, revoked, last_seen}}')
export const getDeprecatedTokens = () => query("deprecated_tokens", {}, '{count, items{payload, last_seen, controller}}') 


// JSON REST API V1

const GET = (url) => fetch(`/api/v1/${url}`)
    .then(x=>{
        if(x.status===200) return x.json()
        else return null;
    })

export const get_backups = () => GET("backups")
