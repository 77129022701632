const PLURALS = {
    "Case open": "Cases open",
    "Case closed": "Cases closed",
    "Recommendation pending": <>Recommendations<br/>&nbsp;pending</>,
    "Recommendation validated": <>Recommendations<br/>&nbsp;validated</>,
    "validated out of": "validated out of",
    "New": "New",
    "Investigating": "Investigating",
    "Open": "Open",
    "Closed": "Closed",
    "investigating": "investigating",
    "Fast closed": "Fast closed",
    "vuln:no_impact": "No impact",
    "vuln:exposed": "Exposed",
    "vuln:notified": "Notified",
    "vuln:patched": "Patched",
    "vuln:Open": "Open",
    "vuln:Closed": "Closed",
    "Vulnerability observed": "Vulnerabilities observed",
    "Vulnerability notified": "Vulnerabilities notifiée",
    "Vulnerability without impact": "Vulnerabilities without impact",
    "Vulnerability patched": "Vulnerabilities patched",
    "Case pending": "Cases pending",
}

const TRANSLATIONS = {
    "Recommendation pending": <>Recommendation<br/>&nbsp;pending</>,
    "Recommendation validated": <>Recommendation<br/>&nbsp;validated</>,
    "alert:New": "New",
    "alert:Done": "Done",
    "all": "All",
    "alert:Ignored": "Ignored",
    "resolved": "done",
    "alert:open": "investigating",
    "vuln:open": "Investigating",
    "alert:Open": "Investigating",
    "alert:New": "New",
    "waiting_for_response": "waiting for response",
    "alert:Closed": "Closed",
    "alerts:fast-closed": "Fast closed",
    "date:from": "from",
    "date:to": "to",
    "vuln:no_impact": "No impact",
    "vuln:exposed": "Exposed",
    "vuln:notified": "Notified",
    "vuln:patched": "Patched",
    "vuln:Open": "Open",
    "vuln:Closed": "Closed",
}


const plural = (text)=>PLURALS[text]||(t(text)+"s")

const t = (text, opts)=> {
    if(typeof(text)!=="string") return text
    return opts?.nb>1 ? plural(text) : TRANSLATIONS[text]||text
}

const t_fem = (text, opts)=> t(text,opts)

const t_n = (nb, text)=><>{nb || "No"} {t(text,{nb})}</>
const t_n_fem = (nb, text)=>t_n(nb,text)

const t_interval = (_from, _to) => `between ${_from} and ${_to}`

export default {t_fem,t_n,t,t_n_fem,t_interval}