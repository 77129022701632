import { watch, onUnmounted } from '@vue/runtime-core'
import { reactive } from 'vue'
import { t_interval } from '../i18n'
import { deep_merge } from '../util'
import './Histogram.scss'


// Monkey-patch Chart.js to allow custom ticks styling
Chart.Scale.prototype._wrappedResolveTickFontOptions = Chart.Scale.prototype._resolveTickFontOptions
Chart.Scale.prototype._resolveTickFontOptions = function (i) {
    const font = this._wrappedResolveTickFontOptions(i)
    const cb = this.chart?.config?.options?.scales[this.axis]?.custom_font
    const label = this.chart.config.data?.labels[i]
    deep_merge(font,cb?.(i, label)||{})
    font.string = (font.style ? font.style + ' ' : '') + (font.weight ? font.weight + ' ' : '') + font.size + 'px ' + font.family;
    return font
}
////

export const Histogram = {
    props:['data', 'type', 'stacked', 'options', 'legend', 'dates'],
    setup(props) {
        const ui = reactive({
            hover:-1
        })

        let el = null
        let chart = null

        function init(_el) {
            if(!_el) return destroy()
            if(el) return;
            el = _el

            const {data={}, type='bar', stacked=false, options={}, legend=false} = props
            
            var ctx = el.getContext("2d");
            chart = new Chart(ctx, {
                type,
                responsive:true,
                scales: {
                    x: {stacked},
                    y: {stacked},
                },
                animation:{duration:0},

                data,
             
                options:deep_merge({
                    plugins:{
                        legend:{
                            display: legend===true,
                        },
                        tooltip: {
                            mode: 'index',
                            intersect: false, 
                            callbacks: props.dates && {
                                title: x=>{
                                    const {dataIndex} = x[0]
                                    const _from = props.data.labels[dataIndex]
                                    const _to = props.data.labels[dataIndex+1]
                                    return t_interval(_from, _to).replace("et", "\net")
                                }
                            }
                        }
                    },
                    scales:{
                        x:{
                            grid:{display:false},
                            custom_font:(i, label)=>({
                                weight:label?.startsWith?.(" ") ? "bold" : "",
                                size:label?.startsWith?.(" ") ? 12 : 10,
                            })
                        },
                        y:{grid:{display:false}},
                    },
                }, options),
            });
        }

        function destroy() {
            chart?.destroy()
        }
        onUnmounted(destroy)

        watch(()=>props.data, ()=>{
            if(!chart) return
            chart.data = props.data
            chart.update('none')
        })

        return ()=>{
            return <canvas class="chart histogram" ref={init}></canvas>
        }
    }
}