import "./Rules.scss"
import { model } from "../model"
import { LiveQuery } from "../api/livequery"
import { DataTable } from "../ui/DataTable"
import { Button } from "../ui/Button"
import { Link } from "../utils/router"
import { t } from "../i18n"
import { optionsFromQueryParams, queryParams } from "../utils/queryParams"
import { link } from "../utils/routing"
import "./Whitelists.scss"
import { ICONS } from "../ui/icons"
import { ref, reactive } from "@vue/reactivity"

export const Whitelists = {
    setup() {
        const guidInput = ref(null);
        const data = reactive({
            isCopied: false,
            guidCopied: ""
        })
        const lq = LiveQuery("whitelists", `{pages, count, items{
                  whitelist_id,
                  name,
                  scope_id,
                  description,
                  git_edit_url,
                  rule_uuids, 
                  git_url,
                }}`, 
            x=>model.whitelists = x, ()=>[{
                scope_id: model.scopeId, limit:parseInt(queryParams().limit) || 20,
                ...optionsFromQueryParams(),
            }],
            {interval:30000}
        )

        function copyGuid(guid) {
            navigator.clipboard.writeText(guid);
            data.isCopied = true
            data.guidCopied = guid

            setTimeout(() => {
                data.isCopied = false
            }, 2000)
        }

        return () => {
            return <div>
                <h1>Whitelists</h1>
                <DataTable
                loading={lq.loading}
                data={model.whitelists}
                columns={[
                    {title:"ID", field:"whitelist_id", render: x=><div onClick={() => copyGuid(x.whitelist_id)} className={data.isCopied && data.guidCopied===x.whitelist_id ? "copied-color" : "guid"}>{data.isCopied && data.guidCopied===x.whitelist_id ? "Copied!" : x.whitelist_id}</div>},
                    {title:"Name/Description", field:"description", render: x=><div><Link href={link(`whitelist/${x.whitelist_id}`)}>{x.description}</Link></div>},
                    {title:"Rules", field:"rule", render: x=>
                        <li class="nav-item dropdown">
                            <span class="dropdown-toggle" id="navbarDropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {x.rule_uuids ? x.rule_uuids.length > 1 ? `${x.rule_uuids.length} ${t("Rules")}` : `${x.rule_uuids.length} ${t("Rule")}` : `${t("none")}` }
                            </span>
                            <div className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                            {x.rule_uuids.map((v) => {
                                return <li><Link href={link(`rule/${v}`)}><span id="links-rule">- {v}</span></Link></li>
                            })
                        }</div>
                    </li>
                    },
                    ...(model.scope ? [] : [{title:t("Scope"), render:ScopeItem}]),
                    {title:"Git", field:"git_url_format", render: x=><div><Link onClick={()=>{window.open(x.git_url_format, '_blank', 'noreferrer');}}>{ICONS["eye"]}</Link></div>},
                    {title:"Editer", field:"git_edit_url", render: x=><div><Link onClick={()=>{window.open(x.git_edit_url, '_blank', 'noreferrer');}}>{ICONS["edit"]}</Link></div>},
                ]}
            />
            </div>
        }
    }
}


export const ScopeItem = ({scope_id})=>
    <div class="scope-item">
        <img src={model.scopes?.find(s=>s.id===scope_id)?.logo} className={scope_id ? null : "scope-logo"}/>
        {scope_id ? <Link href={`scope/${scope_id}/whitelists` + location.search}><b>{model.scopes?.find(s=>s.id===scope_id)?.display_name}</b></Link> : <span>{`${t("Globale")}`}</span>}
    </div>