export const rand_text = () => random_slice(`Where does it come from?
                                Contrary to popular belief, 
                                Lorem Ipsum is not simply random text. 
                                It has roots in a piece of classical Latin 
                                literature from 45 BC, making it over 2000 years old. 
                                Richard McClintock, a Latin professor at Hampden-Sydney 
                                College in Virginia, looked up one of the more obscure Latin 
                                words, consectetur, from a Lorem Ipsum passage, and going through 
                                the cites of the word in classical literature, discovered the 
                                undoubtable source. Lorem Ipsum comes from sections 1.10.32 
                                and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes 
                                    of Good and Evil) by Cicero, written in 45 BC. This book 
                                    is a treatise on the theory of ethics, very popular during 
                                    the Renaissance. The first line of Lore`)

export const random_slice = (s) => s.slice(0, Math.floor(Math.random()*s.length))



export const flat = (arr) => {
    const out = []
    for(var x of arr) {
        if(Array.isArray(x)) out.push(...flat(x))
        else out.push(x)
    }
    return out
}

export const formatDate = ({timestamp, isGlobalDates=false, lang}) => {
    const day = timestamp.getDate()
    const month = timestamp.getMonth() + 1
    const year = timestamp.getFullYear()
    const hours = timestamp.getHours()
    const minutes = timestamp.getMinutes()
    
    if (lang==="en") {
        const ampm =  hours >= 12 ? 'PM' : 'AM'

        let hours12 = hours % 12
        hours12 = hours12 ? hours12 : 12

        const formattedHours = hours12 + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + ampm
        return isGlobalDates ? `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}` :
        `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year} at ${formattedHours}`
    } else {
        return isGlobalDates ? `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}` :
        `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year} à ${hours}:${minutes}`
    }

}

export const STATUS = {
  "Not started": "not-started",
  "In progress": "in-progress",
  "Finished": "finished",
}

export const clickAsEnter = ({input, id}) => {
    input.addEventListener("keyup", function(event) {
        if (event.key === "Enter") {
            document.getElementById(id).click();
        }
    })
}