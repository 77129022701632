import { t } from '../i18n';
import { queryParams, setQueryParams } from '../utils/queryParams';
import './Filter.scss'
import { ICON } from './icons';
import { Switch } from './Switch';

export const Filter = ({type, data, mergeKeys, title, render, defaultFilter=[]})=>{
    if(!data || !render) return;

    const merged = {...data}
    if(mergeKeys) {
        for(const k in mergeKeys) {
            merged[mergeKeys[k]] = (merged[mergeKeys[k]]||0) + (merged[k]||0)
            delete merged[k]
        }
    }

    return <div class="filter">
        <h1>{t(title)}</h1>
        {Object.keys(merged).map(k=>{
            const filter = queryParams()[type]?.split(",") || defaultFilter
            const show = filter.includes(k)

            function toggle () {
                let key = [k]
                if(mergeKeys) {
                    Object.entries(mergeKeys).forEach(([_from,_to])=>{
                        if(_to===k) key.push(_from)
                    })
                }
                const q = {}
                q[type] = (show ? filter.filter(x=>!key.includes(x)) : [...filter, ...key]).join(",")
                q.page = 1
                setQueryParams(q)
            }

            const [icon,title] = render(k) || []
            // console.log(icon)
            // console.log(title)
            return <div>
                    <b>{icon} {merged[k]}</b>{t(title,{nb:merged[k]})}
                    <Switch value={show} disabled={show && filter.length<=1} onClick={toggle}/>
                </div>
        })}
    </div>
}
