import { reactive } from "@vue/reactivity"
import { watchEffect, watch } from "@vue/runtime-core"
import { t, t_fem } from "../i18n"
import { model, API, LQ } from "../model"
import { Accordion, AccordionField, AccordionItem } from "../ui/Accordion"
import { DataTable } from "../ui/DataTable"
import { Box } from "../ui/Grid"
import { H2 } from "../ui/H"
import { ICON } from "../ui/icons"
import { Related } from "../ui/Related"
import { Severity } from "../ui/Severity"
import { Sidebar } from "../ui/Sidebar"
import { Spinner } from "../ui/Spinner"
import {capitalize, debounce, first_paragraph, flat_keys, has_flat_keys} from "../util"
import { download } from "../utils/download"
import { JSONView } from "../ui/JSONView"
import  AlertTableView  from "../ui/AlertTableView.vue"
import { Drilldown } from "../ui/Drilldown"
import {Button, DeleteButton, IconButton} from "../ui/Button"
import { gotoScope, link } from "../utils/routing"
import { Date as UIDate } from "../ui/Date"
import {Tab, Tabs} from "../ui/Tabs"
import { TagsMixin } from "../mixins/Tags"
import { LiveQuery } from "../api/livequery"
import { optionsFromQueryParams, queryParams, setQueryParams } from "../utils/queryParams"
import { convert_histogram, D } from "../ui/dates"
import { getRouter, Link } from "../utils/router"
import { Histogram } from "../ui/Histogram"
import { Badge } from "../ui/Badge"
import { DropdownCombo } from "../ui/DropdownCombo"
import { modal, question } from "../ui/Modal"
import { Dialog } from "../ui/Dialog"
import { md } from "../ui/Markdown"
import { InProgressIcon } from "../ui/InProgressIcon"
import { CaseStatus, Qualification } from "./Cases"
import "./Alert.scss"
import { useCurrentUserStore } from "../store/CurrentUserStore"

const userStore = useCurrentUserStore

export const Alert = {
    setup() { 
        const data = reactive({
            cases:[],
            siblings:{},
        })

        const {Tags} = TagsMixin("AlertGroup")
        
        const lq_alerts = LiveQuery("alerts", `{count, pages, items{
            message, id, detection_date, source, destination, original
        }}`, x=>data.alerts = x, ()=>model.alertGroupId && [{
            limit:parseInt(queryParams().limit) || 20,
            ...optionsFromQueryParams(),
            alert_group_id: model.alertGroupId,
        }, {alert_group_id:model.alertGroupId}], 
        {throttle: 5000}
        )

        const lq_histogram = LiveQuery("alert_group", `{alerts_histogram, aggregated_fields, rule {alert_sections}}`, 
            d=>{
                if(!d) return data.histogram = null
                data.histogram_data = convert_histogram(d.alerts_histogram)
                data.aggregated_fields = d.aggregated_fields
                data.alert_sections = d.rule.alert_sections
            },
            ()=>model.alertGroupId && [{id:model.alertGroupId, scope_id:model.scopeId}]
        )
            
        function refresh() {
            LQ.alert_group?.refresh()
            lq_histogram?.refresh()
        }

        const printRules = (x) =>{
            if(Array.isArray(x.value)){
                return <ul>{x.value.map(x=>{
                 x.name == 'id' ? <li><Link href={link(`rule/${x}`)}>{x}</Link></li> : <li>{x}</li>
                })}</ul>
            } else {
                return x.name == 'id' ? <Link href={link(`rule/${x.value}`)}>{x.value}</Link> : x.value
            }
        }

        async function detach() {
            let reopen = false
            if(model.alertGroup.conclusion_code) {
                const res = await question(
                    t("Reopen alert ?"), 
                    <>
                        <p><b>{t("Yes")}</b>: {t("Alert will be reopened and marked as 'new'")}</p>
                        <p><b>{t("No")}</b>: {t("Alert will keep its qualification and will be marked as 'fast-closed'")}</p>
                    </>,
                    {yes:t("Yes"), no:t("No")}) 
                if(!res) return
                reopen = (res == "yes")
            }
            await API.setAlertGroup(model.alertGroupId, {case_id:null, reopen})
            refresh()
        }

        async function reopen() {
            await API.setAlertGroup(model.alertGroupId, {status:"open"})
            refresh()
        }

        // Highlight the currently selected alert in the histogram
        watchEffect(()=>{
            const [x=[],y=[],bins] = data.histogram_data || []
            const detection_date = new Date(model.alert?.detection_date)
            const iHighlight = bins?.findIndex(d=>detection_date < d)-1
            data.histogram = {
                labels:x,
                datasets: [{
                    backgroundColor: x.map((x,i)=>i===iHighlight ? "red" : "#ffce00"),
                    data:y,
                }],
            }
        })

        function setMessage(message) { API.setAlertGroup(model.alertGroupId, {message}) }
        function setSeverity(severity) { API.setAlertGroup(model.alertGroupId, {severity})}


        return ()=>{
            if(!model.alertGroup) return <Spinner/>
            const {id, message, rule_description, conclusion_code, severity, first_detection, last_detection, alerts_count, scope_id} = model.alertGroup
            const {original} = data.alerts?.items?.[0] || {}
            const rule = original?.rule || data.aggregated_fields?.rule
            const formatRule = {...rule}
            const finalRule = Object.entries(formatRule).slice(0, 3)

            const RW = userStore.hasPermissions("alerts", "write_private")

            const SEVERITIES = ['INFO', 'LOW', 'MEDIUM', 'HIGH', 'CRITICAL'].map(t_fem)

            return <div id="alert" class="page with-sidebar">
            <div>
                <div class="flex-row">
                    <div class="flex">
                        <h1>{ICON("alert")} {message} {alerts_count>1 && <Badge>{ICON("alert")} {alerts_count}</Badge>}</h1>
                        {/* <H2 class="title" onSave={RW && setMessage} value={message}/> */}
                    </div>
                    <AlertStatus vertical alert={model.alertGroup} detach={detach} reopen={reopen} onChange={refresh} />
                </div>

                <Histogram stacked dates data={data.histogram}/>

                <div>
                <Tabs>
                    <Tab active={!model.alertId && queryParams().view!=="alerts"} label={t("Alert group")} onClick={()=>gotoScope(`/alert/${model.alertGroupId}/?view=group`)}/>
                    <Tab active={model.alertId || queryParams().view==="alerts"} label={t("Aggregated alerts")} onClick={()=>gotoScope(`/alert/${model.alertGroupId}/?view=alerts`)}/>
                </Tabs>


                    {model.alertId && <AlertDetails/>}

                    <br/>


                {queryParams().view!=="alerts" && !model.alertId && data.aggregated_fields && <>
                    <br/>
                    <Tabs>
                        <Tab label='Table'><AlertTableView source={data.aggregated_fields} prioritySections={data.alert_sections} drilldown={true}/></Tab>
                        <Tab label='JSON'><JSONView source={data.aggregated_fields}/></Tab>
                    </Tabs>
                </>}

                {queryParams().view==="alerts" && <DataTable 
                    title={t("Aggregated alerts")}
                    loading={lq_alerts.loading} 
                    data={data.alerts}
                    columns={[
                        {title:t("Detection date"), render:x=>D(x.detection_date)},
                        {title:t("ID"), type:'id', render:x=><Link href={link(`/scope/${scope_id}/alert/${id}/${x.id}`)}>
                            {x.id}
                        </Link>},
                        {title:t("Message"), render:x=><Link href={link(`/scope/${scope_id}/alert/${id}/${x.id}`)}>
                            {x.message}
                        </Link>},
                        {title:t("Source"), render:x=>x.source},
                        {title:t("Destination"), render:x=>x.destination},
                    ]}
                />}

                </div>

            </div>
                    
            <Sidebar>
                <Accordion>

                    <AccordionField class='center alert-severity' title={t("Severity")}>
                        <Severity val={severity} onChange={RW && setSeverity}/> 
                        <h2 class={["silent", "low", "medium", "high", "critical"][severity]}>{SEVERITIES[severity] || severity}</h2>
                    </AccordionField>
                    
                    <AccordionField title={t("First detection")}><div><UIDate value={first_detection}/></div></AccordionField>
                    <AccordionField title={t("Last detection")}><div><UIDate value={last_detection}/></div></AccordionField>

                    {model.alertGroup.case && <AccordionField class="center" title={t("Investigation")}>
                        <div>
                            <Link href={link(`/scope/${scope_id}/case/${model.alertGroup.case.id}`)}>
                                {ICON("case")} {model.alertGroup.case.title}
                            </Link>
                        </div>
                        {RW && <DeleteButton onClick={()=>detach()} title={t("Detach")}/>}
                    </AccordionField>}

                    {conclusion_code && <><AccordionField class="center" title={t("Qualification")}>
                        {Qualification(conclusion_code)}
                        {RW && <DeleteButton onClick={()=>reopen()} title={t("Reopen")}/>}
                    </AccordionField>
                    <br/>
                    </>}

                    
                    {(RW||model.alertGroup.tags) && <AccordionField title={t("Tags")}>{Tags(model.alertGroup)}</AccordionField>}

                    {!!rule && <AccordionItem title={t("Rule")}>
                    <DataTable class="fields" noHeader
                        data={[finalRule[0], finalRule[2]].map(([k,v])=>({name:k,value:v}))}
                        columns={[
                            {render:x=><b>{t(x.name)}</b>},
                            {render:x=>printRules(x) 
                            },
                        ]}
                    />
                </AccordionItem>}


                    {!!model.alertId && data.alerts && <Related type='alert' title="Aggregated alerts" items={data.alerts}
                        update={debounce(x=>setQueryParams({search:x.search}))}
                        columns={[
                            {title:t("Detection date"), render:x=>D(x.detection_date, "short")},
                            {title:t("Message"), render:x=>
                                <Link href={link(`/scope/${model.alertGroup.scope_id}/alert/${model.alertGroupId}/${x.id}`)}>
                                {/* {x.id===model.alertId && <i class="fas fa-caret-right"/>} */}
                                {x.message}
                                </Link>
                            },
                        ]}
                        rowClass={x=>({selected:x.id===model.alertId})}
                    />
                    }

                </Accordion>
            </Sidebar>
        </div>
        }
    }
}

const AlertDetails = () => {
    const { rule } = model.alertGroup
    const RW = userStore.hasPermissions("alerts", "write_private")
    const RO = userStore.hasPermissions("alerts", "read") && !RW
    if(!model.alert) return <Spinner/>
    const {original, message, prev, next, rank, nb_siblings} = model.alert
    const event = original?.event?.original
    return <div id="alert-details">
        <h4 class='nav'>
            <Link href={link(`/scope/${model.alertGroup.scope_id}/alert/${model.alertGroupId}/${prev?.id}`)} class={{disabled:!prev}}><i class="fas fa-arrow-alt-circle-left"/>{t("Previous alert")}</Link>
            <div>{message}</div>
            <i>{rank}/{nb_siblings+1}</i>
            <Link href={link(`/scope/${model.alertGroup.scope_id}/alert/${model.alertGroupId}/${next?.id}`)} class={{disabled:!next}}>{t("Next alert")}<i class="fas fa-arrow-alt-circle-right"/></Link>
            <Link href={link(`/scope/${model.alertGroup.scope_id}/alert/${model.alertGroupId}?view=alerts`)}><i class="fas fa-undo-alt"/>{t("See all")}</Link>
        </h4>

        {(RW || RO) && <>
            <br/>
            
            {event && <>
                <h4>{t("Triggering event")}</h4>
                <Tabs buttons={[<>
                    <IconButton title={t("Download as CSV")}
                                onClick={() => download(event, "csv", `alert_${model.alertId}_event`)}>
                        {ICON('csv')}
                    </IconButton>

                    <IconButton title={t("Download as JSON")}
                                onClick={() => download(event, "json", `alert_${model.alertId}_event`)}>
                        {ICON('download')}
                    </IconButton>
                </>
                ]}>
                    <Tab label='Table'><AlertTableView source={event} prioritySections={rule.alert_sections} drilldown={true}/></Tab>
                    <Tab label='JSON'><JSONView source={event}/></Tab>
                </Tabs>
            </>}
            
            {original && <>
                <h4>{t("Raw alert")}</h4>
                <Tabs buttons={[<>
                    <IconButton title={t("Download as CSV")}
                                onClick={() => download(original, "csv", `alert_${model.alertId}`)}>
                        {ICON('csv')}
                    </IconButton>


                    <IconButton title={t("Download as JSON")}
                                onClick={() => download(original, "json", `alert_${model.alertId}`)}>
                        {ICON('download')}
                    </IconButton>
                    
                    </>]}>
                    <Tab label='Table'><AlertTableView source={original} prioritySections={rule.alert_sections} drilldown={true}/></Tab>
                    <Tab label='JSON'><JSONView source={original}/></Tab>
                </Tabs>
            </>}
        </>}
    </div>
}


// Fields

function process_fields(fields) {
    if(typeof(fields)==="string" && fields.trim()) fields = JSON.parse(fields)

    if(!fields) return [];

    delete fields["rule"]
    delete fields["fields"]
    delete fields["event"]
    delete fields["alert"]
    delete fields["tags"]


    // Handle flat keys format
    if(has_flat_keys(fields)) {
        var out = {}
        for(var k in fields) {
            var [key,rest] = k.split(".", 2)
            if(!out[key])  out[key] = {}
            out[key][rest] = fields[k]
        }
        return Object.entries(out)
    }
    // Handle nested keys format
    else {
        return Object.entries(fields).filter(([k,v])=>typeof(v)!=="string").map(([k,v])=>[k,flat_keys(v)])
    }
}



export const AlertStatus = {
    props:["alert", "onChange", "detach", "reopen", "vertical", "horizontal"], 
    setup(props) {
        const RW = userStore.hasPermissions("alerts", "write_private")

        const data = reactive({conclusion_codes:[]})

        async function attach(case_id) {
            await API.setAlertGroup(props.alert.id, {case_id, keep_conclusion:true})
            props.onChange?.()
        }

        const queryCases = async filter => {
            const {page, search=""} = filter || {}
            return await API.getCases({scope_id:props.alert.scope_id, limit:10, order:"desc", sort:"last_activity", page, search})
        }

        async function chooseInvestigation() {
            const data = reactive({cases: await queryCases()})
            const res = await modal(({close})=><Dialog class="choose-investigation"
                ref={el=>el && setImmediate(()=>$(el).addClass("show"))}
                close={close} onSubmit={()=>close(data)}
                header={t("Attach to existing investigation")}
                body={<DataTable
                    onClick={e=>{e.stopPropagation(); e.preventDefault();}} 
                    update={async (f)=>data.cases = await queryCases(f)}
                    data={data.cases}
                    columns={[
                        {title:t('First activity'), type:'date', render:x=>x.created_at},
                        {title:t('Last activity'), type:'date', render:x=>x.last_activity},
                        {title:"Description", type:'description', render:(x)=><div class='description'>
                            <div>{x.title}</div>
                            <div v-html={md.render(first_paragraph(x.body))}/>
                            {!!x.conclusion && <div class='conclusion'>{ICON("drilldown")} <span v-html={md.render(first_paragraph(x.conclusion))}/></div>}
                        </div>},
                        {title:t("Qualification"), render:x=><CaseStatus case={x}/>},
                    ]}
                    onRowClick={(x)=>close(x.id)}
                />}
            />)
            if(!res) return
            await attach(res)
        }

        async function setQualification(c) {
            await API.setAlertGroup(props.alert.id, {conclusion_code: c.code})
            props.onChange?.()
        }

        async function createInvestigation() {
            gotoScope(`newcase?type=investigation&attach_alerts=${props.alert.id}`); 
        }
        
        async function fetchConclusionCodes() {
            let conclusion_codes = await API.getConclusionCodes()
            if(!props.alert.case) {
                conclusion_codes = conclusion_codes.filter(c=>c.fast_close)
            }
            data.conclusion_codes = conclusion_codes
        }

        return ()=>{
            const {alert, reopen, detach, vertical, horizontal} = props
            const {status, conclusion_code} = alert    
            const Status = conclusion_code ? Qualification(conclusion_code) 
                           : alert.case ? <><InProgressIcon/><span>{t("alert:open")}</span></> 
                           : <>{ICON("star")}<span>{t_fem("New")}</span></>
            if(!RW) return Status
            return <DropdownCombo menuClass="status"
                    class="status"
                    class={{vertical,horizontal}}
                    class={status}
                    onOpen={fetchConclusionCodes}
                    button={Status} 
                    topActions={<>
                        {status!=="new" && (alert.case ? 
                            <Button onClick={detach}><i class="fas fa-unlink"/> {t("Detach")}</Button> : 
                            <Button onClick={reopen}>{ICON("star")} {t("Reopen")}</Button>
                        )}
                        {status!=="open" && <Button onClick={createInvestigation}>{ICON("case")} {ICON("add")} {t("Create investigation")}</Button>}
                        {status==="open" && <Button onClick={chooseInvestigation}>{ICON("case")} {t("Change investigation")}</Button>}
                        {status!=="open" && <Button onClick={chooseInvestigation}>{ICON("case")} {t("Attach to existing investigation")}</Button>}
                        <span class="separator">{t("Fast close")}</span>
                    </>}
                    items={filter=>data.conclusion_codes.filter(c=>`${c.code} ${c.name}`.toLowerCase().includes(filter.toLowerCase()))}    
                    render={Qualification}
                    onSelect={setQualification}
                />
        }
    }
}