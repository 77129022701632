import './Badge.scss'
import { IconButton } from './Button'
import { ICON } from './icons'
import { linkify } from '../utils/router'

export const Badge = ({title,icon,onDelete,href,color},{slots}) => linkify(
    <span title={title} class="badge" style={color && {background:color}}>
        {icon}
        {slots?.default?.()}
        {onDelete && <IconButton onClick={(e)=>{onDelete?.(); e.stopPropagation();}}>{ICON("delete")}</IconButton>}
    </span>
    ,href)
