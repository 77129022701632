import { reactive, onUpdated } from 'vue'
import { API, model } from '../model'
import { t } from '../i18n'
import { NewMessagesBoundary } from './NewMessagesBoundary'
import { Avatar } from './Avatar'
import { Badge } from './Badge'
import {  DeleteButton, FileButton, IconButton} from './Button'
import { D } from './dates'
import { Dropdown, DropdownItem } from './Dropdown'
import { Editor } from './Editor'
import { ICON } from './icons'
import { Markdown } from './Markdown'
import './Timeline.scss'
import { TopBanner } from './TopBanner'
import { Spinner } from './Spinner'
import { useCurrentUserStore } from '../store/CurrentUserStore'

export const Timeline = {
    props:["editable", "alert", "items", "onSend", "visibilities", "setVisibility", "onSave", "allowed_tags", "buttons", "onDelete", "fetchUsers", "fetchObjects", "onDeleteObject","previousSelector","is_public"],
    mounted(){
        if($(".timeline .new-messages-boundary")[0]){
            $(".timeline .new-messages-boundary").last()[0]?.scrollIntoView({block: "center"})
        }else{
            $(".timeline .message").last()[0]?.scrollIntoView()
        }
    },
    setup(props) {
        let editor;
        let nb=0;
        let scrollToNewMessage = false;
        let isFirstNewMessage = 0;

        const data = reactive({
            showArrow: false,
            showBanner: false,
            dirty:false,
            editing:false,
            visibility:props.visibilities?.[0],
            files:[],
            content:"",
            notified_users:[],
        })
        
        const userStore = useCurrentUserStore

        onUpdated(() => {
            handleScroll()
        })


        function getNotifiedUsers() {
            props.items.length > 0 && API.getNotifiedUsers(props.items[0].id, 'notify_comment')
            .then(x=>{
                data.notified_users = <>{x.map((u)=><span>{u.display_name}<br/></span>)}</>
           })
        }

        function send(e) {
            e.preventDefault();
            e.stopPropagation();
            const body = data.content
            if(!body) return;
            props?.onSend?.(body, data.visibility, data.files)
            editor.setData("")
            data.files = []
            scrollToNewMessage = true
            
        }

        function onNewMessage() {
            if(scrollToNewMessage) $(".timeline .message").last()[0]?.scrollIntoView()
            if($(".timeline .message:last-of-type").children()[0]?.classList[0] === "new-messages-boundary"){
                isFirstNewMessage = 0
            }
            getNotifiedUsers()
        }

        function checkNewMessage() {
            if(props.items?.length > nb) {
                setImmediate(onNewMessage())
            }
            nb = props.items?.length||0
        }

        function onChange(x) {
            data.dirty = !!x
            data.content = x
        }

        function handleScroll(){
            let timelineTop = $(".timeline")[0]?.offsetTop
            let lastMsg = $(".timeline .message").last()[0]
            let firstUnread = $(".timeline .new-messages-boundary")
            let docViewTop = $(window).scrollTop();
            let docViewBottom = docViewTop + $(window).height();
            let elemTop = lastMsg?.offsetTop
            let elemBottom = elemTop + $(lastMsg)?.height() + timelineTop;
            
            if(firstUnread[0]){
                let unreadTop = firstUnread.offset().top
                data.showBanner = (docViewTop>=unreadTop - 190);
            }

            data.showArrow = !(elemBottom <= docViewBottom) && (elemTop >= docViewTop);
        }
        
        function jumpToUnread() {
            $(".timeline .new-messages-boundary")[0]?.scrollIntoView({block: "center"})
        }

        function jumpToLast() {
            $(".timeline .message").last()?.[0]?.scrollIntoView({block: "center"})
            data.hideBanner = true
        }

        const notify = ({id}) => API.notifyComment(id)

        return ()=>{
            const {editable, is_public} = props
            is_public && data.notified_users === [] && getNotifiedUsers()
            window.addEventListener('scroll', handleScroll);
            function buttons(x) {
                if(!props.buttons) return;
                if(typeof(props.buttons)==="function") return props.buttons(x)
                return props.buttons
            }
            const canEdit = (comment) => editable || comment.author.id===userStore.user.id
            const isSOC = (comment) => comment.author.email.endsWith("ct-square.com")
            if(!model.members || !model.scope) return <Spinner/>
            return <>
                {!data.hideBanner && <TopBanner onClick={jumpToLast}>{t("Jump to last messages")}</TopBanner>}
                <div id="timeline" class="timeline"  ref="boundary">
                <IconButton class="goDown" style={{"display": data.showArrow ? "" : "none"}} 
                            onClick={() => {
                                data.showArrow = false;
                                $(".timeline .message").last()[0]?.scrollIntoView();
                                }}>{ICON("down")}</IconButton>
                {props.items?.map(x=>{
                    if(isFirstNewMessage === 0 && x.author.id !== userStore.user.id && !x.seen){
                        isFirstNewMessage = x.id
                    }
                    return <>
                        {/* {isFirstNewMessage === x.id && <NewMessagesBoundary/>} */}
                        <div class="message" class={[x.visibility && "visibility-"+x.visibility]}>
                        
                            <div class='content'>
                            <Avatar user={x.author}/>
                            <span class="scope">{
                                isSOC(x) ? <Badge color="#ffc912">CT-square</Badge> 
                                : <Badge color="#170748">{model.scope.display_name}</Badge>}
                            </span>
                            <span class="author">{x.author.id === userStore.user.id ? t("You") : x.author.display_name}</span>
                            <i class="sep"/>
                            <span class="date">{D(x.created_at)}</span>
                            {+new Date(x.created_at) !== +new Date(x.updated_at) && <span class="date">({t('edited')} {D(x.updated_at, "elapsed")})</span>}

                            <div class='controls'>
                                {props.visibilities && canEdit(x) &&
                                <Dropdown title="Visibility" button={<span class='visibility'>{ICON("eye")} {x.visibility!=="waiting_for_response" && t(x.visibility)}</span>} items={()=>
                                    props.visibilities.map(v=><DropdownItem onClick={()=>props.setVisibility?.(x, v)}>{v===x.visibility && ICON("ok")} {t(v)}</DropdownItem>)
                                }/>}
                                {canEdit(x) && props.onSave && <IconButton onClick={()=>data.editing=x}>{ICON('edit')}</IconButton>}
                                {canEdit(x) && props.onDelete && <IconButton onClick={()=>props.onDelete(x)}>{ICON('trash')}</IconButton>}
                                {canEdit(x) && is_public && ["public", "waiting_for_response"].includes(x.visibility) && <IconButton title={data.notified_users} class="notify" class={{notified:x.notified}} onClick={()=>notify(x)}>
                                    {ICON('mail')}
                                    {x.notified && ICON('done')}
                                </IconButton>}
                                {buttons(x)}
                            </div>
                            <br/>
                            </div>
                            <Markdown 
                                editing={data.editing===x}
                                source={x.body}
                                onSave={body=>{props.onSave?.(x, body); data.editing=false}}
                                onCancel={()=>data.editing=false}
                                fetchUsers={props.fetchUsers}
                                fetchObjects={props.fetchObjects}
                                />
                            {x.files?.map(f=><a class='download-file' href={`/files/${f.url}`} download>{ICON('attach')} {f.filename}</a>)}
                            {x.objects?.map(o=><div class="attached">
                                {o.icon || ICON(o.objType)} <span class="date">{D(o.created_at, 'short')}</span> {o.title}
                                {editable && props.onDeleteObject && <DeleteButton onClick={()=>props.onDeleteObject(x,o)}/>}
                            </div>)}
                            {(x.visibility === "waiting_for_response") && <p class="annotation">
                                {t("waiting for response ...")}
                                {isSOC && userStore.hasPermissions("comments", "write_private") && <IconButton class="ack" title="Acknowledge" onClick={()=>props.setVisibility?.(x, "public")}>{ICON("ok")}</IconButton>} 
                            </p>}
                        </div>                        
                    </>
                })}

                <form onSubmit={send} id="post-message" ref={checkNewMessage}>
                    <Editor onEditorInit={e=>editor=e}
                        class="toolbar-hover"
                        placeholder="Ajouter un message..." 
                        onInput={onChange}
                        fetchUsers={props.fetchUsers}
                        fetchObjects={props.fetchObjects}
                    />
                    <IconButton class="send" disabled={!data.dirty} onClick={send}>{ICON("send")}</IconButton>

                    <FileButton onChange={(e)=> data.files = Array.from(e.target.files)} class='attach'/>

                    {props.visibilities &&
                    <Dropdown title="Visibility" button={<span class='visibility'>{ICON("eye")} {data.visibility !== props.visibilities[0] && t(data.visibility)}</span>} items={()=>
                        props.visibilities.map(v=><DropdownItem onClick={()=>data.visibility = v}>{v===data.visibility && ICON("ok")} {t(v)}</DropdownItem>)
                    }/>}
                    {data.files?.length>0 && <div class='files-to-upload'>
                        {data.files.map((file) => <p>{file.name}</p>)}
                    </div>}
                </form>
            </div>
            </>
        }
    },

}
