import { reactive } from "@vue/reactivity"
import { ref } from '@vue/runtime-core'
import { LiveQuery } from "../api/livequery"
import { t } from "../i18n"
import { API, model } from "../model"
import { Accordion, AccordionItem } from "../ui/Accordion"
import { AddButton } from "../ui/AddButton"
import {FileButton, IconButton} from "../ui/Button"
import { DataTable } from "../ui/DataTable"
import { Grid, Box } from '../ui/Grid'
import { ICON } from "../ui/icons"
import { Markdown } from "../ui/Markdown"
import { Sidebar } from "../ui/Sidebar"
import { Spinner } from "../ui/Spinner"
import { getMonth } from "../util"
import { Link } from "../utils/router"
import { scopeLink } from "../utils/routing"
import './Documents.scss'
import { generateReport } from "./MonthlyReport"
import {FilesManager} from "../ui/FilesManager";
import {Upload} from "../ui/Upload";
import {Dropdown, DropdownItem} from "../ui/Dropdown";
import {Switch} from "../ui/Switch";
import { useCurrentUserStore } from "../store/CurrentUserStore"

const userStore = useCurrentUserStore

export const Documents = {
    setup() {
        const data = reactive({})

        LiveQuery("reports", "{id, year, month, scope_id, is_public}",
            i=>model.reports=i?.map(({id,is_public,month,year})=>({id: id,is_public:is_public,month:(""+month).padStart(2,"0"),year:""+year})),
            ()=>!!model.scopeId && [{
                scope_id:model.scopeId,
            }]
        )

        LiveQuery("scope", "{support, checklist, coordination, control_points, vulnerabilities_monitoring," +
            "files {filename, url}" +
            "cotechs {filename, url}}",
            x=>Object.assign(data, x),
            ()=>!!model.scopeId && [{id:model.scopeId}]
        )

        const setCoordination = coordination => API.setScope(model.scopeId, {coordination})
        const setControlPoints = control_points => API.setScope(model.scopeId, {control_points})
        const setSupport = support => API.setScope(model.scopeId, {support})
        const setChecklist = checklist => API.setScope(model.scopeId, {checklist})
        const setVulnerabilitiesMonitoring = vulnerabilities_monitoring => API.setScope(model.scopeId, {vulnerabilities_monitoring})

        const deleteFile = async (file) => {
            if (confirm("You are about to delete " + file.filename.split(".pdf")[0] + " !\nAre you sure ?")) {
                const formData = new FormData();
                formData.append('file', file.url);
                await fetch("/files", {
                    method: 'DELETE',
                    body: formData
                })
            }
        }

        function togglePublic(x) {API.setReport(x.id, {is_public:!x.is_public})}

        const cotechInput = ref()

        return ()=>{
            const {scope} = model
            if(!scope) return <Spinner/>

            const RW = userStore.hasPermissions("reports", "write_private")

            return <div class="with-sidebar" id="documents">
                <div>
                    <h1>{t("Tracking")}</h1>
                    <Grid>
                    <Box w='12' h='1'>
                            <h2>{ICON('support')} Support</h2>
                            <Markdown 
                                editable={RW}
                                source={data.support} 
                                onSave={setSupport} 
                            />
                        </Box>
                    <Box w='6' h='1'>
                        <h2>{ICON('coordination')} Coordination</h2>
                        <Markdown 
                            editable={RW}
                            source={data.coordination} 
                            onSave={setCoordination} 
                        />
                    </Box>
                    <Box w='6'>
                        <h2>{ICON("control")} Points de contrôle</h2>
                        <Markdown 
                            editable={RW}
                            source={data.control_points} 
                            onSave={setControlPoints}
                        />
                    </Box>
                    <Box w='12'>
                        <h2>{ICON("ok")} Checklist</h2>
                        <Markdown 
                            editable={RW}
                            source={data.checklist} 
                            onSave={setChecklist}
                        />
                    </Box>
                    <Box w='12'>
                        <h2>{ICON("ok")} Veille sur les vulnérabilités</h2>
                        <Markdown 
                            editable={RW}
                            source={data.vulnerabilities_monitoring} 
                            onSave={setVulnerabilitiesMonitoring}
                        />
                    </Box>
                    </Grid>
                </div>

                <Sidebar>
                    <Accordion>
                        <AccordionItem class="reports" icon={ICON("documents")} title={t("Monthly reports")}>
                                <DataTable noHeader
                                data={model.reports||[]}
                                columns={[
                                    {title:"Name",render:x=><>
                                        <Link href={scopeLink(`reports/${x.year}/${x.month}`)}>
                                            {ICON("chart")} {t(getMonth(x.month))} {x.year}
                                        </Link>
                                        {RW && <div>
                                            <Switch value={x.is_public} onClick={()=>togglePublic(x)}>{t("public")}</Switch>
                                            <IconButton onClick={()=>generateReport(x)}>{ICON("reset")}</IconButton>
                                        </div>}
                                    </>
                                    }
                                ]}
                                rowClass={x=>!x.is_public && "private"}
                                />
                            {RW && <div class="generate-report"><AddButton onClick={generateReport}>{t("Generate new report")}</AddButton></div>}
                        </AccordionItem>

                        <AccordionItem class="reports" icon={ICON("documents")} title={t("Cotech reports")}>
                            <DataTable noHeader
                                       data={data.cotechs||[]}
                                       columns={[
                                           {title:"Name",render:x=><>
                                                   <a href={`/files/${x.url}`} download>
                                                   {ICON("document")} {x.filename.split(".pdf")[0]}
                                                   </a>
                                                   {RW && <IconButton onClick={()=>deleteFile(x)}>{ICON("minus")}</IconButton>}
                                               </>
                                           }
                                       ]}
                            />
                            {RW && <div class="generate-report">
                                <input placeholder={t("Filename")} value={data.cotech_name}
                                       onChange={(x) => {
                                           data.cotech = new File([data.cotech], x.target.value)
                                           data.cotech_name = x.target.value
                                       }}
                                />
                                <FileButton icon={ICON("plus")} ref={cotechInput} onChange={(x) => {
                                    data.cotech = x.target.files[0]
                                    if (!data.cotech_name) data.cotech_name = x.target.files[0].name.split(".pdf")[0]
                                }}/>
                                {data.cotech && <Upload files={[data.cotech]}
                                                        params={[{"scope_id": model.scopeId}, {"kind": "cotech"}]}
                                                        afterUpload={() => {
                                                            data.cotech = undefined
                                                            data.cotech_name = undefined
                                                        }}
                                />}
                            </div>
                            }
                        </AccordionItem>
                        
                        {/* {RW && 
                        <AccordionItem icon={ICON("playbook")} title="Playbooks">
                                <DataTable noHeader
                                data={["Investigation scans de ports", "Investigation SQLi", "Investigation RDP externe", "Investigation authent Office 365"]}
                                columns={[{title:"Name",render:x=><>{ICON("playbook-light")}</>}]}
                            />
                        </AccordionItem>} */}
                    </Accordion>
                </Sidebar>
            </div>
        }
    }
}

