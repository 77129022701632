import "./RuleInfos.scss"

export const RuleInfos = ({object}) => {
    const displayList = (list) => {
        return Object.entries(list).map((v) => {
            if (typeof v==='object') {
                return <pre><span className="sub-title" id="list-indent">{v[0]}:</span> {v[1]}</pre>;
            }
            return <pre id="list">{v[1]}</pre>;
        })
    }
    
    const displayValues = (elements) => {
        if (typeof elements[1]!=='object') {
            return <pre id="process" className="title"><span>{elements[1]}: </span></pre>
        }
        return Object.entries(elements[1]).map((v) => {
            if (typeof v[1]==='object') {
                return <div className="title">
                        <span>{v[0].match(/^\d+$/) ? null : `${v[0]}:`}</span> 
                        <ul id="key">{displayList(v[1])}</ul>
                    </div> 
            } else  {
                return <pre className="sub-title title"><span>{v[0]}: </span>{v[1]}</pre>
            }
        })
    }

    const displayDetails = (values) => {
        return Object.entries(values).map((element) => {
            if (typeof element[1]!=="object") {
                return <pre id="selection" className="title"><span>{element[1]}: </span></pre>
            } else {
                return displayValues(element)
            }
        })
    }

    const displayObject = (v) => {
        return Object.entries(v).map((v) => {
            return typeof v[1]==="object" ? displayDetails(v) : <pre className="sub-title title"><span>{v[0]}: </span>{v[1]}</pre>
        })
    }
    if (Array.isArray(object)) {
        return object.map((v) => {
            {return typeof v==="object" ? displayObject(v) : <li>{v}</li>}
        })
    } else  {
        return <code className="yaml-detection">
            {Object.entries(object).map((v) => {
                if (typeof v[1]==="object") {
                    return displayDetails(v)
                } else {
                    return typeof v[1]==="object" ? <pre className="title"><span>{v[0]}: </span>{displayDetails(v[1])}</pre> : <pre className="title"><span>{v[0]}: </span>{v[1]}</pre>
                }
            })}
        </code>
    }
}



