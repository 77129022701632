import { model, API } from "../model"
import { DataTable } from "../ui/DataTable"
import { optionsFromQueryParams, queryParams, setQueryParams } from "../utils/queryParams"
import { t } from "../i18n"
import { LiveQuery } from "../api/livequery"
import { ICON } from "../ui/icons"
import './Recommendations.scss'
import { createTagDialog, Tags } from "../ui/Tags"
import { Severity } from "../ui/Severity"
import {gotoScope} from "../utils/routing"
import {Link} from "../utils/router"
import {link} from "../utils/routing"
import { AddButton } from "../ui/AddButton"
import { ScopeItem } from "./Alerts"
import { reactive } from "@vue/reactivity"
import { RecommendationStatus } from "./Recommendation"
import { Checkbox } from "../ui/Checkbox"
import { Switch } from "../ui/Switch";
import { Tabs, Tab } from "../ui/Tabs";
import {Badge} from "../ui/Badge";
import {Dropdown, DropdownItem} from "../ui/Dropdown";
import {md} from "../ui/Markdown";
import { TagsMixin } from "../mixins/Tags"
import { CATEGORY } from "../api/mock/diag/utils"
import { Button } from "../ui/Button"
import { useCurrentUserStore } from "../store/CurrentUserStore"

const userStore = useCurrentUserStore

export const Recommendations = {
    setup() {
        const storage = localStorage.getItem("categories")?.split(",")
        const data = reactive({
            show_privates: !queryParams().is_public,
            days: parseInt(queryParams().days),
            filteredCategory: storage ? new Set([...localStorage.getItem("categories")?.split(",") ]) : undefined,
        })

        const RW = userStore.hasPermissions("recommendations", "write_private")

        const lq = LiveQuery("recommendations", `{
            pages count,
            items { 
                id, scope_id, category_id, closed, title, short_body, priority, difficulty, cost, tags, created_at, updated_at, status, is_public
            }
        }`, r=>model.recommendations=r,
            ()=>[{
                scope_id:model.scopeId,
                sort:"updated_at",
                order:"desc",
                closed: queryParams().closed === "true" ? true : queryParams().closed === "false" ? false : undefined,
                is_public: queryParams().is_public === "true" ? true : queryParams().is_public === "false" ? false : undefined,
                category_ids: storage ? [...localStorage.getItem("categories")?.split(",")] : data.filteredCategory ? data.filteredCategory : undefined,
                days: parseInt(queryParams().days),
                ...optionsFromQueryParams(),
            }, {scope_id:model.scopeId}]
        )

        LiveQuery("recommendations_by_status", "", a=>data.recommendations_by_status=a,
            ()=>[{
                scope_id: model.scopeId,
                is_public: queryParams().is_public === "true" ? true : queryParams().is_public === "false" ? false : undefined,
                days: parseInt(queryParams().days),
            }]
        )

        const {Tags} = TagsMixin("Recommendation")

        function togglePublic(c) { API.setRecommendation(c.id, {is_public:!c.is_public})}

        function newRecommendation() { gotoScope("newrecommendation") }

        const selectableDays = {
            100: `${t("In")} 100 ${t("last days")}`,
            30: `${t("In")} 30 ${t("last days")}`,
            15: `${t("In")} 15 ${t("last days")}`,
            7: `${t("In")} 7 ${t("last days")}`,
            2: `${t("In")} 2 ${t("last days")}`,
            1: t("Today")
        }

        const update = (value)=>{
            setQueryParams({categories: Array.from(value)})
        }

        return ()=>{
            return <div id="recommendations">
                <Button onClick={() => {
                    setQueryParams({categories:"all"})
                    data.filteredCategory = new Set([])
                    localStorage.setItem("categories", [...Object.keys(CATEGORY)])
                }}>{t("All categories")}</Button>
            <DataTable
                headerLeft={<div>
                    <Tabs>
                        <Tab active={queryParams().closed === undefined}
                             label={<span>{t("reco:All")}<Badge>{data.recommendations_by_status?.total}</Badge></span>}
                             onClick={()=>setQueryParams({closed: null, page: 1})}
                        />
                        <Tab active={queryParams().closed === 'false'}
                             label={<span>{t("reco:Open", {nb: data.recommendations_by_status?.open})}<Badge>{data.recommendations_by_status?.open}</Badge></span>}
                             onClick={()=>setQueryParams({closed: 'false', page: 1})}
                        />
                        <Tab active={queryParams().closed === 'true'}
                             label={<span>{t("reco:Closed", {nb: data.recommendations_by_status?.closed})}<Badge>{data.recommendations_by_status?.closed}</Badge></span>}
                             onClick={()=>setQueryParams({closed: 'true', page: 1})}
                        />
                    </Tabs>
                    <Dropdown button={data.days ?
                        <span>{t("Active")} {selectableDays[data.days].toLowerCase()}</span> :
                        <span>{t("reco:All")}</span>
                    } items={()=><>
                        <DropdownItem onClick={() => {
                            data.days = null
                            setQueryParams({days: null, page: 1})
                        }}>
                            {t("reco:All")}
                        </DropdownItem>
                        {Object.keys(selectableDays).map((k) => {
                            return <DropdownItem
                                onClick={()=>{
                                    data.days = k
                                    setQueryParams({days: k, page: 1})
                                }
                                }>
                                {selectableDays[k]}
                            </DropdownItem>
                        })}
                    </>}/>
                    <div className="filter-categories">
                            <Dropdown button={t("Filter categories")} items={()=>Object.entries(CATEGORY).map((category) => 
                                <DropdownItem onClick={(e) => {
                                    data.filteredCategory.delete(category[0]) || data.filteredCategory.add(category[0])
                                    if (data.filteredCategory?.length===0) return 
                                    localStorage.setItem("categories", Array.from(data.filteredCategory))
                                    update?.(data.filteredCategory)
                                    e.stopPropagation()
                                    e.preventDefault()
                                }}>
                                    <Checkbox value={data.filteredCategory?.has(category[0])}/>
                                    &nbsp;
                                    {category[0]}
                                    &nbsp;
                                    {category[1]}
                                </DropdownItem>)}
                            />
                    </div>
                    {RW && <Switch value={data.show_privates} onClick={() => {
                        data.show_privates = !data.show_privates
                        if (data.show_privates) setQueryParams({is_public: null, page: 1})
                        else setQueryParams({is_public: true, page: 1})
                    }}>{t("Show privates")}</Switch>}
                </div>}
                header={<>
                    {RW && model.scopeId && <AddButton secondary onClick={newRecommendation}>{t("New Recommendation")}</AddButton>}
                </>}
                loading={lq.loading}
                rowClass={x=>!x.is_public && "private"}
                data={model.recommendations}
                classSelect={'select-display-none'}
                columns={[
                    ...(model.scope ? [] : [{title:t("Scope"), type:'scope', render:ScopeItem, sort:"scope"}]),
                    {title:t('First activity'), type:'date', render:x=>x.created_at, sort:"created_at"},
                    {title:t('Last activity'), type:'date', render:x=>x.updated_at, sort:"updated_at"},
                    {title:t("Status"), type:'status', render:x=><RecommendationStatus status={x.status}/>, sort:"status"},
                    RW && {title:"Public", render:x=><Checkbox value={x.is_public} onClick={()=>togglePublic(x)}/>},
                    {title:"Description", type:"description", render:x=><div class='description'>
                        <div><Link href={link(`scope/${x.scope_id}/recommendation/${x.id}`)}>{ICON("recommendation")} {x.title}</Link></div>
                        <div class="body">{x.short_body}</div>
                    </div>, sort:"body"},
                    {title:t('Priority'), render:x=><Severity val={x.priority}/>, sort:"priority"},
                    {title:t('Difficulty'), render:x=><Severity val={x.difficulty}/>, sort:"difficulty"},
                    {title:t('Cost'), render:x=><Severity val={x.cost}/>, sort:"cost"},
                    {title:t('Tags'), type:"tags", noLink:true, render:a=>Tags(a)},
                    {title:"Category id", type:"category_id", render:a=> <span id={a.category_id ? "reco-category-id" : "d-none"}>{a.category_id}</span>},
                ]}
                defaultSort={{sort:"updated_at", order:"desc"}}
            />
        </div>
        }
    }
}