import { reactive } from "vue"
import { API, model } from "../../model"
import "./NewDiag.scss"
import { Dropdown, DropdownItem } from "../../ui/Dropdown"
import { goTo } from "../../utils/routing";
import { Input } from "../../ui/Input"
import { t } from "../../i18n"

export const NewDiag = {
  setup() {
    const data = reactive({
      scopeId: null,
      name: null,
      scopeName: null,
      start: null,
      end: null,
      error: null,
    })

    const createDiag = async (e) => {
      e.preventDefault()
      try {
        const response = await API.createDiag({scope_id:data.scopeId, name:data.name})
        data.diagId = response["id"]

        goTo(`/scope/${data.scopeId}/audit/${data.diagId}`)
      } catch (error) {
        data.error = error
      }
    }

    return () => {
      return <div className="form">
        <h1>New Diag</h1>
        <h3>Enter a informations about this new Diag</h3>

        <form>
          <label for="name">{t("Audit name")} :</label>
          <Input type="text" value={data.name}  onChange={e=> data.name = e.target.value} required/>
            <div class='filter-scopes'>
              <Dropdown button='Select a scope' items={()=>model.scopes.map((scope)=>
                  <DropdownItem onClick={(e) => {
                    data.scopeId = scope.id
                    data.scopeName = scope.display_name
                    e.preventDefault()
                  }}>
                    <img src={scope.logo} alt=''/>
                    {scope.display_name}
                  </DropdownItem>)}
              required/>
            <span>{data.scopeName}</span>
          </div>
          <input type="submit" className="submit-button" onClick={(e) => createDiag(e)} value={t("Send")}/>
          {data.error && <span>{data.error}</span>}
        </form>
      </div>
    }
  }
}
