import { API, model } from "../model"
import { Dropdown, DropdownItem } from '../ui/Dropdown'
import { optionsFromQueryParams, setQueryParams } from "../utils/queryParams"
import { Checkbox } from "../ui/Checkbox"
import { t } from "../i18n"
import { useCurrentUserStore } from "../store/CurrentUserStore"

export const ScopesFilter = ({value, onChange}) => {
	const userStore = useCurrentUserStore
  const update = async()=>{
      if (optionsFromQueryParams().page) setQueryParams({page: 1})
      await API.setUserSettings(userStore.user.id, {
          ...userStore.user.settings,
          filteredScopes: [...value],            
      })
      onChange?.(value)
  }
  return <div class='filter-scopes'>
      <Dropdown button={t('Filter scopes')} items={()=>model.scopes.map((scope)=>
          <DropdownItem onClick={(e) => {
              value.delete(scope.id) || value.add(scope.id)
              update?.(value)
              e.stopPropagation()
              e.preventDefault()
          }}>
              <Checkbox value={!value.has(scope.id.toString())}/>
              <img src={scope.logo} alt=''/>
              {scope.display_name}
          </DropdownItem>)}
      />
  </div>
}