import { LiveQuery } from '../../api/livequery'
import { API, model } from '../../model'
import { Spinner } from '../../ui/Spinner'
import { JSONEditor } from '../../ui/JSONEditor'
import { Tabs, Tab } from '../../ui/Tabs'
import { DataTable } from "../../ui/DataTable"
import { optionsFromQueryParams, queryParams, setQueryParams } from "../../utils/queryParams"
import './SettingsMaestro.scss'
import { ICON } from '../../ui/icons'
import { D } from '../../ui/dates'
import { onUnmounted } from '@vue/runtime-core'
import { onMounted } from '@vue/runtime-core'

export const SettingsMaestro = {
    props:[],
    setup(props) {
        const lq = LiveQuery('scope', '{display_name, scope_identifier, maestro_spec, maestro_status, maestro_events}', x=>model.maestro=x,()=>[{id:model.scopeId}])

        let refresher = null
        onMounted(()=>refresher = setInterval(()=>{
            if(queryParams().page === "config") return;
            lq.refresh()
        }, 10000))
        onUnmounted(()=>refresher!==null && clearInterval(refresher))

        function updateSpec(x) {
            API.setScopeMaestroSpec(model.scopeId, JSON.stringify(x))
        }

        return ()=>{
            return <div id="maestro">
                <h1>MAESTRO Deployment</h1>
                {(!model.maestro?.maestro_spec && lq.loading) ? <Spinner/>
                : !model.maestro?.maestro_spec ? <h4 class="error">This scope hasn't been deployed by Maestro</h4>
                : <>
                    <Tabs>
                        <Tab active={!queryParams().page || queryParams().page === 'status'}
                                label="Status"
                                onClick={()=>setQueryParams({page: "status"})}
                        />
                        <Tab active={queryParams().page==="config"}
                                label="Configuration"
                                onClick={()=>setQueryParams({page: "config"})}
                        />
                        <Tab active={queryParams().page === 'events'}
                                label="Events"
                                onClick={()=>setQueryParams({page: "events"})}
                        />
                    </Tabs>
            

                    {(queryParams().page === "status"  || !queryParams().page) && <>
                        <Status loading={lq.loading} source={model.maestro?.maestro_status}/>
                    </>}

                    {queryParams().page === "config" && <>
                        <JSONEditor source={model.maestro?.maestro_spec} onSave={x=>updateSpec(x)}/>
                    </>}

                    {queryParams().page === "events" && <>
                        <Events loading={lq.loading} events={model.maestro?.maestro_events}/>
                    </>}
                
                </>}

            </div>  
        }
    }
}

const Events = ({events, loading}) => {
    // console.log(events)
    const parsedEvents = events.map(e=>({
        ...e, 
        timestamp: e.eventTime || e.lastTimestamp || 0,
    })).sort((a,b)=>+new Date(b.timestamp) - new Date(a.timestamp))
    // console.log(events)
    // console.log(parsedEvents)
    return <DataTable
        loading={loading}
        class="Events"
        noHeader noSearch
        data={parsedEvents}
        columns={[
            {render:e=>D(e.timestamp, "long")},
            {render:e=>e.type==="Warning" ? ICON("alert") : ""},
            {render:e=>e.reason},
            {render:e=>e.involvedObject?.kind},
            {render:e=>e.involvedObject?.name},
            {render:e=>e.message},
        ]}
        rowClass={x=>x.type==="Warning" ? "warning" : "message"}
    />
}

const Status = ({source, loading}) => {
    // console.log(source)
    const status = source.map(x=>({
        ...x,
        readyReplicas: x.availableReplicas || (x.replicas - x.unavailableReplicas) || 0,
        ok: x.readyReplicas === x.replicas,
    }))
    return <DataTable
        loading={loading}
        class="Status"
        noSearch
        data={status}
        columns={[
            {render:x=>x.ok ? ICON("fas fa-check") : ICON("alert")},
            {render:x=>x.name},
            {title:"Ready", render:x=>x.readyReplicas + "/" + x.replicas},
            {title:"Created", render:x=>D(x.timestamp, "long")},
        ]}
        rowClass={x=>({ok:x.ok})}
    />
}