import { LiveQuery } from "../api/livequery"
import { API, model } from "../model"
import { Spinner } from "../ui/Spinner"
import { t } from "../i18n"
import {reactive} from "@vue/reactivity"
import { Sidebar } from "../ui/Sidebar"
import { convert_histogram_series } from "../ui/dates"
import { AgentStatus } from "./Agents"
import { Accordion, AccordionField } from "../ui/Accordion"
import { Box } from "../ui/Grid"
import  DatePicker  from "../ui/DatePicker"
import { Date as UIDate } from "../ui/Date"
import { DataTable } from "../ui/DataTable"
import { queryParams, setQueryParams, watchQueryParams, intervalFromQueryParams} from "../utils/queryParams"
import {capitalize, flat_keys, has_flat_keys} from "../util"
import { modal } from "../ui/Modal";
import { Dialog } from "../ui/Dialog";
import {Histogram} from "../ui/Histogram"
import { Dropdown, DropdownItem } from "../ui/Dropdown"
import { Button } from "../ui/Button"
import { Checkbox } from "../ui/Checkbox"
import "./Agent.scss"
import { encodeKibanaQuery } from "../ui/Drilldown"
import { useCurrentUserStore } from "../store/CurrentUserStore"

const userStore = useCurrentUserStore

export async function updateAgentDialog () {
    const data = reactive({
        profileSelected: {name: model.agent.profile_name},
        checked: false,
        profiles: await API.getAgentProfiles()
    })


    return modal(({close})=>
        <Dialog class='update-dialog' close={close} onSubmit={()=>close(data)}
            header={t("Update agent profile")}
            body={<>
                <Dropdown button={data.profileSelected.name} items={()=><>
                    {data.profiles.map((p)=>{
                        return <DropdownItem onClick={()=>data.profileSelected = p}>{p.name}</DropdownItem>
                    })}
                </>}/>
            </>}
            buttons={<>
                <p><Checkbox value={data.checked} onClick={() => data.checked = !data.checked}/>{t("Confirmer")}</p>
                <Button secondary onClick={()=>close(null)}>{t("Cancel")}</Button>
                <Button type="submit" disabled={!data.checked || data.profileSelected.name == model.agent.profile_name}>{t("Update")}</Button>
            </>}
        />
    )
}

export async function updateAgentProfile({profileSelected}, ask=true) {
    if(!profileSelected) return ask && updateAgentProfile(await updateAgentDialog() || {}, false)

    API.updateAgent(model.agentId, {profile_id: profileSelected.id})
} 

const AgentsHistogram = {
    setup(props) {
        const data=reactive({
            histogram:null,
            interval:intervalFromQueryParams("now-2d"),
        })

        watchQueryParams(()=>data.interval = intervalFromQueryParams("now-2d"))


        const lq = LiveQuery("agent_histogram", "", update, ()=>[{
            agent_id:model.agentId,
            ...data.interval
        }])
        
        const colors = {
            "error" : '#ff440b',
            "installing": '#5b7c5b',
            "not_installed": '#bbbbbb',
            "running": '#57bf56',
            "starting": '#5b7c5b',
            "stopped": '#ffc912',
            "stopping": '#ffc912',
            "uninstalling": '#bbbbbb',
        }
        
        function update(d) {
            const test = convert_histogram_series(d || {})
            const [x,y] = test
            data.histogram = {
                labels:x,
                datasets: ["error", "installing", "not_installed", "running", "starting", "stopped", "stopping", "uninstalling"].map(s=>({
                    label: capitalize(s) + " en %",
                    backgroundColor: colors[s],
                    data:y[s],
                    stack:0
                })),
            }
        }
        return ()=><Histogram stacked dates data={data.histogram}/>
    }
}

function process_fields(fields) {
    if(!fields) return [];

    if(typeof(fields)==="string" && fields.trim()) fields = JSON.parse(fields)


    // Handle flat keys format
    if(has_flat_keys(fields)) {
        var out = {}
        for(var k in fields) {
            var [key,rest] = k.split(".", 2)
            if(!out[key])  out[key] = {}
            out[key][rest] = fields[k]
        }
        return Object.entries(out)
    }
    // Handle nested keys format
    else {
        return Object.entries(fields).filter(([k,v])=>typeof(v)!=="string").map(([k,v])=>[k,flat_keys(v)])
    }
}


export const Agent = {
    setup(){
        const data = reactive({
            interval:intervalFromQueryParams("now-2d"),
        })

        watchQueryParams(()=>data.interval = intervalFromQueryParams("now-2d"))

        return () => {
            if(!model.agent) return <div><Spinner/></div>
            const {hostname, last_complete_status, status, last_seen, created_at} = model.agent
            let fields = process_fields(JSON.parse(JSON.stringify(last_complete_status !== null ? last_complete_status["modules"] : "{}")))
            return <div id="agent" class="page with-sidebar">
                <div>
                    <div class="page-header">
                        <h1>{hostname}</h1>
                        <DatePicker />
                    </div>
                    <AgentsHistogram/>
                    <h2>Modules</h2>
                    <div class="agent-fields">
                    {fields?.map(([k,v])=>
                        <Box>
                            <h2>{capitalize(k)}</h2>
                            <DataTable
                                noHeader
                                data={Object.entries(v)}
                                columns={[
                                    {render:([kk])=><b>{t(kk)}</b>},
                                    {render:([_, v])=>{
                                        return <p>{v ? v : "?"}</p>
                                    }},
                                ]}
                            />
                        </Box>
                    )}
                    </div>
                </div>
                
                <Sidebar>
                    <Accordion>

                        <AccordionField class="icon">{AgentStatus(status)}</AccordionField>

                        <AccordionField title={t("Creation date")}><div><UIDate value={created_at}/></div></AccordionField>
                        <AccordionField title={t("Last detection")}><div><UIDate value={last_seen}/></div></AccordionField>
                        
                        {userStore.hasPermissions("agents", "write_private") && <AccordionField>
                            <Button onClick={updateAgentProfile}>{t("Update agent profile")}</Button>
                            </AccordionField>}
                        
                        {userStore.hasPermissions("agents", "read_private") && <AccordionField>

                            <Button onClick={()=>window.open(`https://astraia.ct-squa.re/kb/s/${model.scope?.kibana_space}/app/discover#/?` + encodeKibanaQuery({from: data.interval.from, to: data.interval.to, query: {"agent.name":model.agent?.uuid}}), '_blank')}>{t("Show agent logs on kibana")}</Button>
                            </AccordionField>}

                        <AccordionField>
                        <Box>
                            <h2>{capitalize("Agents info")}</h2>
                            <DataTable
                                noHeader
                                data={last_complete_status ? Object.entries(last_complete_status.core) : null}
                                columns={[
                                    {render:([k])=><b>{t(k)}</b>},
                                    {render:([_, v])=>{
                                        return <p>{v ? v : "?"}</p>
                                    }},
                                ]}
                            />
                        </Box>
                        </AccordionField>
                        
                        <AccordionField>
                        <Box>
                            <h2>{capitalize("Host infos")}</h2>
                            <DataTable
                                noHeader
                                data={last_complete_status ? Object.entries(last_complete_status.host) : null}
                                columns={[
                                    {render:([k])=><b>{t(k)}</b>},
                                    {render:([_, v])=>{
                                        return <p>{v ? v : "?"}</p>
                                    }},
                                ]}
                            />
                        </Box>
                        </AccordionField>
                    </Accordion>
                </Sidebar>
                
            </div>
        }
    }
}