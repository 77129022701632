import './Select.scss'

import { Dropdown, DropdownItem } from "./Dropdown"

export const Select = ({value="", placeholder="", pre, post, items, displayNone}) => (
    <div class={displayNone ? `input-group select flex-nowrap ${displayNone}` : 'input-group select flex-nowrap '}>
      <Dropdown class={placeholder.includes('Page') ? 'adjust-btn' : null} button={<span>{value || placeholder}</span>} items={()=>items?.()}/>
      {post}
    </div>
    
  )
  export const SelectItem = DropdownItem
  
  
  