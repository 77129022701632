export const WhitelistConditions = (elements) => {
    const displayObject = (object) => {
        return Object.entries(object).map((v) => {
            if (v[0].match(/^\d+$/)) {
                return <pre>- {v[1]}</pre> 
            } else {
                return <div className="sub-lists">
                    <span>- {v[0]}:</span>
                    <pre>{typeof v[1]==="object" ? displayObject(v[1]) : v[1]}</pre> 
                </div>
            }
        })
    }
    
    const displayList = (list) => {
        const keys = Object.keys(list);
        return keys.map((key, index) => {
            if (typeof list[key]==="object") {
                return <div className="block">
                <span>{key.match(/^\d+$/) ? null : `${key}:`}</span>
                <pre>{displayObject(list[key])}</pre>
            </div>
            } else {
                return <div className="block">
                <span>{key}:</span><pre>{list[key]}</pre> 
            </div>
            }
        });
    }

    const displayDetails = (values) => {
        return Object.entries(values).map((v) => {
            if (typeof v[1]==="object") {
                return <div>
                    <span className="header-conditions">{v[0].match(/^\d+$/) ? "Condition" : v[0]}</span>
                    <div className="body-contidions">{displayList(v[1])}</div>
                </div>
            } else {
                return <div className={v[0].match(/^\d+$/) ? "list" : v[0]==="condition" ?  "block condition" : null}>
                    <span className="header-conditions">{v[0].match(/^\d+$/) ? null : v[0]}</span>
                    <div className="body-contidions">
                        <pre>{v[1]}</pre>
                    </div>
                </div>
            }
        })
    }

    return Object.values(elements).map((v) => {
        return displayDetails(v)
    })
}