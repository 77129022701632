import "./Rule.scss"
import { Spinner } from "../ui/Spinner"
import { Button } from "../ui/Button"
import { model } from "../model"
import { CodeMirror } from "../ui/CodeMirror"
import { Severity } from "../ui/Severity"
import { Tags } from "../ui/Tags"
import { RuleDetection } from "../pages/RuleDetection.jsx"
import { Link } from "../utils/router"
import { link } from "../utils/routing"
import { ICONS } from "../ui/icons"
import { t, PLURALS } from "../i18n"
import { Donut } from "../ui/Donut"
import { PALETTE } from "../ui/ui"
import { reactive } from "@vue/reactivity"
import { API } from "../model"
import { watchEffect } from "vue"
const yaml = require("js-yaml")

export const Rule = {
    setup() {
        const data = reactive({
            inputValue: "",
            errors: "",
            successMessage: false
        })

        return () => {
            
            if(!model.rule) return <Spinner/>
            
            const {rule_id, conclusion_codes, alerts_count,  name, rule_body, git_url, severity, tags, date, status, detection, fields, groups, description, falsepositives, references, git_edit_url, whitelists, message, alert_sections} = model.rule

            const getScore = (severity) => {
                switch(severity) {
                    case "low" :
                        return <Severity val={1} />;
                    case "medium" :
                        return <Severity val={2} />;
                    case "high" :
                        return <Severity val={3} />;
                    case "critical" :
                        return <Severity val={4} />;
                };
            }   

            const getfalsepositives = (falsepositives) => {
                return Object.entries(falsepositives).map((v) => {
                    return <p>{v[1]}</p>
                })
            }

            const getList = (object) => {
                return Object.entries(object).map((v) => {
                    return <pre className="shell-part" id="lists">{v[1]}</pre> 
                })
            }

            const displayDonut = (values) => {    
                const donutComponents = [];
                const colorPalette = [PALETTE.open, PALETTE.audit, PALETTE.closed, PALETTE.incident, PALETTE.investigation, PALETTE.other, PALETTE.patrol, PALETTE.pending]; 

                for (let i = 0; i < values[0].length; i++) {
                    const data = values[0][i];
                    const color = colorPalette[i % colorPalette.length];
                    
                    const code = data["code_id"] ? `${data["code"]} : ${data["percentage"]} %` : `${t("open")} : ${data["percentage"]} %`
                    donutComponents.push({
                        label: code,
                        data: data["percentage"],
                        color: color,
                    });

                }
                return <Donut data={donutComponents} id='donut-conclusion-codes'/>;
            }

            const displayConclusionCodes = (codes) => {
                let list = []
                return Object.entries(codes).map((v) => {
                    if (v[0]==="codes") {
                        list.push(v[1])
                        return displayDonut(list)
                    }
                })
            }

            const updateSectionsInRule = async (values) => {
                let fixSyntax
                if (values.includes('-') && !values.includes('sections')) fixSyntax = values.replace("-", "- ") 
                else if (values.includes('hidden- sections')) fixSyntax = values.replace("hidden- sections", "hidden-sections") 
                if (typeof values==='string') fixSyntax = values
                else {
                    const jsonData = yaml.load(values)
                    fixSyntax = JSON.stringify(jsonData)
                }

                try {
                    if (typeof values==='string') {
                        await API.setRuleSections({rule_id:rule_id, alert_sections:fixSyntax})
                        data.errors = ""
                        data.successMessage = true
                
                        setTimeout(() => {
                            data.successMessage = false
                        }, 2000)
                    }
                } catch (error) {
                    data.successMessage = false
                    data.errors = `YML format is got a syntax error :  ${error.message}`
                }
            }


            return <div id="rule">
                <div className="header">
                    <h1>{name}</h1>
                    <div className="buttons-gitlab">
                        <Button onClick={()=>{window.open(git_url, '_blank', 'noreferrer');}}>{ICONS["eye"]}</Button>
                        <Button onClick={()=>{window.open(git_edit_url, '_blank', 'noreferrer');}}>{ICONS["edit"]}</Button>
                    </div>
                </div>
                <section className="grid-rule">
                    <div className="box-content">
                        <section id="body-rule" className="box-rule">
                            <div className={description ? null : "align"}>
                                <b>Description : </b>
                                <p>{description ? description : ICONS["unlink"]}</p>
                            </div>
                            <div className={falsepositives ? null : "align"}>
                                <b>Falsepositives : </b>
                                <ul  id="falsepositives">{falsepositives ? getfalsepositives(falsepositives) : ICONS["unlink"]}</ul>
                            </div>
                            <div className={references ? null : "align"}>
                                <b>References : </b>
                                {references ? references.map((v) => {
                                    return <div className="links">
                                         <a onClick={() => {window.open(v, '_blank', 'noreferrer')}}>{v}</a>
                                         <a onClick={() => {window.open(v, '_blank', 'noreferrer')}}>{ICONS["external-link"]}</a>
                                    </div>
                                }) : ICONS["unlink"]}
                            </div>
                        </section>
                        <section id="body-rule" className="box-rule">
                            <div className={fields ? null : "align"}>
                                <b>Fields : </b>
                                <ul id="fields">{fields ? getList(fields) : ICONS["empty"]}</ul>
                            </div>
                            <div className={groups ? null : "align"}>
                                <b>Groups : </b>
                                <ul  id="groups">{groups ? getList(groups) : ICONS["unlink"]}</ul>
                            </div>
                            <div className={message ? null : "align"}>
                                <b>Message : </b>
                                <ul id="message">{message ? message : ICONS["unlink"]}</ul>
                            </div>
                        </section>
                        <section className="box-rule">
                            <div id="detection"  className={detection ? null : "align"}>
                                <b>Detection</b> 
                                {detection ? <div><RuleDetection elements={detection} /></div> : ICONS["unlink"]}
                            </div>
                        </section>
                    </div>
                    <div>
                        <section id="meta-datas-rule">
                            <div className="meta-datas-rule">
                                <h3 className="text">Severity</h3>
                                <span>{getScore(severity)}</span>
                            </div>
                            <div id="tags" className="meta-datas-rule">
                                <h3 className="text">Tags</h3>
                                <span>{<Tags tags={tags}/>}</span>
                            </div>
                            <div className="meta-datas-rule">
                                <h3 className="text">Date</h3>
                                <span>{date ? date : "indisponible"}</span>
                            </div>
                            <div className="meta-datas-rule">
                                <h3 className="text">Status</h3>
                                <span>{status}</span>
                            </div>
                            <div className="meta-datas-rule">
                                <h3 className="text" id={alerts_count ? "alerts" : null}>Alerts</h3>
                                <span className={alerts_count ? "nb-alerts" : null}>{alerts_count ? alerts_count : ICONS["unlink"]}</span>
                            </div>
                        </section>
                        <section className="whitelists">
                            <b>Whitelists : </b> 
                            {whitelists.length!==0 ? 
                            <table>
                                <thead>
                                    <tr>
                                        <th>Scope</th>
                                        <th>Name/Description</th>
                                    </tr>
                                </thead>
                                {whitelists ? whitelists.map((v) => {
                                    return <tbody className="tab-whitelists">
                                            <td><Link href={link(`whitelist/${v.whitelist_id}`)} target="_blank">{v.scope_id ? v.scope_id : t("Globale")}</Link></td> 
                                            <td className="name"><Link href={link(`whitelist/${v.whitelist_id}`)} target="_blank">{v.description}</Link></td>
                                    </tbody>
                                }) : null}
                            </table>
                            : ICONS["unlink"]}
                        </section>
                        <section className="conclusion-codes">
                            <div className="description">
                                <b>Conclusions codes : </b>
                                <span>{conclusion_codes ? t("Percentage distribution of conclusion_codes") : null}</span> 
                            </div>
                            {conclusion_codes ? displayConclusionCodes(conclusion_codes) : ICONS["unlink"]}
                        </section>
                        <form class="code-editor">
                            <span id="title">{t("Sections highlighted in alerts associated with this rule")} :</span>
                            <textarea id="yaml-input" onInput={e=>(data.inputValue = e.target.value)}>
                                {alert_sections ? yaml.dump(alert_sections) : ""}
                            </textarea>
                            <div id="bottom-form">
                                <div>
                                    <Button onClick={()=> updateSectionsInRule(data.inputValue)}>{ICONS["plus"]}</Button>
                                    {data.successMessage && <span id="success-message">{t("Your sections have been added")}</span>}
                                </div>
                                <span>{data.errors && data.errors}</span>
                            </div>
                        </form>
                    </div>
                </section>
                <CodeMirror
                    value={rule_body}
                    readOnly
                    lang='text/x-yaml'
                    extraKeys={{
                        'Alt-F': 'findPersistent',
                        Tab: function (cm) {
                          var spaces = Array(cm.getOption('indentUnit') + 1).join(' ');
                          cm.replaceSelection(spaces);
                        },
                      }}
                />
            </div>
        }
    }
}