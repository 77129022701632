import { onMounted } from "@vue/runtime-core"
import "./Input.scss"

export const Input = ({label, ...rest}) => (
  label ? <div class="form-group"><label>{label}</label><InputGroup {...rest}/></div>
  : <InputGroup {...rest}/>
)

const InputGroup = ({pre, post, ...rest}) => <div class="input-group flex-nowrap">
  {!!pre && <span class="input-group-text">{pre}</span>}
  <CInput {...rest} />
  {!!post && <span class="input-group-text">{post}</span>}
</div>


const CInput = {
  props: ["value", "validate", "autofocus", "debounce", "onInput", "onChange"],
  setup(props) {
    let input = null
    
    onMounted(()=>{
      if(input && props.autofocus) setImmediate(()=>input.focus())
      if(input) input.value = props.value;
    })

    let timeout = null
    const onInput = (e) => {
      if(props.debounce) {
          if(timeout!==null) clearTimeout(timeout)
          timeout = setTimeout(async ()=>{
            await props.onChange?.(e)
            timeout = null;
          }, props.debounce)
      } 
      props.onInput?.(e)
    }

    return ()=>{
      const {value, validate, autofocus} = props
      return <input type="text" 
      onChange={props.onChange}
      onInput={onInput}
      ref={e=>{
          if(e) {            
            input = e
          }
      }}
      value={value}
      class={["form-control", value && validate && !validate(value) && "is-invalid"]} 
      autofocus={autofocus}
    />
    }
  }
}