import { API, model } from "../model"
import { Badge } from "../ui/Badge"
import { DataTable } from "../ui/DataTable"
import { ICON } from "../ui/icons"
import { optionsFromQueryParams, queryParams, setQueryParams, watchQueryParams, intervalFromQueryParams } from "../utils/queryParams"
import { t, t_fem, t_n } from "../i18n"
import { modal } from "../ui/Modal";
import './Cases.scss'
import { LiveQuery } from "../api/livequery"
import { Recos } from "../ui/Recos"
import { Link } from "../utils/router"
import { link, gotoScope } from "../utils/routing"
import { Button } from "../ui/Button"
import { reactive, toRaw } from "@vue/reactivity"
import { first_paragraph } from "../util"
import { Checkbox } from "../ui/Checkbox"
import {Tab, Tabs} from "../ui/Tabs"
import {Switch} from "../ui/Switch";
import { Dropdown, DropdownItem } from '../ui/Dropdown'
import {Filter} from "../ui/Filter";
import {md} from "../ui/Markdown";
import { TagsMixin } from "../mixins/Tags"
import { InProgressIcon } from "../ui/InProgressIcon"
import { DropdownCombo } from "../ui/DropdownCombo"
import { Severity } from "../ui/Severity"
import { onMounted } from "@vue/runtime-core"
import { watch, watchEffect, ref } from "@vue/runtime-core"
import  DatePicker  from "../ui/DatePicker.vue"
import { ScopesFilter } from "../ui/ScopesFilter"
import { useCurrentUserStore } from "../store/CurrentUserStore"

const userStore = useCurrentUserStore
import { Avatar } from "../ui/Avatar"

export const Cases = {
    setup() {

        const userStore = useCurrentUserStore
        
        const data = reactive({
            show_privates: !queryParams().is_public,
            days: parseInt(queryParams().days),
            users:{
                roles: [],
                checked: []
            },
            interval:intervalFromQueryParams("-"),
            filteredScopes: new Set(userStore.user?.settings?.filteredScopes || []),
            displayFilteredScopes: userStore.user?.settings?.filteredScopes,
            isDisplayScopes: !model.scopeId && localStorage.getItem('isDisplayScopes') === 'true',
            selected: [],
            loading: false,
            dontDateUpdate: false,
            setPublic: false,
            conclusion_codes: []
        })

        watchEffect(() => {
            localStorage.setItem('isDisplayScopes', data.isDisplayScopes.toString());
        })


        onMounted(async () =>{
            data.conclusion_codes = await API.getConclusionCodes()
        })

        watchQueryParams(()=>{
            data.interval = intervalFromQueryParams("-")
        })

        const lq = LiveQuery("cases", `{
            count, pages,
            items {
                id, scope_id, title, short_body, conclusion, type, created_at, updated_at, last_activity, scope_id, tags, status, recos_by_status, is_public, nb_alerts,
                conclusion_code{name,description,code}, severity, notified, related_users {id display_name}
            }
        }`, c=>model.cases=c,
            ()=>[{
                scope_id:model.scopeId,
                sort:"last_activity",
                order:"desc",
                status: queryParams().status === "all" ? undefined : (queryParams().status || 'open'),
                is_public: queryParams().is_public === "true" ? true : queryParams().is_public === "false" ? false : undefined,
                days: parseInt(queryParams().days),
                case_type: queryParams().case_type?.split(","),
                from: data.interval.from,
                to: data.interval.to,
                not_scope_ids: model.scopeId ? [] : (userStore.user.settings?.filteredScopes || []),
                limit:parseInt(queryParams().limit) || 20,
                ...optionsFromQueryParams(),
            }, {scope_id:model.scopeId}],
            {throttle:5000}
        )

        const lq_cases_by_status = LiveQuery("cases_by_status", "", a=>data.cases_by_status=a,
            ()=>[{
                scope_id: model.scopeId,
                is_public: queryParams().is_public === "true" ? true : queryParams().is_public === "false" ? false : undefined,
                case_type: queryParams().case_type?.split(","),
                from: data.interval.from,
                to: data.interval.to
                
            }],
        )

        const lq_cases_by_type = LiveQuery("cases_by_type", "", a=>data.cases_by_type=a,
            ()=>[{
                scope_id: model.scopeId,
                is_public: queryParams().is_public === "true" ? true : queryParams().is_public === "false" ? false : undefined,
                from: data.interval.from,
                to: data.interval.to
            }],
        )

        function newCase() { gotoScope("newcase") }
        
        const addTagToSelection = async (t, a) => {
            if(!Array.isArray(a)){
                await API.tag('Case', a.id, t)
            }
            else{
                a.map(async (e)=>{
                    await API.tag('Case', e, t)
                })
            }
        }

        const setSelectedConclusionCode = async (ccl) => {
            data.loading = true
            await Promise.all(data.selected.map(async (c) => {
                await API.setCase(c, {conclusion_code: ccl.code, dont_update_date: data.dontDateUpdate})
            }))
            data.loading = false
            data.selected = []
            refresh()
        } 

        const setSelectedPublic = async () => {
            data.setPublic = !data.setPublic
            data.loading = true
            await Promise.all(data.selected.map(async (c) => {
                console.log(c)
                await API.publishCase(c, data.setPublic)
            }))
            data.loading = false
            data.selected = []
            refresh()
        }

        async function onRemove(t, a) {
            if (data.selected.includes(a.id)) {
                await Promise.all(data.selected.map(id=>{
                    let cs = model.cases.items.find(item => item.id == id)
                    if (!!cs && cs.tags.includes(t)) {
                        API.untag("Case", id, t)
                    }
                }))
            }
            else {
                await API.untag("Case", a.id, t)
            }
        }

        
        const {Tags} = TagsMixin("Case", {onAdd:addTagToSelection, onRemove, refresh})

        async function togglePublic(c) { await API.publishCase(c.id, !c.is_public) }

        function refresh() {
            lq?.refresh()
            lq_cases_by_status?.refresh()
            lq_cases_by_type?.refresh()
        }

        
        let displayScopes = ref([])

     
        function fillListScopes(values) {
            model.scopes.map((s) => {
                if (!values?.includes(s.id)) displayScopes.value?.push(s)
            })
        }

        fillListScopes(userStore.user?.settings?.filteredScopes)


        function resetScopesList() {
            displayScopes.value = []
        }

        watch(() => userStore.user?.settings?.filteredScopes, (newValue,oldValue) => { 
            if (newValue !== oldValue) {
                resetScopesList()
                fillListScopes(newValue)
            }
        })

        return ()=>{

            const tab = queryParams().status || "open"
            const RW = userStore.hasPermissions("cases", "write_private")

            return <div id="cases">
                <div class="filter-header">
                    <Filter type='case_type' data={data.cases_by_type}
                        render={x=>({
                            "investigation":[ICON('alert'), "Investigation"],
                            "patrol":[ICON('search'), "Patrol"],
                            "audit":[ICON('coordination'), "Audit"],
                            "incident":[ICON('incident'), "Incident"],
                        }[x])}
                        defaultFilter={["investigation", "patrol", "audit", "incident"]}
                        />
                    <div className="btn-filter-scopes">
                        {RW && !model.scopeId && <Switch value={data.isDisplayScopes} onClick={()=>{data.isDisplayScopes = !data.isDisplayScopes}}>{t("Cases filtered by scopes")}</Switch>}</div>
                    <div className="cases-datepicker">
                        <DatePicker />
                    </div>
                </div>
                <div className="datatable-box">
                    <DataTable
                        headerLeft={<div>
                            <Tabs>
                                <Tab active={tab==="open"} onClick={()=>setQueryParams({status:undefined})} label={<span>{t_fem("Open", {nb:data.cases_by_status?.open})} <Badge>{data.cases_by_status?.open||0}</Badge></span>}/>
                                <Tab active={tab==="pending"} onClick={()=>setQueryParams({status:"pending"})} label={<span>{t_fem("Pending", {nb:data.cases_by_status?.pending})} <Badge>{data.cases_by_status?.pending||0}</Badge></span>}/>
                                <Tab active={tab==="closed"} onClick={()=>setQueryParams({status:"closed"})} label={<span>{t_fem("Closed", {nb:data.cases_by_status?.closed})} <Badge>{data.cases_by_status?.closed||0}</Badge></span>}/>
                                <Tab active={tab==="all"} onClick={()=>setQueryParams({status:"all"})} label={<span>{t("All")} <Badge>{(data.cases_by_status?.open||0) + (data.cases_by_status?.closed||0) + (data.cases_by_status?.pending||0)}</Badge></span>}/>
                            </Tabs>
                            {RW && <Switch value={data.show_privates} onClick={() => {
                                data.show_privates = !data.show_privates
                                if (data.show_privates) setQueryParams({is_public: null, page: 1})
                                else setQueryParams({is_public: true, page: 1})
                            }}>{t("Show privates")}</Switch>}
                        </div>}
                        header={<div>
                            {RW && model.scopeId && <Button secondary onClick={newCase}>{ICON("add")} {t("New case")}</Button>}
                        </div>}
                        loading={lq.loading || data.loading}
                        data={model.cases}
                        rowClass={x=>!x.is_public && "private"}
                        headerBottom={
                            <div class="header-bottom">
                                {!model.scopeId && data.isDisplayScopes && <div className="scopes-filtered">
                                    {displayScopes.value.map((scope) => {
                                        return <ul>
                                            <li>{scope.display_name}</li>
                                            <img src={scope.logo} alt=''/>
                                        </ul>
                                    })}
                                </div>}
                                <div class='action-bar'>
                                    {!model.scopeId && <ScopesFilter value={data.filteredScopes} onChange={x=>data.filteredScopes=x}/>}
                                    <div class='flex'>
                                        <div class='actions' class={{hide: !data?.selected?.length}}>
                                        <span class="selected-count">{t_n(data.selected.length, "Case selected")}</span>
                                            <span><Checkbox class="checkbox" value={data.dontDateUpdate} onClick={() => data.dontDateUpdate = !data.dontDateUpdate}/>{t("Don't update dates")}</span>
                                            
                                            <div class="operations">
                                            <span>{t("Public")}<Switch value={data.setPublic} onClick={()=>setSelectedPublic()}/></span>
                                                <DropdownCombo menuClass="fast-close-actions" 
                                                    button={<><i class="fas fa-meteor"/>{t("Fast close")}</>} 
                                                    items={filter=>data.conclusion_codes.filter(c=>`${c.code} ${c.name}`.toLowerCase().includes(filter.toLowerCase()))}
                                                    render={c=>Qualification(c)}
                                                    onSelect={setSelectedConclusionCode}
                                                    placement="top-start"
                                                />
                                            </div>
                                            {Tags(data.selected)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        columns={[
                            {title:t("ID"), type:"id", render:x=><Link href={link(`scope/${x.scope_id}/case/${x.id}`)}>
                                {x.id}
                            </Link>},
                            {title:t('First activity'), type:'date-abs', render:x=>x.created_at, sort:"created_at"},
                            {title:t('Last activity'), type:'date-abs', render:x=>x.last_activity, sort:"last_activity"},
                            {title:"Type", type:'type', render:({type="investigation", nb_alerts})=><div class={{type:true, [type]:true}}>
                                <span class={type}/>
                                {t(type)}
                                {!!nb_alerts && <Badge icon={ICON('alert')}>{nb_alerts}</Badge>}
                            </div>, sort:"case_type"},
                            ...(model.scope ? [] : [{title:t("Scope"), render:ScopeItem, ...(model.scope ? {} : {sort: "scope_id"})}]),
                            {title:"Description", type:'description', render:(x)=><div class='description'>
                                <div><Link href={link(`scope/${x.scope_id}/case/${x.id}`)}>{x.title}</Link></div>
                                <div>{x.short_body}</div>
                                {!!x.conclusion && <div class='conclusion'>{ICON("drilldown")} <span v-html={md.render(first_paragraph(x.conclusion))}/></div>}
                            </div>, sort:"body"},
                            {title:t("Qualification"), type:'status', render:x=><CaseStatus horizontal case={x} onChange={refresh} />, sort: "status"},
                            {title:t('Severity'), render:x=><Severity val={x.severity}/>, sort:"severity"},
                            {title:t("Assigned"),type:"users", render:x=>x.users?.map(u => <Avatar user={u} />)},
                            {title:t('Tags'), type:"tags", noLink:true, render:a=>Tags(a)},
                            RW && {title:"Public", render:x=><Checkbox value={x.is_public} onClick={()=>togglePublic(x)}/>}
                        ]}

                        selection={RW && data.selected}
                        
                        defaultSort={{sort:"last_activity", order:"desc"}}
                    />
                </div>
            </div>
        }
    }
}

export const CaseStatus = {
    props:["case", "onChange", "vertical", "horizontal", "readonly", "onReopen", "onSetQualification", "multiple"], 
    setup(props) {
        const data = reactive({conclusion_codes:[]})

        async function reopen(c) {
            if(props.onReopen) await props.onReopen?.()
            else await API.setCase(props.case.id, {status:"open"})
            props.onChange?.()
        }
        async function setQualification(q) {
            if(props.onSetQualification) await props.onSetQualification?.(q)
            else await API.setCase(props.case.id, {conclusion_code: q.code})
            props.onChange?.()
        }
        
        return ()=>{
            const {vertical, horizontal} = props
            const {status, conclusion_code} = props.case    
            const Status = conclusion_code ? Qualification(conclusion_code) 
                           : status==="open" ? <><InProgressIcon/><span>{t("case:open")}</span></> 
                           : <>{ICON("pending")}<span>{t("pending")}</span></>
            if(props.readonly || !userStore.hasPermissions("cases", "write_private")) return <div class="status" class={status}>{Status}</div>
            return <DropdownCombo menuClass="status"
                    class="status"
                    class={{vertical,horizontal}}
                    class={status}
                    onOpen={async ()=>data.conclusion_codes = await API.getConclusionCodes()}
                    button={Status} 
                    topActions={<>
                        {status==="closed" && <Button onClick={reopen}>{ICON("star")} {t("Reopen")}</Button>}
                        {status==="pending" && <Button onClick={reopen}>{ICON("star")} {t("Investigate")}</Button>}
                    </>}
                    items={filter=>data.conclusion_codes.filter(c=>`${c.code} ${c.name}`.toLowerCase().includes(filter.toLowerCase()))}
                    render={c=>Qualification(c)}
                    onSelect={setQualification}
                />
        }
    }
}

export const Qualification = ({code, name}) => <div class="qualification">
    <b><svg viewBox="2 0 7 16"><path d="M0 0 L 6 8 L 0 16Z"/></svg> {code}</b> 
    {name}
</div>

export const ScopeItem = ({scope_id})=><div class="scope-item">
    <img src={model.scopes?.find(s=>s.id===scope_id)?.logo}/>
    <Link href={`scope/${scope_id}/cases` + location.search}>{model.scopes?.find(s=>s.id===scope_id)?.display_name}</Link>
</div>