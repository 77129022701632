import { t } from "../i18n"
import { logout, model } from "../model"
import { Button } from "../ui/Button"
import { Fullscreen } from "../ui/Fullscreen"
import { goTo } from "../utils/routing"
import { useCurrentUserStore } from "../store/CurrentUserStore"

export const NoScope = {
    setup() {
        const userStore = useCurrentUserStore
        return ()=>{
            if(model.scopes?.length>0) goTo("/")
            const u = userStore.user?.display_name
            return <Fullscreen loading={!u}
            title={`${t("Welcome")} ${u}`}
            subtitle={t(`You don't have any scope yet`)}>
                <Button secondary onClick={logout}>{t("Sign out")}</Button>
        </Fullscreen>
        }
    }
}