import './ProgressesHistogram.scss'
import {Progress} from "./Progress"

// Data format = [[name,value,color]]
export const ProgressesHistogram = {
    props:['data'],
    setup(props) {
        return ()=>{
            const max = Math.max(...props.data?.map(([,value])=>value)||[])
            return <div class="progresses-histogram">
                {props.data?.map(([name, value, color])=><Progress color={color} value={value} max={max}>
                    <div class='flex'>{name}</div>
                    <div><b>{value}</b></div>                        
                </Progress>)}
            </div>
        }
    }
}