import './Tabs.scss'
import {reactive} from "vue";

export const Tabs = {
    props:["buttons"],
    setup(props, {slots}) {
        const data = reactive({activeTab: 0})
        
        return () => <div class="tabs">
            <div class='header'>
                <div className="tab-buttons">
                    {slots?.default?.().map((child, i) => {
                        if (child.props.active) data.activeTab = i
                        return <button class={{active: i === data.activeTab}}
                                       onClick={() => {
                                           data.activeTab = i
                                           child.props.onClick && child.props.onClick()
                                       }}>
                            {child.props.label}
                        </button>
                    })}
                </div>
                <div className='buttons'>
                    {props.buttons && props.buttons.map((b)=>b)}
                </div>
            </div>
            <div className="tab-content">{slots?.default?.()[data.activeTab].children?.default()}</div>
        </div>
    }
}  

export const Tab = (label, {slots}) => <div>{slots?.default?.()}</div>
