import { ref } from '@vue/runtime-core'
import { t } from '../i18n'
import { getRouter } from '../utils/router'
import './Button.scss'
import { ICON } from "./icons"
import { Tooltip, TOOLTIP } from './Tooltip'

export const ToolButton = (props, {slots}) => <BaseButton class="btn toolbutton" {...props}>{slots?.default?.()}</BaseButton>
export const IconButton = (props, {slots}) => <BaseButton class="btn iconbutton" type="button" {...props}>{slots?.default?.()}</BaseButton>


export const Button = ({secondary=false, ...rest}, {slots}) => <BaseButton 
  class={{btn:true,"btn-primary":!secondary,"btn-secondary":secondary}}
  type="button"
  {...rest}
  >
    {slots?.default?.()}
</BaseButton>

export const DeleteButton = <IconButton class="delete">{ICON("delete")}</IconButton>

export const FileButton = ({onChange, icon, single=false, image}) => <IconButton class="file" onClick={(e)=> {
  const input = e.target.querySelector('input')
  input && input.click()
}}>
  {icon || ICON("attach")}
  <input type="file" multiple={!single} hidden onChange={onChange} accept={image && "image/*"}/>
</IconButton>

// Private

let GUID = 0

export const BaseButton = {
  props:['title', 'to'],
  setup(props,{slots}) {
    const btn = ref()
    const guid = GUID++
    let timeout = null

    function onMouseEnter() {
      if(!props.title) return;
      if(timeout!==null) clearTimeout(timeout)
      timeout = setTimeout(()=>{
        TOOLTIP.guid = guid
        TOOLTIP.cur = ()=><Tooltip anchor={btn.value}>
          {typeof(props.title)==="string" ? t(props.title) : props.title}
        </Tooltip>
        setImmediate(()=>TOOLTIP.show = true)
      }, 500)
    }

    function onMouseLeave() {
      if(timeout!==null) clearTimeout(timeout)
      timeout = setTimeout(()=>{
        if(TOOLTIP.guid === guid) TOOLTIP.show = false
        if(timeout!==null) clearTimeout(timeout)
        timeout = setTimeout(()=>{
          if(TOOLTIP.guid === guid) TOOLTIP.cur = null
        }, 225)
      }, 225)
    }

    return ()=>{
      const {title, to, ...rest} = props
      const onClick = to && (()=>getRouter().push(to))
      return <button onMouseenter={onMouseEnter} onMouseleave={onMouseLeave} onMousedown={onMouseLeave} ref={btn} onClick={onClick} {...rest}>
          {slots?.default?.()}  
      </button>
    }
  }
}


