import { model, API } from "../model"
import { DataTable } from "../ui/DataTable"
import { optionsFromQueryParams, queryParams, setQueryParams } from "../utils/queryParams"
import { t } from "../i18n"
import { LiveQuery } from "../api/livequery"
import { ICON, ASSET_ICON } from "../ui/icons"
import './Findings.scss'
import {gotoScope} from "../utils/routing"
import {Link} from "../utils/router"
import {link} from "../utils/routing"
import { ScopeItem } from "./Alerts"
import { reactive } from "@vue/reactivity"
import { Checkbox } from "../ui/Checkbox"
import { Switch } from "../ui/Switch";
import {Dropdown, DropdownItem} from "../ui/Dropdown";
import { TagsMixin } from "../mixins/Tags"
import { Severity } from "../ui/Severity"
import { CATEGORY } from "../api/mock/diag/utils"
import { Button } from "../ui/Button"
import { computed } from "vue"
import { useCurrentUserStore } from "../store/CurrentUserStore"

const userStore = useCurrentUserStore

export const Findings = {
    setup() {
        const storage = localStorage.getItem("categories")?.split(",")
        const data = reactive({
            show_privates: !queryParams().is_public,
            days: parseInt(queryParams().days),
            filteredCategory: storage ? new Set([...storage ]) : undefined,
        })  

        const RW = userStore.hasPermissions("findings", "write_private")

        const lq = LiveQuery("findings", `{
            pages, count,
            items { 
                id, assets, kraken_id, scope_id, title, criticity, tags, created_at, updated_at, is_public
            }
        }`, f=>model.findings=f,
            ()=>[{
                scope_id:model.scopeId,
                sort:"updated_at",
                order:"desc",
                is_public: queryParams().is_public === "true" ? true : queryParams().is_public === "false" ? false : undefined,
                days: parseInt(queryParams().days),
                category_ids: storage ? [...localStorage.getItem("categories")?.split(",")] : data.filteredCategory ? data.filteredCategory : undefined,
                ...optionsFromQueryParams(),
            }, {scope_id:model.scopeId}],
            { interval: 30000 }
        )

        const {Tags} = TagsMixin("Finding")

        function togglePublic(c) { API.setFinding(c.id, {is_public:!c.is_public})}

        const selectableDays = {
            100: `${t("In")} 100 ${t("last days")}`,
            30: `${t("In")} 30 ${t("last days")}`,
            15: `${t("In")} 15 ${t("last days")}`,
            7: `${t("In")} 7 ${t("last days")}`,
            2: `${t("In")} 2 ${t("last days")}`,
            1: t("Today")
        }

        const getScore = (criticity) => {
            switch(criticity) {
                case "low" :
                    return <Severity val={1} />;
                case "medium" :
                    return <Severity val={2} />;
                case "high" :
                    return <Severity val={3} />;
                case "critical" :
                    return <Severity val={4} />;
                default :
                    return <Severity val={0} />;
            };
        }


        const update = (value)=>{
            setQueryParams({categories: Array.from(value)})
        }

        const getAssets = (assets) => {
            if (!assets) return
            if (assets==="{}") return

            return Object.entries(assets).slice(0, 3).map((a) => {
                if (a[0].startsWith("device--")) {
                    return <div className="assets-section"><Link href={link(`scope/${model.scopeId}/asset/${a[0].split("--")[0]}?q=${a[1]}&id=${a[0]}`)}><span id="asset-badge">{ASSET_ICON("workstation")} {a[1]}</span></Link></div>
                } else if (a[0].startsWith("domainname--")) {
                    return <div className="assets-section"><Link href={link(`scope/${model.scopeId}/asset/${a[0].split("--")[0]}?q=${a[1]}&id=${a[0]}`)}><span id="asset-badge">{ASSET_ICON("domain")} {a[1]}</span></Link></div>
                } else if (a[0].startsWith("account--")) {
                    return <div className="assets-section"><Link href={link(`scope/${model.scopeId}/asset/${a[0].split("--")[0]}?q=${a[1]}&id=${a[0]}`)}><span id="asset-badge">{ASSET_ICON("account")} {a[1]}</span></Link></div>
                } else if (a[0].startsWith("activedirectory--")) {
                    return <div className="assets-section"><Link href={link(`scope/${model.scopeId}/asset/${a[0].split("--")[0]}?q=${a[1]}&id=${a[0]}`)}><span id="asset-badge">{ASSET_ICON("activedirectory")} {a[1]}</span></Link></div>
                } else {
                    return <div className="assets-section"><Link href={link(`scope/${model.scopeId}/asset/${a[0].split("--")[0]}?q=${a[1]}&id=${a[0]}`)}><span id="asset-badge"><i className="fas fa-ethernet me-1/"/> {a[1]}</span></Link></div>
                }
            })
        }

        return ()=>{
            queryParams().categories==="all" ? data.filteredCategory = new Set([..."all"]) : data.filteredCategory

            return <div id="findings">
                <Button onClick={() => {
                    setQueryParams({categories:"all"})
                    data.filteredCategory = new Set([])
                    localStorage.setItem("categories", [...Object.keys(CATEGORY)])
                }}>{t("All categories")}</Button>
            <DataTable
                headerLeft={<div>
                    <Dropdown button={data.days ?
                        <span>{t("Active")} {selectableDays[data.days].toLowerCase()}</span> :
                        <span>{t("reco:All")}</span>
                    } items={()=><>
                        <DropdownItem onClick={() => {
                            data.days = null
                            setQueryParams({days: null, page: 1})
                        }}>
                            {t("reco:All")}
                        </DropdownItem>
                        {Object.keys(selectableDays).map((k) => {
                            return <DropdownItem
                                onClick={()=>{
                                    data.days = k
                                    setQueryParams({days: k, page: 1})
                                }
                                }>
                                {selectableDays[k]}
                            </DropdownItem>
                        })}
                    </>}/>


                    <div className="filter-categories">
                            <Dropdown button={t("Filter categories")} items={()=>Object.entries(CATEGORY).map((category) => 
                                <DropdownItem onClick={(e) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    data.filteredCategory.delete(category[0]) || data.filteredCategory.add(category[0])
                                    if (data.filteredCategory?.length===0) return 
                                    localStorage.setItem("categories", Array.from(data.filteredCategory))
                                    update?.(data.filteredCategory)
                                }}>
                                    <Checkbox value={data.filteredCategory?.has(category[0])}/>
                                    &nbsp;
                                    {category[0]}
                                    &nbsp;
                                    {category[1]}
                                </DropdownItem>)}
                            />
                    </div>


                    {RW && <Switch value={data.show_privates} onClick={() => {
                        data.show_privates = !data.show_privates
                        if (data.show_privates) setQueryParams({is_public: null, page: 1})
                        else setQueryParams({is_public: true, page: 1})
                    }}>{t("Show privates")}</Switch>}
                </div>}
                loading={lq.loading}
                rowClass={x=>!x.is_public && "private"}
                data={model.findings}
                classSelect={'select-display-none'}
                columns={[
                    ...(model.scope ? [] : [{title:t("Scope"), type:'scope', render:ScopeItem, sort:"scope"}]),
                    {title:t('First activity'), type:'date', render:x=>x.created_at, sort:"created_at"},
                    {title:t('Last activity'), type:'date', render:x=>x.updated_at, sort:"updated_at"},
                    RW && {title:"Public", render:x=><Checkbox value={x.is_public} onClick={()=>togglePublic(x)}/>},
                    {title:"Description", type:"description", render:x=><div class='description'>
                        <div><Link href={link(`scope/${x.scope_id}/finding/${x.id}`)}>{ICON("recommendation")} {x.title}</Link></div>
                    </div>, sort:"title"},
                    {title:"Assets", render: x=>x.assets && <div className={data.showAssets ? "assets limit-assets" : "assets"}>
                            {getAssets(x.assets)}
                            {Object.entries(x.assets).length>3 ? <span>{`${Object.entries(x.assets).length} assets`}</span> : null}
                    </div>},
                    {title:t('Criticity'), render: x=>getScore(x.criticity), sort:"criticity"},
                    {title:t('Tags'), type:"tags", noLink:true, render:a=>Tags(a)},
                    RW && {title:t("Category"), type:'status', render:x=><span id={x.kraken_id  ? "finding-kraken-id" : "d-none"}>{x.kraken_id}</span>, sort:"kraken_id"},
                ]}
                defaultSort={{sort:"updated_at", order:"desc"}}
            />
        </div>
        }
    }
}