import { LiveQuery } from '../api/livequery'
import { model, API } from '../model'
import { AddButton } from '../ui/AddButton'
import { Avatar } from '../ui/Avatar'
import { DataTable } from '../ui/DataTable'
import { D } from '../ui/dates'
import { Spinner } from '../ui/Spinner'
import { Switch } from '../ui/Switch'
import { optionsFromQueryParams, queryParams } from '../utils/queryParams'
import { goTo } from '../utils/routing'
import {Link} from "../utils/router"
import {link} from "../utils/routing"
import { t } from '../i18n'
import './Users.scss'
import { ICON } from '../ui/icons'
import { Button, ToolButton } from '../ui/Button'
import { ERR, INFO } from '../ui/Toasts'
import { Dropdown, DropdownItem } from '../ui/Dropdown'
import { capitalize } from '../util'
import { useCurrentUserStore } from '../store/CurrentUserStore'

export const Users = {
    setup() {
        const userStore = useCurrentUserStore
        const lq = LiveQuery("users", `{
                count, pages, items {
                    id, display_name, locked, avatar, email, created_at, global_role, 
                    activated, password_reset_requested, last_login, last_active,
                    password_reset_requested_from
                    password_reset_at
                    password_set_at
                }
            }`, x=>model.users=x, 
            ()=>[{
                ...optionsFromQueryParams(),
                limit: parseInt(queryParams().limit) || 20,
                sort:"display_name",
                order:"asc"
            }, {}])

        function newUser() { goTo("/newuser") }
        function toggleLocked(user) { API.setUser(user.id, {locked:!user.locked})}
        function setRole(user, role) {
            if(
                (role === "administrator" && !confirm(`You are about to grant admin rights to ${user.display_name}\nAre you sure ?`)) ||
                (role ===" supervisor" && !confirm(`You are about to grant supervisor rights to ${user.display_name}\n`))
            ) return;
            API.setGlobalRole(user.id, role).catch(({code})=>ERR(code))
        }

        async function resend_activation_email(user) { 
            await API.resendActivationEmail(user.id) 
            INFO(t("Activation email sent to ") + user.email)
        }

        async function reset_password(user) { 
            await API.reset_password(user.id) 
            INFO(t("Password reset email sent to ") + user.email)
        }

        return ()=>{
            return <div id="users">
                <DataTable 
                    loading={lq.loading}
                    header={<>
                    <AddButton secondary onClick={newUser}>{t("New user")}</AddButton>
                    </>}
                    data={model.users}
                    columns={[
                        {title:t("Avatar"), render:x=><Avatar user={x}/>},                        
                        {title:t("Name"), render:x=><>
                            {x.locked && ICON("lock")}
                            {!x.activated && ICON("fas fa-question")}
                            <Link href={link(`/user/${x.id}`)}>{x.display_name}</Link>
                        </>},
                        {title:t("Email"), render:x=><Link href={link(`/user/${x.id}`)}>{x.email}</Link>,},
                        {title:t("Role"), render:u=><Dropdown button={capitalize(t(u.global_role))} items={()=>
                            ["external", "audit_manager", "manager", "operator", "auditor", "analyst", "project_manager", "supervisor", "administrator"].map(r=>
                                r!==u.global_role && <DropdownItem onClick={()=>setRole(u, r)}>{capitalize(t(r))}</DropdownItem>
                            )
                        }/>},
                        {title:t("Locked"), noLink:true, render:x=><Switch value={x.locked} disabled={x.id === userStore.user.id} onClick={()=>toggleLocked(x)}/>},
                        {title:t("Created"), render:x=>D(x.created_at)},
                        {title:t("Last signin"), render:x=>D(x.last_login)},
                        {title:t("Last activity"), render:x=>D(x.last_active)},
                        {type:"actions", render:x=>(x.password_reset_requested || !x.activated) ? <Dropdown menuClass="users_actions" class="warning" button={<>
                                {ICON('alert')}
                                {x.password_reset_requested ? ICON("fas fa-key") : ICON("fas fa-question")}
                            </>} items={()=>
                                <div onClick={e=>e.stopPropagation()}>
                                    {x.password_reset_requested && !x.password_reset_at && <>
                                        <h3>{t('Password reset requested')}</h3>
                                        <pre>{x.password_reset_requested_from}</pre>
                                        <Button secondary onClick={()=>reset_password(x)}>{t("Reset password")}</Button>
                                    </>}

                                    {x.password_reset_requested && x.password_reset_at && <>
                                        <h3>{t('Password reset email sent to user but user has\'nt set his password yet')}</h3>
                                        <p>{t("Email sent on")} {D(x.password_reset_at, "long")}</p>
                                        <Button secondary onClick={()=>reset_password(x)}>{t("Resend email")}</Button>
                                    </>}

                                    {!x.activated && <>
                                        <h3>{t('Activation pending')}</h3>
                                        {x.password_reset_at && <p>{t("Activation email sent on")} {D(x.password_reset_at, "long")}</p>}
                                        <Button secondary onClick={()=>resend_activation_email(x)}>{t("Resend activation email")}</Button>
                                    </>}

                                </div>
                            }/> : 
                            <Dropdown button={ICON("fas fa-ellipsis-v")} items={()=>
                                <DropdownItem onClick={()=>reset_password(x)}>{t("Reset password")}</DropdownItem>
                            }/>
                        },
                    ]}
                    rowClass={x=>x.locked || !x.activated ? "inactive" : "active"}
                />
            </div>
        }
    }
}