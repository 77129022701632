import { DataTable } from "../ui/DataTable"
import "./Whitelist.scss"
import { Spinner } from "../ui/Spinner"
import { Button } from "../ui/Button"
import { model } from "../model"
import { CodeMirror } from "../ui/CodeMirror"
import "./Rule.scss"
import "./Whitelists.scss"
import { RuleInfos } from "../ui/RuleInfos"
import { Link } from "../utils/router"
import { link } from "../utils/routing"
import { WhitelistConditions } from "./WhitelistConditions"
import { ICONS } from "../ui/icons"
import { t } from "../i18n"

export const Whitelist = {
    setup() {

        return () => {
            
            if(!model.whitelist) return <Spinner/>
            
            const {name, whitelist_body, git_url_format, description, scope_id, git_edit_url, rule_uuids, conditions, deleted, set, whitelist_id, rules} = model.whitelist

            const deletedBoolean = new Boolean(deleted);

            const ScopeItem = ({scope_id})=> 
                <div id="scope">
                    {scope_id ? <img src={model.scopes?.find(s=>s.id===scope_id)?.logo}/> : null }
                    {scope_id ? <Link id="link-scope" href={`scope/${scope_id}/whitelists` + location.search}><b>{model.scopes?.find(s=>s.id===scope_id)?.display_name}</b></Link>  : null}
                </div>

            const getList = (object) => {
                return Object.entries(object).map((v) => {
                    return <div className="block">
                        <span>{v[0].match(/^\d+$/) ? null : v[0]}</span><pre>- {v[1]}</pre> 
                </div>
                })
            }

            return <div id="whitelist">
                <div>
                    <h1>{name}</h1>
                    <div className="buttons-gitlab">
                        <Button onClick={()=>{window.open(git_url_format, '_blank', 'noreferrer');}}>{ICONS["eye"]}</Button>
                        <Button onClick={()=>{window.open(git_edit_url, '_blank', 'noreferrer');}}>{ICONS["edit"]}</Button>
                    </div>
                </div>
                <section className="grid-whitelist">
                    <div className="box-content">
                        <section id="body" className="box-whitelists">
                            <div>
                                <b>Description :</b>
                                <p>{description}</p>
                            </div>
                            <section className="rules">
                                <b><span className="color">{rules && rules.length}</span> - {rules && rules.length > 1 ? t("Rules") : t("Rule")} </b>
                                {rules!==0 ?
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Scope</th>
                                                <th>Name/Description</th>
                                            </tr>
                                        </thead>
                                        {rules ? rules.map((v) => {
                                            return <tbody className="tab-rules">
                                                    <td><Link href={link(`rule/${v.rule_id}`)} target="_blank">{v.rule_id}</Link></td> 
                                                    <td className="name"><Link href={link(`rule/${v.whitelist_id}`)} target="_blank">{v.name}</Link></td>
                                            </tbody>
                                        }) : null}
                                    </table>
                                : ICONS["unlink"]}
                            </section>
                            <div id={`${scope_id ? null : "display-scope"}`}>
                                <b>Whitelist {scope_id ? t("specific") : t("Global")} </b>
                                {ScopeItem({scope_id})}
                            </div>
                        </section>
                        <section className="box-whitelists">
                            <div className={conditions ? null : "conditions-wl align"}>
                                <b>{conditions ? null : `${t("Conditions")} :`} </b>
                                {conditions ? <WhitelistConditions elements={conditions}/> :  ICONS["unlink"]}
                            </div>
                        </section>
                        <section className="box-whitelists">
                            <b>Actions</b>
                            <div className={set ? null : "align"}>
                                <b className={set ? "header-action" : null }>Set : </b>
                                <div>{set ? <pre className="body-action">{getList(set)}</pre> : ICONS["unlink"]}</div>
                           </div>
                           <div className={deleted ? null : "align"}>
                                <b className={deleted ? "header-action" : null }>Delete : </b>
                                <div>{deleted ? <pre className="body-action">{deletedBoolean.toString()}</pre> :  ICONS["unlink"]}</div>
                            </div>
                        </section>
                    </div>
                    <section id="meta-datas-whitelist">
                        <div className="meta-datas-whitelists">
                            <h3 className="text">id</h3>
                            <span>{whitelist_id}</span>
                        </div>
                    </section>
                </section>
                <CodeMirror
                    value={whitelist_body}
                    readOnly
                    lang='text/x-yaml'
                    extraKeys={{
                        'Alt-F': 'findPersistent',
                        Tab: function (cm) {
                          var spaces = Array(cm.getOption('indentUnit') + 1).join(' ');
                          cm.replaceSelection(spaces);
                        },
                      }}
                />
            </div>
        }
    }
}