import { model, API } from '../model'
import { Avatar } from '../ui/Avatar'
import { Form } from '../ui/Form'
import {capitalize, validateEmail} from '../util'
import { t } from '../i18n'
import './Profile.scss'
import { reactive } from '@vue/reactivity'
import { LiveQuery } from '../api/livequery'
import { useRoute } from 'vue-router'
import { Spinner } from '../ui/Spinner'
import {Dropdown, DropdownItem} from "../ui/Dropdown";
import {DataTable} from "../ui/DataTable";
import {Switch} from "../ui/Switch";
import {Link} from "../utils/router";
import {link} from "../utils/routing";
import { useCurrentUserStore } from '../store/CurrentUserStore'


export const EditUser = {
    setup() {
        const userStore = useCurrentUserStore
        const id = useRoute()?.params?.id
        const data = reactive({user:null, v2:false})

        LiveQuery('user', `{id, display_name, avatar, email, locked, settings, global_role, roles{id, role, scope{id}}}`, x=>(data.user=x) && (data.v2=!!x.settings.useV2),
            ()=> id && [{id}]
        )

        function save(x) {
            API.setUser(data.user.id, x)
        }

        function setRole(id, role, scopeId) {
            if (id) {
                if (role === 'none') {
                    API.removeMember({id})
                } else {
                    API.setMember(id, {role})
                }
            } else if (role !== 'none' && scopeId) {
                API.setMember(data.user.id, scopeId, role)
            }
        }

        async function setUseV2(v2) {
            await API.setUserSettings(data.user.id, {
                ...data.user.settings,
                useV2: v2
            })
            data.v2 = v2
        }


        const allowed_roles = ['external', 'audit_manager', 'manager', 'operator', 'auditor', 'analyst', 'project_manager', 'none']


        return ()=>{
            if(!data.user) return <Spinner/>

            let roles = {}
            data.user?.roles.forEach(r => {
                roles[r.scope?.id] = {id: r.id, role: r.role}
            })

            return <div id="profile">  
            <h1><Avatar class="big" user={data.user}/> {data.user.display_name}</h1>
            <span>{ data.v2 }</span>
            <Form object={data.user} fields={{
                display_name:{name:t("Name")},
                email:{validate:validateEmail},
                avatar:{type: 'image'},
                locked:{name:t("Locked"), type:"switch", disabled:data.user.id===userStore.user.id}
            }} onSave={save}>
                <Switch
                    class='autoreload-switch'
                    value={data.v2}
                    onClick={async ()=> {
                        await setUseV2(!data.v2)
                    }}>
                    {"Use V2"}
                </Switch>
            </Form>
            
                <DataTable
                    noSearch
                    data={model.scopes}
                    columns={[
                        {title:t('Logo'), type:'logo', render:x=><img src={x.logo} alt=''/>},
                        {title:t('Name'), type:'name', render:x=><Link href={link(`/scope/${x.id}`)}>{x.display_name}</Link>},
                        {title:t('Role'), type:"role", render:x=>
                                <Dropdown class='role' button={capitalize(t(roles[x.id]?.role || "None"))} items={()=>
                                    allowed_roles.map(r =>
                                        <DropdownItem onClick={() => setRole(roles[x.id]?.id, r, x.id)}>
                                            {capitalize(t(r))}
                                        </DropdownItem>)
                                }/>
                        },
                    ]}
                />
            </div>
        }
    }
}