import { reactive } from "@vue/reactivity"
import { API } from "../../model"
import { Button } from "../../ui/Button"
import { ICON } from "../../ui/icons"
import { t } from "../../i18n"
import { DataTable } from "../../ui/DataTable"
import { Switch } from "../../ui/Switch"
import { Input } from "../../ui/Input"
import { onMounted } from "@vue/runtime-core"
import { modal } from "../../ui/Modal"
import { Dialog } from "../../ui/Dialog"
import { Qualification } from "../Cases"
import "./SettingsQualifications.scss"
import { queryParams } from "../../utils/queryParams"
import { H6 } from "../../ui/H"

export const SettingsQualifications = {
    setup() {
        const data = reactive({conclusion_codes:[]})
        async function refresh() {
            API.getConclusionCodes().then(x=>data.conclusion_codes = x)
        }
        onMounted(refresh)

        async function createConclusionCode() {
            const form = reactive({
                code:"",
                name:"",
                description:""
            })

            const validate_code = x => !data.conclusion_codes?.find(c=>c.code.toLowerCase() === x?.trim().toLowerCase())
            const validate_description = x => x.trim().length>0

            const res = await modal(({close}) =>
            <Dialog close={close} onSubmit={()=>close(form)}
                    className="dialog"
                    header={t("New conclusion code")}
                    body={<>
                        <p>{t("Conclusion code")}<Input validate={validate_code} value={form.code} onInput={e=>form.code=e.target.value}/></p>
                        <p>{t("Name")}<Input validate={x=>!!x?.trim()} value={form.name} onInput={e=>form.name=e.target.value}/></p>
                        <p>{t("Example")}<Input validate={validate_description} value={form.description} onInput={e=>form.description=e.target.value}/></p>
                    </>}
                    buttons={<>
                        <Button disabled={!validate_code(form.name) || !form.name?.trim() || !validate_description(form.description)} type="submit">OK</Button>
                        <Button secondary onClick={()=>close()}>{t("Cancel")}</Button>
                    </>}
            />)
            if(!res) return
            let { name, code, description} = res
            name = name.trim()
            await API.createConclusionCode({name:code + " " + name, description})
            refresh()
        }

        async function toggleFastClose(x) {
            await API.setConclusionCode(x.id, {fast_close:!x.fast_close})
            refresh()
        }

        return ()=>{
            let conclusion_codes = data.conclusion_codes
            if(queryParams().search) conclusion_codes = conclusion_codes?.filter(t=>
                    t.name?.toLowerCase().startsWith(queryParams().search.toLowerCase()) ||
                    t.code?.toLowerCase().startsWith(queryParams().search.toLowerCase())
            )
            return <div id="qualifications-settings">
            <h3>Qualifications</h3>
            <DataTable
                header={<Button secondary onClick={()=>createConclusionCode()}>{ICON("add")} {t("New qualification")}</Button>}
                data={conclusion_codes}
                columns={[
                    {title:t("Code"), render:x=><H6 value={x.code} render={x=>Qualification({code:x})} onSave={(v)=>API.setConclusionCode(x.id, {code:v}).then(refresh)}/>},
                    {title:t("Description"), render:x=><H6 value={x.name} onSave={(v)=>API.setConclusionCode(x.id, {name:v}).then(refresh)}/>},
                    {title:t("Example"), render:x=><H6 value={x.description} onSave={(v)=>API.setConclusionCode(x.id, {description:v}).then(refresh)}/>},
                    {title:t("Allow fast close"), render:x=><Switch value={x.fast_close} onClick={()=>toggleFastClose(x)}/>}
                ]}
            />
            </div>
        }
    }
}

