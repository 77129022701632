import { API, model } from "../model"
import { ICON } from "../ui/icons"
import { Badge } from "../ui/Badge"
import { AddButton } from '../ui/AddButton'
import {optionsFromQueryParams, queryParams, setQueryParams} from "../utils/queryParams"
import {Right} from "../ui/Right"
import './Scopes.scss'
import { Dropdown, DropdownItem } from "../ui/Dropdown"
import { DataTable } from "../ui/DataTable"
import { t } from "../i18n"
import { Switch } from "../ui/Switch"
import { goTo } from "../utils/routing"
import {Link} from "../utils/router"
import {link} from "../utils/routing"
import { readableNumber } from "../util"
import { reactive } from "@vue/reactivity"
import { LiveQuery } from "../api/livequery"
import { Recos } from "../ui/Recos"
import { Spinner } from "../ui/Spinner"
import { Level } from "selenium-webdriver/lib/logging"
import { useCurrentUserStore } from "../store/CurrentUserStore"

const userStore = useCurrentUserStore

function newScope() {
    goTo("newscope")
}

export const Scopes = {
    setup() {
        const data = reactive({scopes:false})

        // TODO: MISSING FIELDS : created_at, updated_at, 

        const lq = LiveQuery("scopes", `{
            count, pages, 
            items {
                id, display_name, logo, locked, kibana_space, kpis, kpi_alerts, kpi_cases, kpi_incidents, 
                role, kpi_recommendations, kpi_scenarios, recos_by_status
            }
        }`, s=>data.scopes=s, ()=>[{sort: "display_name",...optionsFromQueryParams(), limit:parseInt(queryParams().limit) || 20}])

        return () => {
            return <div id="scopes">
                <h1>{t("My scopes")}
                    <Right><Dropdown button={queryParams().view==="grid" ? ICON("grid") : ICON("list")} items={()=><>
                        <DropdownItem onClick={()=>setQueryParams({view:"grid"})}>{ICON("grid")} Afficher en grille</DropdownItem>
                        <DropdownItem onClick={()=>setQueryParams({view:null})}>{ICON("list")} Afficher en liste</DropdownItem>
                    </>}/></Right>
                    <Left>
                        {userStore.hasPermissions("scopes", "write_private") && <div id="new-scope"><AddButton onClick={newScope}>Nouveau scope</AddButton></div>}
                    </Left>
                </h1>
                {queryParams().view === "grid" ? <Grid scopes={data.scopes}/> : <List scopes={data.scopes} loading={lq.loading} />}
            </div>
        }
    }
}

const List = ({scopes, loading}) => {

    function toggleLock(scope) { API.setScope(scope.id, {locked:!scope.locked})}
    return <>
        <DataTable 
        loading={loading}
        header={<>
            {userStore.hasPermissions("scopes", "write_private") && <AddButton onClick={newScope}>{t("New Scope")}</AddButton>}
        </>}
        loading={scopes===false}
        data={scopes}
        columns={[
            {title:t('Logo'), type:'logo', render:x=><img src={x.logo} alt=''/>},
            {title:t('Name'), type:'name', render:x=><Link href={link(`/scope/${x.id}`)}>{x.display_name}</Link>},
            {title:t('Role'), type:"role", render:x=>t(x.role)},
            {title:t('# Logs'), type:"counts", render:x=><>
                <div>{ICON('network')} {readableNumber(Math.round(x.kpis.kpi_network))}</div>
                <div>{ICON('computer')} {readableNumber(Math.round(x.kpis.kpi_agents))}</div>
                <div>{ICON('cloud')} {readableNumber(Math.round(x.kpis.kpi_cloud))}</div>
            </>},
            {title:t('KPIs'), type:'counts', render:x=><>
                <div>{x.kpi_alerts>0 && <>{ICON("alert")} {readableNumber(x.kpi_alerts)}</>}</div>
                <div>{x.kpi_cases>0 && <>{ICON("case")} {x.kpi_cases}</>}</div>
                <div>{x.kpi_incidents>0 && <>{ICON("incident")} {x.kpi_incidents}</>}</div>
                <div>{x.kpi_scenarios>0 && <>{ICON("scenario")} {x.kpi_scenarios}</>}</div>
            </>},
            {title:t('Recommendations'), type:'recos', render:Recos},
            userStore.hasPermissions("scopes", "read_private") && {title:t('Kibana space'), type:"kibana-space", render:x=>x.kibana_space},
            userStore.hasPermissions("scopes", "write_private") && {title:t('Locked'), noLink:true, render:x=><Switch value={x.locked} onClick={()=>toggleLock(x)}/>},
        ]}
        />
    </>
}


const Grid = ({scopes}) => <><div class="grid">
    {scopes?.items.map(({id, logo, display_name, role="analyst", kpi_alerts=0, kpi_cases=0, kpi_incidents=0, kpi_recommendations=0, kpi_scenarios=0})=><div> 
        <router-link to={link(`/scope/${id}/`)}>
        <img src={logo}/>
        <div>{display_name}</div>
        <i>{role}</i>
        <div>
            <Badge>{ICON("alert")} {readableNumber(kpi_alerts)}</Badge>
            <Badge>{ICON("case")} {kpi_cases}</Badge>
            <Badge>{ICON("incident")} {kpi_incidents}</Badge>
            <Badge>{ICON("scenario")} {kpi_scenarios}</Badge>
            <Badge>{ICON("recommendation")} {kpi_recommendations}</Badge>
        </div>
        </router-link>
    </div>)}
    </div>
</>