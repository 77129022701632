import { reactive, watch, watchEffect } from "@vue/runtime-core"
import { t } from "../i18n"
import { TagsMixin } from "../mixins/Tags"
import { model, API, getMembers } from "../model"
import { Accordion } from "../ui/Accordion"
import { Button } from "../ui/Button"
import { Date } from "../ui/Date"
import { Description } from "../ui/Description"
import { H1 } from "../ui/H"
import { ICON } from "../ui/icons"
import { Related } from "../ui/Related"
import { Sidebar } from "../ui/Sidebar"
import { Spinner } from "../ui/Spinner"
import { Switch } from "../ui/Switch"
import { Timeline } from "../ui/Timeline"
import {capitalize, debounce} from "../util"
import { gotoScope } from "../utils/routing"
import './Scenario.scss'
import { useCurrentUserStore } from "../store/CurrentUserStore"

const userStore = useCurrentUserStore

const VISIBILITIES = ["public", "internal", "private", "waiting_for_response"]


export const Scenario = {
    setup() {
        const data = reactive({
            related_cases:[],
            related_recommendations:[],
            vars:{
                related_cases:{},
                related_recommendations:{}
            }
        })

        function setTitle(t) { API.setScenarioTitle(model.scenarioId, t) }
        function setDescription(d) { API.setScenarioDescription(model.scenarioId, d) }
        function setCreatedAt(created_at) { API.setScenario(model.scenarioId, {created_at})}                    
        function setUpdatedAt(updated_at) { API.setScenario(model.scenarioId, {updated_at})}  
        function setPublic(is_public) { API.setScenario(model.scenarioId, {is_public})}

        const {Tags} = TagsMixin("Scenario")

        function sendMessage(body, visibility, files) {
            API.addComment("scenario", model.scenarioId, files, {body, visibility})
            Array.from(body.matchAll(/#\\\[(.+?):(.+?)\\]\(.+?\)/gm)).forEach((x) => {
                const [_,type,id] = x
                API.addRelated("Scenario", model.scenarioId, capitalize(type), id)
            })
        }
        function setVisibility(comment, visibility) { API.setComment(comment.id, {visibility}) }
        function saveMessage(comment, body) { API.setComment(comment.id, {body}) }
        function deleteMessage(comment) { API.removeComment(comment.id)} 
        function deleteCommentObject(comment, o) { API.deleteCommentObject(comment.id, o.objType, o.objId) }

        function addRelatedRecommendation(r) { API.addRelatedRecommendation("scenario", model.scenarioId, r.id) }
        function removeRelatedRecommendation(r) { API.removeRelatedRecommendation("scenario", model.scenarioId, r.id) }
        function newRecommendation() { gotoScope(`newrecommendation?related_scenarios=${model.scenarioId}`); }
        function addRelatedCase(r) { API.addRelatedCase("scenario", model.scenarioId, r.id) }
        function removeRelatedCase(r) { API.removeRelatedCase("scenario", model.scenarioId, r.id) }
        function newCase() { gotoScope(`newcase?related_scenarios=${model.scenarioId}`); }


        const fetchMembers = async x => (await queryMembers(x)).map(m=>"@"+m.user.display_name.replace(/\s/g, "_"))
        const fetchObjects = async x => (await API.getObjects({search:x, scope_id:model.scope.id, type:["case", "recommendation"]}))
                .map(({id,type,title})=>'#['+type+":"+id+"]("+title+")")

        const queryMembers = search => getMembers({search})
        const queryRecommendations = filter => API.getRecommendations({scope_id:model.scope.id, search:filter, order:"desc", sort:"updated_at"})
        const queryCases = filter => API.getCases({scope_id:model.scope.id, search:filter, order:"desc", sort:"updated_at"})

        watchEffect(async ()=>data.related_cases = model.scenario?.id && model.scopeId && await API.getRelatedCases("scenario", model.scopeId, model.scenario.id, data.vars.related_cases))
        watchEffect(async ()=>data.related_recommendations = model.scenario?.id && model.scopeId && await API.getRelatedRecommendations("scenario", model.scopeId, model.scenario.id, data.vars.related_recommendations))

        return ()=>{
        if(!model.scenario) return <Spinner/>

        const {tags, body, comments, created_at, updated_at, is_public} = model.scenario

        const allowed_tags = model.scope?.tags

        const RW = userStore.hasPermissions("scenarios", "write_private")

        return <div id="scenario" class="page with-sidebar">
            <div>
            <div class="sticky-header">
                <h1>
                    <div class="flex">
                        <div class="title-wrapper">
                            <H1 class="title" value={model.scenario.title}
                                onSave={RW && setTitle} 
                                render={x=><h1>{ICON('scenario')} {x}</h1>}
                                />
                        </div>
                    </div>
                    <div class="actions">
                    </div>
                </h1>

                <div class="row">
                     {RW && <Switch name="Public" value={is_public} onClick={()=>setPublic(!is_public)}>{t("Public")}</Switch> }

                    {Tags(model.scenario)}
                </div>

                <Description
                    title="Description"
                    editable={RW}
                    source={body}
                    onSave={setDescription}
                    />
                <br/>
                </div>
                <div>
                    <Timeline
                        items={comments} 
                        onSend={sendMessage}
                        
                        editable={RW}
                        onSave={saveMessage}
                        onDelete={deleteMessage}
                        visibilities={RW && VISIBILITIES}
                        setVisibility={RW && setVisibility}

                        fetchUsers={fetchMembers}
                        fetchObjects={fetchObjects}

                        onDeleteObject={RW && deleteCommentObject}
                        previousSelector={".sticky-header"}
                    />
                </div>
            </div>

            <Sidebar>

                <Accordion>

                    <div class="dates">
                        <div>{t("Created")}</div><div><Date onSave={RW && setCreatedAt} value={created_at}/></div>
                        <div>{t("Last activity")}</div><div><Date onSave={RW && setUpdatedAt} value={updated_at}/></div>
                    </div>

                    <Related type='recommendation' title="Recommendations" items={data.related_recommendations}
                        query={queryRecommendations}
                        editable={RW}
                        watch={()=>model.scenario}
                        onAdd={addRelatedRecommendation}
                        onDelete={removeRelatedRecommendation}
                        addTooltip="Add related recommendation"
                        update={debounce(v=>data.vars.related_recommendations.search=v?.search)}
                        footer={<Button secondary onClick={newRecommendation}>{ICON('add')} {t("New recommendation")}</Button>}
                    />

                    <Related type='case' title="Cases" items={data.related_cases}
                        class="no-dates"
                        editable={RW}
                        query={queryCases}
                        watch={()=>model.scenario}
                        onAdd={addRelatedCase}
                        onDelete={removeRelatedCase}
                        addTooltip="Add related case"
                        update={debounce(v=>data.vars.related_cases.search=v?.search)}
                        footer={<Button secondary onClick={newCase}>{ICON('add')} {t("New case")}</Button>}
                    />

                </Accordion>

            </Sidebar>
        </div>
        }
    }
}




