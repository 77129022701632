import { reactive } from '@vue/reactivity'
import { t } from '../i18n'
import { IconButton } from './Button'
import './Description.scss'
import { ICON } from './icons'
import { Markdown } from './Markdown'

export const Description = {
    props:["editable", "placeholder", "source", "onSave", 'title', "editing", "onInput"],
    setup(props) {
        const data = reactive({
            editing:props.editing || false
        })
        return ()=>{
            return <div class="description">
            <b>
                {t(props.title)} 
                {props.editable && props.onSave && <IconButton onClick={()=>data.editing=true}>{ICON("edit")}</IconButton>}
            </b>
            <Markdown 
                editable={props.editable}
                editing={data.editing}
                source={props.source || ""}
                onSave={props.onSave && (x=>{data.editing=false; props.onSave(x)})}
                onCancel={props.onSave && (()=>{data.editing=false; })}
                onInput={props.onInput}
                placeholder={props.placeholder}
            />
            </div>
        }
    }
}