import { t } from "../../i18n"
import { Input } from "../../ui/Input"
import { Description } from "../../ui/Description"
import { reactive } from "@vue/reactivity"
import "./RequestInvestigation.scss"
import { API, model } from "../../model"
import { Button, FileButton } from "../../ui/Button"
import { gotoScope, scopeLink } from "../../utils/routing"
import { DeleteButton } from "../../ui/Button"
import { ICON } from "../../ui/icons"
import { useCurrentUserStore } from "../../store/CurrentUserStore"

export const RequestInvestigation = {
    setup(props) {
        const userStore = useCurrentUserStore
        const data = reactive({
            title: "",
            body: "",
            message: "Bonjour, pourriez-vous procéder à l'investigation de cette activité suspecte svp ?\nMerci !\n\n\n" + userStore.user.display_name,
            files: [],
        })

        async function send() {
            const id = (await API.createCase({
                scope_id: model.scopeId,
                body: data.body,
                title: data.title,
                is_public: true,
                type: "investigation",
                status: "pending",
            }))?.id
            if(id) await API.addComment("Case", id, data.files, {body:data.message, visibility:"waiting_for_response"})
            gotoScope("")
        }

        return ()=>{
            return <div id="request-investigation">
                <h1>{t("Investigation request")}</h1>
                
                <p class="info">
                    Vous venez d'observer une activité réseau suspecte ? Un comportement utilisateur inhabituel ? 
                    <br/>Décrivez cet événement à notre équipe d'analystes afin que
                    nous procédions à une levée de doute.
                </p>
                
                <p><b><em>1</em>Décrivez l'activité suspecte en quelques mots</b>
                </p>
                
                <h1>
                <Input placeholder={t("Exemples : \"Email d'hameçonnage avec lien suspect\", \"Navigation internet vers sites inhabituels depuis un poste admin\", \"Nouvel utilisateur non-identifié sur serveur SRV123\", etc")} 
                        value={data.title} 
                        onInput={e=>data.title = e.target.value}
                        key={data.key}
                    />
                </h1>

                <Description
                    editing
                    title={<p><em>2</em>Donnez à notre SOC un maximum d'éléments de contexte      
                        <span><FileButton onChange={(e)=> data.files = Array.from(new Set([...data.files, ...Array.from(e.target.files)]))} class='attach'/> joindre des fichiers</span>
                        <p class="details">N'hesitez pas à y associer captures d'écran (via copier-coller), liens, pièces-jointes qui aideraient nos analystes dans leur investigation. 
                        Si possible, mentionnez la ou les machines (nom d'hôte, IP, domaine), comptes utilisateurs, concernés ainsi que la plage de temps approximative à investiguer</p>           
                    </p>}
                    source={data.body}
                    onInput={x=>data.body = x}
                />
                {data.files.map(f=><span class="attachment"><a class='download-file' href={"#"}>{ICON('fas fa-file')} {f.name}</a><DeleteButton onClick={()=>data.files = data.files?.filter(x=>x!==f)}/></span>)}

                

                <Description
                    editing
                    title={<p><em>3</em>Si désiré, complétez votre demande par un message qui sera directement adressé à nos analystes</p>}
                    source={data.message}
                    onInput={x=>data.message = x}
                />


                <footer>          
                <p class="warn">
                    <b>ATTENTION</b> Ne mentionnez dans votre message aucun élément sensible tel que mot de passe, identifiants bancaires, <i>etc</i>.
                    <br/>Notre SOC vous indiquera en réponse à votre demande la procédure à suivre pour un échange plus restreint de données personnelles ou confidentielles le cas échéant.
                </p>

                <Button disabled={!data.title || !data.message || !data.body} primary onClick={send}>Envoyer</Button>
                <Button secondary to={scopeLink("")}>Annuler</Button>
                </footer>
            </div>
        }
    }
}