import { onMounted, reactive, watch } from "vue"
import { IconButton } from "./Button"
import { ICON } from "./icons"
import './Menu.scss'
import { model, API } from "../model"
import { DropdownItem, Dropdown } from "./Dropdown"
import { useRoute } from "vue-router"
import { LiveQuery } from "../api/livequery"  
import { UI } from "../App"
import { Switch } from "./Switch"
import { getRouter } from "../utils/router"
import {link} from "../utils/routing"

import { scopeLink } from "../utils/routing"
import { t } from "../i18n"

import SCOPES_LOGO from "../../public/assets/astraia-icon-yellow.png"
import { Badge } from "./Badge"

import { useCurrentUserStore } from "../store/CurrentUserStore"

const MENUS = (newAlerts, openCases) =>
model.scopeId ? [
//  [isBeta,  to,                  icon,               text,          min_role,           submenus]
    [false, scopeLink("dashboard"), ICON("dashboard"), t("Dashboard"), {"subject": "cases", "action": "read"}],
    [false, scopeLink("messages"), ICON("mail", model.messages_stats?.unread>0 ? model.messages_stats?.unread : undefined), t("Messages")],
    model.scope?.has_carto && [false, scopeLink("assets"), ICON("asset"), t("Assets"), {"subject": "assets", "action": "read"}],
    [false, scopeLink("documents"), ICON("documents"), t("Tracking"), {"subject": "cases", "action": "read"}],
    [false, scopeLink("alerts"), ICON("alert", newAlerts > 0 ? newAlerts : undefined),t("Alerts"), {"subject": "alerts", "action": "read_private"}],
    [false, scopeLink("cases"), ICON("case", openCases > 0 ? openCases : undefined), t("Cases"), {"subject": "cases", "action": "read"}],
    [false, scopeLink("audit"), ICON("audit"), "Audit", {"subject": "diags", "action": "read"}],
    [false, scopeLink("findings"), ICON("finding"), t("Findings"), {"subject": "findings", "action": "read"}],
    [false, scopeLink("recommendations"), ICON("recommendation"), t("Action plan"), {"subject": "recommendations", "action": "read"}],
    [false, scopeLink("scenarios"), ICON("scenario"), t("Scenarios"), {"subject": "scenarios", "action": "read_private"}, [
        [false, scopeLink("whitelists"), null, t("Whitelists"), {"subject": "whitelists", "action": "read_private"}],
    ]],
    [false, scopeLink("agents"), ICON("computer"), t("Agents"), {"subject": "agents", "action": "read_private"}],
    [false, scopeLink("iocs"), ICON("ioc"), t("IOCs"), {"subject": "iocs", "action": "read_private"}],
    [false, scopeLink("vulnerabilities"), ICON("vulnerability"), t("Vulnerabilities"), {"subject": "vulnerabilities", "action": "read_private"}],
    [false, scopeLink("members"), ICON("members"), t('Members'), {"subject": "users", "action": "read_private"}, 
        [
            [false, scopeLink("settings/notifications"), null, t("Notifications"), {"subject": "roles", "action": "read_private"}],
        ]
    ],
    [false, scopeLink("settings"), ICON("settings"), t("Settings"), {"subject": "scopes", "action": "write"}, 
        [
            [false, scopeLink("settings/tags"), null, t("Tags"), {"subject": "tags", "action": "read_private"}],
            [false, scopeLink("settings/drilldowns"), null, t("Drilldowns"), {"subject": "drilldowns", "action": "read_private"} ],
            [false, scopeLink("settings/maestro"), null, t("Maestro"), {"subject": "scopes", "action": "read_admin"}],
            [false, scopeLink("settings/saved-alerts-searches"), null, t("Saved Searches"), {"subject": "alerts", "action": "read_private"}],
            [false, scopeLink("settings/shuffle-workflows"), null, t("Shuffle workflows"), {"subject": "shuffle", "action": "read_private"}],
            [false, scopeLink("settings/shuffle-authentications"), null, t("Shuffle auths"), {"subject": "shuffle", "action": "read_private"}]
        ]
    ],
]
: [
    [false, link("/scopes"), ICON("scopes"), t("Scopes")],
    [false, link("/audits"), ICON("audit"), "Audit", {"subject": "diags", "action": "read"}],
    [false, link("/messages"), ICON("mail", model.messages_stats?.unread>0 ? model.messages_stats?.unread : undefined),t("Messages")],
    [false, link("/alerts"), ICON("alert", newAlerts > 0 ? newAlerts : undefined), t("Alerts"), {"subject": "alerts", "action": "read_private"}],
    [false, link("/cases"), ICON("case", openCases > 0 ? openCases : undefined), t("Cases"), {"subject": "cases", "action": "read_private"}],
    [false, link("findings"), ICON("finding"), t("Findings"), {"subject": "findings", "action": "read_private"}],
    [false, link("/recommendations"), ICON("recommendation"), t("Action plan"), {"subject": "recommendations", "action": "read_private"}],
    [false, link("/agents"), ICON("computer"), t("Agents"), {"subject": "agents", "action": "read_private"}, 
        [
            [false, link("/agents/settings"), null, t("Settings"), {"subject": "agents", "action": "read_private"}],
        ]
    ],
    [false, link("/scenarios"), ICON("scenario"), t("Scenarios"), {"subject": "scenarios", "action": "read_private"}, [
        [false, scopeLink("rules"), null, t("Rules"), {"subject": "rules", "action": "read_private"}],
        [false, scopeLink("whitelists"), null, t("Whitelists"), {"subject": "whitelists", "action": "read_private"}],
    ]],
    [false, link("/iocs"), ICON("ioc"), t("IOCs"), {"subject": "iocs", "action": "read_private"}],
    [false, link("/vulnerabilities"), ICON("vulnerability"), t("Vulnerabilities"), {"subject": "vulnerabilities", "action": "read_private"}],
    [false, link("/users"), ICON("members"), t("Users"), {"subject": "users", "action": "read_all"}],
    [false, link("/rules-coverage"), ICON("rule"), t("Rules coverage"), {"subject": "rules_ci", "action": "read_private"}, 
        [
            [false, link("/rules/attack-coverage"), null, t("Att&ck Coverage"), {"subject": "attack_coverages", "action": "read"}],
        ]
    ],
    [false, link("/settings"), ICON("settings"), t("Settings"), {"subject": "settings", "action": "read"}, 
        [
            [false, link("/settings/tags"), null, t("Tags"), {"subject": "tags", "action": "read_private"}],
            [false, link("/settings/qualifications"), null, t("Qualifications"), {"subject": "conclusion_codes", "action": "read_private"}], 
            [false, link("/settings/drilldowns"), null, t("Drilldowns"), {"subject": "drilldowns", "action": "read_private"}],
            [false, link("/settings/backups"), null, t("Backups"), {"subject": "backups", "action": "read_admin"}],
            [false, scopeLink("/settings/saved-alerts-searches"), null, t("Saved Searches"), {"subject": "alerts", "action": "read_private"}],
            [false, link(`/settings/rules-default-section`), null, t("Rules sections"), {"subject": "rules", "action": "read_private"}],
            [false, link(`/settings/api`), null, t("Api"), {"subject": "api", "action": "read_private"}],
        ] 
    ],
    
]

export const Menu = {
    setup() {
        const userStore = useCurrentUserStore

        const ui = reactive({
            collapse:false,
            new_alerts:0,
            case_open:0
        })
        function collapse() {
            ui.collapse = !ui.collapse
            document.body.classList.toggle("collapse-menu")
        }

        const is_selected = (to) => {
            const route = useRoute()
            return (to.name ? to.name===route.name : to===route.path) //|| to.includes(route.name)
        }

        const lq_alert_badge = LiveQuery("alert_groups_by_status", "", n=> ui.new_alerts = n.new,
        ()=>[{
        scope_id:model.scopeId,
        not_scope_ids: model.scopeId ? [] : (userStore.user.settings?.filteredScopes || []),
        }, {scope_id:model.scopeId}], 
        {subscription:"alert_groups", throttle:5000})

        const lq_case_badge = LiveQuery("cases_by_status", "", n=> ui.case_open = n.open,
        ()=>[{
        scope_id:model.scopeId,
        not_scope_ids: model.scopeId ? [] : (userStore.user.settings?.filteredScopes || []),
        }, {scope_id:model.scopeId}], 
        {subscription:"cases", throttle:5000})

        return () => {
            const scope = model.scope
            const scopes = model.scopes || []
            const logo = scope?.logo
            const title = scope?.display_name || "Mes scopes"


            function replaceRouteScope(id) {
                let p = getRouter().currentRoute?.value.fullPath
                // When changing scope, stay on same page for DO lists
                if(["assets","messages","alerts","cases","recommendations","settings","scenarios","report","vulnerabilities","members","documents","iocs","agents"].some(x=>p.includes(x))) {
                    p = "/" + p.split("/").slice(2).join("/")
                    if(id===null) {
                        p = p.replace(/\/scope\/[^/]+\//,"/")
                    } else {
                        if(!p.match(/\/scope\/[^/]+\//)) p = "/scope/"+id+p
                        else p = p.replace(/\/scope\/[^/]+\//,"/scope/"+id+"/")
                        p = p.replace(/page=\d*/, "page=1") // Go back to page 1 if changing scope in table
                    }

                    return link(p)
                } 
                // Otherwise goto default page
                else {
                    if(id===null) return link("/")
                    else return link("/scope/" + id + "/")
                }
            }

            return <div id="menu" >
                    <IconButton id="collapse" onClick={collapse}>{ICON(ui.collapse ? "fas fa-bars" : "fas fa-chevron-left")}</IconButton>

                    <div id="logo">
                        <div><img src={logo} onError={(e)=>e.target.src=SCOPES_LOGO}/></div>
                        <h2>
                            {
                            (scopes.length>1) ?
                            <Dropdown button={<>{title}{scope?.locked && <>&nbsp;{ICON('lock')}</>}</>} items={()=><>
                                <DropdownItem class="scope" to={replaceRouteScope(null)}>{ICON("scopes")}Tous mes scopes</DropdownItem>
                                {scopes.map(({id, display_name,logo,locked})=><DropdownItem to={replaceRouteScope(id)}>
                                    <img src={logo} alt=''/>
                                    {display_name}
                                    {locked && <>&nbsp;{ICON('lock')}</>}
                                </DropdownItem>)}
                            </>}/>
                            : 
                            title
                            }
                        </h2>
                    </div>

                    <ul>
                        {MENUS(ui.new_alerts, ui.case_open).filter(x=>x).map(([isBeta,to,icon,text,permission,submenus=[]])=>{
                            if(!!permission && !userStore.hasPermissions(permission.subject, permission.action)) return;
                            const selected = is_selected(to) || submenus?.some(([_,to])=>is_selected(to))
                            return <>
                                <Item selected={is_selected(to)} key={to} to={to} icon={icon}>
                                    {text} {isBeta && <span class={"beta"}>Beta</span>} 
                                </Item>
                                {selected && submenus.map(([isBeta,to,icon,text,permission])=>
                                    (!permission || userStore.hasPermissions(permission.subject, permission.action)) && 
                                    <Item class="submenu" selected={is_selected(to)} key={to} to={to} icon={icon}>
                                        {text} {isBeta && <span class={"beta"}>Beta</span>} 
                                    </Item>
                                )}
                            </>
                        })}
                    </ul>

                    {model.scope?.kibana_space && userStore.hasPermissions("alerts", "read_private") && <a href={`https://astraia.ct-squa.re/kb/s/${model.scope?.kibana_space}/app/dashboards`} target="_blank"><IconButton class="tool" title="kibana">{ICON("kibana")}</IconButton></a>}
                </div>
        }
    }
}   


const Item = ({to, icon, selected=false}, {slots}) => <li>
    <router-link to={to} class={{selected}}>
    {icon} <span>{slots.default()}</span>
    </router-link>
</li>