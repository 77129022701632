import { API } from "../../model"
import { LiveQuery } from "../../api/livequery"
import { model } from "../../model"
import { optionsFromQueryParams } from "../../utils/queryParams"
import { ASSET_ICON, ICON } from "../../ui/icons"
import { modal } from "../../ui/Modal"
import { Dialog } from "../../ui/Dialog"
import { watch } from "@vue/runtime-core"
import { reactive } from "@vue/reactivity"
import { showAssetJSON, sort_ip } from "../Assets"
import { t, t_n } from "../../i18n"
import { DataTable } from "../../ui/DataTable"
import { Button, IconButton, ToolButton } from "../../ui/Button"
import { Input } from "../../ui/Input"
import { capitalize } from "../../util"
import { Dropdown, DropdownItem } from "../../ui/Dropdown"
import { Box } from "../../ui/Grid"
import "../Assets.scss"
import { Badge } from "../../ui/Badge"
import { TagsMixin } from "../../mixins/Tags"
import { useCurrentUserStore } from "../../store/CurrentUserStore"

        
const DEVICE_KINDS = {
    laptop:'Laptop',
    router:'Router',
    firewall:'Firewall',
    'voip-terminal':'Voip-terminal',
    'mobile-phone':'Mobile Phone',
    printer:'Printer',
    server:'Server',
    workstation:'Workstation',
    'domain-controller':'Domain controller',
    switch:'Switch',
    nas:'nas',
    'raspberry-pi':'Raspberry-pi',
    hypervisor:'Hypervisor',
    camera:'Camera'
}

const PALETTE = (x) => ({
    workstation: 'blue',
    laptop: 'cyan',
    server: 'orange',
    'domain-controller': 'gold',
    hypervisor: 'goldenrod',
    router: 'violet',
    switch: 'purple',
    firewall: 'peru',
    printer: 'lightgrey',
    'voip-terminal': 'green',
    'mobile-phone': 'lime',
    nas: 'grey',
    'raspberry-pi': 'pink',
    tcp: 'blue',
    udp: 'orange',
}[x] || "")

export const DevicesTable = {
    props:["onError", "refresh"],
    setup(props) {
        const lq = LiveQuery("assets", `{
            count, pages, items {
                type, data, nb_ifaces, nb_services, nb_datasets, nb_accounts
            }
        }`, s => model.devices = s,
            () => model.scope?.has_carto && [{
                scope_id: model.scopeId,
                type: "device",
                ...optionsFromQueryParams(),
            }, {scope_id: model.scopeId}],
            {onError:props?.onError}
        )

        watch(()=>model.scopeId, ()=>{model.devices = null;})


        async function createDevice() {
            const data = reactive({
                hostname:""
            })
            const r = await modal(({close}) => 
                <Dialog close={close} onSubmit={() => close(data)}
                        className='dialog'
                        header={t('New device')}
                        body={<>
                                <Input label="Hostname" value={data.hostname || ""} onChange={x=>data.hostname = x.target.value}/>
                        </>}
                        buttons={<>
                            <Button secondary onClick={()=>close(null)}>{t("Cancel")}</Button>
                            <Button type="submit" >{t("Add")}</Button>
                        </>}
                />
            )
            if(!r) return;
            await API.createAsset("device", model.scopeId, data)
            lq?.refresh()
            props.refresh?.()
        }
        
        async function deleteDevice(x) {
            await API.deleteAsset(x.data.id, model.scopeId, "device")
            lq?.refresh()
            props.refresh?.()
        }

        async function updateDeviceKind(id, k) {
            await API.updateAsset("device", id, model.scopeId, {'kind':[k]})
            lq?.refresh()
        }

        async function editHostnameDomain(x) {
            const data = reactive({
                "hostname": x.data.hostname || "",
                "domain": x.data.domain || "",
            })
            const r = await modal(({close})=><Dialog 
                onSubmit={()=>close(data)}
                body={<>
                    <Input label={t("Hostname")} value={data.hostname} onChange={x=>data.hostname = x.target.value}/>
                    <Input label={t("Domain")} value={data.domain} onChange={x=>data.domain = x.target.value}/>
                </>}
                buttons={<>
                    <Button type="submit" onClick={()=>close(data)}>OK</Button>
                    <Button onClick={()=>close(null)}>{t("Cancel")}</Button>
                </>}
            />)
            if(!r) return;
            await API.updateAsset("device", x.data.id, model.scopeId, data)
            lq?.refresh()
        }

        const {Tags} = TagsMixin("asset", {
            onAdd:(t,x)=>API.updateAsset("device", x.id, model.scopeId, {"tags":t}),
            onRemove:(t,x)=>API.updateAsset("device", x.id, model.scopeId, {"untag":t}),
            refresh:()=>lq?.refresh()
        })
        
        const userStore = useCurrentUserStore

        return ()=><DataTable {...props}            
            data={model.devices}
            loading={lq.loading}

            header={
                userStore.hasPermissions("assets", "write_private") && <Button  onClick={createDevice} >{ICON("add")} {t("New device")}</Button>
            }

            columns={ [
                {type:'type', render: x => !userStore.hasPermissions("assets", "write_private") ? <>{ASSET_ICON(x.data.kind)} <div style={{whiteSpace:"nowrap",paddingLeft:"8px"}}>{t(DEVICE_KINDS[x.data.kind])}</div></> :
                    <Dropdown button={<>{ASSET_ICON(x.data.kind)} {t(DEVICE_KINDS[x.data.kind])}</>} items={()=>
                        Object.keys(DEVICE_KINDS).map((k) => 
                            <DropdownItem onClick={()=>updateDeviceKind(x.data.id, k)}>
                                {ASSET_ICON(k)} {t(DEVICE_KINDS[k])}
                            </DropdownItem>)
                    }/>
                },
                {
                    title: t('Hostname'), render: x => <>
                        {x.data.hostname && x.data.domain ? <><span class='hostname'>{x.data.hostname}</span><span>.{x.data.domain}</span></>
                        : x.data.hostname ? <span class='hostname'>{x.data.hostname}</span>
                        : x.data.domain ? <span>{x.data.domain}</span>
                        : <span>-</span>}
                        {userStore.hasPermissions("assets", "write_private") && <IconButton class="hover" onClick={(e)=>{editHostnameDomain(x);e.stopPropagation();}}>{ICON("edit")}</IconButton>}
                    </>    
                    
                },
                {title: t('IP addresses'), type:'ips',
                    render: x => x.data.ips && <ul>
                        {x.data.ips.sort(sort_ip).slice(0,2).map((ip,i)=><li>
                            {ip}
                            {i==1 && x.data.ips.length>2 && <Badge style={{float:"right"}}>+ {x.data.ips.length-2}</Badge>}
                        </li>)}
                    </ul>
                },
                {
                    title: t('KPIs'), type: 'counts', render: x => <>
                        <div>{x.nb_ifaces > 0 &&
                        <span title={t('Interfaces')}>{ICON("iface")} {t_n(x.nb_ifaces, "iface")}</span>}</div>
                        <div>{x.nb_services > 0 &&
                        <span title={t('Services')}>{ICON("service")} {t_n(x.nb_services, "service")}</span>}</div>
                        <div>{x.nb_datasets > 0 &&
                        <span title={t('Datasets')}>{ICON("dataset")} {t_n(x.nb_datasets, "datasets")}</span>}</div>
                        <div>{x.nb_accounts > 0 &&
                        <span title={t('Accounts')}>{ICON("account")} {t_n(x.nb_accounts, "accounts")}</span>}</div>
                    </>
                },
                {title: t('Tags'), type:"tags",render: x => Tags(x.data) },
                {title: <>&nbsp;</>, type:'type', render: x => <>
                    <IconButton onClick={(e)=>{showAssetJSON(x.data); e.stopPropagation();}}>{ICON("json")}</IconButton>
                    {userStore.hasPermissions("assets", "write_private") && <IconButton onClick={(e)=>{deleteDevice(x); e.stopPropagation();}}>{ICON("trash")}</IconButton>}
                </>}
            ]}

            collapse={(x) => {
                if(x.nb_ifaces + x.nb_services + x.nb_datasets + x.nb_accounts + x.data.ips?.length <= 0) return false;
                
                return <div class='fields'>
                    {x.data.ifaces_ips?.length>0 && <Box>
                        <h2>{ICON("iface")} {capitalize(t(`Network`))}</h2>
                        <DataTable noSearch noToolbar
                            data={x.data.ifaces_ips}
                            columns={[
                                {title: t('Interface'), render: i => i.iface?.name || "?"},
                                {title: t('Vendor'), render: i => i.iface?.vendor || "?"},
                                {title: t('MAC'), render: i => i.iface?.mac || "?"},
                                {title: t('IP addresses'), render: i => <ul style={{columns:3}}>{i.ips?.sort(sort_ip).map(ip=><li>{ip}</li>)}</ul>},
                            ]}
                        />
                    </Box>}

                    {x.data.services_ips?.length>0 && <Box>
                        <h2>{ICON("service")} {capitalize(t(`Services`))}</h2>
                        <DataTable noSearch noToolbar
                            data={x.data.services_ips}
                            columns={[
                                {title: t('Service'), render: i => i.service?.kind[0] || "?"},
                                {title: t('Port'), render: i => i.service?.port || "?"},
                                {title: t('IP addresses'), type:'ips', render: i => <ul style={{columns:3}}>
                                    {i.ips?.sort(sort_ip).slice(0,3).map((ip,ii)=><li>
                                        {ip}
                                        {ii==2 && i.ips?.length>3 && <Badge>+{i.ips?.length-3}</Badge>}
                                    </li>)}
                                </ul>},
                            ]}
                        />
                    </Box>}
                    
                    
                    {x.nb_datasets > 0 && <Box>
                        <h2>{ICON("dataset")} {capitalize(t(`Datasets`))}</h2>
                        <DataTable noSearch noToolbar
                                data={x.data.datasets}
                                columns={[
                                    {title: t('Name'), render: i => i.name},
                                    {title: t('Kind'), render: x => <Tags tags={x.kind && x.kind.map((k) => ({tag: k, color: PALETTE(k)}))}/>},
                                ]}
                        />
                    </Box>}

                    {x.nb_accounts > 0 && <Box>
                        <h2>{ICON("account")} {capitalize(t(`Accounts`))}</h2>
                        <ul>
                            {x.data.accounts.map(a => <li>{a.login}</li>)}
                        </ul>
                    </Box>}
                </div>
            }}
        />
    }
}



