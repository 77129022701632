import { model, API, LQ } from "../model"
import { reactive } from "@vue/reactivity"
import { DataTable } from "../ui/DataTable"
import { optionsFromQueryParams, queryParams, setQueryParams } from "../utils/queryParams"
import { t, t_n } from "../i18n"
import { LiveQuery } from "../api/livequery"
import { ICON } from "../ui/icons"
import './Agents.scss'
import {Link} from "../utils/router"
import { Button, IconButton } from "../ui/Button"
import { modal } from "../ui/Modal"
import {Dialog} from "../ui/Dialog";
import {JSONView} from "../ui/JSONView";
import { Switch } from "../ui/Switch"
import { link } from "../utils/routing"
import { Dropdown, DropdownItem } from "../ui/Dropdown"
import { capitalize } from "../util"
import { Box } from "../ui/Grid"
import { Checkbox } from "../ui/Checkbox"
import { onMounted } from "vue"
import { useCurrentUserStore } from "../store/CurrentUserStore"

const userStore = useCurrentUserStore

const ITEMS = [
    "running",
    "not installed",
    "stopped",
    "installing",
    "stopping",
    "uninstalling",
    "error",
    "unknow"
]

export const ScopeItem = ({scope_id})=><div class="scope-item">
    <img src={model.scopes?.find(s=>s.id===scope_id)?.logo}/>
    <Link href={`scope/${scope_id}/agents` + location.search}>{model.scopes?.find(s=>s.id===scope_id)?.display_name}</Link>
</div>

export const AgentStatus = (st) => {
    if(st==null){
        return ICON("dont-know")
    }
    var {status} = st
    if(status!==null){
        switch(status){
            case 'running':
                return ICON("done")
            case 'not_installed':
                return ICON("not_installed")
            case 'stopped':
                return ICON("off")
            case 'installing':
                return <div class="double-icons" >{ICON("busy-moving")}{ICON('download')}</div>
            case 'stopping':
                return <div class="double-icons" >{ICON("busy-moving")}{ICON('off')}</div>
            case 'uninstalling':
                return <div>{ICON('trash')}</div>
            case 'starting':
                return <div class="double-icons" >{ICON("busy-moving")}{ICON('done')}</div>
            case 'error':
                return ICON("problem")
            default:
                return ICON("dont-know")
        }
    }
    else{
        return ICON("dont-know")
    }
 }

export const Agents = {
    setup() {

        const data = reactive({
            all: queryParams().visible == "all" ? true : false,
            selected: [],
            profileSelected: {name: "No profile"},
            defaultProfiles: new Map(),
            profiles: [],
            selectedFilter: [],
            loading: false,
            disabled: true
        })


        const setStatus = () =>{
            let status = queryParams().status
            status = status ? status.split(",") : null
            let selectedFilter = Array(ITEMS.length).fill(false)
            if(status){
                for(let i in ITEMS){
                    if(status.includes(ITEMS[i].replace(" ", "_"))){
                        selectedFilter[i] = true
                    }
                }
            }else{
                selectedFilter = Array(ITEMS.length).fill(true)
            }
            return selectedFilter
        }
        
        const lq = LiveQuery("agents", `{
            count,
            pages,
            items {
                id, hostname, last_seen, created_at, last_complete_status, status, profile_name, scope_id, visible
            }
        }`, c=>model.agents=c,
            () => [{
                scope_id:model.scopeId,
                current_statuses: queryParams().status?.split(",") || null,
                visible: queryParams().visible == "all" ? null : true,
                limit: parseInt(queryParams().limit) || 20,
                ...optionsFromQueryParams(),
            }, {scope_id:model.scopeId}],
            {interval:10000}
        )

    const setProfiles = async () => {
        data.loading = true
        for(let agentId of data.selected) {
            await API.updateAgent(agentId, {profile_id: data.profileSelected.id})
        }
        lq?.refresh()
        data.selected = []
        data.profileSelected = {name: "No profile"}
        data.loading = false
    }

    const applyDefaultProfile = () => {
        if(data.defaultProfiles.get("windows"))
            API.applyAgentDefaultProfile(model.scopeId, data.defaultProfiles.get("windows"), "windows")
        if(data.defaultProfiles.get("linux"))
            API.applyAgentDefaultProfile(model.scopeId, data.defaultProfiles.get("linux"), "linux")
        data.disabled = true
    }

    onMounted(()=>{
        API.getAgentsDefaultProfiles(model.scopeId).then((profile)=>{
            data.defaultProfiles = new Map()
            profile.map((p)=>{
                data.defaultProfiles.set(p.os, p.profile.name)
            })
        })
        API.getAgentProfiles().then((profiles)=>{data.profiles = profiles})
        data.selectedFilter = setStatus()
    })

    return() => {
        return <div id="agents">
            <h1>{t('Agents')}</h1>
            {model.scopeId && userStore.hasPermissions("agents", "read_private") && <Box class="profile-box" w="3">
                <div class="box-header">
                    <img src={model.scope?.logo} alt=''/>
                    <h2>{model.scope?.display_name}</h2>
                </div>
                <span>
                    <label>{t("Windows")}</label>
                    <Dropdown button={<>{data.defaultProfiles.get("windows") ? data.defaultProfiles.get("windows") : t("Profil")}</>} items={()=><>
                        {data.profiles.map((p)=>{
                            return <DropdownItem onClick={()=>{data.defaultProfiles.set("windows", p.name);data.disabled=false}}>{p.name}</DropdownItem>
                        })}
                    </>}/>
                </span>
                <span>
                    <label>{t("Linux")}</label>
                    <Dropdown button={<>{data.defaultProfiles?.get("linux") ? data.defaultProfiles.get("linux") : t("Profil")}</>} items={()=><>
                        {data.profiles.map((p)=>{
                            return <DropdownItem onClick={()=>{data.defaultProfiles.set("linux", p.name);data.disabled=false}}>{p.name}</DropdownItem>
                        })}
                    </>}/>
                </span>
                <span>
                    <Button secondary disabled={data.disabled} onClick={()=>applyDefaultProfile()} class="button">{t("Apply")}</Button>
                </span>
            </Box>}
            <DataTable
                headerLeft={
                    <div className="headerSwitch">
                      
                    {userStore.hasPermissions("agents", "read_private") &&
                    
                    <Switch value={data.all} 
                        onClick={()=>{
                            data.all = !data.all
                            data.all ? setQueryParams({visible: "all"}) : setQueryParams({visible: null})
                        }}>
                        {t("Show privates")}
                    </Switch>}
                    
                    <Dropdown button={t("Filters")} class="dropdown" items={()=><>{ITEMS.map((item, i)=>{
                            return <DropdownItem onClick={(e)=>{
                                data.selectedFilter[i] = !data.selectedFilter[i]
                                let selected = ITEMS.filter((_, i)=>data.selectedFilter[i])
                                selected = selected.map((e)=>e.replace(" ", "_"))
                                setQueryParams({status: selected.length ? selected.join(",") : null})
                                e.stopPropagation()

                            }}>
                                <Checkbox value={data.selectedFilter[i]}/>
                                {AgentStatus({status: item})}{t(capitalize(item))}
                            </DropdownItem>
                        })

                        }
                        </>}/>
                    
                    </div>}
                loading={lq.loading || data.loading}
                data={model.agents}
                columns={[
                    ...(model.scope ? [] : [{title:t("Scope"), type:'scope', render:ScopeItem}]),
                    {title:t('Status'), render:x=><AgentStatus status={x.status} />},
                    ...(userStore.hasPermissions("agents", "read_private") ? [{title:t('Hostname'), render:x=><div><Link href={link(`scope/${x.scope_id}/agent/${x.id}`)}>{x.hostname}</Link></div>, sort: "hostname"}] :
                    [{title:t('Hostname'), render:x=><div>{x.hostname}</div>, sort: "hostname"}]),
                    {title:t('OS Family'), render:x=><div>{x.last_complete_status?.host?.OSFamily}</div>},
                    {title:t('Agent Profile'), render:x=>x.profile_name, sort: "profile_id"},
                    {title:t('Creation date'), type:'date', render:x=>x.created_at, sort: "created_at"},
                    {title:t('Last activity'), type:'date', render:x=>x.last_seen, sort: "last_seen"},
                    {title: <>&nbsp;</>, type:'type', render: x => <>
                    <IconButton onClick={()=>showAgentJSON(x.last_complete_status)}>{ICON("json")}</IconButton>
                    </>},
                    ...(userStore.hasPermissions("agents", "read_private") ? [{title: <>&nbsp;</>, type:'type', render: x => <>
                        <IconButton onClick={async ()=>{
                            x.visible ? await API.updateAgent(x.id, {visible:false}) 
                            : await API.updateAgent(x.id, {visible:true})
                            lq?.refresh()
                        }}>{x.visible ? ICON("eye") : ICON("no-eye")}</IconButton>
                    </>}] : []),
                ]}

                headerBottom={
                    <div class="header-bottom">
                        <div class='action-bar'>
                            <div class='flex'>
                                <div class='actions' class={{hide: !data?.selected?.length}}>
                                <span class="selected-count">{t_n(data.selected.length, "Agent selected")}</span>
                                    <div class="operations">
                                        <Dropdown button={t(data.profileSelected.name)} items={()=><>
                                            {data.profiles.map((p)=>{
                                            return <DropdownItem onClick={()=>data.profileSelected = p}>{p.name}</DropdownItem>
                                            })}
                                        </>}/>
                                        <Button onClick={()=>setProfiles()} disabled={data.profileSelected.name == "No profile"}>{t("Apply")}</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                selection={userStore.hasPermissions("agents", "write_private") && data.selected}

            />
        </div>
    }},
}

function showAgentJSON(x) {
    return modal(({close}) =>
        <Dialog close={close} onSubmit={()=>close(null)}
                className="dialog"
                header={t("JSON Asset")}
                body={<JSONView source={x}/>}
        />
    )
}
