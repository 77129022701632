import {reactive, watchEffect} from 'vue'
import { Donut } from "../ui/Donut";
import './Dashboard.scss'
import { Badge } from "../ui/Badge"
import { DataTable } from '../ui/DataTable';
import {readableNumber, first_paragraph, sum} from "../util";
import { optionsFromQueryParams, queryParams, setQueryParams, watchQueryParams } from "../utils/queryParams";
import { Spinner } from "../ui/Spinner";
import { getTags, model } from "../model";
import { ICON } from "../ui/icons";
import { LiveQuery } from "../api/livequery";
import { Recos } from "../ui/Recos";
import {t_n, t_n_fem, t} from '../i18n'
import { Button } from "../ui/Button";
import { PALETTE } from "../ui/ui"
import {Link} from "../utils/router";
import {link, gotoScope, scopeLink} from "../utils/routing";
import {md} from "../ui/Markdown";
import { CaseStatus } from './Cases';
import { Tags } from '../ui/Tags';
import { Severity } from '../ui/Severity';
import { ago_to_date } from '../ui/Age';


export const Dashboard = {
    setup() {
        const data = reactive({allowed_tags:[]})
        
        const lq = LiveQuery("cases", `{
            items {
                id, scope_id, title, body, conclusion
                created_at, last_activity, 
                nb_alerts, type,
                recos_by_status, status, tags,
                conclusion_code {name, description, code},
                severity
            }, 
            count, pages
        }`, c=>model.dashboard_cases=c,
        ()=>!!model.scopeId && (queryParams().sort || queryParams().order) && [{
            scope_id:model.scopeId,
            ...optionsFromQueryParams(),
            days: 30,
            is_public: true,
        }, {scope_id:model.scopeId}]
        )
        
        LiveQuery("scope", `{
                cases_by_status, cases_by_type, recos_by_status, kpis, vulns_by_status
            }`, c=>model.scope_stats=c,
            ()=>!!model.scopeId && [{id:model.scopeId}], {
                subscription: "scope_stats"
        })
        
        LiveQuery("reports", "{year, month}", 
            i=>model.reports=i?.map(({month,year})=>({month:(""+month).padStart(2,"0"),year:""+year})), 
            ()=>!!model.scopeId && [{
                scope_id:model.scopeId,
            }]
        )
        
        // Enforce default sort/order
        watchQueryParams(({sort,order})=>{
            if(!sort && !order) setQueryParams({sort:"last_activity", order:"desc"}, true)
        })
        
        watchEffect(()=>{
            data.allowed_tags = getTags(model.scopeId, "Case")
        })

        return ()=>{
            const {scope, scope_stats} = model
            if(!scope || !scope_stats) return <Spinner/>

            const casesByStatus = [
                {label:t_n_fem(scope_stats.cases_by_status.pending, "pending"), data: scope_stats.cases_by_status.pending, color:PALETTE.pending, goTo: () => gotoScope("cases?status=pending&days=30&is_public=true")},
                {label:t_n_fem(scope_stats.cases_by_status.open, "open"), data: scope_stats.cases_by_status.open, color:PALETTE.open, goTo: () => gotoScope("cases?status=open&days=30&is_public=true")},
                {label:t_n_fem(scope_stats.cases_by_status.closed, "closed"), data: scope_stats.cases_by_status.closed, color:"#57bf57", goTo: () => gotoScope("cases?status=closed&days=30&is_public=true")},
            ]

            const casesByType = [
                {label:t_n_fem(scope_stats.cases_by_type.investigation, "investigation"), data: scope_stats.cases_by_type.investigation, color:PALETTE.investigation, showZero:true, goTo: () => gotoScope("cases?case_type=investigation&days=30&is_public=true")},
                {label:t_n_fem(scope_stats.cases_by_type.patrol, "patrol"), data: scope_stats.cases_by_type.patrol, color:PALETTE.patrol, showZero:true, goTo: () => gotoScope("cases?case_type=patrol&days=30&is_public=true")},
                {label:t_n(scope_stats.cases_by_type.audit, "audit"), data: scope_stats.cases_by_type.audit, color:PALETTE.audit, showZero:true, goTo: () => gotoScope("cases?case_type=audit&days=30&is_public=true")},
                {label:t_n(scope_stats.cases_by_type.incident, "incident"), data: scope_stats.cases_by_type.incident, color:PALETTE.incident, showZero:true, goTo: () => gotoScope("cases?case_type=incident&days=30&is_public=true")},
            ]

            const recosByStatus = [
                {label:t_n_fem(scope_stats.recos_by_status.open, "pending"), data: scope_stats.recos_by_status.open, color:"#bbb", goTo: () => gotoScope("recommendations?days=30&is_public=true&closed=false")},
                {label:t_n_fem(scope_stats.recos_by_status.closed, "validated"), data: scope_stats.recos_by_status.closed, color:"#57bf57", goTo: () => gotoScope("recommendations?days=30&is_public=true&closed=true")},
            ]

            const vulnsByStatus = [
                {label:t_n_fem(scope_stats.vulns_by_status.exposed, "to fix"), data: scope_stats.vulns_by_status.exposed, color:PALETTE.incident, goTo: () => gotoScope("vulnerabilities?status=exposed")},
                {label:t_n_fem(scope_stats.vulns_by_status.no_impact, "without impact"), data: scope_stats.vulns_by_status.no_impact, color:"#cdcdcd", goTo: () => gotoScope("vulnerabilities?status=no_impact")},
                {label:t_n_fem(scope_stats.vulns_by_status.patched, "patched"), data: scope_stats.vulns_by_status.patched, color:"#57bf57", goTo: () => gotoScope("vulnerabilities?status=patched")},
            ]

            return <div id="manager-dashboard">
                    <div>
                        <h1>
                            <span className="timespan"> {t("Last 30 days")}</span>
                            <div id='kpis'>
                                    <KPI title={t("Network logs")} id="network" icon={ICON('network')} value={scope_stats.kpis.kpi_network} />
                                    <KPI title={t("System logs")} id="agents" icon={ICON('computer')} value={scope_stats.kpis.kpi_agents} />
                                    <KPI title={t("Cloud logs")} id="cloud" icon={ICON('cloud')} value={scope_stats.kpis.kpi_cloud}/>
                            </div>
                        </h1>
                    </div>
                    <div id="charts">
                        <Donut class='responsive no-box' data={casesByType} title={t("Cases by type")} />
                        <Donut class='responsive no-box' data={casesByStatus} title={t("Cases by status")}/>{
                        }
                        { sum(scope_stats.recos_by_status) > 0 ? 
                            <Donut class='responsive' data={recosByStatus} title={t("Recommendations")}/> 
                            : <div class='chart donut responsive no-box'><p>{t("No recommendation")}</p></div>
                        }
                        { sum(scope_stats.vulns_by_status) > 0 ? 
                            <Donut class='responsive' data={vulnsByStatus} title={t("Vulnerabilities")}/> 
                            : <div class='chart donut responsive no-box'><p>{t("No vulnerability")}</p></div>
                        }
                    </div>
            
                    <div>
                        <DataTable
                        header={<>
                            {model.reports?.length>0 && <Button secondary to={scopeLink("reports")}>{ICON("documents")} {t("Monthly reports")}</Button>}
                            <Button to={scopeLink("request-investigation")}>{t("Request for investigation")}</Button>
                            <Button class="btn-danger" to={scopeLink("declare-incident")}>{t("Declare incident")}</Button>
                        </>}
                        loading={lq.loading}
                        data={model.dashboard_cases}
                        columns={[
                            {title:t('First activity'), type:'date', render:x=>x.created_at, sort:'created_at'},
                            {title:t('Last activity'), type:'date', render:x=>x.last_activity, sort:'last_activity'},
                            {title:"Type", render:({type="investigation", nb_alerts, tags})=><div class='type-column'>
                                <div class={{type:true, [type]:true}}>
                                    <span class={type}/>
                                    {t(type)}
                                    {!!nb_alerts && <Badge icon={ICON('alert')}>{nb_alerts}</Badge>}
                                </div>
                            </div>, sort:'case_type'},
                            {title:"Description", type:'description', render:x=><div class='description'>
                                <div><Link href={link(`scope/${x.scope_id}/case/${x.id}`)}>{x.title}</Link></div>
                                <div v-html={md.render(first_paragraph(x.body))}/>
                                {!!x.conclusion && <div class='conclusion'>{ICON("drilldown")} <span v-html={md.render(first_paragraph(x.conclusion))}/></div>}
                            </div>, sort:'body'},
                            {title:t("Status"), type:'status', render:x=><CaseStatus horizontal case={x}/>,sort:'status'},
                            {title:t('Severity'), render:x=><Severity val={x.severity}/>},
                            {title:t("Recommendations"), render:Recos, sort:'recommendations'},
                            {title:t("Tags"), render:x=><Tags tags={x.tags} allowed_tags={data.allowed_tags}/>}
                        ]}
                        emptyText={<div class="empty">{t("No case active in last 30 days")}</div>}
                    />
                    </div>
                </div>
        }
    }
}

export const KPI = ({title,value,icon,id}) => {
    if(value) value = readableNumber(Math.round(value))
    return <div class={{withSparkline:Array.isArray(value), kpi:true}}>
        <div id={title} class='icon'>{icon}</div>
        <div class='title'>{title}</div>
        <div class='value'>{
            value ? <><text id={id}>{value}</text><small>evts</small></>
            : <p>{t("Not deployed on")}<br/>{t("your scope")}</p>
        }</div>
        
    </div>
}

export const Sparkline = ({values}) => {
    const min = 0;
    const max = Math.max(...values)
    const d = "M0 100 L0 10 " + values.map((v,i)=>"L"+(i/values.length*32)+" "+((max-v)/(max-min)*10)+" ").join("") + " L32 100Z"
    return <div class="sparkline">
        <svg viewBox='1 0 29 10'><path d={d}/></svg>
    </div>
}
