import { ICON } from "./icons";
import { IconButton } from "./Button";
import './Topbar.scss'
import { Dropdown, DropdownItem } from "./Dropdown";
import { model, logout } from "../model";
import { BreadCrumb } from "./BreadCrumb";
import { Switch } from "./Switch";
import { Avatar } from "./Avatar";
import { Badge } from "./Badge";
import { UI } from "../App";
import { t, setLang } from "../i18n";
import { link, scopeLink } from "../utils/routing";
import { useCurrentUserStore } from "../store/CurrentUserStore";

const userStore = useCurrentUserStore

export const Topbar = () => {
    const userStore = useCurrentUserStore
    return <div id="top">
    <div>
        <BreadCrumb/>
    </div>
    <div>
        {UI.dbg && <Badge>DEBUG MODE <Switch value={UI.dbg} onClick={()=>{UI.dbg=!UI.dbg; localStorage.setItem("dbg", UI.dbg)}}></Switch></Badge>}
        <IconButton onClick={()=>setLang("en")}>{ICON("flag-icon en")}</IconButton>
        <IconButton onClick={()=>setLang("fr")}>{ICON("flag-icon fr")}</IconButton>
        {userStore.user && 
            <Dropdown button={<><div class='user'>{userStore.user.display_name} <i>{t(model.scope?.role || userStore.user?.global_role)}</i></div><Avatar user={userStore.user}/></>} items={()=><>
                <DropdownItem to={link('/profile')}>{t("Profile")}</DropdownItem>
                {userStore.hasPermissions("users", "impersonate") && model.scopeId && <DropdownItem to={scopeLink('/impersonate')}><i class="fas fa-user-ninja"/>{t("Impersonate")}</DropdownItem>}
                <DropdownItem onClick={logout}>{t("Sign out")}</DropdownItem>
            </>}/>
            }
        </div>
    </div>
}