import { reactive, watchEffect } from '@vue/runtime-core'
import { Dropdown, DropdownItem } from './Dropdown'
import { Input } from './Input'
import './FilteredDropdown.scss'
import { t } from '../i18n'
import { ICON } from './icons'
import { InProgressIcon } from './InProgressIcon'
import { debounce } from '../util'
import { ERR } from './Toasts'

export const FilteredDropdown = {
    props:["iconbutton", "button", "menuClass", "onSelect", "query", "render", "tooltip", "footer", 'disabled'],
    setup(props) {
        const data = reactive({open:false, filter:"", items:[], count:0, loading:false})

        const update = debounce(async ()=>{
            const render = props.render || (x=>x)
            try {
                const x = await props.query(data.filter)
                if(Array.isArray(x) || data.count===undefined) {
                    data.items = x
                    data.count = data.items.map(x=>render(x)).filter(x => x != "").length
                } else {
                    data.items = x.items
                    data.count = x.items.map(x=>render(x)).filter(x => x != "").length
                }
            } catch(e) {
                ERR("Error querying data")
                console.error(e)
            }
        }, 500)

        watchEffect(async ()=>{
            if(!data.open) return;
            const {filter} = data
            data.loading = true
            update()
            data.loading = false
        })

        return ()=>{
            const render = props.render || (x=>x)
            return <Dropdown
                    menuClass={props.menuClass + " combo filtered"}
                    title={props.tooltip}
                    disabled={props.disabled}
                    {...props.button ? {button:props.button} : {iconbutton:props.iconbutton}}
                    onOpen={()=>{data.open=true; data.filter=""}}
                    onClose={()=>{data.open=false;}}
                    top={props.query && 
                        <Input 
                            placeholder={t("Search...")} 
                            value={data.filter} 
                            onInput={e=>data.filter = e.target.value} 
                            pre={ICON("search")} 
                            post={data.loading ? <InProgressIcon/> : <small>{data.count} {t('results')}</small>}
                    />}
                    items={()=><>
                        {data.items.map(x=>render(x) && <DropdownItem onClick={(e)=>props.onSelect?.(x, e)}>{render(x)}</DropdownItem>)}
                        {props.footer}
                    </>}
            />
        }
    }
}
