import "./Footer.scss"
import { t } from "../i18n"
import { Button } from "./Button"

export const DiagFooter = {
    setup() {
        return () => <footer class="footer">
            <div className="body">
                <div className="center-side">
                    <div className="contact">
                        <span className="title">Contact</span>
                        <span id="email">contact@ct-square.com</span>
                        <a href="https://www.ct-square.com/solutions/analyse-et-prevention" target="_blank"><Button target="_blank">{t("Visit us at")}</Button></a>
                    </div>
                </div>
                <div className="left-side">
                    <img src="/img/logo-ct-diag.png"/>
                </div>
                <div className="right-side">
                    <span className="title">CT-SQUARE</span>
                    <span className="list">{t("Support")}</span>
                    <span className="list">{t("Diagnosis")}</span>
                    <span className="list">Pentest</span>
                </div>
            </div>
            <div class="legal">
                <p>&copy; 2023 CT-SQUARE. {t("All rights reserved")}.</p>
            </div>
      </footer>
    }
}