import { reactive } from '@vue/reactivity';
import { watchEffect } from '@vue/runtime-core';
import { t } from '../i18n';
import { Button } from './Button';
import './JSONEditor.scss'

export const JSONEditor = {
    props:["source", "onChange", "onSave", "onCancel"],
    setup(props) {
        const data = reactive({
            dirty:false
        })

        let editor = null;

        function init(el) {
            if(!el || editor) return;
            editor = new window.JSONEditor(el, {
                mode: 'tree',
                onChange:()=>{
                    props.onChange?.(editor.get())
                    data.dirty = true
                }
            })
            editor.set(props.source)
        }

        function save() {
            props.onSave?.(editor.get())
            data.dirty = false
        }

        function cancel() {
            editor.set(props.source)
            data.dirty = false
            props.onCancel?.()
        }

        watchEffect(()=>{
            const {source} = props
            editor?.set(source)
        })

        return ()=>{
            return <div>
            <div ref={init}>
            </div>
            <div>
                <Button disabled={!data.dirty} onClick={save}>{t("Save")}</Button>
                <Button secondary onClick={cancel}>{t("Cancel")}</Button>
            </div>
            </div>
        }
    }
}