import { LiveQuery } from "../../api/livequery"
import { t } from "../../i18n"
import { API, model } from "../../model"
import { Avatar } from "../../ui/Avatar"
import { optionsFromQueryParams } from "../../utils/queryParams"
import { getRouter } from "../../utils/router"
import './Impersonate.scss'

export const Impersonate = {
    setup(props) {
        async function impersonate(user_id) {
            await API.impersonate(user_id)
            document.location = getRouter().options.history.base
        }
        return ()=>{
            return <div id="impersonate">
                {model.members?.sort((a,b)=>a.role.localeCompare(b.role)).map(m=>
                <div class='user' onClick={()=>impersonate(m.id)}>
                    <Avatar user={m}/>
                    <div>{m.display_name}</div>
                    <div>{m.email}</div>
                    <div><b>{t(m.role)}</b></div>
                </div>)}
            </div>
        }
    }
}