import { API } from "../../model"
import { LiveQuery } from "../../api/livequery"
import { model } from "../../model"
import { optionsFromQueryParams } from "../../utils/queryParams"
import { ICON } from "../../ui/icons"
import { D } from "../../ui/dates"
import { modal } from "../../ui/Modal"
import { Dialog } from "../../ui/Dialog"
import { reactive } from "@vue/reactivity"
import { showAssetJSON } from "../Assets"
import { DataTable } from "../../ui/DataTable"
import { Button, IconButton } from "../../ui/Button"
import { Input } from "../../ui/Input"
import { t } from "../../i18n"
import { TagsMixin } from "../../mixins/Tags"
import { watch } from "@vue/runtime-core"
import { useCurrentUserStore } from "../../store/CurrentUserStore"


export const AccountsTable = {
    props:["onError", "refresh"],
    setup(props) {
        const userStore = useCurrentUserStore
        const lq = LiveQuery("assets", `{
            count, pages, items {
                type, data
            }
        }`, s => model.accounts = s,
            () => model.scope?.has_carto && [{
                scope_id: model.scopeId,
                type: "account",
                ...optionsFromQueryParams(),
            }, {scope_id: model.scopeId}],  
            {onError:props?.onError}
        )

        watch(()=>model.scopeId, ()=>{model.devices = null;})

        async function createAccount() {                
            const data = reactive({
                login: "",
                // first_name: "",
                // last_name: "",
            })

            const r = await modal(({close}) => 
                <Dialog close={close} onSubmit={() => close(data)}
                    className='dialog'
                    header={t('New account')}
                    body={<>
                            <Input label="Login" value={data.login || ""} onChange={x=>data.login=x.target.value}/>
                    </>}
                    buttons={<>
                        <Button secondary onClick={()=>close(null)}>{t("Cancel")}</Button>
                        <Button type="submit" >{t("OK")}</Button>
                    </>}
                />
            )
            if(!r) return;
            await API.createAsset("account", model.scopeId, data)
            lq?.refresh()
            props.refresh?.()
        }

        async function editAccount(x) {
            const data = reactive({
                "login": x.data.login || "",
            })
            const r = await modal(({close})=><Dialog 
                onSubmit={()=>close(data)}
                body={<>
                    <Input label={t("Login")} value={data.login} onChange={x=>data.login = x.target.value}/>
                </>}
                buttons={<>
                    <Button type="submit" onClick={()=>close(data)}>OK</Button>
                    <Button onClick={()=>close(null)}>{t("Cancel")}</Button>
                </>}
            />)
            if(!r) return;
            await API.updateAsset("account", x.data.id, model.scopeId, data)
            lq?.refresh()
        }
        
        async function deleteAccount(x) {
            await API.deleteAsset(x.data.id, model.scopeId, "account")
            lq?.refresh()
            props.refresh?.()
        }


        const {Tags} = TagsMixin("asset", {
            onAdd:(t,x)=>API.updateAsset("account", x.id, model.scopeId, {"tags":t}),
            onRemove:(t,x)=>API.updateAsset("account", x.id, model.scopeId, {"untag":t}),
            refresh:()=>lq?.refresh()
        })

        return ()=><DataTable {...props}            
            data={model.accounts}
            loading={lq.loading}

            header={
                userStore.hasPermissions("assets", "write_private") && <Button  onClick={createAccount} >{ICON("add")} {t("New account")}</Button>
            }

            columns={[
                {render: ()=>ICON('user'), type:"icon"},
                {title: t('Login'), render: x => <span>
                    {x.data.login}
                    {userStore.hasPermissions("assets", "write_private") && <IconButton class="hover" onClick={e=>{editAccount(x);e.stopPropagation();}}>{ICON("edit")}</IconButton>}
                </span>},
                {title: t('Tags'), type:"tags",render: x => Tags(x.data) },
                {title: t('Last seen'), render: x => <span>{D(new Date(x.data.last_seen))}</span>},
                {title: <>&nbsp;</>, type:'type', render: x => <>
                    <IconButton onClick={()=>showAssetJSON(x.data)}>{ICON("json")}</IconButton>
                    {userStore.hasPermissions("assets", "write_private") && <IconButton onClick={()=>deleteAccount(x)}>{ICON("trash")}</IconButton>}
                </>}
            ]}
        />
    }
}



