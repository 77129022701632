import { reactive, watchEffect } from "vue"
import { useRoute } from "vue-router"
import { t } from "../i18n"
import { model, resolveObjectName } from "../model"
import { capitalize, getMonth } from "../util"
import {link} from "../utils/routing"
import './BreadCrumb.scss'
import { useCurrentUserStore } from "../store/CurrentUserStore"

const userStore = useCurrentUserStore

export const BreadCrumb = {
    setup() {
        const ui = reactive({obj_type:null, obj_id:null, obj_name:"...", items:[]})
        const route = useRoute()

        watchEffect(()=>{
            if(!ui.obj_id || !ui.obj_type) return;
            ui.obj_name = "..."
            resolveObjectName({type:ui.obj_type,id:ui.obj_id}).then(name=>ui.obj_name=name)
        })
    
        const routeFor = (p) => ({
            "alert":"alerts",
            "recommendation":"recommendations",
            "case":"cases",
            "incident":"incidents",
            "scenario":"scenarios",
            "rule":"rules",
            "whitelist":"whitelists",
            "vulnerability":"vulnerabilities",
            "newscenario":"scenarios",
            "newrecommendation":"recommendations",
            "newalert":"alerts",
            "newuser":"users",
            "newcase":"cases",
            "newvulnerability":"vulnerabilities",
            "newincident":"cases",
            "user":"users",
            "ctsquare":"CT-Square",
        }[p] || p)

        watchEffect(()=>{
            const items = []
            const path = route.path.split("/").slice(1)
            items.push({name:"Scopes", to:"/"}) 
            if(model.scopeId) {
                const scope = model.scope
                if(!scope) return;
                items.push({name:<><img src={scope.logo} alt=''/>{scope.display_name}</>, to:link(`/scope/${scope.id}/`)})
                items.push({name:routeFor(path[3]), to:link(`/scope/${scope.id}/${routeFor(path[3])}`), disabled: path[3] === 'alert' && !userStore.hasPermissions("alerts", "read_private")})
                if(path[3]==="reports") {
                    ui.obj_type = "report"
                    if(path[5] && path[4]) items.push({name:`${t(getMonth(path[5]))} ${path[4]}`, to:route.path})
                }
                else if(path[4]) {
                    ui.obj_type = path[3]
                    ui.obj_id = path[4]
                    items.push({name:ui.obj_id, to:link(`/scope/${scope.id}/${path[3]}/${path[4]}`)})
                } else {
                    ui.obj_name = capitalize(t(routeFor(path[3])))
                    if(path[3]?.startsWith("new")) {
                        ui.obj_name = capitalize(t("New "+path[3].slice(3)))
                        items.push({name:ui.obj_name, to:link(`/scope/${scope.id}/${path[3]}`)})
                    }
                }
            }
            else if(route.name !== "/scopes") {
                ui.obj_type = null;
                if(path[1]?.startsWith("new")) {
                    ui.obj_name = capitalize(t("New "+path[1].slice(3)))
                    items.push({name:ui.obj_name, to:link(path[1])})
                }
                else if(path[1] === "rule" || path[1] === "whitelist") {
                    ui.obj_type = path[1]
                    items.push({name:routeFor(path[1]), to:link(routeFor(path[1]))})
                    items.push({name:path[2], to:link(`/${path[1]}/${path[2]}`)})
                }
                else if(route.name) items.push({name:route.name.replace("/", ""), to:route.name})
                else items.push({name:path[1], to:route.path})
            }
            ui.items = items
        })

    
        return () => {
            let title = "Astraia"
            if(model.scope?.display_name) {
                title += " | " + model.scope.display_name
                if(ui.obj_name) title += " - " + ui.obj_name

                if(ui.obj_type==="report" && model.year && model.month) 
                    title = `CT-Square_rapport_mensuel_${model.scope.display_name}_${t(getMonth(model.month))}_${model.year}`
            }
            document.title = title

            return <nav style="--bs-breadcrumb-divider: '/';" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    {ui.items.map(({name, to, disabled=false})=>
                        <li class="breadcrumb-item">
                            {disabled ?
                                <span>{t(name)}</span> :
                                <router-link to={to}>{t(name)}</router-link>
                            }
                        </li>
                    )}
                </ol>
            </nav>
        }
    }
}