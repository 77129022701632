import { t } from '../i18n';
import { ToolButton } from './Button'
import { ICON } from './icons';
import './JSONView.scss'
import { INFO } from './Toasts';

export const JSONView = {
    props:["source"],
    setup(props) {

        function copy(e) {
            navigator.clipboard.writeText(typeof(props.source)==='string' ? props.source : JSON.stringify(props.source, null, 2));       
            INFO(<>{ICON("ok")} &nbsp; {t("Copied to clipboard !")}</>)
            e.stopPropagation(); e.preventDefault();
        }

        return ()=>{
            const {source} = props
            return <>
            <div class='json'>
                <pre>
                <code>
                    {JSON.stringify(typeof(source)==="string" ? JSON.parse(source) : source, null, 4)}
                </code>
                </pre>
            </div>
            <ToolButton onClick={copy}>{ICON("fas fa-copy")}</ToolButton>
            </>
        }
    }
}