<script setup>
  import { setQueryParams } from "../utils/queryParams"
  import VueDatePicker from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css'
  import { ref, computed, watch } from 'vue'
  import { Button } from "../ui/Button"
  import { model } from "../model"
  import { ICONS_VUE } from "../ui/iconsVue"
  import "./DatePicker.scss"
  import { t } from "../i18n"

  // Ref is used to access and update the reactive reference dates
  const startDate = ref(localStorage.getItem('start') || "now-1d") 
  const endDate = ref(localStorage.getItem('end') || "now")

  // Other ref is used for datepicker
  const pickStart = ref(localStorage.getItem('start-at'))
  const pickEnd = ref(localStorage.getItem('end-at'))
  const isShow = ref(true)

  // Messages
  const message = ref(null)

  // Dynamic options
  const locale = computed(() => model.lang === "fr" ? "fr-FR" : "en-US")
  const formatDate = computed(() => model.lang === "fr" ? 'dd/MM/yyyy HH:mm' : "MM/dd/yyyy h:mm a")
  const is24HoursFormat = computed(() => model.lang === "fr")

  // Dynamic translations
  const today = computed(() => t("Today")) 
  const month = computed(() => t("A month"))
  const beginning = computed(() => t("Since beginning"))

  // Monitor [from & to], set storage and dates params
  watch([startDate, endDate], ([newS, newE]) => {
    if (!newS || !newE) return
    pickStart.value = null
    pickEnd.value = null

    localStorage.setItem('start', newS)
    localStorage.setItem('end', newE)
    localStorage.setItem('show', false)
    setQueryParams({from:newS, to:newE})
  })
  
  // // Monitor [from & to], set storage and dates params from datepicker
  watch([pickStart, pickEnd], ([newS, newE]) => {
    message.value = null
    startDate.value = null
    endDate.value = null
    localStorage.setItem('show', true)
    // if (!newE) setQueryParams({from:new Date(newS).toISOString(), to:"now"})
      
    if (newS || (newS && newE)) {
      localStorage.setItem('start-at', new Date(newS).toISOString())
      localStorage.setItem('end-at', new Date(newE).toISOString())
      setQueryParams({from:new Date(newS).toISOString(), to:new Date(newE).toISOString()})
    }
  })

  const setAbsolutDates = () =>{
    if (isShow.value) isShow.value = false

    localStorage.setItem('show', false)
  }
  
  const closeAbsoluteDates = (e) => {
    e.preventDefault()
    isShow.value = true
    localStorage.setItem('show', true)
  }
</script>

<template>
  <div class="date-filter">
    <div v-if="isShow" class="flex flex-row">
      <VueDatePicker 
      v-model="pickStart" 
      :locale="locale"
      :format="formatDate" 
      :is-24="is24HoursFormat" 
      text-input />
    
      <VueDatePicker 
      v-model="pickEnd" 
      :locale="locale" 
      :format="formatDate" 
      :is-24="is24HoursFormat" 
      text-input />
    </div>
    <div class="flex flex-col">
      <div class="calendar-absolute-option">
        <span v-if="!isShow" style="cursor: pointer;" @click="(e) => closeAbsoluteDates(e)" v-html="ICONS_VUE['calandar']"></span>
        <span v-if="!isShow">{{message}}</span>
      </div>
      <div class="buttons-box">
        <Button @click="startDate = 'now-1d', endDate = 'now', message = today, setAbsolutDates()">{{ today }}</Button>
        <Button @click="startDate = 'now-30d', endDate = 'now', message = month, setAbsolutDates()">{{ month }}</Button>
        <Button @click="startDate = '-', endDate = 'now', message = beginning, setAbsolutDates()">{{ beginning }}</Button>
      </div>
    </div>
  </div>
</template>
