import './AgentsSettings.scss'
import { t } from "../i18n/index"
import { Box, Grid } from '../ui/Grid'
import { model } from '../model'
import { Dropdown, DropdownItem } from '../ui/Dropdown'
import { onMounted, reactive } from 'vue'
import { API } from '../model'
import { Button } from '../ui/Button'
import { Spinner } from '../ui/Spinner'

export const AgentsSettings = {
    setup() {

        const data = reactive({
            scopes: model.scopes,
            loading: false,
            profiles: [],
            profileSelected: [],
            activateButton: []
        })

        const applyDefaultProfile = (scope_id) => {
            if(data.profileSelected[scope_id].get("windows"))
                API.applyAgentDefaultProfile(scope_id, data.profileSelected[scope_id].get("windows"), "windows")
            if(data.profileSelected[scope_id].get("linux"))
                API.applyAgentDefaultProfile(scope_id, data.profileSelected[scope_id].get("linux"), "linux")
            data.activateButton[scope_id] = false
        }

        
        onMounted(()=>{
            data.loading = true

            API.getAgentProfiles().then((profiles)=>{data.profiles = profiles})
            API.getAgentsDefaultProfiles().then((profile)=>{
                profile.map((p)=>{
                    if (!data.profileSelected[p.scope.id])
                        data.profileSelected[p.scope.id] = new Map()
                    data.profileSelected[p.scope.id].set(p.os, p.profile.name)
                })
                data.loading = false
            })
        })

        return () => {
            if (data.loading) return <Spinner/>
            return <div id="agents-settings">
                <h1>{t("Agents Settings")}</h1>
                <Grid>
                    {data.scopes?.map((scope) => {
                        return <Box class="scope-box" w="3">
                            <div class="box-header">
                                <img src={scope.logo} alt=''/>
                                <h2>{scope.display_name}</h2>
                            </div>
                            <span>
                                <label>{t("Windows")}</label>:
                                <Dropdown button={<>{data.profileSelected[scope.id]?.get("windows") ? data.profileSelected[scope.id].get("windows") : t("Profil")}</>} items={()=><>
                                    {data.profiles.map((p)=>{
                                        return <DropdownItem onClick={()=>{data.profileSelected[scope.id].set("windows", p.name);data.activateButton[scope.id]=true}}>{p.name}</DropdownItem>
                                    })}
                                </>}/>
                            </span>
                            <span>
                                <label>{t("Linux")}</label>
                                <Dropdown button={<>{data.profileSelected[scope.id]?.get("linux") ? data.profileSelected[scope.id].get("linux") : t("Profil")}</>} items={()=><>
                                    {data.profiles.map((p)=>{
                                        return <DropdownItem onClick={()=>{data.profileSelected[scope.id].set("linux", p.name);data.activateButton[scope.id]=true}}>{p.name}</DropdownItem>
                                    })}</>}/>
                            </span>
                            {console.log(!!!data.activateButton[scope.id])}
                            <span>
                                <Button secondary disabled={!!!data.activateButton[scope.id]} onClick={()=>applyDefaultProfile(scope.id)} class="button">{t("Apply")}</Button>
                            </span>
                        </Box>
                    })}
                </Grid>
            </div>
        }
    }
}