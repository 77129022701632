import { API } from "../../model"
import { LiveQuery } from "../../api/livequery"
import { model } from "../../model"
import { optionsFromQueryParams } from "../../utils/queryParams"
import { modal } from "../../ui/Modal"
import { Dialog } from "../../ui/Dialog"
import { reactive } from "@vue/reactivity"
import { watch } from "@vue/runtime-core"
import { showAssetJSON, sort_ip } from "../Assets"
import { DataTable } from "../../ui/DataTable"
import { Button, IconButton } from "../../ui/Button"
import { t } from "../../i18n"
import { ICON } from "../../ui/icons"
import { Badge } from "../../ui/Badge"
import { Box } from "../../ui/Grid"
import { Input } from "../../ui/Input"
import { TagsMixin } from "../../mixins/Tags"
import { useCurrentUserStore } from "../../store/CurrentUserStore"

export const IPRangesTable = {
    props:["onError", "refresh"],
    setup(props) {
        const userStore = useCurrentUserStore
        const lq = LiveQuery("assets", `{
            count, pages, items {
                type, data
            }
        }`, s => model.ipranges = s,
            () => model.scope?.has_carto && [{
                scope_id: model.scopeId,
                type: "iprange",
                ...optionsFromQueryParams(),
            }, {scope_id: model.scopeId}], 
            {onError:props?.onError}
        )

        watch(()=>model.scopeId, ()=>{model.devices = null;})


        async function createIPRange() {
            const data = reactive({
                description: "",
                cidr: "",
            })
        
            const r = await modal(({close}) => 
                <Dialog close={close} onSubmit={() => close(data)}
                        className='dialog'
                        header={t('New network')}
                        body={<>
                            <Input label="description" value={data.description} onChange={x=>data.description = x.target.value}/>
                            <Input label="cidr" value={data.cidr} onChange={x=>data.cidr = x.target.value} />
                        </>}
                        buttons={<>
                            <Button secondary onClick={()=>close(null)}>{t("Cancel")}</Button>
                            <Button type="submit" >{t("Add")}</Button>
                        </>}
                />
            )
            if(!r) return;
            await API.createAsset("iprange", model.scopeId, data)
            lq?.refresh()
            props.refresh?.()
        }

        async function editIPRange(x) {
            const data = reactive({
                "description": x.data.description || "",
                "cidr": x.data.cidr || "",
            })
            const r = await modal(({close})=><Dialog 
                onSubmit={()=>close(data)}
                body={<>
                    <Input label={t("Description")} value={data.description} onChange={x=>data.description = x.target.value}/>
                    <Input label={t("CIDR")} value={data.cidr} onChange={x=>data.cidr = x.target.value}/>
                </>}
                buttons={<>
                    <Button type="submit" onClick={()=>close(data)}>OK</Button>
                    <Button onClick={()=>close(null)}>{t("Cancel")}</Button>
                </>}
            />)
            if(!r) return;
            await API.updateAsset("iprange", x.data.id, model.scopeId, data)
            lq?.refresh()
        }
        
        const deleteIPRange = async (x) => {
            await API.deleteAsset(x.data.id, model.scopeId, "iprange")
            lq?.refresh()
            props.refresh?.()
        }


        const {Tags} = TagsMixin("asset", {
            onAdd:(t,x)=>API.updateAsset("iprange", x.id, model.scopeId, {"tags":t}),
            onRemove:(t,x)=>API.updateAsset("iprange", x.id, model.scopeId, {"untag":t}),
            refresh:()=>lq?.refresh()
        })

        return ()=><DataTable {...props}            
            data={model.ipranges}
            loading={lq.loading}

            header={
                userStore.hasPermissions("assets", "write_private") && <Button  onClick={createIPRange} >{ICON("add")} {t("New network")}</Button>
            }

            columns={[
                {render: ()=>ICON('network'), type:"icon"},
                {title: t('Description'), type:'description', render: x => <span>
                    {x.data.description}
                    {userStore.hasPermissions("assets", "write_private") && <IconButton class="hover" onClick={e=>{editIPRange(x);e.stopPropagation();}}>{ICON("edit")}</IconButton>}
                </span>},
                {title: t('CIDR'), render: x => <span>
                    {x.data.cidr}
                    {userStore.hasPermissions("assets", "write_private") && <IconButton class="hover" onClick={e=>{editIPRange(x);e.stopPropagation();}}>{ICON("edit")}</IconButton>}
                </span>},
                {title: t('IP addresses'), type:'ips', render: x => <ul>
                    {x.data.ips.sort(sort_ip).slice(0,5).map((ip, i) => <li>
                        {ip}
                        {x.data.ips.length>5 && i==4 && <Badge>+{x.data.ips.length-5}</Badge>}
                    </li>)}
                </ul>},
                {title: t('Tags'), type:"tags",render: x => Tags(x.data) },
                {title: <>&nbsp;</>, type:'type', render: x => <>
                    <IconButton onClick={()=>showAssetJSON(x.data)}>{ICON("json")}</IconButton>
                    {userStore.hasPermissions("assets", "write_private") && <IconButton onClick={()=>deleteIPRange(x)}>{ICON("trash")}</IconButton>}
                </>}
            ]}

            collapse={x=>x.data.ips.length>5 && <div class='fields'>
                <Box>
                    <h2>{ICON("ip")} {t("IP addresses")}</h2>
                    <ul class="columns-8">
                        {x.data.ips.sort(sort_ip).map(a => <li>{a}</li>)}
                    </ul>
                </Box>
            </div>}
        />
    }
}



