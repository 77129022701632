import "./TrackingDiag.scss"
import { model, API } from "../model"
import { reactive, ref, watch, watchEffect } from "vue"
import { ICONS } from "../ui/icons"
import { Button } from "../ui/Button"
import { Spinner } from "../ui/Spinner"
import { t, t_n } from "../i18n"
import { DiagResponsable } from "../ui/DiagResponsable"
import { UiBar } from "../ui/UiBar"
import { DiagFooter } from "../ui/DiagFooter"
import { formatDate, STATUS } from "../api/mock/util"
import { goTo } from "../utils/routing"
import { useCurrentUserStore } from "../store/CurrentUserStore"
import { ICON } from "../ui/icons"
import { FileButton, IconButton } from "../ui/Button"
import { DataTable } from "../ui/DataTable"
import { Upload } from "../ui/Upload"

const userStore = useCurrentUserStore

export const TrackingDiag = {
	setup() {
		model.onDiag = true
		const userStore = useCurrentUserStore
		document.body.addEventListener("click", () => {
			data.isOpen = false
			data.isDropDownShow = false
		})

		const RW = userStore.hasPermissions("diags", "write_private")

		const data = reactive({
			steps: model.diag?.steps,
			name: null,
			error: null,
			response: null,
			isDisplayedSub: false,
			isDisplayedPrim: false,
			inputValue: undefined,
			targetValue: -1,
			isDropDownShow: false,
			value: "",
			targetUuid: null,
			status: t("Not started"),
			isHiddenStatus: false,
			event: [],
			start: model.diag?.start_at || new Date().toISOString(),
			end: model.diag?.end_at || new Date().toISOString(),
			globalStatus: model.diag?.status,
			reports: []
		})

		watchEffect(() => {
			data.steps = model.diag?.steps
			data.globalStatus = model.diag?.status
			data.reports = model.diag?.reports
		})

		const addSection = async ({ prioSection, value, head = false, status = null }) => {
			const newElement = { value: value, date: "", status: "Hidden"}

			const steps = data.steps

			if (head) return addNewSection(value, steps)

			const allKeys = steps.flatMap((obj) => {
				if (!obj) return
				return Object.keys(obj)
			})

			if (allKeys?.includes(prioSection)) {
				let index = allKeys.indexOf(prioSection)

				if (status) {
					data.status = status

					if (data.status !== "Hidden") {
						data.isHiddenStatus = false
					}

					steps[index][prioSection].forEach((step) => {
						if (data.targetUuid === step["uuid"]) {
							step["status"] = status
							createEvent("Updated", `Change to ${status} in ${value.includes("recommendations") ? "recommendations link" : value} step`)
						}
					})

				} else if (status === null) {
					steps[index][prioSection].push(newElement)
					createEvent("Create", `Add ${value.includes("recommendations") ? "recommendations link" : value}`)
				}

				data.isDisplayedSub = false

				const response = await API.addSectionDiag({ id: model.diag.id, steps: JSON.stringify(steps), start_at: data.start, end_at: data.end })
				data.steps = response["steps"]
			}
		}

		const addNewSection = async (value, steps) => {
			const newSection = { [value]: [] }
			data.steps.push(newSection)
			data.steps = steps

			data.isDisplayedPrim = false
			await API.addSectionDiag({ id: model.diag?.id, steps: JSON.stringify(steps), start_at: data.start, end_at: data.end })
			createEvent("Created", `${value}`)
		}

		const updateSection = async ({ head, section, value, date = false }) => {
			data.targetUuid = section.uuid
			if (date) {
				section.date = value
				data.updateDate = false
				createEvent("Updated", `${value} on ${section.value}`)
			} else {
				section.value = value
			}
			data.isShow = false
			const response = await API.addSectionDiag({ id: model.diag.id, steps: JSON.stringify(data.steps), start_at: data.start, end_at: data.end })
			data.steps = response["steps"]
		}

		const removeSection = async ({ head, section, allSection = false, index }) => {
			if (allSection) {
				delete data.steps[index]

				const reOrdered = JSON.parse(JSON.stringify(data.steps)).filter(x => x !== null)
				data.steps = new Proxy(reOrdered, {})

			} else if (!allSection) {
				data.steps.forEach((el) => {
					if (!el) return

					if (el[head]) {
						const newList = el[head].filter((obj) => obj?.uuid !== section.uuid)
						createEvent("Removed", `Remove step ${section.value.includes("recommendations") ? "recommendations link" : section.value}`)
						el[head] = newList
					}
				})
			}

			const response = await API.addSectionDiag({ id: model.diag.id, steps: JSON.stringify(data.steps), start_at: data.start, end_at: data.end })
			data.steps = response["steps"]
		}

		const createEvent = async (action, title) => {
			const response = await API.diagEventCreate({ user_id: parseInt(userStore.user.id), diag_id: parseInt(model.diag.id), action: action, title: title })
			data.event.push(response)
		}

		watchEffect(() => data.event)

		const showDropDown = (e, uuid) => {
			e.preventDefault()
			data.isDropDownShow = true
			e.stopPropagation()
			if (uuid) data.targetUuid = uuid
		}

		const showUpdateInput = (uuid) => {
			if (uuid) data.targetUuid = uuid
			data.isShow = true
		}

		const caseStatue = (status) => {
			switch (status) {
				case "In progress":
					return ICONS["pending"]
				case "Finished":
					return ICONS["ok"]
				case "Not started":
					return ICONS["lock"]
				default:
					break;
			}
		}

		const displayLink = (value) => {
			const googleDriveDetect = new RegExp(/(https?:\/\/(?:drive|docs\.google)\.com\/[^\s/$.?#]+\S*)/gi)
			const url = value.split(" ").find(el => el.match(new RegExp(/https?:\/\/\S+|www\.\S+/gi)))

			if (value.split(" ").length > 1 && !url.match(googleDriveDetect)) {
				return <>{value.split(" ")[0]}<a href={url} target="_blank">{ICONS["external-link"]}</a></>

			} else if (value.split(" ").length > 1 && url.match(googleDriveDetect)) {
				return <>{value.split(" ")[0]}<a href={url} target="_blank">{ICONS["google-drive"]}</a></>

			} else if (url.match(googleDriveDetect)) {
				return <a href={url} target="_blank">{ICONS["google-drive"]}</a>

			} else {
				return <a href={url} target="_blank">{ICONS["external-link"]}</a>
			}
		}

		const changeColorButton = (status) => {
			switch (status) {
				case "Hidden":
					return "hidden"
				case "Not started":
					return "not-started"
				case "In progress":
					return "in-progress"
				case "Finished":
					return "finished"
				default:
					break;
			}
		}

		const getValue = (head, sections) => {
			const url = new RegExp(/https?:\/\/\S+|www\.\S+/gi)

			if (typeof sections === "string") return
			return sections.map((section) => {
				if (section === null) return
				return <div className="all-state-btn">
					<UiBar section={section} uuid={section?.uuid} />
					{RW && <span onClick={() => removeSection({ head: head, section: section })} className="trash-icon">{ICONS["remove"]}</span>}
					<div className="values">
						{caseStatue(section.status)}
						<div className={data.targetUuid === section.uuid && (data.isShow || data.updateDate) ? "d-none" : null}>
							{(section.status === "Hidden" && !RW) ? null : <span className={section.status === "Finished" ? "format-text" : null}>{section.value?.match(url) ? displayLink(section.value) : section.value?.includes("Recommendations") ? <div className="flex flex-row">Recommendations: <a href={`${window.location.protocol}//${window.location.host}/${model.lang}/scope/${model.scopeId}/recommendations`} target="_blank">{ICONS["recommendation"]}</a></div> : section.value}</span>}
						</div>
						{RW && section.status !== "Finished" && <span className={data.isShow && data.targetUuid === section.uuid ? "d-none" : "update-icon"} onClick={() => (showUpdateInput(section.uuid), data.updateDate = false)}>{ICONS["update"]}</span>}
						<div className="dates">
							<div className={data.targetUuid === section.uuid && (data.isShow || data.updateDate) ? "d-none" : null}>
								{(section.status === "Hidden" && !RW) ? null : section.date ? <span className={section.status === "Finished" ? "format-text" : null}>{formatDate({ timestamp: new Date(section.date), isGlobalDates: true, lang: model.lang })}</span> : null}
							</div>
							{RW && section.uuid && section.status !== "Finished" && <span className={data.updateDate && data.targetUuid === section.uuid ? "d-none" : "update-icon"} onClick={() => (data.updateDate = true, data.targetUuid = section.uuid, data.isShow = false)}>{ICONS["update"]}</span>}

							{!data.isShow && data.updateDate && data.targetUuid === section.uuid && <div className="edit-dates">
								<input type="date" id="datepicker" value={section.date} onInput={e => (data.inputValue = e.target.value)} />
								<span onClick={() => { updateSection({ head: head, section: section, value: data.inputValue !== undefined ? data.inputValue : section.date, date: true }); data.inputValue = undefined; }}>{ICONS["edit"]}</span>
								<span onClick={() => data.updateDate = false}>{ICONS["cross"]}</span>
							</div>}

						</div>

						{data.isShow && data.targetUuid === section.uuid && <div className={"edit-body"}>
							<input type="text" value={section.value} onInput={ e => { data.inputValue = e.target.value } } />
							<span onClick={() => { updateSection({ head: head, section: section, value: data.inputValue !== undefined ? data.inputValue : section.value }); data.inputValue = undefined; }}>{ICONS["edit"]}</span>
							<span onClick={() => data.isShow = false}>{ICONS["cross"]}</span>
						</div>}

					</div>
					<div className="dropdown-box">
						<div className={data.targetUuid === section.uuid && (data.isShow || data.updateDate) ? "d-none" : "flex flex-row gap-x-4"}>
							{RW && section.status === "Hidden" && <button id="state-btn" className={changeColorButton(section.status)} onClick={(e) => showDropDown(e, section.uuid)}>{ICONS["hide"]}</button>}
							{RW && section.status !== "Hidden" && <button id="state-btn" className={changeColorButton(section.status)} onClick={(e) => showDropDown(e, section.uuid)}>{section.status ? t(`${section.status}`) : t("Not started")}</button>}
						</div>
						{data.isDropDownShow && data.targetUuid === section.uuid && <div id="myDropdown" class="dropdown-content">
							<span onClick={() => (addSection({ prioSection: head, value: section.value, head: false, status: "Hidden" }))}>{t("Hidden")}</span>
							<span onClick={() => (addSection({ prioSection: head, value: section.value, head: false, status: "Not started" }), data.status = "Not started")}>{t("Not started")}</span>
							<span onClick={() => (addSection({ prioSection: head, value: section.value, head: false, status: "In progress" }), data.status = "In progress")}>{t("In progress")}</span>
							<span onClick={() => (addSection({ prioSection: head, value: section.value, head: false, status: "Finished" }), data.status = "Finished")}>{t("Finished")}</span>
						</div>}
					</div>
				</div>
			})
		}

		const toggleInput = (index) => {
			data.isDisplayedSub = true
			data.targetValue = index
		}

		function compareDates(a, b) {
			return new Date(a) - new Date(b);
		}

		const calculDays = (dates) => {
			if (dates?.length === 0) return
			const sortedDates = dates?.sort(compareDates)
			const filteredDate = sortedDates?.flat()?.filter((date) => date !== undefined);
			let milisecond = 0

			for (let i = 1; i < filteredDate?.length; i++) {
				const diff = new Date(filteredDate[i]) - new Date(filteredDate[i - 1])
				milisecond += diff
			}
			const sumDays = milisecond / (1000 * 60 * 60 * 24)
			return sumDays
		}

		const getLengthOfDiag = () => {
			const result = data.steps?.map((step) => {
				if (!step) return
				const keys = Object.keys(step)

				return step[keys.toString()].map(v => {
					if (v?.date == undefined || v?.date.length === 0) return

					const year = parseInt(v?.date.split("-")[0])
					const month = parseInt(v?.date.split("-")[1]) - 1
					const day = parseInt(v?.date.split("-")[2])
					return new Date(year, month, day)
				})
			})

			const filteredDate = result?.filter((date) => date !== undefined);
			return calculDays(filteredDate)
		}

		const headerState = (step, headerValue) => {
			if (step[headerValue].length === 0) return "head-section"

			let statusSection = []
			step[headerValue].forEach((v) => {
				statusSection.push(v.status)
			})

			const cleanList = [...new Set(statusSection)]
			const inProgress = cleanList.includes("In progress") || cleanList.includes("Not started")

			if (inProgress && !(cleanList.length === 1 && cleanList[0] === "Not started")) {
				return 'progress-line'
			} else if (cleanList.length === 1 && cleanList[0] === "Finished") {
				return 'finish-line'
			} else if (cleanList.length === 1 && cleanList[0] === "Not started") {
				return 'not-started-line'
			}
		}

		const setDatesDiag = async () => {
			try {
				const response = await API.addSectionDiag({ id: model.diag.id, steps: JSON.stringify(data.steps), start_at: data.start, end_at: data.end })
				if (response["start_at"]) {
					data.start = response["start_at"]
				}
				if (response["end_at"]) {
					data.end = response["end_at"]
				}
				data.error = null

			} catch (error) {
				data.error = error
			}
		}

		async function updateStatus({ status }) {
			const response = await API.updateStatusDiag({ id: model.diag.id, status: parseInt(status) })
			data.globalStatus = response["status"]
		}

		const reportInput = ref()

		const deleteFile = async (file) => {
            if (confirm("You are about to delete " + file.filename + " !\nAre you sure ?")) {
                const formData = new FormData();
                formData.append('file', file.url);
                await fetch("/files", {
                    method: 'DELETE',
                    body: formData
                })
				// await new Promise(r => setTimeout(r, 2000));
				data.reports = (await API.getDiagReports({id:model.diagId, scope_id:model.scopeId})).reports

            }
        }

		return () => {
			if (!model.diag) return <Spinner />
			return <><div className="diag">
				<h1 className="diag-title">{data.name}</h1>
				{RW && <Button onClick={() => goTo("/audits")}>Audits</Button>}
				<section className="steps-diags">
					<div className="left-side">
						<div className="meta-datas">
							<h2>Infos</h2>
							<div className="assigned">
								<span>{t("Auditor")}:</span>
								<DiagResponsable diag={model.diag.id} scope={model.scopeId} user={userStore.user} />
							</div>
							<div className="diags-length">
								<div>
									{data.globalStatus ? <span className={`badge ${STATUS[data.globalStatus]}`}>{t(data.globalStatus)}</span> : null}
									{RW && <div>
										<button id="state-btn" className={`${STATUS[data.globalStatus]}`} onClick={(e) => (e.stopPropagation(), data.isOpen = true)}>{t("State")}</button>
										{data.isOpen && <div id="myDropdown" class="dropdown-content">
											<span onClick={() => (updateStatus({ status: 0 }))}>{t("Not started")}</span>
											<span onClick={() => (updateStatus({ status: 1 }))}>{t("In progress")}</span>
											<span onClick={() => (updateStatus({ status: 2 }))}>{t("Finished")}</span>
										</div>}
									</div>}
								</div>
								{RW && <div>{!getLengthOfDiag() ? null : <span className="length badge">{t("Length")} : {t_n(getLengthOfDiag(), "day")}</span>}</div>}
							</div>
							<div className="list-actions"></div>
							<div className="start-end-date">
								<div className="start-date">
									<span>{t("Start")} : {data.start ? formatDate({ timestamp: new Date(data.start), isGlobalDates: true, lang: model.lang }) : formatDate({ timestamp: new Date(model.diag?.start_at), isGlobalDates: true, lang: model.lang })}</span>
									{RW && <input type="date" id="start" onInput={e => (data.start = e.target.value)} onChange={() => setDatesDiag()} />}
								</div>
								<div className="end-date">
									<span>{t("End")} : {data.end ? formatDate({ timestamp: new Date(data.end), isGlobalDates: true, lang: model.lang }) : formatDate({ timestamp: new Date(model.diag?.end_at), isGlobalDates: true, lang: model.lang })}</span>
									{RW && <input type="date" id="end" onInput={e => (data.end = e.target.value)} onChange={() => setDatesDiag()} />}
								</div>
							</div>
							{data.error && <span className="error-dates">{data.error.message}</span>}
							<div className="reports">
								<span>Documents</span>
								<DataTable noHeader
										data={data.reports||[]}
										columns={[
											{title:"Name",render:x=><>
													<a href={`/files/${x.url}`} download>
													{ICON("document")} {x.filename}
													</a>
													{RW && <IconButton onClick={()=>deleteFile(x)}>{ICON("minus")}</IconButton>}
												</>
											}
										]}
								/>
								{RW && <div class="upload-report">
                                <input placeholder={t("Filename")} value={data.report_name}
                                       onChange={(x) => {
                                           data.report = new File([data.report], x.target.value)
                                           data.report_name = x.target.value
                                       }}
                                />
                                <FileButton icon={ICON("plus")} ref={reportInput} onChange={(x) => {
                                    data.report = x.target.files[0]
                                    if (!data.report_name) data.report_name = x.target.files[0].name
                                }}/>
                                {data.report && <Upload files={[data.report]}
                                                        params={[{"scope_id": model.scopeId}, {"diag_id": model.diag.id }, {"kind": "diag-report"}]}
                                                        afterUpload={async () => {
                                                            data.report = undefined
                                                            data.report_name = undefined
															await new Promise(r => setTimeout(r, 2000));
															data.reports = (await API.getDiagReports({id:model.diagId, scope_id:model.scopeId})).reports
                                                        }}
                                />}
                            </div>
                            }
							</div>
						</div>
						{/* <StepDiag event={data.event} /> */}
					</div>
					{model.diag ? <div className="box-diag">
						<div>
							{data.error && <div>{data.error}</div>}
							{data.steps && data.steps.map((step, index) => {
								if (!step) return

								const section = Object.entries(step).flat()

								let lastIndex = null;

								for (let i = data.steps.length - 1; i >= 0; i--) {
									if (data.steps[i] !== null) {
										lastIndex = i
										break
									}
								}

								const isLast = index === lastIndex

								return <section className="section">
									<div className="all-state-btn">
										<div className={headerState(step, section[0])}></div>
										{RW && <span onClick={() => removeSection({ head: section[0], section: section, allSection: true, index: index })} className="trash-icon">{ICONS["cross"]}</span>}
										<span className="head-section">{section[0]}</span>
									</div>
									<div className="sub-sections">
										<div className="indent">{getValue(section[0], section[1])}</div>
										<div className="indent">
											{RW && <button id="btn-add" class={data.targetValue === index && data.isDisplayedSub ? "d-none" : "add-sub-sections"} onClick={() => toggleInput(index)}>{ICONS["cross"]}</button>}
											{RW && data.isDisplayedSub && data.targetValue === index && <div className="input-section">
												{!data.isInternalLink && <input type="text" onInput={e => (data.inputValue = e.target.value)} />}
												<Button onClick={() => addSection({ prioSection: section[0], value: data.inputValue })}>{ICONS["plus"]}</Button>
												<span onClick={() => data.isDisplayedSub = false}>{ICONS["cross"]}</span>
											</div>}
										</div>
									</div>

									<div className={!isLast ? "d-none" : ""}>
										{RW && !data.isDisplayedPrim && <button class="add-sections" onClick={() => data.isDisplayedPrim = true}>{ICONS["cross"]}</button>}
										{RW && data.isDisplayedPrim && <div className="input-primary-section">
											<input type="text" onInput={e => (data.inputValue = e.target.value)} />
											<Button onClick={() => addSection({ prioSection: section[0], value: data.inputValue, head: true })}>{ICONS["plus"]}</Button>
											<span onClick={() => data.isDisplayedPrim = false}>{ICONS["cross"]}</span>
										</div>}
									</div>
								</section>
							})}
						</div>
					</div> : <Spinner />}
				</section>
			</div>
				<DiagFooter />
			</>
		}
	}
}

