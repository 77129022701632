import { API, LQ, model } from "../model"
import { DataTable } from "../ui/DataTable"
import { optionsFromQueryParams, queryParams, setQueryParams, watchQueryParams } from "../utils/queryParams"
import {t, t_fem, t_n} from "../i18n"
import { LiveQuery } from "../api/livequery"
import {goTo, link} from "../utils/routing"
import { AddButton } from "../ui/AddButton"
import { FilteredDropdown } from "../ui/FilteredDropdown";
import { Badge } from "../ui/Badge";
import {capitalize} from "../util";
import {Link} from "../utils/router";
import { Button, IconButton } from "../ui/Button"
import { Tabs, Tab } from "../ui/Tabs"
import { ICON } from "../ui/icons"
import { InProgressIcon } from "../ui/InProgressIcon"
import { Histogram } from "../ui/Histogram"
import { reactive, onUnmounted } from "@vue/runtime-core"
import { UI } from "../App"
import { convert_histogram_series } from "../ui/dates"
import { ago_to_date } from "../ui/Age"
import { Ellipsis } from "../ui/Ellipsis"
import { Dropdown, DropdownItem } from "../ui/Dropdown"
import { PALETTE } from "../ui/ui"
import { Checkbox } from "../ui/Checkbox"
import './Vulnerabilities.scss'
import { useCurrentUserStore } from "../store/CurrentUserStore"

const userStore = useCurrentUserStore

export function CVSS_COLOR(cvss) {
    if (cvss === 0) return "#aaa";
    else if (cvss < 4)  return "#fdc500";
    else if (cvss < 7) return '#fd8c00';
    else if (cvss < 9) return '#dc0000';
    else return '#780000';
}

export const Vulnerabilities = {
    setup() {

        const data = reactive({
            loading: false,
            vulns_by_status:null,
            selected: {},
            selectedScopes: {}
        })

        const lq = LiveQuery("vulnerabilities", `{
            count, pages, items {
                created_at, updated_at,
                id, cve_id, title, description, exploitation, original, scopes {
                    scope { id, display_name }
                    status
                    notified
                },
                score
                status
                notified
                scopes_by_status
                nb_products, short_products, cvss, modified
            }
        }`, s=>model.vulnerabilities = s,
            ()=>[{
                scope_id:model.scopeId,
                status:queryParams().status==="all" ? undefined : (queryParams().status || (model.scopeId ? "exposed" : "open,new")),
                limit: parseInt(queryParams().limit) || 20,
                ...optionsFromQueryParams(),
            }, {scope_id:model.scopeId}]
        )

        const lq_vulns_by_status = LiveQuery("vulnerabilities_by_status", ``, 
            x=>data.vulns_by_status = x, 
            ()=>[{scope_id:model.scopeId}], 
            {subscription: "vulnerabilities"}
        )

        function selectedVulns({id}){
            if(data.selected[id]){
                delete data.selected[id]
            }
            else{
                data.selected[id] = true
            }
            
        }



        function newVulnerability() { goTo("newvulnerability") }

        async function toggleVulnDelete(x) {
            await API.updateVulnerability(x.id, {deleted:x.status!=="deleted"})
            LQ.vulnerabilities_histogram?.refresh()
            LQ.scope_vulnerabilities_histogram?.refresh()    
            lq.refresh()    
        }

        const getScopes = filter => model.scopes?.filter(s=>
            s.display_name.startsWith(filter) &&
            !model.vulnerability?.scopes?.find(sv=>sv.scope.id === s.id)
        )

        async function setScopeVulnerabilityStatus(vuln, status) {
            await API.setScopeVulnerabilityStatus(vuln.id, model.scopeId, {status})
        }

        async function notify(vuln) {
            await API.setScopeVulnerabilityStatus(vuln.id, model.scopeId, {notified:true})
        }

        function getSelection() {
            const scope_ids = []
            for(const k in data.selectedScopes) {
                if(data.selectedScopes[k]) scope_ids.push(k)
            }
            return scope_ids
        }

        async function addVulnsScopes(){
            data.loading = true
            let vulnerabilities = []
            for(const v in data.selected){
                vulnerabilities.push(v)
            }
            const scopes = getSelection()
            await API.setScopesVulnerabilitiesStatus(vulnerabilities, scopes, {status:"exposed"})
            data.loading = false
            data.selected = {}
            data.selectedScopes = {}
            lq.refresh()
            lq_vulns_by_status.refresh()
        }

        return ()=>{
            return <div id="vulnerabilities">
                <h1>
                    {t("Vulnerabilities")}
                </h1>
                <div class="histograms">
                    {model.scopeId ? <ScopeVulnerabilitiesHistogram key={model.scopeId}/> : <VulnerabilitiesHistogram key={model.scopeId}/>}
                </div>

                <DataTable
                    loading={lq.loading || data.loading}
                    headerLeft={<div>                        
                        {!model.scopeId && <><Tabs>
                            <Tab active={!queryParams().status}
                                label={<span>{t_fem("To fix", {nb: 1})}<Badge>{(data.vulns_by_status?.open || 0) + (data.vulns_by_status?.new || 0)}</Badge></span>}
                                onClick={()=>setQueryParams({status: null, page: 1})}
                            />
                            <Tab active={queryParams().status === "closed"}
                                label={<span>{t_fem("vuln:Closed", {nb: 2})}<Badge>{data.vulns_by_status?.closed || 0}</Badge></span>}
                                onClick={()=>setQueryParams({status: "closed", page: 1})}
                            />
                            <Tab active={queryParams().status === "deleted"}
                                label={<span>{t_fem("Ignored", {nb: 2})}<Badge>{data.vulns_by_status?.deleted || 0}</Badge></span>}
                                onClick={()=>setQueryParams({status: "deleted", page: 1})}
                            />
                            <Tab active={queryParams().status === "all"}
                                label={<span>{t_fem("All")}<Badge>{Object.values(data.vulns_by_status||{}).reduce((s,x)=>s+x,0)}</Badge></span>}
                                onClick={()=>setQueryParams({status:"all", page: 1})}
                            />
                        </Tabs>
                        </>}

                        {model.scopeId && <Tabs>
                            <Tab active={!queryParams().status}
                                label={<span>{t_fem("To fix", {nb:2})}<Badge>{(data.vulns_by_status?.exposed || 0) + (data.vulns_by_status?.notified || 0)}</Badge></span>}
                                onClick={()=>setQueryParams({status: null, page: 1})}
                            />
                            <Tab active={queryParams().status === "patched"}
                                label={<span>{t_fem("Patched", {nb:2})}<Badge>{data.vulns_by_status?.patched || 0}</Badge></span>}
                                onClick={()=>setQueryParams({status: "patched", page: 1})}
                            />
                            <Tab active={queryParams().status === "no_impact"}
                                label={<span>{t_fem("Without impact")}<Badge>{data.vulns_by_status?.no_impact || 0}</Badge></span>}
                                onClick={()=>setQueryParams({status: "no_impact", page: 1})}
                            />
                            <Tab active={queryParams().status === "all"}
                                label={<span>{t_fem("All")}<Badge>{Object.values(data.vulns_by_status||{}).reduce((s,x)=>s+x,0)}</Badge></span>}
                                onClick={()=>setQueryParams({status: "all", page: 1})}
                            />
                        </Tabs>}

                    </div>}

                    header={!model.scopeId && <div class="header-buttons">
                        <AddButton onClick={newVulnerability}>{t("New Vulnerability")}</AddButton>
                        {userStore.hasPermissions('scopes', "read_private") && <FilteredDropdown 
                    class="selected-scopes"
                    button={<>{ICON("add")}Add scopes</>}
                    disabled={Object.keys(data.selected).length === 0}
                    query={getScopes} 
                    onSelect={({id},e)=>{data.selectedScopes[id]=!data.selectedScopes[id]; e.stopPropagation(); e.preventDefault();}}
                    render={x=><div>
                        <Checkbox value={data.selectedScopes[x.id]}/>
                        <img src={x.logo}/>
                        {x.display_name}
                    </div>} 
                    footer={<Button className="btn btn-primary filter-dropdown-button" disabled={!getSelection()?.length} onClick={addVulnsScopes}><i class="fas fa-check"/></Button>}
                />}
                    </div>}

                    data={model.vulnerabilities}
                    columns={[
                        ...(userStore.hasPermissions('vulnerabilities', 'write_private') ? [{title:<>&nbsp;</>, type:'', render:x=><Checkbox id={x.id} value={data.selected[x.id] ? true : false} onClick={(x)=>selectedVulns(x.target)}/>}] : []),
                        {title:t('Collected'), type:'date', render:x=>x.created_at, sort:'created_at'},
                        {title:t('Published'), type:'date', render:x=>x.original.Published, sort:'published_date'},
                        {title:t('Updated'), type:'date', render:x=>x.original.Modified, sort:'modified_date'},
                        {title:t("Status"), type:'status', render:x=>
                            !model.scopeId ? (
                                x.status==="new" ? <>{ICON("star")}<span>{t_fem("New")}</span></> 
                                : x.status==="deleted" ? t_fem("Ignored")
                                : x.status==="open" ? <><InProgressIcon/><span>{t("vuln:Open")}</span></>
                                : <>{ICON("done")} {t_fem("vuln:Closed")}</>
                            ) : ScopeVulnerabilityStatus(x, setScopeVulnerabilityStatus)
                        },

                        model.scopeId && {
                            render:x=><NotifyVuln notify={notify} vuln={x}/>
                        },
                        
                        {title:t('Description'), type:'description', render:x=> <div>
                            <div><Link href={link(model.scopeId ? `scope/${model.scopeId}/vulnerability/${x.id}` : `vulnerability/${x.id}`)}><b>{x.cve_id} {x.title && x.title!==x.cve_id && <>- {x.title}</>}</b></Link></div>
                            {!!x.description && <Ellipsis>{x.description}</Ellipsis>}
                            {/* {!!get_products(x)?.length && <div><small><b>{t("Impacted products")}</b> {get_products(x)?.map(x=><Badge>{x}</Badge>)}</small></div>} */}
                        </div>},
                        
                        !model.scopeId && {title:t('Scopes'), type:'scopes', render:x=>
                            !x.scopes?.length ? t("none") :
                            <>
                                {x.scopes_by_status.exposed && <Badge color='red'>{t_n(x.scopes_by_status.exposed, "to fix")}</Badge>}
                                {x.scopes_by_status.notified && <Badge color='red'>{t_n(x.scopes_by_status.notified, "vuln:notified")}</Badge>}
                                {x.scopes_by_status.no_impact && <Badge color='#aaa'>{t_n(x.scopes_by_status.no_impact, "vuln:no_impact")}</Badge>}
                                {x.scopes_by_status.patched && <Badge color='green'>{t_n(x.scopes_by_status.patched, "vuln:patched")}</Badge>}
                            </>
                        },

                        {title:t('Products'), render:x=> <>
                        <span class="products">
                            {x.short_products?.map(p => <span>{ICON("software")}{capitalize(p.replace('_', ' '))}</span>)}
                            {(x.short_products?.length < x.nb_products) &&  <Badge>+{x.nb_products - x.short_products?.length}</Badge>}
                        </span>
                        </>},
                        
                        {title:t('Exploitation'), render:x=><Badge class={x.exploitation?.replace(/\s+/g, '') || "unknown"}>{capitalize(t_fem(x.exploitation || "unknown"))}</Badge>},
                        {title:t('CVSS score'), type:'score', render:x=><Badge color={CVSS_COLOR(x.score)}>{(x.score||0).toFixed(1)}</Badge>},
                        
                        userStore.hasPermissions('vulnerabilities', 'write_private') && {type:'delete', render:x=>
                            x.status==="deleted" ? <IconButton title={t('Restore')} onClick={()=>toggleVulnDelete(x)}>
                                 <i class="fas fa-trash-restore"/>
                             </IconButton>
                            : x.status==="new" && <IconButton title={t('Ignore')} onClick={()=>toggleVulnDelete(x)}>
                                <i class="fas fa-trash"/>
                            </IconButton>
                        }
                    ]}
                    defaultSort={{sort: "created_at"}}
                />
            </div>
            }
    }
}


const VulnerabilitiesHistogram = {
    setup(props) {
        const data=reactive({
            histogram:null,
            interval:intervalFromQueryParams(),
        })

        watchQueryParams(()=>{
            data.interval = intervalFromQueryParams()
        })

        const colors = {
            "closed": '#57bf57',
            "open": 'red',
            "deleted": '#cdcdcd',
            "new": '#ffce00',
        }

        const labels = {
            "open": t("vuln:Open"),
            "closed": t_fem("vuln:Closed", {nb:2}),
            "new": t_fem("new", {nb:2}),
            "deleted": t_fem("Ignored", {nb:2}),
        }

        function update(d) {
            const [x,y] = convert_histogram_series(d || {})
            data.histogram = {
                labels:x,
                datasets: ["deleted", "closed", "open", "new"].map(s=>({
                    label: capitalize(labels[s]||s),
                    backgroundColor: colors[s],
                    data:y[s],
                    stack:0
                })),
            }
        }

        UI.enable_active_polling = true

        LQ.vulnerabilities_histogram = LiveQuery("vulnerabilities_histogram", "", update, ()=>[{
                scope_id:model.scopeId, 
                ...props,
                ...data.interval,
            }], 
            { subscription: 'vulnerabilities'}
        )

        return ()=><Histogram stacked dates data={data.histogram}/>
    }
}

const ScopeVulnerabilitiesHistogram = {
    setup(props) {
        const data=reactive({
            histogram:null,
            interval:intervalFromQueryParams(),
        })

        watchQueryParams(()=>{
            data.interval = intervalFromQueryParams()
        })

        const colors = {
            "patched": '#57bf57',
            "no_impact": '#cdcdcd',
            "exposed": PALETTE.incident,
        }

        const labels = {
            "patched": t_fem("patched", {nb:2}),
            "no_impact": t("No impact"),
            "exposed": t_fem("to fix", {nb:2}),
        }

        function update(d) {
            const [x,y] = convert_histogram_series(d || {})
            data.histogram = {
                labels:x,
                datasets: ["exposed", "patched", "no_impact"].map(s=>({
                    label: capitalize(labels[s]||s),
                    backgroundColor: colors[s],
                    data:y[s],
                    stack:0
                })),
            }
        }

        UI.enable_active_polling = true

        LQ.scope_vulnerabilities_histogram = LiveQuery("vulnerabilities_histogram", "", update, ()=>[{
                scope_id:model.scopeId, 
                ...props,
                ...data.interval,
            }], 
            {subscription: 'vulnerabilities'}
        )

        return ()=><Histogram stacked dates data={data.histogram}/>
    }
}

function intervalFromQueryParams() {
    function absolute(d) {
        if(!d) return undefined;
        if(d==="-") return null
        if(d==="now") return new Date()
        if(d.includes?.("ago")) return ago_to_date(d)
        return new Date(d)
    }
    let from = queryParams()?.from
    let to = queryParams()?.to
    return {from:absolute(from), to:absolute(to)}    
}



export function CVSS_EXPLOITABILITY(x) {
    let vector = {}
    x?.split('/').forEach(y => {
        let [k, v] = y.split(':')
        vector[k] = v
    })

    let [AccessVector, AccessComplexity, Authentication] = [0, 0, 0]

    switch (vector['AV']) {
        case 'L':
            AccessVector = 0.395;
            break;
        case 'A':
            AccessVector = 0.646;
            break;
        case 'N':
            AccessVector = 1.0;
            break;
    }

    switch (vector['AC']) {
        case 'H':
            AccessComplexity = 0.35;
            break;
        case 'M':
            AccessComplexity = 0.61;
            break;
        case 'L':
            AccessComplexity = 0.71;
            break;
    }

    switch (vector['Au']) {
        case 'M':
            Authentication = 0.45;
            break;
        case 'S':
            Authentication = 0.56;
            break;
        case 'N':
            Authentication = 0.704;
            break;
    }

    return Math.round(20 * AccessVector * AccessComplexity * Authentication * 10)/10
}

export function CVSS_IMPACT(x) {
    let vector = {}
    x?.split('/').forEach(y => {
        let [k, v] = y.split(':')
        vector[k] = v
    })

    let [ConfImpact, IntegImpact, AvailImpact] = [0, 0, 0]

    switch (vector['C']) {
        case 'N':
            ConfImpact = 0.0;
            break;
        case 'P':
            ConfImpact = 0.275;
            break;
        case 'C':
            ConfImpact = 0.660;
            break;
    }

    switch (vector['I']) {
        case 'N':
            IntegImpact = 0.0;
            break;
        case 'P':
            IntegImpact = 0.275;
            break;
        case 'C':
            IntegImpact = 0.660;
            break;
    }

    switch (vector['A']) {
        case 'N':
            AvailImpact = 0.0;
            break;
        case 'P':
            AvailImpact = 0.275;
            break;
        case 'C':
            AvailImpact = 0.660;
            break;
    }

    return Math.round((10.41 * (1 - (1 - ConfImpact) * (1 - IntegImpact) * (1 - AvailImpact)))*10)/10
}


const ScopeVulnerabilityStatus = (x, onChange) => {
    const Status = (
        x.status==="patched" ? <>{ICON("done")}<span>{t_fem("Patched")}</span></> 
        : x.status==="no_impact" ? <>{t_fem("No impact")}</>
        : <><InProgressIcon/><span>{t_fem("To fix")}</span></>
    )
    if(userStore.hasPermissions('vulnerabilities', 'write_private')) return <>
        <Dropdown class="scope-vulnerability-status" button={Status} items={()=><>
            {x.status !== "notified" && <DropdownItem onClick={()=>onChange(x, "exposed")}>{t_fem('To fix')}</DropdownItem>}
            <DropdownItem onClick={()=>onChange(x, "no_impact")}>{t_fem('No impact')}</DropdownItem>
            <DropdownItem onClick={()=>onChange(x, "patched")}>{t_fem('Patched')}</DropdownItem>                                       
        </>}/>
    </>
    return Status
}

export const NotifyVuln = ({vuln, notify}) => vuln.notified ? <>{ICON("mail-check")} {t_fem("Notified")}</> : <Button class='notify' onClick={()=>notify(vuln)}>{ICON("mail")} {t("Notify")}</Button>