export const CATEGORY = {
  "AD":" Sécurité de l'Active Directory",
  "ADMI": "Sécurité de l'administration",
  "AUTH": "Authentification et contrôle des accès",
  "CLOU": "Sécurité des applications Cloud",
  "HR": "Sensibilisation, formation et gestion des ressources humaines",
  "KNOW": "Connaissance du système d'information",
  "NET": "Sécurité du réseau",
  "NOMA": "Gestion du nomadisme",
  "SARI": "Audit, supervision et réponse à incident",
  "SERV": "Sécurité des postes et des serveurs",
  "SUPP": "Gestion des fournisseurs et des interconnexions",
  "UPDA": "Maintien à jour du système d'information",
  "WEB": "Sécurité des applications Web"
}