import {model,API} from "../model"
import {modal} from "../ui/Modal";
import { t, t_fem } from "../i18n"
import { reactive, watch } from "@vue/runtime-core"
import {LiveQuery} from "../api/livequery"
import "./Assets.scss"
import {ICON} from "../ui/icons";
import {Dialog} from "../ui/Dialog";
import {JSONView} from "../ui/JSONView";
import { Badge } from "../ui/Badge"
import {Tab, Tabs} from "../ui/Tabs"
import { queryParams, setQueryParams } from "../utils/queryParams"
import { ERR } from "../ui/Toasts";
import { graphql } from "../api/graphql";
import { DevicesTable } from "./assets/DevicesTable";
import { IPRangesTable } from "./assets/IPRangesTable";
import { AccountsTable } from "./assets/AccountsTable";
import { gotoScope } from "../utils/routing";


export const Assets = {
    setup() {
        const data = reactive({})

        const lq = LiveQuery("assets_stats", ``, x=>{
            data.error = false;
            model.assets_stats = x;
        }, () => model.scope?.has_carto && [{scope_id:model.scopeId}], {
            onError:()=>{}
        })        

        watch(()=>model.scopeId, ()=>{model.assets_stats = null;})

        function refresh() {
            lq?.refresh()
        }

        return () => {
            if(model.scope && !model.scope?.has_carto) return gotoScope("/")

            const tab = queryParams().status || "devices"

            const AssetsTable = {
                "devices": DevicesTable,
                "ipranges": IPRangesTable,
                "accounts": AccountsTable,
            }[tab]

            if(!AssetsTable) graphql.is404 = true

            return <div id="assets">
                <div class='header'>
                    <h1>{ICON('asset')} {t("Assets")}</h1>
                </div>
                {data.error ? <h3 class="feature-disabled">
                    {ICON("settings")} {t("Can't contact cartography service")}
                </h3> 
                :
                <AssetsTable
                    headerLeft={
                        <div>
                        <Tabs>
                            <Tab active={tab==="devices"} onClick={()=>setQueryParams({status:undefined, page:undefined})} label={<span>{t("Devices")} <Badge>{model.assets_stats?.device}</Badge></span>}/>
                            <Tab active={tab==="ipranges"} onClick={()=>setQueryParams({status:"ipranges", page:undefined})} label={<span>{t("Networks")} <Badge>{model.assets_stats?.iprange}</Badge></span>}/>
                            <Tab active={tab==="accounts"} onClick={()=>setQueryParams({status:"accounts", page:undefined})} label={<span>{t("Accounts")} <Badge>{model.assets_stats?.account}</Badge></span>}/>
                        </Tabs>
                        </div>
                    }

                    onError={()=>{
                        ERR("Impossible de contacter le service de cartographie")
                        data.error = true
                    }}

                    refresh={refresh}
                />}
            </div>
        }
    }
}


export function showAssetJSON(x) {
    return modal(({close}) =>
        <Dialog close={close} onSubmit={()=>close(null)}
                className="dialog"
                header={t("JSON Asset")}
                body={<JSONView source={x}/>}
        />
    )
}

export const sort_ip = (a,b) => a.split(".").map(x=>x.padStart(3,"0")).join("") - b.split(".").map(x=>x.padStart(3,"0")).join("")