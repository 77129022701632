import { reactive, watchEffect, toRaw, watch } from "vue";
import { useRoute } from "vue-router";
import {LiveQuery} from './api/livequery'
import { t } from "./i18n";
import { getMonth } from "./util";
import { goTo, scopeLink } from "./utils/routing";
import { getRouter } from "./utils/router";
import { ERR } from "./ui/Toasts";
import { useCurrentUserStore } from "./store/CurrentUserStore";

const userStore = useCurrentUserStore

export const API = require(CONFIG.MOCK ? "./api/mock" : "./api/api")

export const model = reactive({
    lang:'fr',
    user:false,
    token:null,
    config:{},
})

export const resolveObjectName = async ({type,id}) => ({
    alert:async ()=>model.scopeId && model.alertGroupId && (await API.getAlertGroup({id,scope_id:model.scopeId}))?.message,
    case:async ()=>model.scopeId && model.caseId && (await  API.getCase({id,scope_id:model.scopeId}))?.title,
    agent:async ()=>model.scopeId && model.agentId && (await  API.getAgent({id, scope_id:model.scopeId}))?.hostname, 
    incident:async ()=>model.scopeId && model.incidentId && (await  API.getIncident({id,scope_id:model.scopeId}))?.title,
    scenario:async ()=>model.scopeId && model.scenarioId && (await  API.getScenario({id,scope_id:model.scopeId}))?.title,
    rule: async ()=> model.ruleId && (await  API.getRule({rule_id}))?.title,
    whitelist: async ()=> model.whitelistId && (await  API.getWhitelist({whitelist_id}))?.title,
    vulnerability:async ()=>model.vulnerabilityId && (await  API.getVulnerability({id}))?.title,
    recommendation:async ()=>model.scopeId && model.recommendationId && (await  API.getRecommendation({id,scope_id:model.scopeId}))?.title,
    finding:async ()=>model.scopeId && model.findingId && (await  API.getFinding({id,scope_id:model.scopeId}))?.title,
    reports:async ()=>t(getMonth(model.month)) + " " + model.year,
    scopeUser: async()=>model.scopeId && (await API.getScopeUsers({id, scope_id:model.scopeId}))?.title,
}[type] || (()=>""))()
        

// Login

export async function logout() {
    if(!localStorage.getItem("token")) return;
    localStorage.removeItem("token")
    await API.logout()
    userStore.updateUserQuery(null)
    document.location = getRouter().options.history.base
}

export async function login(email, password) {
    const {token} = await API.login(email, password) || {}
    if(token) await connect(token)
    else {
        model.token = localStorage.removeItem("token")
        throw new Error()    
    }
}


export async function connect(token) {
    const userStore = useCurrentUserStore
    try {
        const u = await userStore.setUserData()
        if(!u) throw new Error()
        localStorage.setItem("token", true)
        model.token = true
    }
    catch(e) {
        model.token = localStorage.removeItem("token")
        userStore.updateUserQuery(null)
        goTo("/login")
    }
}

export const getMembers = ({search=""}={}) => model.members?.filter(m=>m.display_name?.toLowerCase().includes(search.toLowerCase()))


// Tags


// Get valid tags for the given scope and object type (nulls allowed). Only keep the finest scoping/typing for each distinct name
export const getTags = (scope_id, taggable_type) => Object.values(
    Object.fromEntries([
        ...model.tags?.[null]?.[null]||[], 
        ...model.tags?.[null]?.[taggable_type]||[], 
        ...model.tags?.[scope_id]?.[null]||[], 
        ...model.tags?.[scope_id]?.[taggable_type]||[], 
    ].map(t=>([t.name, t])))
)

export const getAllTagsForType = (taggable_type) => Object.fromEntries(
    model.all_tags.filter(t=>t.taggable_type===taggable_type || t.taggable_type===null)
    .map(t=>[t.name, t])
)


// Fast links

export const getFastLinks = async (search, types) => (await API.getObjects({search, scope_id:model.scopeId, type:types}))
        .map(({id,type,title,message})=>{
            if(type==="alert_group") { type = "alert"; title = message }
            return '#['+type+" #"+id+" "+title?.replace(/[\(\)]/g, "\\$0")+"]("+document.location.protocol + "//" + document.location.host + scopeLink(type + "/" + id)+")"
        })

// Live queries

export const LQ = {}

export async function init_livequeries() {
    const route = useRoute()
    const userStore = useCurrentUserStore
    watchEffect(()=>{
        model.lang = route?.params.lang || 'fr'
        if(!userStore.user) return;
        if(model.scopes?.length<2 && !userStore.hasPermissions("scopes", "noscope")) model.scopeId = model.scopes?.[0]?.id
        else model.scopeId = route?.params.scopeId
        model.caseId = route?.params.caseId
        model.recommendationId = route?.params.recommendationId
        model.findingId = route?.params.findingId
        model.scenarioId = route?.params.scenarioId
        model.alertId = route?.params.alertId
        model.agentId = route?.params.agentId
        model.alertGroupId = route?.params.alertGroupId
        model.incidentId = route?.params.incidentId
        model.vulnerabilityId = route?.params.vulnerabilityId
        model.iocId = route?.params.iocId
        model.year = route?.params.year
        model.month = route?.params.month
        model.ruleId = route?.params.ruleId
        model.whitelistId = route?.params.whitelistId
        model.diagId = route?.params.diagId

        if (!!userStore.user && !!model.caseId && !!model.scopeId) {
            LQ.case = LiveQuery("case", `{
                id, title, body, conclusion,  
                type, tags, status, created_at, updated_at, last_activity, is_public,
                conclusion_code{name,description,code}, notified,
                comments { id, body, visibility, created_at, updated_at, tags, seen, notified,
                    author { id, display_name, avatar, email },
                    files { filename, url },
                },
                related_users {id display_name}
                severity
            }`, 
                c=>model.case=c, 
                ()=>!!userStore.user && !!model.caseId && !!model.scopeId && 
                    [{id:model.caseId, scope_id:model.scopeId}, {id:model.caseId}]
            )
        }

        if (model.diagId || !!model.onDiag) {
            LQ.diag = LiveQuery("diag", `{
                id, steps, status, related_users {id, display_name}, start_at, end_at, reports {filename, url}
            }`, 
                s=>model.diag=s, 
                ()=>!!userStore.user && [{id:model.diagId, scope_id:model.scopeId}]
            )
        }
    })
    
    LQ.current_user = LiveQuery("current_user", `{ 
        id, display_name, avatar, email, global_role, settings, permissions
    }`, 
        u=>userStore.updateUserQuery(u),
        ()=>!!model.token, 
        { interval: 0 }
    )

    LQ.messages_stats = LiveQuery("messages_stats", "", s=>model.messages_stats = s, ()=>!!userStore.user && [{scope_id:model.scopeId}])

    LQ.scopes = LiveQuery("scopes", `{
        items { id, display_name, logo, locked, kibana_space, role }
    }`, 
        s=>model.scopes=s?.items || s, 
        ()=>!!userStore.user && [{limit:100, sort: "display_name"}, null], 
        { interval: 0 },
    )

    LQ.scope = LiveQuery("scope", `{
        id, display_name, scope_identifier, logo, role, kibana_space, magellan_url, has_carto, locked, tags, api_token, shuffle_url, shuffle_key
    }`, 
        s=>model.scope=s, 
        ()=>!!userStore.user && !!model.scopeId && [{id:model.scopeId}]
    )

    LQ.alert_group = LiveQuery("alert_group", `{
        id, scope_id, message, tags, status, alerts_count, severity, first_detection, last_detection, conclusion_code{name,description,code},
        rule_description, case{title,id}, rule {alert_sections}
    }`, 
        c=>model.alertGroup=c, 
        ()=>!!userStore.user && !!model.alertGroupId && !!model.scopeId && 
            [{id:model.alertGroupId, scope_id:model.scopeId}, {id:model.alertGroupId}], 
        {throttle:2000}
    )

    LQ.agent = LiveQuery("agent", `{
        id, uuid, scope_id, hostname, last_seen, created_at,
        last_complete_status, status, profile_name, visible 
        }`, 
        c=>model.agent=c, 
        ()=>!!userStore.user && !!model.agentId && model.scopeId &&
            [{id:model.agentId, scope_id:model.scopeId}, {id:model.agentId}],
        {interval:2000}
    )

    LQ.alert = LiveQuery("alert", `{
        id, message, original, detection_date, prev{id}, next{id}, nb_siblings, rank
    }`, 
        c=>model.alert=c, 
        ()=>!!userStore.user && !!model.alertGroupId && !!model.alertId && 
            [{id:model.alertId, alert_group_id:model.alertGroupId}, {id:model.alertId}]
    )

    LQ.scenario = LiveQuery("scenario", `{
        id, scope_id, title, body, status,  tags, created_at, updated_at, is_public,
        comments { id, body, visibility, created_at, updated_at, seen, notified,
            author { id, display_name, avatar, email },
            files { filename, url },
        }
    }`, 
        c=>model.scenario=c, 
        ()=>!!userStore.user && !!model.scenarioId && !!model.scopeId && 
            [{id:model.scenarioId, scope_id:model.scopeId}, {id:model.scenarioId}]
    )

    LQ.rule = LiveQuery("rule", `{
        name,
        rule_id,
        description,
        severity,
        date,
        status,
        fields,
        tags,
        rule_body,
        falsepositives,
        references,
        git_edit_url,
        groups,
        detection,
        alerts_count,
        conclusion_codes,
        alert_sections,
        git_url,
        whitelists {
            whitelist_id,
            scope_id,
            description,
        },
        message,
    }`,c=>model.rule=c,()=>!!userStore.user && !!model.ruleId && [{rule_id:model.ruleId}])

    LQ.whitelist = LiveQuery("whitelist", `{
        whitelist_id,
        name,
        whitelist_body,
        description,
        scope_id,
        git_edit_url,
        rule_uuids,
        conditions,
        deleted,
        set, 
        git_url,
        rules {
            name,
            rule_id,
        }
    }`,c=>model.whitelist=c,()=>!!userStore.user && !!model.whitelistId && [{whitelist_id:model.whitelistId}])
        
    LQ.recommendation = LiveQuery("recommendation", `{
        id, title, body, control, refs, difficulty, cost, priority, tags, status, created_at, updated_at, is_public,
        comments { id, body, visibility, created_at, updated_at, seen, notified,
            author { id, display_name, avatar, email },
            files { filename, url }
        }
        related_findings { items { assets } }
    }`, 
        c=>model.recommendation=c, 
        ()=>!!userStore.user && !!model.recommendationId && !!model.scopeId && 
            [{id:model.recommendationId, scope_id:model.scopeId}, {id:model.recommendationId}]
    )

    LQ.finding = LiveQuery("finding", `{
        id, kraken_id, title, body, impact, criticity, docs, assets, tags, created_at, updated_at, is_public, category, category_id, template_id 
        comments { id, body, visibility, created_at, updated_at, seen, notified
            author { id, display_name, avatar, email },
            files { filename, url }
        }
    }`, 
        c=>model.finding=c, 
        ()=>!!userStore.user && !!model.findingId && !!model.scopeId && 
            [{id:model.findingId, scope_id:model.scopeId}, {id:model.findingId}]
    )

    LQ.report = LiveQuery("report", `{
        id, cases, kpis, cases_page_break, is_public, notified,
        cases_by_status, cases_by_type, recos_by_status,
        coordination, control_points, vulnerabilities, summary,
        legacy_vulnerabilities, vulnerabilities_version, vulnerabilities_page_break,
    }`, 
        x=>model.report=x, 
        ()=>!!userStore.user && !!model.scopeId && !!model.year && !!model.month && 
            [{month:model.month, scope_id:model.scopeId, year:model.year}],
        {interval: 0}
    )

    LQ.members = LiveQuery("scope", `{
        members{id, display_name, avatar, activated, locked, email, role, created_at, last_login}
    }`, 
        c=>model.members=c?.members, 
        ()=>!!userStore.user && !!model.scopeId && 
            [{id:model.scopeId}]
    )

    LQ.drilldowns = LiveQuery("drilldowns", ``, 
        c=>model.drilldown=c?.map(({id, data})=>({id,...JSON.parse(data)})), 
        ()=>userStore.hasPermissions("drilldowns", "read_private")
    )

    LQ.vulnerability = LiveQuery("vulnerability", `{
        id, cve_id, title, description, remediation, exploitation, original, status
        scopes { status, notified, scope { id, display_name, logo } }
    }`,
        c=>model.vulnerability=c,
        ()=>!!userStore.user && !!model.vulnerabilityId &&
            [{id:model.vulnerabilityId}, {id:model.vulnerabilityId}]
    )

    LQ.ioc = LiveQuery("ioc", `{
        ioc, details, alert_groups(page:1){
            count
            pages
            items {
                id, scope_id, message, scope_id, tags, severity, first_detection, last_detection, status, alerts_count, source, destination,
                rule_description, case{title,id},
                conclusion_code{name,description,code}
            }
        }
    }`,
        c=>model.ioc=c,
        ()=>!!userStore.user && !!model.iocId && [{id:model.iocId}, {id:model.iocId}], 
        {onError:()=>ERR(t("Can't reach the IOC provider service"))}
    )

    LQ.tags = LiveQuery("tags", `{
        id, name, description, color, taggable_type, scope_id, deleted
    }`, 
        tags=>{ 
            model.all_tags = tags; 
            // Fast index lookup
            const x = {}
            for(const t of tags) {
                if(!x[t.scope_id]) x[t.scope_id] = {}
                if(!x[t.scope_id][t.taggable_type]) x[t.scope_id][t.taggable_type] = []
                x[t.scope_id][t.taggable_type].push(t)
            }
            model.tags = x
        },
        ()=>!!userStore.user && [],
        {interval:0, throttle:2000}
    )
}
